.m_top5 {
    margin-top: 50px;
}

.m_top1 {
    margin-top: 10px;
}

.m_top2 {
    margin-top: 20px;
}

.div-card,
.div-right-card {
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: 0px 0px 5px 0px #ddd;
    padding: 15px;
    margin-bottom: 15px;
}

.div-card p {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 0px;
}

.div-card h4,
.div-right-card h4 {
    font-weight: 700;
}

.img-cart {
    width: 100%;
}

.div-card .div-desc p {
    font-size: 14px;
    margin-bottom: 0px;
    font-weight: 400;
}

.btn-payment {
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 5px 10px;
    width: 100%;
}

.plans .plan {
    cursor: pointer;
    width: 98%;
}

.plans .plan input[type="radio"] {
    position: absolute;
    opacity: 0;
}

.plans .plan .plan-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 10px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border: 2px solid #e1e2e7;
    border-radius: 10px;
    -webkit-transition: -webkit-box-shadow 0.4s;
    transition: -webkit-box-shadow 0.4s;
    -o-transition: box-shadow 0.4s;
    transition: box-shadow 0.4s;
    transition: box-shadow 0.4s, -webkit-box-shadow 0.4s;
    position: relative;
}

.plans {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    /*   background: #fff; */
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.plans .plan .plan-content img {
    height: 30px;
}

.img-fluid {
    max-width: 100%;
    height: auto;
}

.mb-2 {
    margin-bottom: 0.5rem !important;
}

.form-control,
.form-control:focus,
.plans .plan input[type="radio"]:checked+.plan-content {
    border-color: #64748b;
}

.plans .plan input[type="radio"]:checked+.plan-content {
    /* border: 2px solid #216ee0;
    background: #eaf1fe;
    -webkit-transition: ease-in 0.3s;
    -o-transition: ease-in 0.3s;
    transition: ease-in 0.3s; */
}

.plans .plan input[type="radio"]:checked+.plan-content:after {
    content: "";
    position: absolute;
    height: 20px;
    width: 20px;
    background: #216fe0;
    right: 20px;
    top: 20px;
    border-radius: 100%;
    border: 3px solid #fff;
    -webkit-box-shadow: 0px 0px 0px 2px #0066ff;
    box-shadow: 0px 0px 0px 2px #0066ff;
}

.btn-acc-link.btn-link {
    padding: 20px;
    width: 100%;
    text-align: left;
}

.div-col {
    padding:14px;
	font-size: 14px;
}

.collapsed {
    cursor: pointer;
    border-bottom: 1px solid #ddd;
    background-color: #216fe0;
}

.btn-payment:focus,.btn-payment:active{
	
	border:medium none !important;
	outline: none !important;
}

.div-order-items img {
    /* max-width: 50px; */
    border-radius: 5px;
    margin-right: 5px;
    max-height: 30px;
}

.div-name-variation p {
    margin-left: 5px;
    font-weight: 600;
    margin-top: -2px;
    font-size: 13px;
}

.div-order-items p {
    margin-bottom: 0px;
}

.div-img-name {
    display: flex;
}
.free_checkout {
    font-size: 12px;
    font-weight: 500;
}
@media only screen and (max-width: 599px) {
    .d-none-mob {
        display: none;
    }

    .mob-m0 {
        margin-left: 0px !important;
        margin-right: 0px !important;
    }

    .div-arrow-align {
        margin-top: 15px;
        text-align: right;
    }
	.myordpamt{
		font-size:13px;
		
	}
}