.m_bot1{
	
	margin-bottom:10px;
	
}	
.m_bot15{
	
	margin-bottom:15px;
	
}
.m_bot2{
	
	margin-bottom:20px;
	
}
.m0 {
    margin-left: 0px !important;
    margin-right: 0px !important;
}
body {
    overflow-x: hidden;
}
.div-all-rewards {
    text-align: center;
    padding-top: 15px;
    padding-bottom: 15px;
    color:#fff;
}

strong {
    font-weight: 600 !important;
}
.m_bot3 {
    margin-bottom: 30px;
}
.pr0 {
    padding-right: 0px !important;
}
.pl0 {
    padding-left: 0px !important;
}
.pt10 {
    padding-top: 10px;
}
.m0 {
    margin-left: 0px;
    margin-right: 0px;
}

.p18per {
    padding-top: 18%;
}

.p7per {
    padding-top: 7%;
}

.btn_not {
    letter-spacing: unset !important;
    text-transform: inherit !important;
}
/* media query for screen width 320 */
@media only screen and (max-width: 359px) and (min-width: 320px) {
    .m0 .m_bot2 {
        padding-left: 2px;
        padding-right: 2px;
    }
}

.div-pop-desc {
    text-transform: none !important;
    color: #fff !important;
}

.btn-pop-close {
    background-color: transparent !important;
    border: 0px !important;
    color: #fff !important;
    font-size: 16px !important;
}

.btn-back-arrow {
    color: #fff !important;
    position: absolute;
    top: 0px;
    right: 10px;
    font-size: 26px !important;
}

.div-reward-res {
    width: 414px;
    margin: auto;
}

@media (max-width: 500px) {
    .div-reward-res {
        width:100%;
    }
}

/* Template 1 */
.div-template-one{
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    min-height: 200px;
}
.div-template-one .div-template-header {
    background-color: #85502A;
    padding: 10px 15px;
}
.div-template-one .div-template-header span {    
    font-weight: 600;
    font-size: 18px;
    color: #fff;
    letter-spacing: 1.5px;
   
}
.div-template-one .div-template-footer {
    background-color: #F1F1F1;
    padding: 15px;
    width: 100%;
    position: absolute;
    bottom: 0px;
}
.div-template-one .div-template-footer button {
    font-size :10px;
   
    letter-spacing: 1.5px;
    color: #876A54;
    border: 1px solid #876A54;
    background-color: transparent;
    padding: 4px 8px;
}
.div-template-one .div-template-footer span {
    float: right;    
    letter-spacing: 1.2px;
    color: #876A54;
    font-weight: 800;
    margin-top: 2px;
    font-size: 14px;
   
}
.div-template-redeemed{
    display:none;
}
.redeemed .div-template-redeemed {
    background-color: #0000007F;
    z-index: 1;
    width: 100%;
    min-height: 200px;
    position: absolute;
    top: 0px;
    left: 0px;
    display: block;
    text-align: center;    
}
.redeemed .div-template-redeemed i {
    color:#00d25b;
    margin-top:70px;
    font-size: 40px;
}

.div-template-one .btn-info {
    float: right;
    border: 0px;
    background-color: transparent;
}
.div-template-description{
    background-color:#000000BF;
    z-index: 1;
    width: 100%;
    min-height: 200px;
    position: absolute;
    top: 0px;
    left: 0px;
    padding:70px 20px 20px;
    color:#fff;
    margin: auto;
    text-align: center;    
}

.div-template-visit-popup {
    background-color:#000000BF;
    z-index: 99;
    /* position: absolute; */
    position: fixed;
    width: 100%;
    top: 0px;
    left: 0px;
    bottom:0px;
}

.div-template-popup {
    width: 90%;
    background-color: #191c24;
    min-height: 150px;
    margin: auto;
    margin-top: 20%;
    border-radius: 15px;
    position:relative;
}

.div-template-popup p.div-pop-desc {
    width:100%;
    top: 60px;
    text-align: center;
    position: absolute;
}

.div-template-description .btn-pop-close, .div-template-visit-popup .btn-pop-close {
    position: absolute;
    z-index: 2;
    right: 15px;
    top: 10px;
    border:0px;
    background-color: transparent;
    color:#fff;
}
.btn-info:focus, .btn-info:active {
    border: 0px !important;
    outline: 0 !important;
    background-color: transparent !important;
    box-shadow: 0 0 0 0rem rgba(58,176,195,.5) !important;
}
.div-template-description .btn-fiveclose {
   width: 12px;
   height: 12px;
}

/* Template 2 */
.div-template-two{
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    min-height: 200px;
}

.div-template-two .div-template-footer {
    background-color: #E71C22;
    padding: 15px;
    width: 100%;
    position: absolute;
    bottom: 0px;
}
.div-template-two .div-template-footer button {
    font-size :10px;
   
    letter-spacing: 1.5px;
    color: #E71C22;
    /*border: 1px solid #E71C22;*/
    background-color: #fff;
    padding: 4px 8px;
    float:right;
    border-radius: 3px;
    margin-top: 10px;
}
.div-template-two .div-template-footer span {
    letter-spacing: 1.2px;
    color: #FFFFFF;
   
}
.div-template-two .div-template-footer span.item-name {
    font-weight: 300;
}
.div-template-two .div-template-footer span.item-points {    
    font-weight: 700;
}
.div-template-two .btn-info {
    margin-left: 10px;
    margin-top: 10px;
    border: 0px;
    background-color: transparent;
}

/* Template 3 */
.div-template-three{
    border-radius: 15px;
    position: relative;
    overflow: hidden;    
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;    
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    min-height: 200px;
}
/* .div-template-three img {
    max-width:100%;
} */
.div-template-three button {
    font-size :10px;
   
    letter-spacing: 1.5px;
    color: #028950;
    border: 1px solid #028950;
    background-color: #fff;
    padding: 4px 8px;    
    border-radius: 3px;    
}
.div-template-three button:hover, .div-template-three button:focus {
    color: #028950;
    background-color: #fff;
    border: 1px solid #028950;
}
.div-template-three span {    
    color: #FFFFFF;
   
}
.div-template-three span.item-name {
    letter-spacing: 1.2px;
    font-weight: 300;
    /* width:100%; */
    position: absolute;
    top:15px;
    left:40px;
}
.div-template-three .col-5 div.div-re-points {
    width:100%;
    position: absolute;
    bottom:15px;
    left:40px;
}

.div-template-three span.item-points {
    font-size: 9px;
    display: block;
}
.div-template-three span.item-points strong {
    font-size: 14px;
}
/*Right pointing*/
.div-template-three .col-5 div.triangle-right {
    width: 100%;    
    height: 0;
    border-top: 100px solid #028950;
    border-left: 50px solid transparent;
    border-bottom: 100px solid #028950;
}

.div-template-three .btn-info {
    margin-left: 10px;
    margin-top: 10px;
    border: 0px;
    background-color: transparent;
    font-size: unset;
}

/* media query for screen width 414 */
@media only screen and (max-width: 599px) and (min-width: 411px) {
    .div-template-three .btn_not {
        left: -10px;
        position: relative;
    }
}
/* media query for screen width 375 */
@media only screen and (max-width: 410px) and (min-width: 375px) {
    .div-template-three .btn_not {
        left: -20px;
        position: relative;
    }
}
/* media query for screen width 360 */
@media only screen and (max-width: 374px) and (min-width: 360px) {
    .div-template-three .btn_not {
        left: -30px;
        position: relative;
    }
}
/* media query for screen width 320 */
@media only screen and (max-width: 359px) and (min-width: 320px) {
    .div-template-three .btn_not {
        left: -30px;
        position: relative;
    }
}

/* Template 4 */
.div-template-four{
    border-radius: 15px;
    position: relative;
    overflow: hidden;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color: #F6399B;    
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    min-height: 200px;
}
/* .div-template-four img {
    max-width:100%;
} */
.div-template-four button {
    font-size :10px;
   
    letter-spacing: 1.5px;
    color: #F6399B;
    border: 1px solid #F6399B;
    background-color: #FFF301;
    padding: 4px 8px;    
    border-radius: 3px;    
}
.div-template-four button:hover, .div-template-four button:focus {
    color: #F6399B;
    background-color: #FFF301;
    border: 1px solid #F6399B;
}
.div-template-four span, .div-template-four p {    
    color: #FFFFFF;
   
}
.div-template-four span.item-name {
    letter-spacing: 1.2px;
    font-weight: 500;
}
.div-template-four p.item-points-text {
    font-size: 9px;
}
.div-template-four p.item-points {
    font-size: 14px;
    margin-bottom: 0px;
    font-weight: 600;
}
.div-template-four .col-5 div {
    width:100%;
    position: absolute;
    bottom:15px;    
}
.div-template-four .col-5 {
    padding-left: 30px;
    height:200px;
    background-color: #F6399B;
}
.div-template-four .col-7 .triangle-right {
	width: 0;
	height: 0;
	border-top: 7px solid transparent;
	border-left: 8px solid #F6399B;
    border-bottom: 7px solid transparent;
    margin-top: 93px;
}

.div-template-four .btn-info {
    margin-left: 10px;
    margin-top: 10px;
    border: 0px;
    background-color: transparent;
    font-size: unset;    
    position: absolute;
    right:25px;
    top: 0px;
}

/* Template 5 */
.div-template-five{
    border-radius: 15px;
    position: relative;
    overflow: hidden;    
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;    
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    min-height: 200px;
}
.div-template-five button {
    font-size :10px;
   
    letter-spacing: 1.5px;
    color: #2F0A50;
    border: 1px solid #2F0A50;
    background-color: #FFF301;
    padding: 4px 8px;    
    border-radius: 3px;
    position: absolute;
    bottom: 10px;
    right: 20px;
}
.div-template-five button:hover, .div-template-five button:focus {
    color: #2F0A50;
    background-color: #FFF301;
    border: 1px solid #2F0A50;
}
.div-template-five p {    
    color: #FFF301;
   
}
.div-template-five span {    
    color: #FFF;
   
}
.div-template-five span.item-name {
    letter-spacing: 1.2px;
    font-weight: 500;
    width:70%;
    position: absolute;
    top:35%;
    left:20%;
}
.div-template-five .div-points {
    position: absolute;
    top:10px;
    right:30px;
}
.div-template-five .div-points p {
    font-size: 9px;
    margin-bottom: 0px;
}
.div-template-five .div-points p strong {
    font-size: 14px;
}

/*Right pointing*/
.div-template-five .col-6 div.half-circle {
    width: 100%;
    height: 200px;
    background-color: #2F0A50;
    border-top-left-radius: 30% 50%;
    border-bottom-left-radius: 30% 50%;    
    border-bottom: 0;
}

.div-template-five .btn-info {
    margin-left: 10px;
    margin-top: 10px;
    border: 0px;
    background-color: transparent;
    font-size: unset;
    position: unset;
}

/* Template 6 */
.div-template-six{
    border-radius: 15px;
    position: relative;
    overflow: hidden;    
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    min-height: 200px;
    background-color: #FF7369;
}

.div-template-six .div-template-child {
    background-color: #fff;
    margin: 12px;
    border-radius: 15px;
    min-height: 176px;
}
.div-template-six .div-template-child div { 
    width:100%;
    text-align: center;
    padding-top: 25px;
}
.div-template-six .div-template-child div p.item-name {
    color: #FF7369;
    font-family: 'Pacifico', cursive;
    font-size: 20px;    
}
.div-template-six .div-template-child div p.item-points {
   
    letter-spacing: 1.2px;
    font-weight: 700;
    margin-top: 20px;
    color:#333;   
}

.div-template-six button {
    font-size :10px;
   
    letter-spacing: 1.5px;
    color: #fff;
    border: 1px solid #FF7369;
    background-color: #FF7369;
    padding: 6px 12px;    
    border-radius: 3px;
    margin-top: 15px;
}
.div-template-six button:hover {
    background-color: #FF7369;
    color: #fff;
    border: 1px solid #FF7369;
}

.div-template-six .btn-info {
    right: 10px;
    top: 0px;
    border: 0px;
    background-color: transparent;
    font-size: unset;
    position: absolute;
    color: #FF7369;
}

/* Template 7 */
.div-template-seven{
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    min-height: 200px;
}

.div-template-seven .div-template-footer {
    background-color: #4A5568;
    padding: 15px;
    width: 100%;
    position: absolute;
    top: 0px;
}
.div-template-seven .div-template-footer button {
    font-size :10px;
   
    letter-spacing: 1.5px;
    color: #4A5568;
    border: 1px solid #4A5568;
    background-color: #CBD5E0;
    padding: 4px 8px;
    float:right;
    border-radius: 3px;
    margin-top: 10px;
}
.div-template-seven .div-template-footer span {
    letter-spacing: 1.2px;
    color: #CBD5E0;    
}
.div-template-seven .div-template-footer span.item-name {
    font-weight: 300;
}
.div-template-seven .div-template-footer span.item-points {    
    font-weight: 700;
   
}
.div-template-seven .btn-info {
    left: 10px;
    bottom: 10px;
    border: 0px;
    background-color: transparent;
    font-size: unset;
    position: absolute;
}

/* Template 8 */
.div-template-eight{
    border-radius: 15px;
    position: relative;
    overflow: hidden;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color: #004D95;    
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    min-height: 200px;
}

.div-template-eight button {
    font-size :10px;
   
    letter-spacing: 1.5px;
    color: #004D95;
    border: 1px solid #004D95;
    background-color: #D4DCE4;
    padding: 4px 8px;
    border-radius: 3px;
}
.div-template-eight button:hover, .div-template-eight button:focus {
    color: #004D95;
    background-color: #D4DCE4;
    border: 1px solid #004D95;
}
.div-template-eight span, .div-template-eight p {    
    color: #D4DCE4;
   
}
.div-template-eight span.item-name {
    letter-spacing: 1.2px;
    font-weight: 300;
}
.div-template-eight p.item-points-text {
    font-size: 9px;
}
.div-template-eight p.item-points {
    font-size: 14px;
    margin-bottom: 0px;
    font-weight: 600;
}
.div-template-eight .col-5 div {
    width:100%;
    position: absolute;
    bottom:30px;    
}
.div-template-eight .col-5 {
    padding-left: 30px;
    height:200px;
    background-color: #004D95;
    border-bottom-right-radius: 30% 100%;
}
.div-template-eight .btn-info {
    right: 20px;
    top: 10px;
    border: 0px;
    background-color: transparent;
    font-size: unset;
    position: absolute;
    
}

/* media query for screen width 414 */
@media only screen and (max-width: 599px) and (min-width: 411px) {
  
}
/* media query for screen width 375 */
@media only screen and (max-width: 410px) and (min-width: 375px) {
    .div-template-eight button {
        font-size: 9px;
    }
}
/* media query for screen width 360 */
@media only screen and (max-width: 374px) and (min-width: 360px) {
    .div-template-eight button {
        font-size: 8px;
    }
}
/* media query for screen width 320 */
@media only screen and (max-width: 359px) and (min-width: 320px) {
    .div-template-eight button {
        font-size: 8px;
    }
}

/* Template 9 */
.div-template-nine{
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color: #DE6D36;
    min-height: 200px;
    padding: 25px;
}

.div-template-nine p, .div-template-nine span {
    color: #FFF;
   
    letter-spacing: 1.5px;
}
.div-template-nine p.item-name, .div-template-nine p.item-points{
    font-size: 36px;        
}
.div-template-nine p.item-points {
    font-weight: 700;
    margin-bottom: 0px;
}

.div-template-nine span.item-points-text {
    font-size: 20px;
}
.div-template-nine button {
    font-size :10px;
   
    letter-spacing: 1.5px;
    color: #DE6D36;
    border: 1px solid #DE6D36;
    background-color: #fff;
    padding: 4px 8px;
    border-radius: 3px;
    margin-left: 10px;
}
.div-template-nine button:hover, .div-template-nine button:focus {
    color: #DE6D36;
    background-color: #fff;
    border: 1px solid #DE6D36;
}

.div-template-nine .btn-info {
    right: 20px;
    top: 10px;
    border: 0px;
    background-color: transparent;
    font-size: unset;
    position: absolute;
    
}

/* Template 10 */
.div-template-ten{
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    min-height: 200px;
    background-color: #fff;
    border: 1px solid #6A55A4;
}


.div-template-ten .div-template-footer {
    background-color: #fff;
    padding: 10px 15px;
    width: 100%;
    position: absolute;
    bottom: 0px;
}
.div-template-ten .div-template-footer button {
    font-size :10px;
   
    letter-spacing: 1.5px;
    color: #fff;
    border: 1px solid #fff;
    background-color: #6A55A4;
    padding: 4px 8px;
    float:right;
    border-radius: 3px;
    margin-top: 10px;
}
.div-template-ten .div-template-footer p {
    color:#333;
    margin-bottom: 0px;
    font-size: 12px;
}
.div-template-ten .div-template-footer span {
    letter-spacing: 1.2px;
    color: #6A55A4;
   
    font-size: 9px;
}
.div-template-ten .div-template-footer span strong {
    font-weight: 700 !important;
    font-size: 20px;
}
.div-template-ten .div-template-footer .col-md-7
{
    border-right: 1px solid #6A55A4;
}
.div-template-ten .btn-info {
    right: 20px;
    top: 10px;
    border: 0px;
    background-color: transparent;
    font-size: unset;
    position: absolute;
}

/* Template 11 */
.div-template-eleven{
    border-radius: 15px;
    position: relative;
    overflow: hidden;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color: #96A202;    
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    min-height: 200px;
}

.div-template-eleven button {
    font-size :10px;
   
    letter-spacing: 1.5px;
    color: #96A202;
    border: 1px solid #96A202;
    background-color: #fff;
    padding: 4px 8px;
    border-radius: 3px;
}
.div-template-eleven button:hover, .div-template-eleven button:focus {
    color:#96A202;
    background-color: #fff;
    border: 1px solid #96A202;
}
.div-template-eleven span, .div-template-eleven p {    
    color: #fff;
   
}
.div-template-eleven span.item-name {
    letter-spacing: 1.2px;
    font-weight: 300;
    position: absolute;
    top: 15px;
    left:30px;
}
.div-template-eleven p.item-points-text {
    font-size: 9px;
    margin-bottom: 5px;
}
.div-template-eleven p.item-points {
    font-size: 14px;
    margin-bottom: 0px;
    font-weight: 600;
}
.div-template-eleven .col-5 .div-left {
    padding-left: 30px;
    width: 100%;
    height: 0;    
    border-top: 200px solid #96A202;
    border-right: 25px solid transparent;
}

.div-template-eleven .col-5 .div-points-btn {
    position: absolute;
    bottom: 30px;
    left:30px;
}

.div-template-eleven .btn-info {
    right: 20px;
    top: 10px;
    border: 0px;
    background-color: transparent;
    font-size: unset;
    position: absolute;
}

/* media query for screen width 414 */
@media only screen and (max-width: 599px) and (min-width: 411px) {
  
}
/* media query for screen width 375 */
@media only screen and (max-width: 410px) and (min-width: 375px) {
    .div-template-eleven button {
        font-size: 9px;
    }
}
/* media query for screen width 360 */
@media only screen and (max-width: 374px) and (min-width: 360px) {
    .div-template-eleven button {
        font-size: 8px;
    }
}
/* media query for screen width 320 */
@media only screen and (max-width: 359px) and (min-width: 320px) {
    .div-template-eleven button {
        font-size: 8px;
    }
}

/* Template 12 */
.div-template-twelve{
    border-radius: 15px;
    position: relative;
    overflow: hidden;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color: #96A202;    
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    min-height: 200px;
}

.div-template-twelve button {
    font-size :10px;
   
    letter-spacing: 1.5px;
    color: #ED8F29;
    border: 1px solid #ED8F29;
    background-color: #fff;
    padding: 4px 8px;
    border-radius: 3px;
    max-height: 25px;
    margin-top: 7px;
    float:right;
}
.div-template-twelve button:hover, .div-template-twelve button:focus {
    color: #ED8F29;
    background-color: #fff;
    border: 1px solid #ED8F29;
}
.div-template-twelve .col-5 .div-right {    
    width: 100%;
    height: 0;    
    border-top: 120px solid #ED8F29;
    border-left: 130px solid transparent;
}
.div-template-twelve .col-7 {
    min-height: 200px;    
}
.div-template-twelve p, .div-template-twelve span {
    color: #FFF;
   
    letter-spacing: 1.5px;
}
.div-template-twelve p.item-points-text {
    font-size: 9px;
    margin-bottom: 5px;
}
.div-template-twelve p.item-points {
    font-size: 14px;
    margin-bottom: 0px;
    font-weight: 600;
}
.div-template-twelve .div-points {
    position: absolute;
    top:15px;
    right:30px;
}
.div-template-twelve .div-name-btn {
    padding: 10px 15px;
    position: absolute;
    bottom: 0px;
    width: 100%;
    background-color: #ED8F29;
    display: flex;
    border-top-right-radius: 15px;
}
.div-template-twelve .div-name-btn span.item-name {
    letter-spacing: 1.2px;
    font-weight: 500;
    font-size: 12px;
    width:85px;
}

.div-template-twelve .btn-info {
    left: 20px;
    top: 0px;
    border: 0px;
    background-color: transparent;
    font-size: unset;
    position: absolute;
}

/* media query for screen width 414 */
@media only screen and (max-width: 599px) and (min-width: 411px) {
    .div-template-twelve .col-5 .div-right {
        width: 80%;
        margin-left: 28px;
    }
    .div-template-twelve .div-points {
        top:5px;
    }
}
/* media query for screen width 375 */
@media only screen and (max-width: 410px) and (min-width: 375px) {
    .div-template-twelve .col-5 .div-right {
        width: 90%;
        margin-left: 12px;
    }
    .div-template-twelve .div-points {
        top:10px;
    }
}
/* media query for screen width 360 */
@media only screen and (max-width: 374px) and (min-width: 360px) {
    .div-template-twelve .col-5 .div-right {
        width: 95%;
        margin-left: 5px;
    }
    .div-template-twelve .div-points {
        top:10px;
    }
}
/* media query for screen width 320 */
@media only screen and (max-width: 359px) and (min-width: 320px) {
    .div-template-twelve .div-points {
        top:5px;
    }
}


/* Template 13 */
.div-template-thriteen{
    border-radius: 15px;
    position: relative;
    overflow: hidden;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;    
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    min-height: 200px;
    color : #fff;
}

.div-template-thriteen #overlay {    
    width: 100%; /* Full width (cover the whole page) */
    height: 200px; /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #0598EDA5; /* Black background with opacity */
    z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
    cursor: pointer; /* Add a pointer on hover */
}
.div-template-thriteen p {
    margin-bottom: 0px;
    letter-spacing: 1.5px;
}
.div-template-thriteen .item-name {
    font-size: 30px;
    font-weight: 400;
}
.div-template-thriteen .item-name-sub {
    font-size: 14px;
    font-weight: 300;
   
}
.div-template-thriteen .col-md-7 {
    padding-left: 45px;
    position: relative;    
}
.div-template-thriteen .col-md-7 .div-bor-right{
    border-right: 2px solid #fff;
    height: 38px;
    position: absolute;
    right: 5px;
    top: 20px;
}

.div-template-thriteen .item-points {
    font-size: 30px;
    font-weight: 700;
}
.div-template-thriteen .item-points-text {
    font-size: 14px;
    font-weight: 200;
   
}
.div-template-thriteen .div-overall {
    width:100%;
    position: absolute;
    top:25%;
}
.div-template-thriteen .div-overall .col-md-12 { 
    text-align: center;
}
.div-template-thriteen button {
    font-size :10px;
   
    letter-spacing: 1.5px;
    color: #0598ED;
    border: 1px solid #0598ED;
    background-color: #fff;
    padding: 4px 8px;
    border-radius: 3px;
    max-height: 25px;
    margin-top: 20px;    
}
.div-template-thriteen button:hover, .div-template-thriteen button:focus {
    color: #0598ED;
    background-color: #fff;
    border: 1px solid #0598ED;
}
.div-template-thriteen .btn-info {
    right: 20px;
    top: 0px;
    border: 0px;
    background-color: transparent;
    font-size: unset;
    position: absolute;
    margin-top: 10px;
}

/* Template 14 */
.div-template-fourteen{
    border-radius: 15px;
    position: relative;
    overflow: hidden;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;    
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    min-height: 200px;
}

.div-template-fourteen button {
    font-size :10px;
   
    letter-spacing: 1.5px;
    color: #B5803B;
    border: 1px solid #B5803B;
    background-color: #FFF;
    padding: 4px 8px;    
    border-radius: 3px;    
}
.div-template-fourteen button:hover, .div-template-fifteen button:focus {
    color:#B5803B;
    background-color: #fff;
    border: 1px solid #B5803B;
}
.div-template-fourteen p {    
    color: #333;
   
}
.div-template-fourteen span.item-name {
    letter-spacing: 1.2px;
    font-weight: 500;
    color: #FFFFFF;
}
.div-template-fourteen p.item-points-text {
    font-size: 9px;
}
.div-template-fourteen p.item-points {
    font-size: 14px;
    margin-bottom: 0px;
    font-weight: 700;
    margin-top: -5px;
}
.div-template-fourteen .col-5 div.div-name-btn {
    width:60%;
    position: absolute;
    bottom:15px;    
}
.div-template-fourteen .col-5 {
    padding-left: 15px;
    height:200px;
    background-color: #B5803B;
}
.div-template-fourteen .div-points {
    position: absolute;
    background-color: #fff;
    height: 60px;
    width: 60px;
    border-radius: 50%;
    text-align: center;
    left: -30px;
    top: 15px;
}
.div-template-fourteen .btn-info {
    left: 20px;
    top: 0px;
    border: 0px;
    background-color: transparent;
    font-size: unset;
    position: absolute;
    margin-top: 10px;
}

/* media query for screen width 414 */
@media only screen and (max-width: 599px) and (min-width: 411px) {
  
}
/* media query for screen width 375 */
@media only screen and (max-width: 410px) and (min-width: 375px) {
    .div-template-fourteen button {
        font-size: 9px;
    }
}
/* media query for screen width 360 */
@media only screen and (max-width: 374px) and (min-width: 360px) {
    .div-template-fourteen button {
        font-size: 8px;
    }
}
/* media query for screen width 320 */
@media only screen and (max-width: 359px) and (min-width: 320px) {
    .div-template-fourteen button {
        font-size: 8px;
    }
}

/* Template 15 */
.div-template-fifteen{
    border-radius: 15px;
    position: relative;
    overflow: hidden;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;      
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    min-height: 200px;
}

.div-template-fifteen button {
    font-size :10px;
   
    letter-spacing: 1.5px;
    color: #FD4911;
    border: 1px solid #FD4911;
    background-color: #fff;
    padding: 4px 8px;
    border-radius: 3px;
}
.div-template-fifteen button:hover, .div-template-fifteen button:focus {
    color:#FD4911;
    background-color: #fff;
    border: 1px solid #FD4911;
}
.div-template-fifteen p {    
    color: #fff;
   
}
.div-template-fifteen span.item-name {
    font-family: 'Pacifico', cursive;
    font-size: 24px;
    letter-spacing: 1.2px;
    font-weight: 300;
    position: absolute;
    top: 15px;
    left:30px;
    color: #fff;
}
.div-template-fifteen p.item-points {
    font-size: 9px;
    margin-bottom: 5px;
}
.div-template-fifteen p.item-points strong {
    font-size: 14px;
    margin-bottom: 0px;
    font-weight: 600;
}
.div-template-fifteen .col-8 .div-left {
    padding-left: 30px;
    width: 100%;
    height: 0;    
    border-top: 200px solid #FD4911;
    border-right: 100px solid transparent;
}

.div-template-fifteen .col-8 .div-points-btn {
    position: absolute;
    bottom: 30px;
    left:30px;
}

.div-template-fifteen .btn-info {
    right: 20px;
    top: 0px;
    border: 0px;
    background-color: transparent;
    font-size: unset;
    position: absolute;
    margin-top: 10px;
}

/* Template 16 */
.div-template-sixteen{
    border-radius: 15px;
    position: relative;
    overflow: hidden;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;    
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    min-height: 200px;
}

.div-template-sixteen button {
    font-size :10px;
   
    letter-spacing: 1.5px;
    color:#2D9C59;
    border: 1px solid #2D9C59;
    background-color: #FFF;
    padding: 4px 8px;    
    border-radius: 3px;
    margin-top: 15px;  
}
.div-template-sixteen button:hover, .div-template-sixteen button:focus {
    color:#2D9C59;
    border: 1px solid #2D9C59;
    background-color: #FFF;
}
.div-template-sixteen p {    
    color: #2D9C59;
   
}
.div-template-sixteen span.item-name {
    letter-spacing: 1.2px;
    font-weight: 400;
    color: #FFFFFF;
}
.div-template-sixteen p.item-points-text {
    font-size: 12px;
}
.div-template-sixteen p.item-points {
    font-size: 20px;
    margin-bottom: 0px;
    font-weight: 800;
    margin-top: 15px;
}
.div-template-sixteen .col-7 div.div-name-btn {
    width:100%;
    text-align: center;       
}
.div-template-sixteen .col-7 {
    padding-left: 15px;
    height:200px;
    background-color: #2D9C59;
    text-align: center;
}
.div-template-sixteen .div-points {
    position: relative;
    background-color: #fff;
    height: 100px;
    width: 100px;
    border-radius: 50%;
    text-align: center;
    margin-left: 23%;
    margin-top: 15px; 
}
.div-template-sixteen .div-points div {
    position: absolute;
    top:10px;
    left:28px;
}

.div-template-sixteen .btn-info {
    right: 20px;
    top: 0px;
    border: 0px;
    background-color: transparent;
    font-size: unset;
    position: absolute;
    margin-top: 10px;
}

/* Template 17 */
.div-template-seventeen{
    border-radius: 15px;
    position: relative;
    overflow: hidden;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;    
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    min-height: 200px;
}
.div-template-seventeen .col-md-8 {
    background-color: #CBCB72;
    opacity: 0.9;
    min-height: 200px;
    padding-top: 15px;
}
.div-template-seventeen button {
    font-size :10px;
   
    letter-spacing: 1.5px;
    color:#CBCB72;
    border: 1px solid#CBCB72;
    background-color: #4F5016;
    padding: 4px 8px;    
    border-radius: 3px;
    margin-top: 15px;  
}
.div-template-seventeen button:hover, .div-template-seventeen button:focus {
    color:#CBCB72;
    border: 1px solid #CBCB72;
    background-color: #4F5016;
}
.div-template-seventeen span.item-name {
    letter-spacing: 1.2px;
    font-weight: 400;
    color: #FFFFFF;
}
.div-template-seventeen p.item-points-text {
    font-size: 14px;
    letter-spacing: 1.2px;
   
}
.div-template-seventeen p.item-points {
    font-size: 24px;
    margin-bottom: 0px;
    font-weight: 600;
    margin-top: 15px;
}
.div-template-seventeen .btn-info {
    left: 20px;
    top: 0px;
    border: 0px;
    background-color: transparent;
    font-size: unset;
    position: absolute;
    margin-top: 10px;
}

/* media query for screen width 414 */
@media only screen and (max-width: 599px) and (min-width: 411px) {
  
}
/* media query for screen width 375 */
@media only screen and (max-width: 410px) and (min-width: 375px) {
    .div-template-seventeen button {
        font-size: 9px;
    }
}
/* media query for screen width 360 */
@media only screen and (max-width: 374px) and (min-width: 360px) {
    .div-template-seventeen button {
        font-size: 8px;
    }
}
/* media query for screen width 320 */
@media only screen and (max-width: 359px) and (min-width: 320px) {
    .div-template-seventeen button {
        font-size: 8px;
    }
}

/* Template 18 */
.div-template-eighteen{
    border-radius: 15px;
    position: relative;
    overflow: hidden;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;       
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    min-height: 200px;
}

.div-template-eighteen button {
    font-size :10px;
   
    letter-spacing: 1.5px;
    color: #fff;
    border: 1px solid #fff;
    background-color:#353639;
    padding: 4px 8px;    
    border-radius: 3px;    
}
.div-template-eighteen button:hover, .div-template-eighteen button:focus {
    color: #fff;
    border: 1px solid #fff;
    background-color:#353639;
}
.div-template-eighteen p {    
    color: #353639;
   
}
.div-template-eighteen span.item-name {
    letter-spacing: 1.2px;
    font-weight: 700;
    font-size: 24px;
    color: #353639;
}
.div-template-eighteen p.item-points-text {
    font-size: 14px;
}
.div-template-eighteen p.item-points {
    font-size: 28px;
    margin-bottom: 0px;
    font-weight: 700;
}
.div-template-eighteen .col-6 {
    padding-left: 30px;
    height:200px;
    background-color: #fff;
    padding-top: 30px;
}

.div-template-eighteen .btn-info {
    right: 10px;
    bottom: 10px;
    border: 0px;
    background-color: transparent;
    font-size: unset;
    position: absolute;
    margin-top: 10px;
}

/* media query for screen width 320 */
@media only screen and (max-width: 359px) and (min-width: 320px) {
    .div-template-eighteen span.item-name {
        font-size: 22px;
    }
}

/* Template 19 */
.div-template-ninteen{
    border-radius: 15px;
    position: relative;
    overflow: hidden;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color:#F6CD3E;    
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    min-height: 200px;
}

.div-template-ninteen button {
    font-size :10px;
   
    letter-spacing: 1.5px;
    color: #F6CD3E;
    border: 1px solid#F6CD3E;
    background-color: #284762;
    padding: 4px 8px;
    border-radius: 3px;
}
.div-template-ninteen button:hover, .div-template-ninteen button:focus {
    color:#F6CD3E;
    background-color: #284762;
    border: 1px solid#F6CD3E;
}
.div-template-ninteen p {    
    color: #284762;
   
}
.div-template-ninteen span.item-name {
    font-family: 'Pacifico', cursive;
    letter-spacing: 1.2px;
    font-weight: 600;
    color: #284762;
    font-size: 20px;
}
.div-template-ninteen p.item-points-text {
    font-size: 14px;
    margin-bottom: 0px;
   
    letter-spacing: 1.2px;
}
.div-template-ninteen p.item-points {
    font-size: 22px;
    margin-bottom: 0px;
    font-weight: 700;
    letter-spacing: 1.2px;
}
.div-template-ninteen .col-5 div {
    width:100%;
    position: absolute;
    bottom:30px;    
}
.div-template-ninteen .col-5 {
    padding-left: 30px;
    height:200px;
    background-color: #F6CD3E;
    border-bottom-left-radius: 30% 100%;
}
.div-template-ninteen .btn-info {
    left: 20px;
    top: 0px;
    border: 0px;
    background-color: transparent;
    font-size: unset;
    position: absolute;
    margin-top: 10px;
}

/* media query for screen width 320 */
@media only screen and (max-width: 359px) and (min-width: 320px) {
    .div-template-ninteen button {
        font-size: 9px;
    }
}

/* Template 20 */
.div-template-twenty{
    border-radius: 15px;
    position: relative;
    overflow: hidden;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;       
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    min-height: 200px;
}

.div-template-twenty .div-parent{
    background-color: #6A55A4;
    min-height: 130px;
    border-radius: 15px;
    position: absolute;
    top:35px;
    left:35px;
    right:35px;
    opacity: 0.9;
}
.div-template-twenty .item-name{
    color:#fff;
    font-weight: 500;
}

.div-template-twenty button {
    font-size :10px;
   
    letter-spacing: 1.5px;
    color: #6A55A4;
    border: 1px solid #6A55A4;
    background-color:#fff;
    padding: 4px 8px;    
    border-radius: 3px;    
}
.div-template-twenty button:hover, .div-template-twenty button:focus {
    color: #6A55A4;
    border: 1px solid #6A55A4;
    background-color:#fff;
}
.div-template-twenty p.item-points-text {
    font-size: 14px;
    margin-bottom: 0px;
   
}
.div-template-twenty p.item-points {
    font-size: 28px;
    margin-bottom: 0px;
    font-weight: 700;
}
.div-template-twenty .btn-info {
    right: 10px;
    top: 0px;
    border: 0px;
    background-color: transparent;
    font-size: unset;
    position: absolute;
    margin-top: 10px;
}

/* media query for screen width 414 */
@media only screen and (max-width: 599px) and (min-width: 411px) {
    .p18per {
        padding-top: 12%;
    }
}
/* media query for screen width 375 */
@media only screen and (max-width: 410px) and (min-width: 375px) {
    .p18per {
        padding-top: 14%;
    }
}
/* media query for screen width 360 */
@media only screen and (max-width: 374px) and (min-width: 360px) {
    .p18per {
        padding-top: 16%;
    }
    .div-template-twenty button { 
        font-size: 9px;
    }
}
/* media query for screen width 320 */
@media only screen and (max-width: 359px) and (min-width: 320px) {
    .div-template-twenty button { 
        font-size: 9px;
    }
}

/* Template 21 */
.div-template-twentyone{
    border-radius: 15px;
    position: relative;
    overflow: hidden;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color: #96A202;    
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    min-height: 200px;
}

.div-template-twentyone button {
    font-size :10px;
   
    letter-spacing: 1.5px;
    color: #00A9E9;
    border: 1px solid #00A9E9;
    background-color: #fff;
    padding: 4px 8px;
    border-radius: 3px;
    max-height: 25px;
    margin-top: 7px;
    float:right;
}
.div-template-twentyone button:hover, .div-template-twentyone button:focus {
    color:#00A9E9;
    background-color: #fff;
    border: 1px solid #00A9E9;
}
.div-template-twentyone .col-8 {
    min-height: 200px;    
}
.div-template-twentyone p, .div-template-twentyone span {
    color: #FFF;
   
    letter-spacing: 1.5px;
}
.div-template-twentyone p.item-points-text, .div-template-twentyone p.item-points {
    font-size: 12px;
    margin-bottom: 0px;
    font-weight: 500;
}
 
.div-template-twentyone .div-points {
    position: absolute;
    top:0px;
    left:30px;
    width: 25%;
    padding: 10px;
    background-color: #00A9E9;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    color:#fff;
    text-align: center;
}
.div-template-twentyone .div-name-btn {
    padding: 10px;
    position: absolute;
    bottom: 0px;
    width: 100%;
    background-color:#00A9E9;
    display: flex;
    border-top-right-radius: 15px;
}
.div-template-twentyone .div-name-btn span.item-name {
    letter-spacing: 1.2px;
    font-weight: 500;
    font-size: 12px;
    width:125px;
}
.div-template-twentyone .btn-info {
    right: 10px;
    top: 0px;
    border: 0px;
    background-color: transparent;
    font-size: unset;
    position: absolute;
    margin-top: 10px;
}

/* Template 22 */
.div-template-twentytwo{
    border-radius: 15px;
    position: relative;
    overflow: hidden;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color:#F6CD3E;    
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    min-height: 200px;
}

.div-template-twentytwo button {
    font-size :10px;
   
    letter-spacing: 1.5px;
    color: #72229D;
    border: 1px solid #72229D;
    background-color: #D5C2DF;
    padding: 4px 8px;
    border-radius: 3px;
}
.div-template-twentytwo button:hover, .div-template-twentytwo button:focus {
    color:#72229D;
    background-color:#D5C2DF;
    border: 1px solid #72229D;
}
.div-template-twentytwo p {    
    color: #D5C2DF;
   
}
.div-template-twentytwo span.item-name {    
    letter-spacing: 1.2px;
    font-weight: 700;
    color: #D5C2DF;
    font-size: 20px;
   
}
.div-template-twentytwo p.item-points span {
    font-size: 10px;
    margin-bottom: 0px;
   
    letter-spacing: 1.2px;
}
.div-template-twentytwo p.item-points {
    font-size: 12px;
    margin-bottom: 0px;
    font-weight: 600;
    letter-spacing: 1.2px;
}
.div-template-twentytwo .col-5 div {
    width:100%;
    position: absolute;
    bottom:30px;    
}
.div-template-twentytwo .col-5 {
    padding-left: 30px;
    padding-top: 25px;
    height:200px;
    background-color:#72229D;
    border-bottom-left-radius: 30% 100%;
}
.div-template-twentytwo .btn-info {
    left: 20px;
    top: 0px;
    border: 0px;
    background-color: transparent;
    font-size: unset;
    position: absolute;
    margin-top: 10px;
}

/* media query for screen width 414 */
@media only screen and (max-width: 599px) and (min-width: 411px) {
   
}
/* media query for screen width 375 */
@media only screen and (max-width: 410px) and (min-width: 375px) {
   
}
/* media query for screen width 360 */
@media only screen and (max-width: 374px) and (min-width: 360px) {
    .div-template-twentytwo button { 
        font-size: 9px;
    }
}
/* media query for screen width 320 */
@media only screen and (max-width: 359px) and (min-width: 320px) {
    .div-template-twentytwo button { 
        font-size: 9px;
    }
}

/* Template 23 */
.div-template-twentythree{
    border-radius: 15px;
    position: relative;
    overflow: hidden;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    min-height: 200px;
}

.div-template-twentythree .div-circle {
    width: 220px;
    height: 270px;
    background-color: #556B22;
    border-radius: 50%;
    position: absolute;
    top: -150px;
    left: -8px;
}

.div-template-twentythree button {
    font-size :10px;
   
    letter-spacing: 1.5px;
    color: #556B22;
    border: 1px solid #556B22;
    background-color: #FFF301;
    padding: 4px 8px;
    border-radius: 3px;
}
.div-template-twentythree button:hover, .div-template-twentythree button:focus {
    color: #556B22;
    background-color:#FFF301;
    border: 1px solid #556B22;
}
.div-template-twentythree span, .div-template-twentythree p {       
   
}
.div-template-twentythree .div-content {
    width: 200px;
    padding-top:5px;
    padding-left: 20px;
    padding-right: 20px;
    position: absolute;
    top: 0px;
    left: 0px;
}
.div-template-twentythree .div-content div {
    width:100%;
    text-align: center;
}

.div-template-twentythree .div-content .item-points {
    color: #FFF301;
    font-weight: 600;
    margin-bottom: 0px;
}
.div-template-twentythree .div-content .item-points span {
    font-size: 9px;
    font-weight:400;
}
.div-template-twentythree p.item-name {
    letter-spacing: 1.2px;
    font-weight: 600;
    color:#fff;
    min-height: 40px;
}
.div-template-twentythree .btn-info {
    right: 10px;
    top: 0px;
    border: 0px;
    background-color: transparent;
    font-size: unset;
    position: absolute;
    margin-top: 10px;
}

/* media query for screen width 414 */
@media only screen and (max-width: 599px) and (min-width: 411px) {
    .div-template-twentythree .div-content {
        width: 220px;
    }
    .div-template-twentythree .div-circle {
        width: 230px;
        height: 280px;
    }
    .div-template-twentythree .div-content .item-name {
        margin-bottom: 5px;
    }
}
/* media query for screen width 375 */
@media only screen and (max-width: 410px) and (min-width: 375px) {
    .div-template-twentythree .div-content {
        width: 220px;
    }
    .div-template-twentythree .div-circle {
        width: 230px;
        height: 280px;
    }
    .div-template-twentythree .div-content .item-name {
        margin-bottom: 5px;
    }
}
/* media query for screen width 360 */
@media only screen and (max-width: 374px) and (min-width: 360px) {
    .div-template-twentythree .div-content {
        width: 220px;
    }
    .div-template-twentythree .div-circle {
        width: 230px;
        height: 280px;
    }
    .div-template-twentythree .div-content .item-name {
        margin-bottom: 5px;
    }
}
/* media query for screen width 320 */
@media only screen and (max-width: 359px) and (min-width: 320px) {
    .div-template-twentythree .div-content {
        width: 220px;
    }
    .div-template-twentythree .div-circle {
        width: 230px;
        height: 280px;
    }
    .div-template-twentythree .div-content .item-name {
        margin-bottom: 5px;
    }
}

/* Template 24 */
.div-template-twentyfour{
    border-radius: 15px;
    position: relative;
    overflow: hidden;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    min-height: 200px;
}

.div-template-twentyfour button {
    font-size :10px;
   
    letter-spacing: 1.5px;
    color: #A15726;
    border: 1px solid #A15726;
    background-color: #fff;
    padding: 4px 8px;
    border-radius: 3px;
}
.div-template-twentyfour button:hover, .div-template-twentyfour button:focus {
    color: #A15726;
    background-color:#fff;
    border: 1px solid #A15726;
}
.div-template-twentyfour span, .div-template-twentyfour p {    
    color: #fff;
   
}
.div-template-twentyfour span.item-name {
    letter-spacing: 1.2px;
    font-weight: 300;
    position: absolute;
    top: 15px;
    left:40px;
}
.div-template-twentyfour p.item-points-text {
    font-size: 9px;
    margin-bottom: 5px;
}
.div-template-twentyfour p.item-points {
    font-size: 14px;
    margin-bottom: 0px;
    font-weight: 600;
}
.div-template-twentyfour .col-6 .div-right {
    padding-left: 30px;
    width: 100%;
    height: 0;    
    border-top: 200px solid #A15726;
    border-left: 25px solid transparent;
}

.div-template-twentyfour .col-6 .div-points-btn {
    position: absolute;
    bottom: 30px;
    left:40px;
}

.div-template-twentyfour .btn-info {
    left: 20px;
    top: 0px;
    border: 0px;
    background-color: transparent;
    font-size: unset;
    position: absolute;
    margin-top: 10px;
}

/* Template 25 */
.div-template-twentyfive{
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    min-height: 200px;
}

.div-template-twentyfive .div-template-footer {
    background-color: #272727;
    padding: 10px 15px;
    width: 100%;
    position: absolute;
    bottom: 0px;
    opacity: 0.85;
}
.div-template-twentyfive .div-template-footer button {
    font-size :10px;
   
    letter-spacing: 1.5px;
    color: #fff;    
    background-color: #CD7F4E;
    border: 1px solid transparent;
    padding: 4px 8px;
    float:right;
    border-radius: 3px;
    margin-top: 10px;
}
.div-template-twentyfive button:hover, .div-template-twentyfive button:focus {
    color: #fff;
    background-color:#CD7F4E;
    border: 1px solid transparent;
}
.div-template-twentyfive .div-template-footer span {
    letter-spacing: 1.2px;
    color: #FFFFFF;
   
}
.div-template-twentyfive .div-template-footer span.item-name {
    font-weight: 300;
}
.div-template-twentyfive .div-template-footer span.item-points {    
    font-weight: 700;
}
.div-template-twentyfive .btn-info {
    right: 10px;
    top: 0px;
    border: 0px;
    background-color: transparent;
    font-size: unset;
    position: absolute;
    margin-top: 10px;
}

/* media query for screen width 414 */
@media only screen and (max-width: 599px) and (min-width: 411px) {
   
}
/* media query for screen width 375 */
@media only screen and (max-width: 410px) and (min-width: 375px) {
    .div-template-twentyfive .div-template-footer button {
        font-size: 8px;
    }
}
/* media query for screen width 360 */
@media only screen and (max-width: 374px) and (min-width: 360px) {
    .div-template-twentyfive .div-template-footer button {
        font-size: 8px;
    }
}
/* media query for screen width 320 */
@media only screen and (max-width: 359px) and (min-width: 320px) {
    .div-template-twentyfive .div-template-footer button {
        font-size: 8px;
    }
}

/* Template 26 */
.div-template-twentysix{
    border-radius: 15px;
    position: relative;
    overflow: hidden;    
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;    
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    min-height: 200px;
}
.div-template-twentysix button {
    font-size :10px;
   
    letter-spacing: 1.5px;
    color: #023FA0;
    border: 1px solid #023FA0;
    background-color: #fff;
    padding: 4px 8px;    
    border-radius: 3px;    
}
.div-template-twentysix button:hover, .div-template-twentysix button:focus {
    color: #023FA0;
    background-color:#fff;
    border: 1px solid #023FA0;
}
.div-template-twentysix span {    
    color: #FFFFFF;
   
    display: block;
}
.div-template-twentysix span.item-name {
    letter-spacing: 1.2px;
    font-weight: 300;
    width:70%;
    position: absolute;
    top:15px;
    left:25%;
}
.div-template-twentysix .col-5 div.div-re-points {
    width:100%;
    position: absolute;
    bottom:15px;
    left:25%;
}
.div-template-twentysix span.item-points {
    font-size: 9px;
}
.div-template-twentysix span.item-points strong {
    font-size: 14px;
}
/*Right pointing*/
.div-template-twentysix .col-5 div.triangle-left {
    width: 100%;    
	height: 0;
	border-top: 100px solid #023FA0;
	border-right: 50px solid transparent;
	border-bottom: 100px solid #023FA0;
}

.div-template-twentysix .btn-info {
    right: 20px;
    top: 0px;
    border: 0px;
    background-color: transparent;
    font-size: unset;
    position: absolute;
    margin-top: 10px;
}

/* media query for screen width 414 */
@media only screen and (max-width: 599px) and (min-width: 411px) {
    .div-template-twentysix span.item-name {
        width:55%;
    }
}
/* media query for screen width 375 */
@media only screen and (max-width: 410px) and (min-width: 375px) {
    .div-template-twentysix button {
        font-size: 8px;
    }
}
/* media query for screen width 360 */
@media only screen and (max-width: 374px) and (min-width: 360px) {
    .div-template-twentysix button {
        font-size: 8px;
    }
}
/* media query for screen width 320 */
@media only screen and (max-width: 359px) and (min-width: 320px) {
    .div-template-twentysix button {
        font-size: 8px;
    }
    .div-template-twentysix span.item-name {
        font-size: 14px;
    }
}

/* Template 27 */
.div-template-twentyseven{
    border-radius: 15px;
    position: relative;
    overflow: hidden;    
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;    
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    min-height: 200px;
}
.div-template-twentyseven button {
    font-size :10px;
   
    letter-spacing: 1.5px;
    color: #fff;
    border: 1px solid #fff;
    background-color: #353639;
    padding: 4px 8px;
    border-radius: 3px;
}
.div-template-twentyseven button:hover, .div-template-twentyseven button:focus {
    color: #fff;
    background-color:#353639;
    border: 1px solid #fff;
}
.div-template-twentyseven span, .div-template-twentyseven p {    
    color: #353639;
   
}
.div-template-twentyseven p.item-name {
    letter-spacing: 1.0px;
    font-weight: 700;
    width:100%;    
    font-size: 18px;
    min-height: 70px;
}
.div-template-twentyseven .col-5 {
    background-color: #fff;
    min-height: 200px;
    padding-top: 20px;
}
.div-template-twentyseven .col-5 div.div-re-points {
    width:100%;   
    padding-top: 30px;
}
.div-template-twentyseven span.item-points {
    font-size: 9px;
    font-weight: 700;
}
.div-template-twentyseven span.item-points strong {
    font-size: 16px;
    font-weight: 700 !important;
}

.div-template-twentyseven .btn-info {
    left: 20px;
    top: 0px;
    border: 0px;
    background-color: transparent;
    font-size: unset;
    position: absolute;
    margin-top: 10px;
}

/* media query for screen width 375 */
@media only screen and (max-width: 410px) and (min-width: 375px) {
    .div-template-twentyseven p.item-name {
        font-size: 15px;
    }
}
/* media query for screen width 360 */
@media only screen and (max-width: 374px) and (min-width: 360px) {
    .div-template-twentyseven p.item-name {
        font-size: 14px;
    }
}
/* media query for screen width 320 */
@media only screen and (max-width: 359px) and (min-width: 320px) {
    .div-template-twentyseven p.item-name {
        font-size: 14px;
    }
}

/* Template 28 */
.div-template-twentyeight{
    border-radius: 15px;
    position: relative;
    overflow: hidden;    
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;    
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    min-height: 200px;
}
.div-template-twentyeight button {
    font-size :10px;
   
    letter-spacing: 1.5px;
    color: #585E52;
    border: 1px solid #585E52;
    background-color: #E0BE55;
    padding: 4px 8px;
    border-radius: 3px;
}
.div-template-twentyeight button:hover, .div-template-twentyeight button:focus {
    color: #585E52;
    background-color:#E0BE55;
    border: 1px solid #585E52;
}
.div-template-twentyeight span, .div-template-twentyeight p {    
    color:#E0BE55;
   
}
.div-template-twentyeight p.item-name {
    letter-spacing: 1.0px;
    font-weight: 700;
    width:100%;    
    font-size: 18px;
    min-height:70px;
}
.div-template-twentyeight .col-4 {
    background-color: #585E52;
    min-height: 200px;
    padding-top: 20px;
    padding-left: 30px;
}
.div-template-twentyeight .col-4 div.div-re-points {
    width:100%;   
    padding-top: 30px;
}
.div-template-twentyeight span.item-points {
    font-size: 9px;
    font-weight: 700;
}
.div-template-twentyeight span.item-points strong {
    font-size: 16px;
    font-weight: 700;
}
/*Right pointing*/
.div-template-twentyeight .col-1 div.triangle-left {
    width: 100%;    
	height: 0;
	border-top: 50px solid transparent;
	border-left: 30px solid #585E52;
	border-bottom: 150px solid transparent;
}
.div-template-twentyeight .btn-info {
    right: 20px;
    top: 0px;
    border: 0px;
    background-color: transparent;
    font-size: unset;
    position: absolute;
    margin-top: 10px;
}

/* media query for screen width 375 */
@media only screen and (max-width: 410px) and (min-width: 375px) {
    .div-template-twentyeight p.item-name {
        font-size: 16px;
    }
    .div-template-twentyeight button {
        font-size: 8px;
        padding: 4px 6px;
    }
}
/* media query for screen width 360 */
@media only screen and (max-width: 374px) and (min-width: 360px) {
    .div-template-twentyeight p.item-name {
        font-size: 15px;
    }
    .div-template-twentyeight button {
        font-size: 8px;
        padding: 4px 6px;
    }
}
/* media query for screen width 320 */
@media only screen and (max-width: 359px) and (min-width: 320px) {
    .div-template-twentyeight p.item-name {
        font-size: 14px;
    }
    .div-template-twentyeight button {
        font-size: 8px;
        padding: 4px 6px;
    }
}

/* Template 29 */
.div-template-twentynine{
    border-radius: 15px;
    position: relative;
    overflow: hidden;    
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;    
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    min-height: 200px;
}
.div-template-twentynine button {
    font-size :10px;
   
    letter-spacing: 1.5px;
    color: #721219;
    border: 1px solid #721219;
    background-color: #E6BBB3;
    padding: 4px 8px;
    border-radius: 3px;
}
.div-template-twentynine button:hover, .div-template-twentynine button:focus {
    color: #721219;
    background-color: #E6BBB3;
    border: 1px solid #721219;
}
.div-template-twentynine span, .div-template-twentynine p {    
    color: #E6BBB3;
   
}
.div-template-twentynine p.item-name {
    letter-spacing: 1.0px;
    font-weight: 700;
    width:100%;    
    font-size: 15px;
    min-height: 35px;
}
.div-template-twentynine .col-7.div-twentynine-content {    
    min-height: 200px;    
    padding-left: 30px;
}
.div-template-twentynine .col-8 {
    min-height: 200px;
    background-color: #721219;
    z-index: 1;
    padding-top: 20px;
}
.div-template-twentynine .col-8 div.div-re-points {
    width:100%;   
    padding-top: 60px;
}
.div-template-twentynine span.item-points {
    font-size: 9px;    
}
.div-template-twentynine span.item-points strong {
    font-size: 12px;
    font-weight: 700;
}
.div-template-twentynine .col-7 {
    position: relative;
    overflow: hidden;
    min-height: 200px;
}

.div-template-twentynine .col-4 div.semicircle-left {    
    position: absolute;
    top: 0; 
    bottom: 0;
    box-shadow: 0px 0px 0px 110px #721219;
    width: 100%
}

.div-template-twentynine .col-4 div.semicircle-left-curve {   
    border-radius: 100% 0 0 25% / 50% 0  0 50%;
}

.div-template-twentynine .btn-info {
    right: 20px;
    top: 0px;
    border: 0px;
    background-color: transparent;
    font-size: unset;
    position: absolute;
    margin-top: 10px;
}

/* media query for screen width 375 */
@media only screen and (max-width: 410px) and (min-width: 375px) {
    .div-template-twentynine button {
        font-size: 9px;
    }   
}
/* media query for screen width 360 */
@media only screen and (max-width: 374px) and (min-width: 360px) {
    .div-template-twentynine button {
        font-size: 9px;
    }  
}
/* media query for screen width 320 */
@media only screen and (max-width: 359px) and (min-width: 320px) {
    .div-template-twentynine button {
        font-size: 9px;
    }  
}

/* Template 30 */
.div-template-thrity{
    border-radius: 15px;
    position: relative;
    overflow: hidden;    
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;    
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    min-height: 200px;
}
.div-template-thrity button {
    font-size :10px;
   
    letter-spacing: 1.5px;
    color: #fff;
    border: 1px solid transparent;
    background-color: #196A6B;
    padding: 4px 8px;
    border-radius: 3px;
}
.div-template-thrity button:hover, .div-template-thrity button:focus {
    color: #fff;
    background-color: #196A6B;
    border: 1px solid transparent;
}
.div-template-thrity span, .div-template-thrity p {    
    color:#196A6B;    
}
.div-template-thrity p.item-name {
    letter-spacing: 1.0px;
    font-weight: 700;
    width:100%;    
    font-size: 18px;
    min-height: 45px;
}
.div-template-thrity .col-5 {    
    min-height: 200px;
    padding-top: 30px;
    padding-left: 30px;
}
.div-template-thrity .col-5 div.div-re-points {
    width:100%;   
    padding-top: 30px;
}
.div-template-thrity span.item-points {
    font-size: 9px;
    font-weight: 700;
   
}
.div-template-thrity span.item-points strong {
    font-size: 16px;
    font-weight: 700;
}
.div-template-thrity .btn-info {
    right: 20px;
    top: 0px;
    border: 0px;
    background-color: transparent;
    font-size: unset;
    position: absolute;
    margin-top: 10px;
}

/* Template 31 */
.div-template-thrityone{
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    min-height: 200px;
}

.div-template-thrityone .div-template-footer {
    background-color: #215439;
    padding: 15px;
    width: 100%;
    position: absolute;
    bottom: 0px;
}
.div-template-thrityone .div-template-footer button {
    font-size :10px;
   
    letter-spacing: 1.5px;
    color: #215439;
    border: 1px solid #215439;
    background-color: #A1F6C9;
    padding: 4px 8px;
    float:right;
    border-radius: 3px;
    margin-top: 10px;
}
.div-template-thrityone .div-template-footer button:hover, .div-template-thrityone .div-template-footer button:focus {
    color: #215439;
    background-color: #A1F6C9;
    border: 1px solid #215439;
}
.div-template-thrityone .div-template-footer span {
    letter-spacing: 1.2px;
    color: #FFFFFF;
}
.div-template-thrityone .div-template-footer span strong {
    font-size: 14px;
}
.div-template-thrityone .div-template-footer span.item-points {    
    /* font-weight: 700; */
   
    font-size: 9px;
    letter-spacing: 1.0px;
}
.div-template-thrityone .btn-info {
    left: 10px;
    top: 0px;
    border: 0px;
    background-color: transparent;
    font-size: unset;
    position: absolute;
    margin-top: 10px;
}


/* media query for screen width 375 */
@media only screen and (max-width: 410px) and (min-width: 375px) {
    .div-template-thrityone .div-template-footer button {
        font-size: 9px;
    }
}
/* media query for screen width 360 */
@media only screen and (max-width: 374px) and (min-width: 360px) {
    .div-template-thrityone .div-template-footer button {
        font-size: 8px;
    } 
    .div-template-thrityone .div-template-footer span {
        font-size: 14px;
    }
}
/* media query for screen width 320 */
@media only screen and (max-width: 359px) and (min-width: 320px) {
    .div-template-thrityone .div-template-footer button {
        font-size: 8px;
    } 
    .div-template-thrityone .div-template-footer span {
        font-size: 14px;
    }
}

/* Template 32 */

.div-template-thritytwo{
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    min-height: 200px;
}

.div-template-thritytwo .div-template-header {
    background-color: #66B7B8;
    padding: 10px 15px;
}
.div-template-thritytwo .div-template-header span {    
    font-weight: 600;
    font-size: 18px;
    color: #fff;
    letter-spacing: 1.5px;    
}

.div-template-thritytwo .div-template-footer {
    background-color: #F1F1F1;
    padding: 15px;
    width: 100%;
    position: absolute;
    bottom: 0px;
}
.div-template-thritytwo .div-template-footer button {
    font-size :10px;
   
    letter-spacing: 1.5px;
    color: #fff;
    border: 1px solid transparent;
    background-color: #66B7B8;
    padding: 4px 8px;
}
.div-template-thrityone button:hover, .div-template-thrityone button:focus {
    color: #fff;
    background-color:#66B7B8;
    border: 1px solid transparent;
}
.div-template-thritytwo .div-template-footer span {
    float: right;    
    letter-spacing: 1px;
    color: #66B7B8;
    font-weight: 700;
    margin-top: 2px;
    font-size: 13px;
}
.div-template-thritytwo .btn-info {
    right: 20px;
    top: 0px;
    border: 0px;
    background-color: transparent;
    font-size: unset;
    position: absolute;
    margin-top: 10px;
}

/* Template 33 */
.div-template-thritythree{
    border-radius: 15px;
    position: relative;
    overflow: hidden;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;    
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    min-height: 200px;
}
.div-template-thritythree .col-md-8 {
    background-color:#1D1D19;
    opacity: 0.9;
    min-height: 200px;
    padding-top: 30px;
}
.div-template-thritythree button {
    font-size :10px;
   
    letter-spacing: 1.5px;
    color:#1B1A16;
    border: 1px solid#1B1A16;
    background-color:#F2CEA7;
    padding: 4px 8px;    
    border-radius: 3px;
}
.div-template-thritythree button:hover, .div-template-thritythree button:focus {
    color:#1B1A16;
    border: 1px solid #1B1A16;
    background-color:#F2CEA7;
}
.div-template-thritythree p.item-name {
    letter-spacing: 1.2px;
    font-weight: 400;
    color: #FFFFFF;
    min-height: 70px;
}
.div-template-thritythree p.item-points strong {
    font-size: 18px;
    font-weight: 600;
}
.div-template-thritythree p.item-points {
    font-size: 10px;
    margin-top: 30px;
    margin-bottom: 0px;
}
.div-template-thritythree .btn-info {
    right: 20px;
    top: 0px;
    border: 0px;
    background-color: transparent;
    font-size: unset;
    position: absolute;
    margin-top: 10px;
}

/* media query for screen width 375 */
@media only screen and (max-width: 410px) and (min-width: 375px) {
    .div-template-thritythree p.item-name {
        font-size: 14px;
    }
    .div-template-thritythree button {
        font-size: 9px;
    }
}
/* media query for screen width 360 */
@media only screen and (max-width: 374px) and (min-width: 360px) {
    .div-template-thritythree p.item-name {
        font-size: 14px;
    } 
    .div-template-thritythree button {
        font-size: 8px;
    }
}
/* media query for screen width 320 */
@media only screen and (max-width: 359px) and (min-width: 320px) {
    .div-template-thritythree p.item-name {
        font-size: 14px;
    }
    .div-template-thritythree button {
        font-size: 8px;
    }
}

/* Template 34 */
.div-template-thrityfour{
    border-radius: 15px;
    position: relative;
    overflow: hidden;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color: #96A202;    
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    min-height: 200px;
}

.div-template-thrityfour button {
    font-size :10px;
   
    letter-spacing: 1.5px;
    color: #E45017;
    border: 1px solid #E45017;
    background-color: #fff;
    padding: 4px 8px;
    border-radius: 3px;
}
.div-template-thrityfour button:hover, .div-template-thrityfour button:focus {
    color:#E45017;
    background-color: #fff;
    border: 1px solid #E45017;
}
.div-template-thrityfour span {    
    color: #fff;
   
}
.div-template-thrityfour span.item-name {
    letter-spacing: 1.2px;
    font-weight: 300;
    position: absolute;
    top: 30px;
    left:30px;
    width: 55%;
}
.div-template-thrityfour span.item-name strong {
    font-weight: 600;
}
.div-template-thrityfour p.item-points {
    font-size: 9px;
    margin-bottom: 5px;
    color:#fff;
    text-transform: capitalize;
}
.div-template-thrityfour p.item-points strong {
    font-size: 14px;
    margin-bottom: 0px;
    font-weight: 600;
}
.div-template-thrityfour .col-6 .div-left {
    padding-left: 30px;
    width: 100%;
    height: 0;    
    border-top: 200px solid#E45017;
    border-right: 50px solid transparent;
}

.div-template-thrityfour .col-6 .div-points-btn {
    position: absolute;
    bottom: 30px;
    left:30px;
}

.div-template-thrityfour .btn-info {
    right: 20px;
    top: 0px;
    border: 0px;
    background-color: transparent;
    font-size: unset;
    position: absolute;
    margin-top: 10px;
}

/* media query for screen width 360 */
@media only screen and (max-width: 374px) and (min-width: 360px) {
    .div-template-thrityfour button {
        font-size: 9px;
    }
}
/* media query for screen width 320 */
@media only screen and (max-width: 359px) and (min-width: 320px) {
    .div-template-thrityfour button {
        font-size: 9px;
    }
}

/* Template 35 */
.div-template-thrityfive{
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    min-height: 200px;
}

.div-template-thrityfive .div-template-footer {
    background-color: #272727;
    padding: 10px 15px;
    width: 65%;
    position: absolute;
    bottom: 0px;
    opacity: 0.95;
    border-top-right-radius: 15px;
}
.div-template-thrityfive .div-template-footer button {
    font-size :10px;
   
    letter-spacing: 1.5px;
    color: #FC0120;    
    background-color: #fff;
    padding: 4px 8px;    
    border-radius: 3px;
    margin-top: 10px;
}
.div-template-thrityfive button:hover, .div-template-thrityfive button:focus {
    color: #FC0120;
    background-color:#fff;
}
.div-template-thrityfive .div-template-footer span {
    letter-spacing: 1.2px;
    color: #FFFFFF;    
}
.div-template-thrityfive .div-template-footer p.item-name {
    font-weight: 300;
    min-height: 40px;
}
.div-template-thrityfive .div-template-footer p.item-name strong {
    font-weight: 600;
    display:block;
    font-size: 16px;
}
.div-template-thrityfive .div-template-footer span.item-points {    
    font-weight: 300;
    text-transform: capitalize;
}
.div-template-thrityfive .div-template-footer span.item-points strong {    
    font-weight: 600;
    font-size: 16px;
}
.div-template-thrityfive .btn-info {
    right: 10px;
    top: 0px;
    border: 0px;
    background-color: transparent;
    font-size: unset;
    position: absolute;
    margin-top: 10px;
}

/* Template 36 */
.div-template-thritysix{
    border-radius: 15px;
    position: relative;
    overflow: hidden;    
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;    
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    min-height: 200px;
}
.div-template-thritysix button {
    font-size :10px;
   
    letter-spacing: 1.5px;
    color: #585E52;
    border: 1px solid #212121;
    background-color: #E0BE55;
    padding: 4px 8px;
    border-radius: 3px;
}
.div-template-thritysix button:hover, .div-template-thritysix button:focus {
    color: #585E52;
    background-color:#E0BE55;
    border: 1px solid #212121;
}
.div-template-thritysix span, .div-template-thritysix p {    
    color: #E0BE55;
}
.div-template-thritysix span.item-name {
    letter-spacing: 1.0px;
    position: absolute;
    width: 65%;
    top: 20px;
    left: 30px;    
}
.div-template-thritysix span.item-name strong {
    font-weight: 600;
    font-size: 18px;
    display:block;
}
.div-template-thritysix .col-6 {
    height:200px;
}
.div-template-thritysix .col-6 div.div-re-points {
    width:100%;   
    position: absolute;    
    left: 30px;
    bottom: 15px;
}
.div-template-thritysix p.item-points {
    font-size: 9px;
    font-weight: 300;
   
}
.div-template-thritysix p.item-points strong {
    font-size: 16px;
    font-weight: 600;
}
/*Right pointing*/
.div-template-thritysix .col-6 div.triangle-left {
    width: 100%;
    height: 200px;
    border-top: 10px solid transparent;
    border-left: 150px solid #212121;
    border-bottom: 60px solid transparent;
    position: absolute;
    top: 15px;
}

.div-template-thritysix .btn-info {
    right: 20px;
    top: 0px;
    border: 0px;
    background-color: transparent;
    font-size: unset;
    position: absolute;
    margin-top: 10px;
}

/* Template 37 */
.div-template-thrityseven{
    border-radius: 15px;
    position: relative;
    overflow: hidden;    
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;    
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    min-height: 200px;
}

.div-template-thrityseven button {
    font-size :10px;
   
    letter-spacing: 1.5px;
    color: #F3A84A;
    border: 1px solid#F3A84A;
    background-color:#323232;
    padding: 4px 8px;
    border-radius: 3px;
}
.div-template-thrityseven button:hover, .div-template-thrityseven button:focus {
    color: #F3A84A;
    background-color:#323232;
    border: 1px solid #F3A84A;
}

.div-template-thrityseven .col-6 {
    background-color: #F3A84A;
    height: 200px;
}
.div-template-thrityseven p {
    color:#323232;
   
}

.div-template-thrityseven p.item-name {
    letter-spacing: 1.0px;    
    width: 100%;
    padding-top: 30px;
    padding-left: 15px;
    font-weight: 500;
    min-height: 100px;
}
.div-template-thrityseven p.item-name strong {
    font-weight: 700;
    font-size: 18px;
    display:block;
}
.div-template-thrityseven .div-re-points{
    padding-top: 15px;
    padding-left: 15px;
}

.div-template-thrityseven p.item-points {
    font-size: 9px;
    font-weight: 500;    
}
.div-template-thrityseven p.item-points strong {
    font-size: 16px;
    font-weight: 700 !important;
}
.div-template-thrityseven .btn-info {
    right: 10px;
    top: 0px;
    border: 0px;
    background-color: transparent;
    font-size: unset;
    position: absolute;
    margin-top: 10px;
}

/* Template 38 */
.div-template-thrityeight{
    border-radius: 15px;
    position: relative;
    overflow: hidden;    
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;    
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    min-height: 200px;
}

.div-template-thrityeight button {
    font-size :10px;
   
    letter-spacing: 1.5px;
    color:#292242;
    border: 1px solid #292242;
    background-color:#F3A84A;
    padding: 4px 8px;
    border-radius: 3px;
}
.div-template-thrityeight button:hover, .div-template-thrityeight button:focus {
    color:#292242;
    background-color:#F3A84A;
    border: 1px solid #292242;
}

.div-template-thrityeight .col-7, .div-template-thrityeight .col-5 {
    height: 200px;
}

.div-template-thrityeight .col-7 div, .div-template-thrityeight .col-5 div {
    width:100%;
    padding: 10px 15px;
    background-color: #292242;
    position: absolute;
    bottom: 0px;
}
.div-template-thrityeight .col-5 div {
    right:0px;
    padding: 10px 15px 10px 30px;
}
.div-template-thrityeight .col-7 div .item-name {
    color: #F3A84A;
    font-weight: 600;
    margin-bottom: 0px;
    letter-spacing: 1.2px;
}
.div-template-thrityeight .col-5 .item-points {
    color: #F3A84A;
    text-transform: capitalize;
    margin-bottom: 0px;
}
.div-template-thrityeight .btn-info {
    right: 10px;
    top: 0px;
    border: 0px;
    background-color: transparent;
    font-size: unset;
    position: absolute;
    margin-top: 10px;
}

/* media query for screen width 375 */
@media only screen and (max-width: 410px) and (min-width: 375px) {
   
}
/* media query for screen width 360 */
@media only screen and (max-width: 374px) and (min-width: 360px) {
    .div-template-thrityeight button {
        font-size: 8px;
    }  
}
/* media query for screen width 320 */
@media only screen and (max-width: 359px) and (min-width: 320px) {
    .div-template-thrityeight button {
        font-size: 8px;
    }
}

/* Template 39 */
.div-template-thritynine{
    border-radius: 15px;
    position: relative;
    overflow: hidden;    
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;    
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    min-height: 200px;
}

.div-template-thritynine button {
    font-size :10px;
   
    letter-spacing: 1.5px;
    color: #fff;
    border: 1px solid #fff;
    background-color:#352C33;
    padding: 4px 8px;
    border-radius: 3px;
    margin-top: 15px;
    margin-left: 10px;
}
.div-template-thritynine button:hover, .div-template-thritynine button:focus {
    color: #fff;
    background-color:#352C33;
    border: 1px solid #fff;
}

.div-template-thritynine .col-4 {
    background-color: #352C33;
    height: 200px;
}
.div-template-thritynine p {
    color:#fff;    
}

.div-template-thritynine p.item-name  {
    letter-spacing: 1.0px;    
    width: 100%;
    padding-top: 15px;
    padding-left: 10px;
    min-height: 75px;   
}
/* .div-template-thritynine p.item-name span {
    font-weight: 400;
    font-size: 8px;
    display: block;
} */
.div-template-thritynine p.item-name {
    font-weight: 600;
    font-size: 14px;
}
.div-template-thritynine .div-re-points{
    padding-top: 15px;    
    width: 60px;
    height: 60px;
    background-color: #fff;
    border-radius: 50%;
    margin-left: 20px;
    text-align: center;
}

.div-template-thritynine p.item-points {    
    font-weight: 500;
   
    color: #352C33; 
    font-size: 10px;
}
.div-template-thritynine p.item-points strong {    
    font-weight: 700 !important;
    font-size: 12px;
}

.div-template-thritynine .btn-info {
    left: 10px;
    top: 0px;
    border: 0px;
    background-color: transparent;
    font-size: unset;
    position: absolute;
    margin-top: 10px;
}

/* media query for screen width 375 */
@media only screen and (max-width: 410px) and (min-width: 375px) {
    .div-template-thritynine p.item-name {
        font-weight: 600;
        font-size: 11px;
    }
    .div-template-thritynine p.item-points {
        font-weight: 500;
        color: #352C33;
        font-size: 8px;
    }
    .div-template-thritynine button {
        font-size: 8px;
    }
}
/* media query for screen width 360 */
@media only screen and (max-width: 374px) and (min-width: 360px) {
    .div-template-thritynine p.item-name {
        font-weight: 600;
        font-size: 11px;
    }
    .div-template-thritynine p.item-points {
        font-weight: 500;
        color: #352C33;
        font-size: 8px;
    }
    .div-template-thritynine button {
        font-size: 8px;
    }
}
/* media query for screen width 320 */
@media only screen and (max-width: 359px) and (min-width: 320px) {
    .div-template-thritynine p.item-name {
        font-weight: 600;
        font-size: 11px;
    }
    .div-template-thritynine p.item-points {
        font-weight: 500;
        color: #352C33;
        font-size: 8px;
    }
    .div-template-thritynine button {
        font-size: 8px;
    }
}

/* Template 40 */
.div-template-forty{
    border-radius: 15px;
    position: relative;
    overflow: hidden;    
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;    
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    min-height: 200px;
}
.div-template-forty button {
    font-size :10px;
   
    letter-spacing: 1.5px;
    color: #925F35;
    border: 1px solid #925F35;
    background-color: #fff;
    padding: 4px 8px;
    border-radius: 3px;
}
.div-template-forty button:hover, .div-template-forty button:focus {
    color: #925F35;
    background-color: #fff;
    border: 1px solid #925F35;
}
.div-template-forty span, .div-template-forty p {    
    color: #fff;
}
.div-template-forty p.item-name {
    letter-spacing: 1.0px;    
    width:100%;    
    font-size: 12px;
    min-height: 50px;
}
.div-template-forty p.item-name strong {
    font-size: 16px;
    font-weight: 600;
    padding-left: 15%;
}
.div-template-forty .col-7.div-forty-content {    
    min-height: 200px;    
    padding-left: 0px;
}
.div-template-forty .col-8 {
    min-height: 200px;
    background-color: #925F35;
    z-index: 1;
    padding-top: 20px;
}
.div-template-forty .col-8 div.div-re-points {
    width:100%;   
    padding-top: 50px;
}
.div-template-forty span.item-points {
    font-size: 10px;
   
    padding-left: 15px;
    font-weight: 300;
}
.div-template-forty span.item-points strong {
    font-size: 10px;
    font-weight: 700;
}
.div-template-forty .col-7 {
    position: relative;
    overflow: hidden;
    min-height: 200px;
}

.div-template-forty .col-4 div.semicircle-right {    
    position: absolute;
    top: 0; 
    bottom: 0;
    box-shadow: 0px 0px 0px 110px #925F35;
    width: 100%
}

.div-template-forty .col-4 div.semicircle-right-curve {   
    border-radius: 0 140% 60% 0 / 0 60% 100% 0;
}
.div-template-forty .btn-info {
    left: 20px;
    top: 0px;
    border: 0px;
    background-color: transparent;
    font-size: unset;
    position: absolute;
    margin-top: 10px;
}

/* Template 41 */
.div-template-fortyone{
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    min-height: 200px;
}

.div-template-fortyone .div-template-footer {
    background-color: #415A5D;
    padding: 15px;
    width: 100%;
    position: absolute;
    top: 0px;
}
.div-template-fortyone .div-template-footer button {
    font-size :10px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    color: #415A5D;
    border: 1px solid #415A5D;
    background-color: #CBD5E0;
    padding: 4px 8px;
    float:right;
    border-radius: 3px;
    margin-top: 10px;
}
.div-template-fortyone .div-template-footer span, .div-template-fortyone .div-template-footer p {
    letter-spacing: 1.2px;
    color: #CBD5E0;
    text-transform: uppercase;
}
.div-template-fortyone .div-template-footer p.item-name {
    font-weight: 300;
    margin-bottom: 0px;
    max-height: 21px;
    overflow: hidden;
}
.div-template-fortyone .div-template-footer span.item-points {    
    font-weight: 700;    
}

.div-template-fortyone .btn-info {
    right: 20px;
    bottom: 10px;
    border: 0px;
    background-color: transparent;
    font-size: unset;
    position: absolute;    
}

/* Template 42 */
.div-template-fortytwo{
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    min-height: 200px;
}

.div-template-fortytwo .div-template-footer {
    background-color: #E71C22;
    padding: 15px;
    width: 100%;
    position: absolute;
    bottom: 0px;
}
.div-template-fortytwo .div-template-footer button {
    font-size :10px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    color: #E71C22;
    border: 1px solid #E71C22;
    background-color: #fff;
    padding: 4px 8px;
    float:right;
    border-radius: 3px;
    margin-top: 10px;
}
.div-template-fortytwo .div-template-footer span, .div-template-fortytwo .div-template-footer p {
    letter-spacing: 1.2px;
    color: #FFFFFF;
    text-transform: uppercase;
}
.div-template-fortytwo .div-template-footer p.item-name {
    font-weight: 300;
    margin-bottom: 0px;
    max-height: 21px;
    overflow: hidden;
}
.div-template-fortytwo .div-template-footer span.item-points {    
    font-weight: 700;
}

.div-template-fortytwo .btn-info {
    right: 20px;
    top: 10px;
    border: 0px;
    background-color: transparent;
    font-size: unset;
    position: absolute;
}

/* Template 43 */
.div-template-fortythree{
    border-radius: 15px;
    position: relative;
    overflow: hidden;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color: #004D95;    
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    min-height: 200px;
}

.div-template-fortythree button {
    font-size :10px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    color: #202434;
    border: 1px solid #004D95;
    background-color: #D4DCE4;
    padding: 4px 8px;
    border-radius: 3px;
}
.div-template-fortythree button:hover, .div-template-fortythree button:focus {
    color: #004D95;
    background-color: #D4DCE4;
    border: 1px solid #004D95;
}
.div-template-fortythree span, .div-template-fortythree p {    
    color: #D4DCE4;
    text-transform: uppercase;
}
.div-template-fortythree span.item-name {
    letter-spacing: 1.2px;
    font-weight: 300;
}
.div-template-fortythree p.item-points-text {
    font-size: 9px;
}
.div-template-fortythree p.item-points {
    font-size: 16px;
    margin-bottom: 0px;
    font-weight: 600;
}
.div-template-fortythree .col-5 div {
    width:100%;
    position: absolute;
    bottom:30px;    
}
.div-template-fortythree .col-5 {
    padding-left: 30px;
    height:200px;
    background-color: #202434;
    border-bottom-right-radius: 30% 100%;
}

.div-template-fortythree .btn-info {
    right: 20px;
    top: 10px;
    border: 0px;
    background-color: transparent;
    font-size: unset;
    position: absolute;    
}

/* Template 44 */
.div-template-fortyfour{
    border-radius: 15px;
    position: relative;
    overflow: hidden;    
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;    
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    min-height: 200px;
}
/* .div-template-three img {
    max-width:100%;
} */
.div-template-fortyfour button {
    font-size :10px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    color: #162758;
    border: 1px solid #162758;
    background-color: #fff;
    padding: 4px 8px;    
    border-radius: 3px;    
}
.div-template-fortyfour button:hover, .div-template-fortyfour button:focus {
    color: #028950;
    background-color: #fff;
    border: 1px solid #028950;
}
.div-template-fortyfour span {    
    color: #FFFFFF;
    text-transform: uppercase;
}
.div-template-fortyfour span.item-name {
    letter-spacing: 1.2px;
    font-weight: 300;
    width:62%;
    position: absolute;
    top:15px;
    left:27%;
}
.div-template-fortyfour .col-5 div.div-re-points {
    width:100%;
    position: absolute;
    bottom:15px;
    left:25%;
}
.div-template-fortyfour span.item-points {
    font-size: 9px;
    display: block;
}
.div-template-fortyfour span.item-points strong {
    font-size: 14px;
}
/*Right pointing*/
.div-template-fortyfour .col-5 div.triangle-right {
    width: 100%;    
	height: 0;
	border-top: 100px solid #162758;
	border-left: 50px solid transparent;
	border-bottom: 100px solid #162758;
}

.div-template-fortyfour .btn-info {
    left: 20px;
    top: 10px;
    border: 0px;
    background-color: transparent;
    font-size: unset;
    position: absolute;    
}

/* Template 45 */
.div-template-fortyfive{
    border-radius: 15px;
    position: relative;
    overflow: hidden;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;    
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    min-height: 200px;
}

.div-template-fortyfive button {
    font-size :10px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    color: #B5803B;
    border: 1px solid #B5803B;
    background-color: #FFF;
    padding: 4px 8px;    
    border-radius: 3px;    
}
.div-template-fortyfive button:hover, .div-template-fortyfive button:focus {
    color:#B5803B;
    background-color: #fff;
    border: 1px solid #B5803B;
}
.div-template-fortyfive p {    
    color: #333;
    text-transform: uppercase;
}
.div-template-fortyfive span.item-name {
    letter-spacing: 1.2px;
    font-weight: 500;
    color: #FFFFFF;
}
.div-template-fortyfive p.item-points-text {
    font-size: 9px;
}
.div-template-fortyfive p.item-points {
    font-size: 14px;
    margin-bottom: 0px;
    font-weight: 800;
    margin-top: 15px;
}
.div-template-fortyfive .col-5 div.div-name-btn {
    width:60%;
    position: absolute;
    bottom:15px;    
}
.div-template-fortyfive .col-5 {
    padding-left: 15px;
    height:200px;
    background-color: #B5803B;
}
.div-template-fortyfive .div-points {
    position: absolute;
    background-color: #fff;
    height: 60px;
    width: 60px;
    border-radius: 50%;
    text-align: center;
    left: -30px;
    top: 15px;
    padding-top: 0px;
}

.div-template-fortyfive .btn-info {
    left: 20px;
    top: 10px;
    border: 0px;
    background-color: transparent;
    font-size: unset;
    position: absolute;
}
/* media query for screen width 360 */
@media only screen and (max-width: 374px) and (min-width: 360px) {
    .div-template-fortyfive button.btn_not {
        font-size: 8px;
    }
}
/* media query for screen width 320 */
@media only screen and (max-width: 359px) and (min-width: 320px) {    
    .div-template-fortyfive button.btn_not {
        font-size: 8px;
    }
}

/* Template 46 */
.div-template-fortysix{
    border-radius: 15px;
    position: relative;
    overflow: hidden;    
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;    
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    min-height: 200px;
}
.div-template-fortysix button {
    font-size :10px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    color: #585E52;
    border: 1px solid #585E52;
    background-color: #E0BE55;
    padding: 4px 8px;
    border-radius: 3px;
}
.div-template-fortysix button:hover, .div-template-fortysix button:focus {
    color: #585E52;
    background-color:#E0BE55;
    border: 1px solid #585E52;
}
.div-template-fortysix span, .div-template-fortysix p {    
    color:#E0BE55;
    text-transform: uppercase;
}
.div-template-fortysix p.item-name {
    letter-spacing: 1.0px;
    font-weight: 700;
    width:100%;    
    font-size: 18px;
    min-height:70px;
}
.div-template-fortysix .col-4 {
    background-color: #585E52;
    min-height: 200px;
    padding-top: 20px;
    padding-left: 30px;
}
.div-template-fortysix .col-4 div.div-re-points {
    width:100%;   
    padding-top: 30px;
}
.div-template-fortysix span.item-points {
    font-size: 9px;
    font-weight: 700;
}
.div-template-fortysix span.item-points strong {
    font-size: 16px;
    font-weight: 700;
}
/*Right pointing*/
.div-template-fortysix .col-1 div.triangle-left {
    width: 100%;    
	height: 0;
	border-top: 50px solid transparent;
	border-left: 30px solid #585E52;
	border-bottom: 150px solid transparent;
}

.div-template-fortysix .btn-info {
    right: 20px;
    top: 10px;
    border: 0px;
    background-color: transparent;
    font-size: unset;
    position: absolute;
}
/* media query for screen width 360 */
@media only screen and (max-width: 374px) and (min-width: 360px) {
    .div-template-fortysix button.btn_not {
        font-size: 8px;
    }
}
/* media query for screen width 320 */
@media only screen and (max-width: 359px) and (min-width: 320px) {    
    .div-template-fortysix button.btn_not {
        font-size: 8px;
    }
}

/* Template 47 */
.div-template-fortyseven{
    border-radius: 15px;
    position: relative;
    overflow: hidden;    
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;    
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    min-height: 200px;
}
.div-template-fortyseven button {
    font-size :10px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    color: #162758;
    border: 1px solid #162758;
    background-color: #FFF301;
    padding: 4px 8px;    
    border-radius: 3px;
    position: absolute;
    bottom: 10px;
    right: 20px;
}
.div-template-fortyseven button:hover, .div-template-fortyseven button:focus {
    color: #162758;
    background-color: #FFF301;
    border: 1px solid #162758;
}
.div-template-fortyseven p {    
    color: #FFF301;
    text-transform: uppercase;
}
.div-template-fortyseven span {    
    color: #FFF;
    text-transform: uppercase;
}
.div-template-fortyseven span.item-name {
    letter-spacing: 1.2px;
    font-weight: 500;
    width:70%;
    position: absolute;
    top:35%;
    left:20%;
}
.div-template-fortyseven .div-points {
    position: absolute;
    top:10px;
    right:30px;
}
.div-template-fortyseven .div-points p {
    font-size: 9px;
    margin-bottom: 0px;
}
.div-template-fortyseven .div-points p strong {
    font-size: 14px;
}

.div-template-fortyseven .btn-info {
    left: 20px;
    top: unset;
    bottom: 10px;
    border: 0px;
    background-color: transparent;
    font-size: unset;
    position: absolute;
}

/*Right pointing*/
.div-template-fortyseven .col-6 div.half-circle {
    width: 100%;
    height: 200px;
    background-color: #162758;
    border-top-left-radius: 30% 50%;
    border-bottom-left-radius: 30% 50%;    
    border-bottom: 0;
}

/* Template 48 */
.div-template-fortyeight{
    border-radius: 15px;
    position: relative;
    overflow: hidden;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;    
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    min-height: 200px;
}

.div-template-fortyeight button {
    font-size :10px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    color:#CA0103;
    border: 1px solid #CA0103;
    background-color: #FFF;
    padding: 4px 8px;    
    border-radius: 3px;
    margin-top: 15px;  
}
.div-template-fortyeight button:hover, .div-template-fortyeight button:focus {
    color:#CA0103;
    border: 1px solid #CA0103;
    background-color: #FFF;
}
.div-template-fortyeight p {    
    color: #CA0103;
    text-transform: uppercase;
}
.div-template-fortyeight span.item-name {
    letter-spacing: 1.2px;
    font-weight: 400;
    color: #FFFFFF;
    text-transform: uppercase;
}
.div-template-fortyeight p.item-points-text {
    font-size: 12px;
    text-transform: uppercase;
}
.div-template-fortyeight p.item-points {
    font-size: 20px;
    margin-bottom: 0px;
    font-weight: 800;
    margin-top: 15px;
}
.div-template-fortyeight .col-7 div.div-name-btn {
    width:100%;
    text-align: center;       
}
.div-template-fortyeight .col-7 {
    padding-left: 15px;
    height:200px;
    background-color: #CA0103;
    text-align: center;
}
.div-template-fortyeight .div-points {
    position: relative;
    background-color: #fff;
    height: 100px;
    width: 100px;
    border-radius: 50%;
    text-align: center;
    margin-left: 27%;
    margin-top: 15px; 
}
.div-template-fortyeight .div-points div {
    position: absolute;
    top:10px;
    left:28px;
}
.div-template-fortyeight .btn-info {
    right: 10px;
    top: 0px;
    border: 0px;
    background-color: transparent;
    font-size: unset;
    position: absolute;
}

/* Template 49 */
.div-template-fortynine{
    border-radius: 15px;
    position: relative;
    overflow: hidden;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;    
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    min-height: 200px;
}

.div-template-fortynine button {
    font-size :10px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    color:#D23038;
    border: 1px solid #D23038;
    background-color: #FFF;
    padding: 4px 8px;    
    border-radius: 3px;
    margin-right: 0px;    
}
.div-template-fortynine button:hover, .div-template-fortynine button:focus {
    color:#D23038;
    border: 1px solid #D23038;
    background-color: #FFF;
}
.div-template-fortynine p {    
    color: #CA0103;
    text-transform: uppercase;
}
.div-template-fortynine span.item-name {
    letter-spacing: 1.2px;
    font-weight: 400;
    color: #FFFFFF;
}
.div-template-fortynine p.item-points-text {
    font-size: 15px;
    color: #fff;
    font-weight: 600; 
    margin-bottom: 5px;   
}
.div-template-fortynine p.item-points {
    font-size: 20px;
    margin-bottom: 0px;
    font-weight: 800;
    margin-top: 15px;
    text-transform: uppercase;
}

.div-template-fortynine .div-items {
    width: 125px;
    text-align: center;
    position: absolute;
    left: 30%;
    top: 27%;
}
.div-template-fortynine .div-points {
    padding-top: 0px;
}

.div-template-fortynine .div-diamond {
    width: 125px;
    height: 125px;
    background-color: #D23038;
    position: absolute;
    transform: rotate(45deg);
    top: 20%;
    left: 30%;
}

.div-template-fortynine .btn-info {
    right: 20px;
    top: 10px;
    border: 0px;
    background-color: transparent;
    font-size: unset;
    position: absolute;
}

/* Template 50 */
.div-template-fifty{
    border-radius: 15px;
    position: relative;
    overflow: hidden;    
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;    
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    min-height: 200px;
}
.div-template-fifty button {
    font-size :10px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    color: #A15726;
    border: 1px solid #A15726;
    background-color: #ffffff;
    padding: 4px 8px;
    border-radius: 3px;
}
.div-template-fifty button:hover, .div-template-fifty button:focus {
    color: #A15726;
    background-color:#ffffff;
    border: 1px solid #A15726;
}
.div-template-fifty span, .div-template-fifty p {    
    color:#ffffff;    
}
.div-template-fifty p.item-name {
    letter-spacing: 1.0px;
    font-weight: 500;
    width:100%;    
    font-size: 12px;
    min-height:70px;
}
.div-template-fifty .col-5 {
    background-color: #A15726;
    min-height: 200px;
    padding-top: 20px;
    padding-left: 40px;
}
.div-template-fifty .col-5 div.div-re-points {
    width:100%;   
    padding-top: 30px;
}
.div-template-fifty span.item-points {
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
}
/*Right pointing*/
.div-template-fifty .col-1 div.triangle-left {
    width: 100%;    
	height: 0;
	border-top: 100px solid transparent;
	border-left: 30px solid #A15726;
	border-bottom: 100px solid transparent;
}

.div-template-fifty .btn-info {
    right: 20px;
    top: 10px;
    border: 0px;
    background-color: transparent;
    font-size: unset;
    position: absolute;
}

/* Template 51 */
.div-template-fiftyone{
    border-radius: 15px;
    position: relative;
    overflow: hidden;    
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;    
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    min-height: 200px;
}
.div-template-fiftyone button {
    font-size :10px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    color: #fff;
    border: 1px solid transparent;
    background-color: #061010;
    padding: 4px 8px;
    border-radius: 3px;
}
.div-template-fiftyone button:hover, .div-template-fiftyone button:focus {
    color: #fff;
    background-color: #061010;
    border: 1px solid transparent;
}
.div-template-fiftyone span, .div-template-fiftyone p {    
    color: #061010;    
}
.div-template-fiftyone p.item-name {
    letter-spacing: 1.0px;
    font-weight: 700;
    width:100%;    
    font-size: 18px;
    min-height: 115px;
}
.div-template-fiftyone .col-5 {    
    min-height: 200px;
    padding-top: 15px;
    padding-left: 30px;
}
.div-template-fiftyone .col-5 div.div-re-points {
    width:100%;       
}
.div-template-fiftyone p.item-points {
    font-size: 9px;
    font-weight: 700;
    text-transform: uppercase;
}
.div-template-fiftyone p.item-points strong {
    font-size: 16px;
    font-weight: 700 !important;
}
.div-template-fiftyone .btn-info {
    right: 20px;
    top: 10px;
    border: 0px;
    background-color: transparent;
    font-size: unset;
    position: absolute;
}

/* Template 52 */
.div-template-fiftytwo{
    border-radius: 15px;
    position: relative;
    overflow: hidden;    
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;    
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    min-height: 200px;
}

.div-template-fiftytwo button {
    font-size :10px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    color:#ffffff;
    border: 1px solid #ffffff;
    background-color:#690C28;
    padding: 4px 8px;
    border-radius: 3px;
}
.div-template-fiftytwo button:hover, .div-template-fiftytwo button:focus {
    color:#ffffff;
    background-color:#690C28;
    border: 1px solid #ffffff;
}

.div-template-fiftytwo .col-7, .div-template-fiftytwo .col-5 {
    height: 200px;
}

.div-template-fiftytwo .col-7 div, .div-template-fiftytwo .col-5 div {
    width:100%;
    padding: 10px 15px;
    background-color: #690C28;
    position: absolute;
    bottom: 0px;
    min-height: 66px;
}
.div-template-fiftytwo .col-5 div {
    right:0px;
    padding: 10px 15px 10px 30px;
}
.div-template-fiftytwo .col-7 div .item-name {
    color: #ffffff;
    font-weight: 600;
    margin-bottom: 0px;
    letter-spacing: 1.2px;
}
.div-template-fiftytwo .col-5 .item-points {
    color: #ffffff;
    text-transform: uppercase;
    margin-bottom: 0px;
    font-size: 10px;
}

.div-template-fiftytwo .col-5 .item-points span {
    font-size: 14px;
    font-weight: 600;
}

.div-template-fiftytwo .btn-info {
    right: 20px;
    top: 10px;
    border: 0px;
    background-color: transparent;
    font-size: unset;
    position: absolute;
}

/* Template 53 */

.div-template-fiftythree{
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    min-height: 200px;
}

.div-template-fiftythree .div-template-header {
    background-color: #66B7B8;
    padding: 10px 15px;
}
.div-template-fiftythree .div-template-header span {    
    font-weight: 600;
    font-size: 18px;
    color: #fff;
    letter-spacing: 1.5px;    
}

.div-template-fiftythree .div-template-footer {
    background-color: #F1F1F1;
    padding: 15px;
    width: 100%;
    position: absolute;
    bottom: 0px;
}
.div-template-fiftythree .div-template-footer button {
    font-size :10px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    color: #fff;
    border: 1px solid transparent;
    background-color: #66B7B8;
    padding: 4px 8px;
}
.div-template-fiftythree button:hover, .div-template-fiftythree button:focus {
    color: #fff;
    background-color:#66B7B8;
    border: 1px solid transparent;
}
.div-template-fiftythree .div-template-footer span {
    float: right;    
    letter-spacing: 1.2px;
    color: #66B7B8;
    font-weight: 700;
    margin-top: 2px;
}
.div-template-fiftythree .btn-info {
    right: 20px;
    top: 10px;
    border: 0px;
    background-color: transparent;
    font-size: unset;
    position: absolute;
}

/* Template 54 */
.div-template-fiftyfour{
    border-radius: 15px;
    position: relative;
    overflow: hidden;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;    
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    min-height: 200px;
}
.div-template-fiftyfour .col-md-8 {
    background-color:#1D1D19;
    opacity: 0.9;
    min-height: 200px;
    padding-top: 30px;
}
.div-template-fiftyfour button {
    font-size :10px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    color:#1B1A16;
    border: 1px solid#1B1A16;
    background-color:#F2CEA7;
    padding: 4px 8px;    
    border-radius: 3px;
}
.div-template-fiftyfour button:hover, .div-template-fiftyfour button:focus {
    color:#1B1A16;
    border: 1px solid #1B1A16;
    background-color:#F2CEA7;
}
.div-template-fiftyfour p.item-name {
    letter-spacing: 1.2px;
    font-weight: 400;
    color: #FFFFFF;
    min-height: 70px;
}
.div-template-fiftyfour p.item-points strong {
    font-size: 18px;
    font-weight: 600;
}
.div-template-fiftyfour p.item-points {
    font-size: 10px;
    margin-top: 30px;
    margin-bottom: 0px;
}

.div-template-fiftyfour .btn-info {
    right: 20px;
    top: 10px;
    border: 0px;
    background-color: transparent;
    font-size: unset;
    position: absolute;
}
/* media query for screen width 360 */
@media only screen and (max-width: 374px) and (min-width: 360px) {
    .div-template-fiftyfour button.btn_not {
        font-size: 9px;
    }
}
/* media query for screen width 320 */
@media only screen and (max-width: 359px) and (min-width: 320px) {    
    .div-template-fiftyfour button.btn_not {
        font-size: 8px;
    }
}

/* Template 55 */
.div-template-fiftyfive{
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    min-height: 200px;
}

.div-template-fiftyfive .div-template-footer {
    background-color: #272727;
    padding: 10px 15px;
    width: 65%;
    position: absolute;
    bottom: 0px;
    opacity: 0.95;
    border-top-right-radius: 15px;
}
.div-template-fiftyfive .div-template-footer button {
    font-size :10px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    color: #B4A27A;    
    background-color: #fff;
    padding: 4px 8px;    
    border-radius: 3px;
    margin-top: 10px;
}
.div-template-fiftyfive button:hover, .div-template-fiftyfive button:focus {
    color: #B4A27A;
    background-color:#fff;
}
.div-template-fiftyfive .div-template-footer span {
    letter-spacing: 1.2px;
    color: #FFFFFF;    
}
.div-template-fiftyfive .div-template-footer p.item-name {
    font-weight: 600;
    min-height: 40px;
}
.div-template-fiftyfive .div-template-footer p.item-name strong {
    font-weight: 600;
    display:block;
    font-size: 16px;
}
.div-template-fiftyfive .div-template-footer span.item-points {    
    font-weight: 300;
    text-transform: capitalize;
}
.div-template-fiftyfive .div-template-footer span.item-points strong {    
    font-weight: 600;
    font-size: 16px;
}

.div-template-fiftyfive .btn-info {
    right: 20px;
    top: 10px;
    border: 0px;
    background-color: transparent;
    font-size: unset;
    position: absolute;
}

/* Template 56 */
.div-template-fiftysix{
    border-radius: 15px;
    position: relative;
    overflow: hidden;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color: #96A202;    
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    min-height: 200px;
}

.div-template-fiftysix button {
    font-size :10px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    color: #E45017;
    border: 1px solid #E45017;
    background-color: #fff;
    padding: 4px 8px;
    border-radius: 3px;
}
.div-template-fiftysix button:hover, .div-template-fiftysix button:focus {
    color:#E45017;
    background-color: #fff;
    border: 1px solid #E45017;
}
.div-template-fiftysix span {    
    color: #fff;
    text-transform: uppercase;
}
.div-template-fiftysix span.item-name {
    letter-spacing: 1.2px;
    font-weight: 300;
    position: absolute;
    top: 30px;
    left:30px;
    width: 55%;
}
.div-template-fiftysix span.item-name strong {
    font-weight: 600;
}
.div-template-fiftysix p.item-points {
    font-size: 9px;
    margin-bottom: 5px;
    color:#fff;
    text-transform: capitalize;
}
.div-template-fiftysix p.item-points strong {
    font-size: 14px;
    margin-bottom: 0px;
    font-weight: 600;
}
.div-template-fiftysix .col-6 .div-left {
    padding-left: 30px;
    width: 100%;
    height: 0;    
    border-top: 200px solid #E45017;
    border-right: 50px solid transparent;
}

.div-template-fiftysix .col-6 .div-points-btn {
    position: absolute;
    bottom: 30px;
    left:30px;
}
.div-template-fiftysix .btn-info {
    right: 20px;
    top: 10px;
    border: 0px;
    background-color: transparent;
    font-size: unset;
    position: absolute;
}

/* Template 57 */
.div-template-fiftyseven{
    border-radius: 15px;
    position: relative;
    overflow: hidden;    
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;    
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    min-height: 200px;
}

.div-template-fiftyseven button {
    font-size :10px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    color: #F3A84A;
    border: 1px solid#F3A84A;
    background-color:#323232;
    padding: 4px 8px;
    border-radius: 3px;
}
.div-template-fiftyseven button:hover, .div-template-fiftyseven button:focus {
    color: #F3A84A;
    background-color:#323232;
    border: 1px solid #F3A84A;
}

.div-template-fiftyseven .col-6 {
    background-color: #F3A84A;
    height: 200px;
}
.div-template-fiftyseven p {
    color:#323232;
    text-transform: uppercase;
}

.div-template-fiftyseven p.item-name {
    letter-spacing: 1.0px;    
    width: 100%;
    padding-top: 30px;
    padding-left: 15px;
    font-weight: 500;
    min-height: 100px;
}
.div-template-fiftyseven p.item-name strong {
    font-weight: 700;
    font-size: 18px;
    display:block;
}
.div-template-fiftyseven .div-re-points{
    padding-top: 15px;
    padding-left: 15px;
}

.div-template-fiftyseven p.item-points {
    font-size: 9px;
    font-weight: 500;    
}
.div-template-fiftyseven p.item-points strong {
    font-size: 16px;
    font-weight: 700;
}
.div-template-fiftyseven .btn-info {
    right: 20px;
    top: 10px;
    border: 0px;
    background-color: transparent;
    font-size: unset;
    position: absolute;
}

/* Template 58 */
.div-template-fiftyeight{
    border-radius: 15px;
    position: relative;
    overflow: hidden;    
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;    
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    min-height: 200px;
}

.div-template-fiftyeight button {
    font-size :10px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    color:#292242;
    border: 1px solid #292242;
    background-color:#F3A84A;
    padding: 4px 8px;
    border-radius: 3px;
}
.div-template-fiftyeight button:hover, .div-template-fiftyeight button:focus {
    color:#292242;
    background-color:#F3A84A;
    border: 1px solid #292242;
}

.div-template-fiftyeight .col-7, .div-template-fiftyeight .col-5 {
    height: 200px;
}

.div-template-fiftyeight .col-7 div, .div-template-fiftyeight .col-5 div {
    width:100%;
    padding: 10px 15px;
    background-color: #292242;
    position: absolute;
    bottom: 0px;
}
.div-template-fiftyeight .col-5 div {
    right:0px;
    padding: 10px 15px 10px 30px;
}
.div-template-fiftyeight .col-7 div .item-name {
    color: #F3A84A;
    font-weight: 600;
    margin-bottom: 0px;
    letter-spacing: 1.2px;
}
.div-template-fiftyeight .col-5 .item-points {
    color: #F3A84A;
    text-transform: capitalize;
    margin-bottom: 0px;
}
.div-template-fiftyeight .btn-info {
    right: 20px;
    top: 10px;
    border: 0px;
    background-color: transparent;
    font-size: unset;
    position: absolute;
}

/* Template 59 */
.div-template-fiftynine{
    border-radius: 15px;
    position: relative;
    overflow: hidden;    
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;    
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    min-height: 200px;
}

.div-template-fiftynine button {
    font-size :10px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    color:#092533;
    border: 1px solid #092533;
    background-color:#ffffff;
    padding: 4px 8px;
    border-radius: 3px;
}
.div-template-fiftynine button:hover, .div-template-fiftynine button:focus {
    color:#092533;
    background-color:#ffffff;
    border: 1px solid #092533;
}

.div-template-fiftynine .col-7, .div-template-fiftynine .col-5 {
    height: 200px;
}

.div-template-fiftynine .col-7 div, .div-template-fiftynine .col-5 div {
    width:100%;
    padding: 10px 15px;
    background-color: #092533;
    position: absolute;
    bottom: 0px;
    min-height: 66px;
}
.div-template-fiftynine .col-5 div {
    right:0px;
    padding: 10px 15px 10px 30px;
}
.div-template-fiftynine .col-7 div .item-name {
    color: #ffffff;
    font-weight: 600;
    margin-bottom: 0px;
    letter-spacing: 1.2px;
    max-height: 48px;
    overflow: hidden;
}
.div-template-fiftynine .col-5 .item-points {
    color: #ffffff;
    text-transform: uppercase;
    margin-bottom: 0px;
    font-size: 10px;
}

.div-template-fiftynine .col-5 .item-points span {
    font-size: 14px;
    font-weight: 600;
}
.div-template-fiftynine .btn-info {
    right: 20px;
    top: 10px;
    border: 0px;
    background-color: transparent;
    font-size: unset;
    position: absolute;
}

/* Template 60 */
.div-template-sixty{
    border-radius: 15px;
    position: relative;
    overflow: hidden;    
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;    
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    min-height: 200px;
}
.div-template-sixty button {
    font-size :10px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    color: #721219;
    border: 1px solid #721219;
    background-color: #E6BBB3;
    padding: 4px 8px;
    border-radius: 3px;
}
.div-template-sixty button:hover, .div-template-sixty button:focus {
    color: #721219;
    background-color: #E6BBB3;
    border: 1px solid #721219;
}
.div-template-sixty span, .div-template-sixty p {    
    color: #E6BBB3;
    text-transform: uppercase;
}
.div-template-sixty p.item-name {
    letter-spacing: 1.0px;
    font-weight: 700;
    width:100%;    
    font-size: 15px;
    min-height: 105px;
}
.div-template-sixty .col-7.div-sixty-content {    
    min-height: 200px;    
    padding-left: 30px;
}
.div-template-sixty .col-8 {
    min-height: 200px;
    background-color: #721219;
    z-index: 1;
    padding-top: 20px;
}
.div-template-sixty .col-8 div.div-re-points {
    width:100%;   
    
}
.div-template-sixty span.item-points {
    font-size: 9px;    
}
.div-template-sixty span.item-points strong {
    font-size: 12px;
    font-weight: 700;
}
.div-template-sixty .col-7 {
    position: relative;
    overflow: hidden;
    min-height: 200px;
}

.div-template-sixty .col-4 div.semicircle-left {    
    position: absolute;
    top: 0; 
    bottom: 0;
    box-shadow: 0px 0px 0px 110px #721219;
    width: 100%
}

.div-template-sixty .col-4 div.semicircle-left-curve {   
    border-radius: 100% 0 0 25% / 50% 0  0 50%;
}
.div-template-sixty .btn-info {
    right: 20px;
    top: 10px;
    border: 0px;
    background-color: transparent;
    font-size: unset;
    position: absolute;
}
/* media query for screen width 360 */
@media only screen and (max-width: 374px) and (min-width: 360px) {
    .div-template-sixty button.btn_not {
        font-size: 9px;
    }
}
/* media query for screen width 320 */
@media only screen and (max-width: 359px) and (min-width: 320px) {    
    .div-template-sixty button.btn_not {
        font-size: 8px;
    }
}

/* Template 61 */
.div-template-sixtyone{
    border-radius: 15px;
    position: relative;
    overflow: hidden;    
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;    
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    min-height: 200px;
}

.div-template-sixtyone button {
    font-size :10px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    color: #fff;
    border: 1px solid #fff;
    background-color:#352C33;
    padding: 4px 8px;
    border-radius: 3px;
    margin-top: 15px;
    margin-left: 10px;
}
.div-template-sixtyone button:hover, .div-template-sixtyone button:focus {
    color: #fff;
    background-color:#352C33;
    border: 1px solid #fff;
}

.div-template-sixtyone .col-4 {
    background-color: #352C33;
    height: 200px;
}
.div-template-sixtyone p {
    color:#fff;    
}

.div-template-sixtyone p.item-name  {
    letter-spacing: 1.0px;    
    width: 100%;
    padding-top: 15px;
    padding-left: 10px;
    min-height: 90px;
    margin-bottom: 0px;
    max-height: 90px;
    overflow: hidden;
}
.div-template-sixtyone p.item-name span {
    font-weight: 400;
    font-size: 8px;
    display: block;
}
.div-template-sixtyone p.item-name strong {
    font-weight: 600;
    font-size: 16px;
}
.div-template-sixtyone .div-re-points{
    padding-top: 15px;    
    width: 60px;
    height: 60px;
    background-color: #fff;
    border-radius: 50%;
    margin-left: 20px;
    text-align: center;
}

.div-template-sixtyone p.item-points {    
    font-weight: 500;
    text-transform: uppercase;
    color: #352C33; 
    font-size: 10px;
}
.div-template-sixtyone p.item-points strong {    
    font-weight: 700;
    font-size: 12px;
}

.div-template-sixtyone .btn-info {
    left: 20px;
    top: 10px;
    border: 0px;
    background-color: transparent;
    font-size: unset;
    position: absolute;
    margin: 0px;
}
/* media query for screen width 360 */
@media only screen and (max-width: 374px) and (min-width: 360px) {
    .div-template-sixtyone button.btn_not {
        font-size: 9px;
    }
}
/* media query for screen width 320 */
@media only screen and (max-width: 359px) and (min-width: 320px) {    
    .div-template-sixtyone button.btn_not {
        font-size: 8px;
    }
}


/* Template 62 */
.div-template-sixtytwo{
    border-radius: 15px;
    position: relative;
    overflow: hidden;    
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;    
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    min-height: 200px;
}
.div-template-sixtytwo button {
    font-size :10px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    color: #EE3139;
    border: 1px solid #EE3139;
    background-color: #fff;
    padding: 4px 8px;
    border-radius: 3px;
}
.div-template-sixtytwo button:hover, .div-template-sixtytwo button:focus {
    color: #EE3139;
    background-color: #fff;
    border: 1px solid #EE3139;
}
.div-template-sixtytwo span, .div-template-sixtytwo p {    
    color: #fff;
}
.div-template-sixtytwo p.item-name {
    letter-spacing: 1.0px;    
    width:100%;    
    font-size: 12px;
    min-height: 105px;
    text-transform: uppercase;
}
.div-template-sixtytwo p.item-name strong {
    font-size: 16px;
    font-weight: 600;
    padding-left: 15%;
}
.div-template-sixtytwo .col-7.div-sixtytwo-content {    
    min-height: 200px;    
    padding-left: 0px;
}
.div-template-sixtytwo .col-8 {
    min-height: 200px;
    background-color: #EE3139;
    z-index: 1;
    padding-top: 20px;
}
.div-template-sixtytwo .col-8 div.div-re-points {
    width:100%;    
}
.div-template-sixtytwo span.item-points {
    font-size: 10px;
    text-transform: uppercase;
    padding-left: 15px;
    font-weight: 300;
}
.div-template-sixtytwo span.item-points strong {
    font-size: 10px;
    font-weight: 700;
}
.div-template-sixtytwo .col-7 {
    position: relative;
    overflow: hidden;
    min-height: 200px;
}

.div-template-sixtytwo .col-4 div.semicircle-right {    
    position: absolute;
    top: 0; 
    bottom: 0;
    box-shadow: 0px 0px 0px 110px #EE3139;
    width: 100%
}

.div-template-sixtytwo .col-4 div.semicircle-right-curve {   
    border-radius: 0 140% 60% 0 / 0 60% 100% 0;
}
.div-template-sixtytwo .btn-info {
    left: 20px;
    top: 10px;
    border: 0px;
    background-color: transparent;
    font-size: unset;
    position: absolute;
}

/* Template 63 */
.div-template-sixtythree{
    border-radius: 15px;
    position: relative;
    overflow: hidden;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color: #F6399B;    
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    min-height: 200px;
}
/* .div-template-sixtythree img {
    max-width:100%;
} */
.div-template-sixtythree button {
    font-size :10px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    color: #212121;
    border: 1px solid #212121;
    background-color: #FFF301;
    padding: 4px 8px;    
    border-radius: 3px;    
}
.div-template-sixtythree button:hover, .div-template-sixtythree button:focus {
    color: #212121;
    background-color: #FFF301;
    border: 1px solid #212121;
}
.div-template-sixtythree span, .div-template-sixtythree p {    
    color: #FFFFFF;
    text-transform: uppercase;
}
.div-template-sixtythree span.item-name {
    letter-spacing: 1.2px;
    font-weight: 500;
}
.div-template-sixtythree p.item-points-text {
    font-size: 9px;
}
.div-template-sixtythree p.item-points {
    font-size: 14px;
    margin-bottom: 0px;
    font-weight: 600;
}
.div-template-sixtythree .col-5 div {
    width:100%;
    position: absolute;
    bottom:15px;    
}
.div-template-sixtythree .col-5 {
    padding-left: 30px;
    height:200px;
    background-color: #212121;
}
.div-template-sixtythree .col-7 .triangle-right {
	width: 0;
	height: 0;
	border-top: 7px solid transparent;
	border-right: 8px solid #212121;
    border-bottom: 7px solid transparent;
    margin-top: 93px;
    right:0px;
    position: absolute;
}
.div-template-sixtythree .btn-info {
    left: 20px;
    top: 10px;
    border: 0px;
    background-color: transparent;
    font-size: unset;
    position: absolute;
}

/* Template 64 */
.div-template-sixtyfour{
    border-radius: 15px;
    position: relative;
    overflow: hidden;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    min-height: 200px;
}

.div-template-sixtyfour button {
    font-size :10px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    color: #A15726;
    border: 1px solid #A15726;
    background-color: #fff;
    padding: 4px 8px;
    border-radius: 3px;
}
.div-template-sixtyfour button:hover, .div-template-sixtyfour button:focus {
    color: #A15726;
    background-color:#fff;
    border: 1px solid #A15726;
}
.div-template-sixtyfour span, .div-template-sixtyfour p {    
    color: #fff;
    text-transform: uppercase;
}
.div-template-sixtyfour span.item-name {
    letter-spacing: 1.2px;
    font-weight: 300;
    position: absolute;
    top: 15px;
    left:40px;
}
.div-template-sixtyfour p.item-points-text {
    font-size: 9px;
    margin-bottom: 5px;
}
.div-template-sixtyfour p.item-points {
    font-size: 14px;
    margin-bottom: 0px;
    font-weight: 600;
}
.div-template-sixtyfour .col-6 .div-right {
    padding-left: 30px;
    width: 100%;
    height: 0;    
    border-top: 200px solid #A15726;
    border-left: 25px solid transparent;
}

.div-template-sixtyfour .col-6 .div-points-btn {
    position: absolute;
    bottom: 30px;
    left:40px;
}
.div-template-sixtyfour .btn-info {
    left: 20px;
    top: 10px;
    border: 0px;
    background-color: transparent;
    font-size: unset;
    position: absolute;
}

/* Template 65 */
.div-template-sixtyfive{
    border-radius: 15px;
    position: relative;
    overflow: hidden;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;    
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    min-height: 200px;
}
.div-template-sixtyfive .col-md-8 {
    background-color: #CBCB72;
    opacity: 0.9;
    min-height: 200px;
    padding-top: 15px;
}
.div-template-sixtyfive button {
    font-size :10px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    color:#CBCB72;
    border: 1px solid#CBCB72;
    background-color: #4F5016;
    padding: 4px 8px;    
    border-radius: 3px;
    margin-top: 15px;  
}
.div-template-sixtyfive button:hover, .div-template-sixtyfive button:focus {
    color:#CBCB72;
    border: 1px solid #CBCB72;
    background-color: #4F5016;
}
.div-template-sixtyfive span.item-name {
    letter-spacing: 1.2px;
    font-weight: 400;
    color: #FFFFFF;
}
.div-template-sixtyfive p.item-points-text {
    font-size: 14px;
    letter-spacing: 1.2px;
    text-transform: uppercase;
}
.div-template-sixtyfive p.item-points {
    font-size: 24px;
    margin-bottom: 0px;
    font-weight: 600;
    margin-top: 15px;
}
.div-template-sixtyfive .btn-info {
    left: 20px;
    top: 10px;
    border: 0px;
    background-color: transparent;
    font-size: unset;
    position: absolute;
}

/* media query for screen width 360 */
@media only screen and (max-width: 374px) and (min-width: 360px) {
    .div-template-sixtyfive button.btn_not {
        font-size: 9px;
    }
}
/* media query for screen width 320 */
@media only screen and (max-width: 359px) and (min-width: 320px) {    
    .div-template-sixtyfive button.btn_not {
        font-size: 8px;
    }
}

/* Template 66 */
.div-template-sixtysix{
    border-radius: 15px;
    position: relative;
    overflow: hidden;    
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;    
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    min-height: 200px;
}
.div-template-sixtysix button {
    font-size :10px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    color: #585E52;
    border: 1px solid #212121;
    background-color: #E0BE55;
    padding: 4px 8px;
    border-radius: 3px;
}
.div-template-sixtysix button:hover, .div-template-sixtysix button:focus {
    color: #585E52;
    background-color:#E0BE55;
    border: 1px solid #212121;
}
.div-template-sixtysix span, .div-template-sixtysix p {    
    color: #E0BE55;
}
.div-template-sixtysix span.item-name {
    letter-spacing: 1.0px;
    position: absolute;
    width: 70%;
    top: 20px;
    left: 30px;
    text-transform: uppercase;  
}
.div-template-sixtysix span.item-name strong {
    font-weight: 600;
    font-size: 18px;
    display:block;
}
.div-template-sixtysix .col-6 {
    height:200px;
}
.div-template-sixtysix .col-6 div.div-re-points {
    width:100%;   
    position: absolute;    
    left: 30px;
    bottom: 15px;
}
.div-template-sixtysix p.item-points {
    font-size: 9px;
    font-weight: 300;
    text-transform: uppercase;
}
.div-template-sixtysix p.item-points strong {
    font-size: 16px;
    font-weight: 600;
}
/*Right pointing*/
.div-template-sixtysix .col-6 div.triangle-left {
    width: 100%;
    height: 200px;
    border-top: 10px solid transparent;
    border-left: 150px solid #212121;
    border-bottom: 60px solid transparent;
    position: absolute;
    top: 15px;
}
.div-template-sixtysix .btn-info {
    right: 20px;
    top: 10px;
    border: 0px;
    background-color: transparent;
    font-size: unset;
    position: absolute;
}

/* Template 67 */
.div-template-sixtyseven{
    border-radius: 15px;
    position: relative;
    overflow: hidden;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    min-height: 200px;
}

.div-template-sixtyseven .div-circle {
    width: 220px;
    height: 270px;
    background-color: #F83238;
    border-radius: 50%;
    position: absolute;
    top: -140px;
    left: 8px;
}

.div-template-sixtyseven button {
    font-size :10px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    color: #F83238;
    border: 1px solid #F83238;
    background-color: #FFFfff;
    padding: 4px 8px;
    border-radius: 3px;
}
.div-template-sixtyseven button:hover, .div-template-sixtyseven button:focus {
    color: #F83238;
    background-color:#FFFfff;
    border: 1px solid #F83238;
}
.div-template-sixtyseven span, .div-template-sixtyseven p {       
    
}
.div-template-sixtyseven .div-content {
    width: 210px;
    padding-top:5px;
    padding-left: 20px;
    padding-right: 20px;
    position: absolute;
    top: 0px;
    left: 15px;
}
.div-template-sixtyseven .div-content div {
    width:100%;
    text-align: center;
}

.div-template-sixtyseven .div-content .item-points {
    color: #ffffff;
    font-weight: 300;
    margin-bottom: 0px;
    text-align: center;
    text-transform: uppercase;
}

.div-template-sixtyseven p.item-name {
    letter-spacing: 1.2px;
    font-weight: 600;
    color:#fff;
    min-height: 40px;
    font-size: 14px;
}
.div-template-sixtyseven .btn-info {
    right: 20px;
    top: 10px;
    border: 0px;
    background-color: transparent;
    font-size: unset;
    position: absolute;
}

/* Template 68 */
.div-template-sixtyeight{
    border-radius: 15px;
    position: relative;
    overflow: hidden;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;      
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    min-height: 200px;
}

.div-template-sixtyeight button {
    font-size :10px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    color: #C05653;
    border: 1px solid #C05653;
    background-color: #fff;
    padding: 4px 8px;
    border-radius: 3px;
}
.div-template-sixtyeight button:hover, .div-template-sixtyeight button:focus {
    color:#C05653;
    background-color: #fff;
    border: 1px solid #C05653;
}
.div-template-sixtyeight p {    
    color: #fff;
    text-transform: uppercase;
}
.div-template-sixtyeight span.item-name {
    font-family: 'Pacifico', cursive;
    font-size: 24px;
    letter-spacing: 1.2px;
    font-weight: 300;
    position: absolute;
    top: 15px;
    left:30px;
    color: #fff;
}
.div-template-sixtyeight p.item-points {
    font-size: 9px;
    margin-bottom: 5px;
}
.div-template-sixtyeight p.item-points strong {
    font-size: 14px;
    margin-bottom: 0px;
    font-weight: 600;
}
.div-template-sixtyeight .col-8 .div-left {
    padding-right: 30px;
    width: 100%;
    height: 0;    
    border-top: 200px solid #C05653;
    border-left: 100px solid transparent;
}

.div-template-sixtyeight .col-8 .div-points-btn {
    position: absolute;
    bottom: 30px;
    right:30px;
}
.div-template-sixtyeight .btn-info {
    left: 20px;
    bottom: 10px;
    border: 0px;
    background-color: transparent;
    font-size: unset;
    position: absolute;
}


/* Template 69 */
.div-template-sixtynine{
    border-radius: 15px;
    position: relative;
    overflow: hidden;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    min-height: 200px;
}

.div-template-sixtynine .div-circle {
    width: 220px;
    height: 270px;
    background-color: #556B22;
    border-radius: 50%;
    position: absolute;
    top: -150px;
    left: -8px;
}

.div-template-sixtynine button {
    font-size :10px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    color: #556B22;
    border: 1px solid #556B22;
    background-color: #FFF301;
    padding: 4px 8px;
    border-radius: 3px;
}
.div-template-sixtynine button:hover, .div-template-sixtynine button:focus {
    color: #556B22;
    background-color:#FFF301;
    border: 1px solid #556B22;
}
.div-template-sixtynine span, .div-template-sixtynine p {       
    text-transform: uppercase;
}
.div-template-sixtynine .div-content {
    width: 200px;
    padding-top:5px;
    padding-left: 20px;
    padding-right: 20px;
    position: absolute;
    top: 0px;
    left: 0px;
}
.div-template-sixtynine .div-content div {
    width:100%;
    text-align: center;
}

.div-template-sixtynine .div-content .item-points {
    color: #FFF301;
    font-weight: 600;
    margin-bottom: 0px;
}
.div-template-sixtynine .div-content .item-points span {
    font-size: 9px;
    font-weight:400;
}
.div-template-sixtynine p.item-name {
    letter-spacing: 1.2px;
    font-weight: 600;
    color:#fff;
    min-height: 40px;
    font-size: 14px;
    margin-bottom: 0px;
}
.div-template-sixtynine .btn-info {
    right: 20px;
    bottom: 10px;
    border: 0px;
    background-color: transparent;
    font-size: unset;
    position: absolute;
}

/* Template 70 */
.div-template-seventy{
    border-radius: 15px;
    position: relative;
    overflow: hidden;    
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;    
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    min-height: 200px;
}
.div-template-seventy button {
    font-size :10px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    color: #0397A4;
    border: 1px solid #0397A4;
    background-color: #fff;
    padding: 4px 8px;
    border-radius: 3px;
}
.div-template-seventy button:hover, .div-template-seventy button:focus {
    color: #0397A4;
    background-color: #fff;
    border: 1px solid #0397A4;
}
.div-template-seventy span, .div-template-seventy p {    
    color: #fff;
}
.div-template-seventy p.item-name {
    letter-spacing: 1.0px;    
    width:100%;    
    font-size: 12px;
    min-height: 105px;
    text-transform: uppercase;
}
.div-template-seventy p.item-name strong {
    font-size: 16px;
    font-weight: 600;
    padding-left: 15%;
}
.div-template-seventy .col-7.div-seventy-content {    
    min-height: 200px;    
    padding-left: 0px;
}
.div-template-seventy .col-8 {
    min-height: 200px;
    background-color: #0397A4;
    z-index: 1;
    padding-top: 20px;
}
.div-template-seventy .col-8 div.div-re-points {
    width:100%;    
}
.div-template-seventy span.item-points {
    font-size: 10px;
    text-transform: uppercase;
    padding-left: 15px;
    font-weight: 300;
}
.div-template-seventy span.item-points strong {
    font-size: 10px;
    font-weight: 700;
}
.div-template-seventy .col-7 {
    position: relative;
    overflow: hidden;
    min-height: 200px;
}

.div-template-seventy .col-4 div.semicircle-right {    
    position: absolute;
    top: 0; 
    bottom: 0;
    box-shadow: 0px 0px 0px 110px #0397A4;
    width: 100%
}

.div-template-seventy .col-4 div.semicircle-right-curve {   
    border-radius: 0 140% 60% 0 / 0 60% 100% 0;
}
.div-template-seventy .btn-info {
    left: 20px;
    bottom: 10px;
    border: 0px;
    background-color: transparent;
    font-size: unset;
    position: absolute;
}

/* Template 71 */
.div-template-seventyone{
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    min-height: 200px;
}

.div-template-seventyone .div-template-header {
    background-color: #33557D;
    padding: 10px 15px;
}
.div-template-seventyone .div-template-header span {    
    font-weight: 600;
    font-size: 18px;
    color: #fff;
    letter-spacing: 1.5px;
    text-transform: uppercase;
}

.div-template-seventyone .div-template-footer {
    background-color: #f1f1f1;
    padding: 15px;
    width: 100%;
    position: absolute;
    bottom: 0px;
}
.div-template-seventyone .div-template-footer button {
    font-size :10px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    padding: 4px 8px;
	border: 1px solid #33557D;
    background-color: transparent;
	color: #33557D;
}
.btn_sec1{
	border: 1px solid #33557D;
    background-color: transparent;
	color: #33557D;
}	

.div-template-seventyone .div-template-footer span {
    float: right;    
    letter-spacing: 1.2px;
    color: #33557D;
    font-weight: 800;
    margin-top: 2px;
    text-transform: uppercase;
}

.div-template-seventyone .btn-info {
    right: 20px;
    top: 10px;
    border: 0px;
    background-color: transparent;
    font-size: unset;
    position: absolute;
}

/* Template 72 */
.div-template-seventytwo{
    border-radius: 15px;
    position: relative;
    overflow: hidden;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color:#F6CD3E;    
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    min-height: 200px;
}

.div-template-seventytwo button {
    font-size :10px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    color: #72229D;
    border: 1px solid #72229D;
    background-color: #D5C2DF;
    padding: 4px 8px;
    border-radius: 3px;
}
.div-template-seventytwo button:hover, .div-template-seventytwo button:focus {
    color:#72229D;
    background-color:#D5C2DF;
    border: 1px solid #72229D;
}
.div-template-seventytwo p {    
    color: #D5C2DF;
    text-transform: uppercase;
}
.div-template-seventytwo span.item-name {    
    letter-spacing: 1.2px;
    font-weight: 700;
    color: #D5C2DF;
    font-size: 20px;
}
.div-template-seventytwo p.item-points span {
    font-size: 10px;
    margin-bottom: 0px;
    text-transform: uppercase;
    letter-spacing: 1.2px;
}
.div-template-seventytwo p.item-points {
    font-size: 12px;
    margin-bottom: 0px;
    font-weight: 600;
    letter-spacing: 1.2px;
}
.div-template-seventytwo .col-5 div {
    width:100%;
    position: absolute;
    bottom:30px;    
}
.div-template-seventytwo .col-5 {
    padding-left: 30px;
    padding-top: 25px;
    height:200px;
    background-color:#72229D;
    border-bottom-left-radius: 30% 100%;
}
.div-template-seventytwo .btn-info {
    left: 20px;
    bottom: 10px;
    border: 0px;
    background-color: transparent;
    font-size: unset;
    position: absolute;
}

/* Template 73 */
.div-template-seventythree{
    border-radius: 15px;
    position: relative;
    overflow: hidden;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color: #581B98;    
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    min-height: 200px;
}

.div-template-seventythree button {
    font-size :10px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    color: #581B98;
    border: 1px solid #581B98;
    background-color: #fff;
    padding: 4px 8px;
    border-radius: 3px;
}
.div-template-seventythree button:hover, .div-template-seventythree button:focus {
    color:#581B98;
    background-color: #fff;
    border: 1px solid #581B98;
}
.div-template-seventythree span, .div-template-seventythree p {    
    color: #fff;
    font-weight: 600;
    font-size: 12px;
}
.div-template-seventythree span.item-name {
    letter-spacing: 1.2px;
    font-weight: 300;
    position: absolute;
    top: 15px;
    left:30px;
}
.div-template-seventythree p.item-points-text {
    font-size: 9px;
    margin-bottom: 5px;
}
.div-template-seventythree p.item-points {
    font-size: 14px;
    margin-bottom: 0px;
    font-weight: 600;
}
.div-template-seventythree .col-5 .div-left {
    padding-left: 30px;
    width: 100%;
    height: 0;    
    border-top: 200px solid #581B98;
    border-right: 25px solid transparent;
}

.div-template-seventythree .col-5 .div-points-btn {
    position: absolute;
    bottom: 30px;
    left:30px;
}
.div-template-seventythree .btn-info {
    right: 20px;
    bottom: 10px;
    border: 0px;
    background-color: transparent;
    font-size: unset;
    position: absolute;
}
/* media query for screen width 320 */
@media only screen and (max-width: 359px) and (min-width: 320px) {    
    .div-template-seventythree button.btn_not {
        font-size: 8px;
    }
}

/* Template 74 */
.div-template-seventyfour{
    border-radius: 15px;
    position: relative;
    overflow: hidden;    
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;    
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    min-height: 200px;
}
.div-template-seventyfour button {
    font-size :10px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    color: #A81F23;
    border: 1px solid #A81F23;
    background-color: #FBE7F4;
    padding: 4px 8px;    
    border-radius: 3px;
    position: absolute;
    bottom: 35px;
    right: 60px;
}
.div-template-seventyfour button:hover, .div-template-seventyfour button:focus {
    color: #A81F23;
    background-color: #FBE7F4;
    border: 1px solid #A81F23;
}
.div-template-seventyfour p {    
    color: #FBE7F4;
    text-transform: uppercase;
}
.div-template-seventyfour span {    
    color: #FBE7F4;
}
.div-template-seventyfour span.item-name {
    letter-spacing: 1.2px;
    font-weight: 500;
    width: 70%;
    position: absolute;
    top: 12%;
    left: 20%;
    font-size: 12px;
}
.div-template-seventyfour .div-points {
    position: absolute;
    top:120px;
    right:60px;
    padding-top: 0px;
}
.div-template-seventyfour .div-points p {
    font-size: 14px;
    margin-bottom: 0px;
}
.div-template-seventyfour .div-points p strong {
    font-size: 14px;
}
.div-template-seventyfour .btn-info {
    left: 20px;
    bottom: 10px;
    border: 0px;
    background-color: transparent;
    font-size: unset;
    position: absolute;
}

/*Right pointing*/
.div-template-seventyfour .col-6 div.half-circle {
    width: 100%;
    height: 200px;
    background-color: #A81F23;
    border-top-left-radius: 30% 50%;
    border-bottom-left-radius: 30% 50%;    
    border-bottom: 0;
}
/* media query for screen width 320 */
@media only screen and (max-width: 359px) and (min-width: 320px) {    
    .div-template-seventyfour button.btn_not {
        font-size: 9px;
        right:45px;
    }
}

/* Template 75 */
.div-template-seventyfive{
    border-radius: 15px;
    position: relative;
    overflow: hidden;    
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;    
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    min-height: 200px;
}
.div-template-seventyfive button {
    font-size :10px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    color: #64326D;
    border: 1px solid #64326D;
    background-color: #ffffff;
    padding: 4px 8px;    
    border-radius: 3px;
    position: absolute;
    bottom: 35px;
    right: 60px;
}
.div-template-seventyfive button:hover, .div-template-seventyfive button:focus {
    color: #64326D;
    background-color: #ffffff;
    border: 1px solid #64326D;
}
.div-template-seventyfive p {    
    color: #ffffff;
    text-transform: uppercase;
}
.div-template-seventyfive span {    
    color: #ffffff;
}
.div-template-seventyfive span.item-name {
    letter-spacing: 1.2px;
    font-weight: 500;
    width: 70%;
    position: absolute;
    top: 12%;
    left: 20%;
    font-size: 12px;
}
.div-template-seventyfive .div-points {
    position: absolute;
    top:120px;
    right:60px;
    padding-top: 0px;
}
.div-template-seventyfive .div-points p {
    font-size: 14px;
    margin-bottom: 0px;
}
.div-template-seventyfive .div-points p strong {
    font-size: 14px;
}
.div-template-seventyfive .btn-info {
    right: 20px;
    bottom: 10px;
    border: 0px;
    background-color: transparent;
    font-size: unset;
    position: absolute;
}

/*Right pointing*/
.div-template-seventyfive .col-6 div.half-circle {
    width: 100%;
    height: 200px;
    background-color: #64326D;
    border-top-right-radius: 30% 50%;
    border-bottom-right-radius: 30% 50%;    
    border-bottom: 0;
}
/* media query for screen width 320 */
@media only screen and (max-width: 359px) and (min-width: 320px) {    
    .div-template-seventyfive button.btn_not {
        font-size: 9px;
        right:45px;
    }
}

/* Template 76 */
.div-template-seventysix{
    border-radius: 15px;
    position: relative;
    overflow: hidden;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color: #F6399B;    
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    min-height: 200px;
}
/* .div-template-seventysix img {
    max-width:100%;
} */
.div-template-seventysix button {
    font-size :10px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    color: #D4974C;
    border: 1px solid #D4974C;
    background-color: #FFFFFF;
    padding: 4px 8px;    
    border-radius: 3px;    
}
.div-template-seventysix button:hover, .div-template-seventysix button:focus {
    color: #D4974C;
    background-color: #ffffff;
    border: 1px solid #D4974C;
}
.div-template-seventysix span, .div-template-seventysix p {    
    color: #FFFFFF;    
}
.div-template-seventysix span.item-name {
    letter-spacing: 1.2px;
    font-weight: 500;
    font-size: 12px;
}
.div-template-seventysix p.item-points-text {    
    text-transform: uppercase;
    font-size: 14px;
    margin-bottom: 0px;
}
.div-template-seventysix .col-5 div {
    width:100%;
    position: absolute;
    bottom:15px;    
}
.div-template-seventysix .col-5 {
    padding-left: 30px;
    height:200px;
    background-color: #D4974C;
}
.div-template-seventysix .col-7 .triangle-right {
	width: 0;
	height: 0;
	border-top: 7px solid transparent;
	border-left: 8px solid #D4974C;
    border-bottom: 7px solid transparent;
    margin-top: 93px;
    left:0px;
    position: absolute;
}

.div-template-seventysix .btn-info {
    right: 20px;
    top: 10px;
    border: 0px;
    background-color: transparent;
    font-size: unset;
    position: absolute;
}

/* Template 77 */
.div-template-seventyseven{
    border-radius: 15px;
    position: relative;
    overflow: hidden;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color: #F6399B;    
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    min-height: 200px;
}
.div-template-seventyseven button {
    font-size :10px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    color: #899134;
    border: 1px solid #899134;
    background-color: #FFFFFF;
    padding: 4px 8px;    
    border-radius: 3px;    
}
.div-template-seventyseven button:hover, .div-template-seventyseven button:focus {
    color: #899134;
    background-color: #ffffff;
    border: 1px solid #899134;
}
.div-template-seventyseven span, .div-template-seventyseven p {    
    color: #FFFFFF;    
}
.div-template-seventyseven span.item-name {
    letter-spacing: 1.2px;
    font-weight: 500;
    font-size: 12px;
}
.div-template-seventyseven p.item-points-text {    
    text-transform: uppercase;
    font-size: 14px;
    margin-bottom: 0px;
}
.div-template-seventyseven .col-5 div {
    width:100%;
    position: absolute;
    bottom:15px;    
}
.div-template-seventyseven .col-5 {
    padding-left: 30px;
    height:200px;
    background-color: #899134;
}
.div-template-seventyseven .col-7 .triangle-right {
	width: 0;
	height: 0;
	border-top: 7px solid transparent;
	border-right: 8px solid #899134;
    border-bottom: 7px solid transparent;
    margin-top: 93px;
    right:0px;
    position: absolute;
}
.div-template-seventyseven .btn-info {
    left: 20px;
    top: 10px;
    border: 0px;
    background-color: transparent;
    font-size: unset;
    position: absolute;
}
/* media query for screen width 320 */
@media only screen and (max-width: 359px) and (min-width: 320px) {    
    .div-template-seventyseven button.btn_not {
        font-size: 9px;
    }
}

/* Template 78 */
.div-template-seventyeight{
    border-radius: 15px;
    position: relative;
    overflow: hidden;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;     
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    min-height: 200px;
}

.div-template-seventyeight button {
    font-size :10px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    color: #FD9500;
    border: 1px solid #FD9500;
    background-color: #ffffff;
    padding: 4px 8px;
    border-radius: 3px;
}
.div-template-seventyeight button:hover, .div-template-seventyeight button:focus {
    color: #FD9500;
    background-color: #ffffff;
    border: 1px solid #FD9500;
}
.div-template-seventyeight span, .div-template-seventyeight p {    
    color: #ffffff;   
}
.div-template-seventyeight span.item-name {
    letter-spacing: 1.2px;
    font-weight: 500;
    font-size: 12px;
}
.div-template-seventyeight p.item-points-text {
    font-size: 14px;
    text-transform: uppercase;
    margin-bottom: 0px;
}

.div-template-seventyeight .col-5 div {
    width:100%;
    position: absolute;
    bottom:30px;    
}
.div-template-seventyeight .col-5 {
    padding-left: 30px;
    height:200px;
    background-color: #FD9500;
    border-bottom-right-radius: 30% 100%;
}
.div-template-seventyeight .btn-info {
    right: 20px;
    bottom: 10px;
    border: 0px;
    background-color: transparent;
    font-size: unset;
    position: absolute;
}
/* media query for screen width 320 */
@media only screen and (max-width: 359px) and (min-width: 320px) {    
    .div-template-seventyeight button.btn_not {
        font-size: 9px;
    }
}

/* Template 79 */
.div-template-seventynine{
    border-radius: 15px;
    position: relative;
    overflow: hidden;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;     
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    min-height: 200px;
}

.div-template-seventynine button {
    font-size :10px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    color: #E57B0E;
    border: 1px solid #E57B0E;
    background-color: #ffffff;
    padding: 4px 8px;
    border-radius: 3px;
}
.div-template-seventynine button:hover, .div-template-seventynine button:focus {
    color: #E57B0E;
    background-color: #ffffff;
    border: 1px solid #E57B0E;
}
.div-template-seventynine span, .div-template-seventynine p {    
    color: #ffffff;   
}
.div-template-seventynine span.item-name {
    letter-spacing: 1.2px;
    font-weight: 500;
    font-size: 12px;
}
.div-template-seventynine p.item-points-text {
    font-size: 14px;
    text-transform: uppercase;
    margin-bottom: 0px;
}

.div-template-seventynine .col-5 div {
    width:100%;
    position: absolute;
    bottom:30px;    
}
.div-template-seventynine .col-5 {
    padding-left: 30px;
    height:200px;
    background-color: #E57B0E;
    border-bottom-left-radius: 30% 100%;
}
.div-template-seventynine .btn-info {
    left: 20px;
    bottom: 10px;
    border: 0px;
    background-color: transparent;
    font-size: unset;
    position: absolute;
}
/* media query for screen width 320 */
@media only screen and (max-width: 359px) and (min-width: 320px) {    
    .div-template-seventynine button.btn_not {
        font-size: 9px;
    }
}

/* Template 80 */
.div-template-eighty{
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    min-height: 200px;
}

.div-template-eighty .div-template-footer {
    background-color: #4B9AAC;
    padding: 5px 15px;
    width: 100%;
    position: absolute;
    bottom: 0px;
}
.div-template-eighty .div-template-footer button {
    font-size :10px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    color: #4B9AAC;
    border: 1px solid #4B9AAC;
    background-color: #fff;
    padding: 4px 8px;
    float:right;
    border-radius: 3px;
    margin-top: 2px;
}
.div-template-eighty .div-template-footer span, .div-template-eighty .div-template-footer p {
    letter-spacing: 1.2px;
    color: #FFFFFF;    
}
.div-template-eighty .div-template-footer p.item-name {
    font-weight: 500;
    margin-bottom: 0px;
    max-height: 52px;
    overflow: hidden;
    font-size: 12px;
}
.div-template-eighty .div-template-footer span.item-points {    
    font-weight: 300;
    text-transform: uppercase;
}
.div-template-eighty .btn-info {
    right: 20px;
    top: 10px;
    border: 0px;
    background-color: transparent;
    font-size: unset;
    position: absolute;
}
/* media query for screen width 375 */
@media only screen and (max-width: 410px) and (min-width: 375px) {
    .div-template-eighty .div-template-footer span.item-points {
        font-size: 15px;
    }
    .div-template-eighty button.btn_not {
        font-size: 9px;
    }
}
/* media query for screen width 360 */
@media only screen and (max-width: 374px) and (min-width: 360px) {
    .div-template-eighty .div-template-footer span.item-points {
        font-size: 14px;
    }
    .div-template-eighty button.btn_not {
        font-size: 9px;
    }
}
/* media query for screen width 320 */
@media only screen and (max-width: 359px) and (min-width: 320px) {
    .div-template-eighty .div-template-footer span.item-points {
        font-size: 14px;
    }
    .div-template-eighty button.btn_not {
        font-size: 8px;
    }
}

/* Template 81 */
.div-template-eightyone{
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    min-height: 200px;
}

.div-template-eightyone .div-template-footer {
    background-color: #E52A59;
    padding: 5px 15px;
    width: 100%;
    position: absolute;
    top: 0px;
}
.div-template-eightyone .div-template-footer button {
    font-size :10px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    color: #E52A59;
    border: 1px solid #E52A59;
    background-color: #fff;
    padding: 4px 8px;
    float:right;
    border-radius: 3px;
    margin-top: 2px;
}
.div-template-eightyone .div-template-footer span, .div-template-eightyone .div-template-footer p {
    letter-spacing: 1.2px;
    color: #FFFFFF;    
}
.div-template-eightyone .div-template-footer p.item-name {
    font-weight: 500;
    margin-bottom: 0px;
    max-height: 52px;
    overflow: hidden;
    font-size: 12px;
}
.div-template-eightyone .div-template-footer span.item-points {    
    font-weight: 300;
    text-transform: uppercase;
}
.div-template-eightyone .btn-info {
    left: 20px;
    bottom: 10px;
    border: 0px;
    background-color: transparent;
    font-size: unset;
    position: absolute;
}
/* media query for screen width 375 */
@media only screen and (max-width: 410px) and (min-width: 375px) {
    .div-template-eightyone .div-template-footer span.item-points {
        font-size: 15px;
    }
    .div-template-eightyone button.btn_not {
        font-size: 9px;
    }
}
/* media query for screen width 360 */
@media only screen and (max-width: 374px) and (min-width: 360px) {
    .div-template-eightyone .div-template-footer span.item-points {
        font-size: 14px;
    }
    .div-template-eightyone button.btn_not {
        font-size: 9px;
    }
}
/* media query for screen width 320 */
@media only screen and (max-width: 359px) and (min-width: 320px) {
    .div-template-eightyone .div-template-footer span.item-points {
        font-size: 14px;
    }
    .div-template-eightyone button.btn_not {
        font-size: 8px;
    }
}

/* Template 82 */
.div-template-eightytwo{
    border-radius: 15px;
    position: relative;
    overflow: hidden;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;      
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    min-height: 200px;
}

.div-template-eightytwo button {
    font-size :10px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    color: #B63303;
    border: 1px solid #B63303;
    background-color: #fff;
    padding: 4px 8px;
    border-radius: 3px;
}
.div-template-eightytwo button:hover, .div-template-eightytwo button:focus {
    color:#B63303;
    background-color: #fff;
    border: 1px solid #B63303;
}
.div-template-eightytwo p {    
    color: #fff;
    text-transform: uppercase;
}
.div-template-eightytwo span.item-name {
    
    font-size: 12px;
    letter-spacing: 1.2px;
    font-weight: 500;
    position: absolute;
    top: 15px;
    left:30px;
    color: #fff;
    width: 55%;
}
.div-template-eightytwo p.item-points {
    font-size: 14px;
    margin-bottom: 5px;
}
.div-template-eightytwo .col-8 .div-left {
    padding-right: 30px;
    width: 100%;
    height: 0;    
    border-top: 200px solid #B63303;
    border-right: 100px solid transparent;
}

.div-template-eightytwo .col-8 .div-points-btn {
    position: absolute;
    bottom: 30px;
    left:30px;
}
.div-template-eightytwo .btn-info {
    right: 20px;
    bottom: 10px;
    border: 0px;
    background-color: transparent;
    font-size: unset;
    position: absolute;
}

/* Template 83 */
.div-template-eightythree{
    border-radius: 15px;
    position: relative;
    overflow: hidden;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;      
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    min-height: 200px;
}

.div-template-eightythree button {
    font-size :10px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    color: #011C46;
    border: 1px solid #011C46;
    background-color: #fff;
    padding: 4px 8px;
    border-radius: 3px;
}
.div-template-eightythree button:hover, .div-template-eightythree button:focus {
    color:#011C46;
    background-color: #fff;
    border: 1px solid #011C46;
}
.div-template-eightythree p {    
    color: #fff;
    text-transform: uppercase;
}
.div-template-eightythree span.item-name {
    
    font-size: 12px;
    letter-spacing: 1.2px;
    font-weight: 500;
    position: absolute;
    top: 15px;
    left:95px;
    color: #fff;
    width: 55%;
}
.div-template-eightythree p.item-points {
    font-size: 14px;
    margin-bottom: 5px;
}
.div-template-eightythree .col-8 .div-left {
    padding-right: 30px;
    width: 100%;
    height: 0;    
    border-top: 200px solid #011C46;
    border-left: 100px solid transparent;
}

.div-template-eightythree .col-8 .div-points-btn {
    position: absolute;
    bottom: 30px;
    left:95px;
}
.div-template-eightythree .btn-info {
    left: 20px;
    bottom: 10px;
    border: 0px;
    background-color: transparent;
    font-size: unset;
    position: absolute;
}

/* Template 84 */
.div-template-eightyfour{
    border-radius: 15px;
    position: relative;
    overflow: hidden;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    min-height: 200px;
}

.div-template-eightyfour .div-circle {
    width: 220px;
    height: 270px;
    background-color: #1E2739;
    border-radius: 50%;
    position: absolute;
    top: -140px;
    left: 8px;
}

.div-template-eightyfour button {
    font-size :10px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    color: #1E2739;
    border: 1px solid #1E2739;
    background-color: #FFFfff;
    padding: 4px 8px;
    border-radius: 3px;
}
.div-template-eightyfour button:hover, .div-template-eightyfour button:focus {
    color: #1E2739;
    background-color:#FFFfff;
    border: 1px solid #1E2739;
}
.div-template-eightyfour span, .div-template-eightyfour p {       
    
}
.div-template-eightyfour .div-content {
    width: 210px;
    padding-top:5px;
    padding-left: 20px;
    padding-right: 20px;
    position: absolute;
    top: 0px;
    left: 15px;
}
.div-template-eightyfour .div-content div {
    width:100%;
    text-align: center;
}

.div-template-eightyfour .div-content .item-points {
    color: #ffffff;
    font-weight: 300;
    margin-bottom: 0px;
    text-align: center;
    text-transform: uppercase;
}

.div-template-eightyfour p.item-name {
    letter-spacing: 1.2px;
    font-weight: 500;
    color:#fff;
    min-height: 40px;
    font-size: 12px;
}
.div-template-eightyfour .btn-info {
    right: 20px;
    bottom: 10px;
    border: 0px;
    background-color: transparent;
    font-size: unset;
    position: absolute;
}

/* Template 85 */
.div-template-eightyfive{
    border-radius: 15px;
    position: relative;
    overflow: hidden;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    min-height: 200px;
}

.div-template-eightyfive .div-circle {
    width: 220px;
    height: 270px;
    background-color: #FF8F23;
    border-radius: 50%;
    position: absolute;
    top: 85px;
    left: 50px;
}

.div-template-eightyfive button {
    font-size :10px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    color: #FF8F23;
    border: 1px solid #FF8F23;
    background-color: #FFFfff;
    padding: 4px 8px;
    border-radius: 3px;
}
.div-template-eightyfive button:hover, .div-template-eightyfive button:focus {
    color: #FF8F23;
    background-color:#FFFfff;
    border: 1px solid #FF8F23;
}
.div-template-eightyfive .div-content {
    width: 160px;
    padding-top:5px;
    padding-left: 20px;
    padding-right: 20px;
    position: absolute;
    top: 100px;
    left: 85px;
}
.div-template-eightyfive .div-content div {
    width:100%;
    text-align: center;
}

.div-template-eightyfive .div-content .item-points {
    color: #ffffff;
    font-weight: 300;
    margin-bottom: 0px;
    text-align: center;
    text-transform: uppercase;
}

.div-template-eightyfive p.item-name {    
    font-weight: 500;
    color:#fff;
    min-height: 40px;
    font-size: 12px;
    text-align: center;
    margin-bottom: 0px;
    max-height: 40px;
    overflow:hidden;
}
.div-template-eightyfive .btn-info {
    right: 20px;
    top: 10px;
    border: 0px;
    background-color: transparent;
    font-size: unset;
    position: absolute;
}

/* media query for screen width 414 */
@media only screen and (max-width: 599px) and (min-width: 411px) {
    .div-template-eightyfive .div-circle {
        left:85px;
    }
    .div-template-eightyfive .div-content {
        left:112px;
    }
}
/* media query for screen width 375 */
@media only screen and (max-width: 410px) and (min-width: 375px) {
    .div-template-eightyfive .div-circle {
        left:65px;
    }
    .div-template-eightyfive .div-content {
        left:95px;
    }
}
/* media query for screen width 360 */
@media only screen and (max-width: 374px) and (min-width: 360px) {
    .div-template-eightyfive .div-circle {
        left:60px;
    }
    .div-template-eightyfive .div-content {
        left:90px;
    }
}

/* Template 86 */
.div-template-eightysix{
    border-radius: 15px;
    position: relative;
    overflow: hidden;    
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;    
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    min-height: 200px;
}
.div-template-eightysix button {
    font-size :10px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    color: #31617A;
    border: 1px solid #31617A;
    background-color: #fff;
    padding: 4px 8px;    
    border-radius: 3px;    
}
.div-template-eightysix button:hover, .div-template-eightysix button:focus {
    color: #31617A;
    background-color: #fff;
    border: 1px solid #31617A;
}
.div-template-eightysix span {    
    color: #FFFFFF;    
}
.div-template-eightysix span.item-name {
    letter-spacing: 1.2px;
    font-weight: 500;
    width:62%;
    position: absolute;
    top:15px;
    left:30px;
    font-size: 12px;
}
.div-template-eightysix .col-6 div.div-re-points {
    width:100%;
    position: absolute;
    bottom:15px;
    left:30px;
}
.div-template-eightysix span.item-points {
    font-size: 14px;
    display: block;
    text-transform: uppercase;
}
.div-template-eightysix span.item-points strong {
    font-size: 14px;
}
/*Right pointing*/
.div-template-eightysix .col-6 div.triangle-right {
    width: 100%;    
	height: 0;
	border-top: 100px solid #31617A;
	border-right: 50px solid transparent;
	border-bottom: 100px solid #31617A;
}
.div-template-eightysix .btn-info {
    right: 10px;
    bottom: 10px;
    border: 0px;
    background-color: transparent;
    font-size: unset;
    position: absolute;
}

/* Template 87 */
.div-template-eightyseven{
    border-radius: 15px;
    position: relative;
    overflow: hidden;    
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;    
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    min-height: 200px;
}
.div-template-eightyseven button {
    font-size :10px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    color: #C32025;
    border: 1px solid #C32025;
    background-color: #fff;
    padding: 4px 8px;    
    border-radius: 3px;    
}
.div-template-eightyseven button:hover, .div-template-eightyseven button:focus {
    color: #C32025;
    background-color: #fff;
    border: 1px solid #C32025;
}
.div-template-eightyseven span {    
    color: #FFFFFF;    
}
.div-template-eightyseven span.item-name {
    letter-spacing: 1.2px;
    font-weight: 500;
    width:62%;
    position: absolute;
    top:15px;
    left:50px;
    font-size: 12px;
}
.div-template-eightyseven .col-6 div.div-re-points {
    width:100%;
    position: absolute;
    bottom:15px;
    left:50px;
}
.div-template-eightyseven span.item-points {
    font-size: 14px;
    display: block;
    text-transform: uppercase;
}
.div-template-eightyseven span.item-points strong {
    font-size: 14px;
}
/*Right pointing*/
.div-template-eightyseven .col-6 div.triangle-right {
    width: 100%;    
	height: 0;
	border-top: 100px solid #C32025;
	border-left: 50px solid transparent;
	border-bottom: 100px solid #C32025;
}
.div-template-eightyseven .btn-info {
    left: 20px;
    bottom: 10px;
    border: 0px;
    background-color: transparent;
    font-size: unset;
    position: absolute;
}

/* Template 88 */
.div-template-eightyeight{
    border-radius: 15px;
    position: relative;
    overflow: hidden;    
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;    
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    min-height: 200px;
}
.div-template-eightyeight button {
    font-size :10px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    color: #0397A4;
    border: 1px solid #0397A4;
    background-color: #fff;
    padding: 4px 8px;
    border-radius: 3px;
}
.div-template-eightyeight button:hover, .div-template-eightyeight button:focus {
    color: #0397A4;
    background-color: #fff;
    border: 1px solid #0397A4;
}
.div-template-eightyeight span, .div-template-eightyeight p {    
    color: #fff;
}
.div-template-eightyeight p.item-name {
    letter-spacing: 1.0px;    
    width:100%;    
    font-size: 12px;
    min-height: 105px;
    font-weight: 500;
}
.div-template-eightyeight .col-7.div-eightyeight-content {    
    min-height: 200px;    
    padding-left: 0px;
}
.div-template-eightyeight .col-8 {
    min-height: 200px;
    background-color: #0397A4;
    z-index: 1;
    padding-top: 20px;
}
.div-template-eightyeight .col-8 div.div-re-points {
    width:100%;    
}
.div-template-eightyeight span.item-points {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 300;
}
.div-template-eightyeight .col-7 {
    position: relative;
    overflow: hidden;
    min-height: 200px;
}

.div-template-eightyeight .col-4 div.semicircle-right {    
    position: absolute;
    top: 0; 
    bottom: 0;
    box-shadow: 0px 0px 0px 110px #0397A4;
    width: 100%
}

.div-template-eightyeight .col-4 div.semicircle-right-curve {   
    border-radius: 0 140% 60% 0 / 0 60% 100% 0;
}
.div-template-eightyeight .btn-info {
    left: 20px;
    top: 10px;
    border: 0px;
    background-color: transparent;
    font-size: unset;
    position: absolute;
}

/* Template 89 */
.div-template-eightynine{
    border-radius: 15px;
    position: relative;
    overflow: hidden;    
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;    
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    min-height: 200px;
}
.div-template-eightynine button {
    font-size :10px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    color: #FC6173;
    border: 1px solid #FC6173;
    background-color: #fff;
    padding: 4px 8px;
    border-radius: 3px;
}
.div-template-eightynine button:hover, .div-template-eightynine button:focus {
    color: #FC6173;
    background-color: #fff;
    border: 1px solid #FC6173;
}
.div-template-eightynine span, .div-template-eightynine p {    
    color: #fff;
}
.div-template-eightynine p.item-name {
    letter-spacing: 1.0px;    
    width:100%;    
    font-size: 12px;
    min-height: 105px;
    font-weight: 500;
    padding-left: 15px;
}
.div-template-eightynine .col-7.div-eightynine-content {    
    min-height: 200px;    
    padding-left: 0px;
}
.div-template-eightynine .col-8 {
    min-height: 200px;
    background-color: #FC6173;
    z-index: 1;
    padding-top: 20px;
}
.div-template-eightynine .col-8 div.div-re-points {
    width:100%;
    padding-left: 15px;   
}
.div-template-eightynine span.item-points {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 300;
}
.div-template-eightynine .col-7 {
    position: relative;
    overflow: hidden;
    min-height: 200px;
}

.div-template-eightynine .col-4 div.semicircle-right {    
    position: absolute;
    top: 0; 
    bottom: 0;
    box-shadow: 0px 0px 0px 110px #FC6173;
    width: 100%
}

.div-template-eightynine .col-4 div.semicircle-right-curve {   
    border-radius: 100% 0 0 25% / 50% 0 0 50%;
}
.div-template-eightynine .btn-info {
    right: 20px;
    top: 10px;
    border: 0px;
    background-color: transparent;
    font-size: unset;
    position: absolute;
}
/* media query for screen width 360 */
@media only screen and (max-width: 374px) and (min-width: 360px) {
    .div-template-eightynine button.btn_not {
        font-size: 9px;
    }
}
/* media query for screen width 320 */
@media only screen and (max-width: 359px) and (min-width: 320px) {
    .div-template-eightynine button.btn_not {
        font-size: 8px;
    }
}

/* Template 90 */
.div-template-ninty{
    border-radius: 15px;
    position: relative;
    overflow: hidden;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;    
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    min-height: 200px;
}

.div-template-ninty button {
    font-size :10px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    color:#EAC100;
    border: 1px solid #EAC100;
    background-color: #FFF;
    padding: 4px 8px;    
    border-radius: 3px;
    margin-right: 0px;    
}
.div-template-ninty button:hover, .div-template-ninty button:focus {
    color:#EAC100;
    border: 1px solid #EAC100;
    background-color: #FFF;
}
.div-template-ninty p {    
    color: #EAC100;
    text-transform: uppercase;
}
.div-template-ninty span.item-name {
    letter-spacing: 1.2px;
    font-weight: 400;
    color: #FFFFFF;
    font-size: 12px;
}
.div-template-ninty p.item-points-text {
    font-size: 14px;
    color: #fff;
    font-weight: 600; 
    margin-bottom: 5px;   
}
.div-template-ninty p.item-points {    
    margin-bottom: 0px;    
    margin-top: 15px;
    text-transform: uppercase;
}

.div-template-ninty .div-items {
    width: 125px;
    text-align: center;
    position: absolute;
    left: 10%;
    top: 27%;
}
.div-template-ninty .div-points {
    padding-top: 0px;
}
.div-template-ninty .div-item-name{
    max-height: 48px;
    overflow: hidden;
}

.div-template-ninty .div-diamond {
    width: 160px;
    height: 160px;
    background-color: #EAC100;
    position: absolute;    
    top: 10%;
    left: 5%;
    border-radius: 50%;
}
.div-template-ninty .btn-info {
    right: 20px;
    top: 10px;
    border: 0px;
    background-color: transparent;
    font-size: unset;
    position: absolute;
}

/* Template 91 */
.div-template-nintyone{
    border-radius: 15px;
    position: relative;
    overflow: hidden;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;    
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    min-height: 200px;
}

.div-template-nintyone button {
    font-size :10px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    color:#00FFF0;
    border: 1px solid #00FFF0;
    background-color: #333333;
    padding: 4px 8px;    
    border-radius: 3px;
    margin-right: 0px;    
}
.div-template-nintyone button:hover, .div-template-nintyone button:focus {
    color:#00FFF0;
    border: 1px solid #00FFF0;
    background-color: #333333;
}
.div-template-nintyone p {    
    color: #00FFF0;
    text-transform: uppercase;
}
.div-template-nintyone span.item-name {
    letter-spacing: 1.2px;
    font-weight: 400;
    color: #333333;
    font-size: 12px;
}
.div-template-nintyone p.item-points-text {
    font-size: 14px;
    color: #333333;
    font-weight: 600; 
    margin-bottom: 5px;   
}
.div-template-nintyone .div-points {
    padding-top: 0px;
}
.div-template-nintyone .div-item-name{
    max-height: 48px;
    overflow: hidden;
}
.div-template-nintyone p.item-points {    
    margin-bottom: 0px;    
    margin-top: 15px;
    text-transform: uppercase;
}

.div-template-nintyone .div-items {
    width: 125px;
    text-align: center;
    position: absolute;
    right: 10%;
    top: 27%;
}

.div-template-nintyone .div-diamond {
    width: 160px;
    height: 160px;
    background-color: #00FFF0;
    position: absolute;    
    top: 10%;
    right: 5%;
    border-radius: 50%;
}
.div-template-nintyone .btn-info {
    left: 20px;
    bottom: 10px;
    border: 0px;
    background-color: transparent;
    font-size: unset;
    position: absolute;
}

/* Template 92 */
.div-template-nintytwo{
    border-radius: 15px;
    position: relative;
    overflow: hidden;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color: #F6399B;    
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    min-height: 200px;
}
.div-template-nintytwo button {
    font-size :10px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    color: #581B98;
    border: 1px solid #581B98;
    background-color: #FFFFFF;
    padding: 4px 8px;    
    border-radius: 3px;    
}
.div-template-nintytwo button:hover, .div-template-nintytwo button:focus {
    color: #581B98;
    background-color: #ffffff;
    border: 1px solid #581B98;
}
.div-template-nintytwo span, .div-template-nintytwo p {    
    color: #FFFFFF;    
}
.div-template-nintytwo span.item-name {
    letter-spacing: 1.2px;
    font-weight: 500;
    font-size: 12px;
}
.div-template-nintytwo p.item-points-text {    
    text-transform: uppercase;
    font-size: 14px;
    margin-bottom: 0px;
}
.div-template-nintytwo .col-5 div {
    width:100%;
    position: absolute;
    bottom:15px;    
}
.div-template-nintytwo .col-5 {
    padding-left: 30px;
    height:200px;
    background-color: #581B98;
}
.div-template-nintytwo .btn-info {
    right: 20px;
    bottom: 10px;
    border: 0px;
    background-color: transparent;
    font-size: unset;
    position: absolute;
}

/* Template 93 */
.div-template-nintythree{
    border-radius: 15px;
    position: relative;
    overflow: hidden;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color: #F6399B;    
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    min-height: 200px;
}
.div-template-nintythree button {
    font-size :10px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    color: #F39BC5;
    border: 1px solid #F39BC5;
    background-color: #88355D;
    padding: 4px 8px;    
    border-radius: 3px;    
}
.div-template-nintythree button:hover, .div-template-nintythree button:focus {
    color: #F39BC5;
    background-color: #88355D;
    border: 1px solid #F39BC5;
}
.div-template-nintythree span, .div-template-nintythree p {    
    color: #88355D;    
}
.div-template-nintythree span.item-name {
    letter-spacing: 1.2px;
    font-weight: 500;
    font-size: 12px;
}
.div-template-nintythree p.item-points-text {    
    text-transform: uppercase;
    font-size: 14px;
    margin-bottom: 0px;
}
.div-template-nintythree .col-5 div {
    width:100%;
    position: absolute;
    bottom:15px;    
}
.div-template-nintythree .col-5 {
    padding-left: 30px;
    height:200px;
    background-color: #F39BC5;
}
.div-template-nintythree .btn-info {
    left: 20px;
    bottom: 10px;
    border: 0px;
    background-color: transparent;
    font-size: unset;
    position: absolute;
}
/* media query for screen width 320 */
@media only screen and (max-width: 359px) and (min-width: 320px) {
    .div-template-nintythree button.btn_not {
        font-size: 9px;
    }
}

/* Template 94 */
.div-template-nintyfour{
    border-radius: 15px;
    position: relative;
    overflow: hidden;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;    
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    min-height: 200px;
}

.div-template-nintyfour button {
    font-size :10px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    color:#1D1D1D;
    border: 1px solid #1D1D1D;
    background-color: #FFF;
    padding: 4px 8px;    
    border-radius: 3px;
    margin-right: 0px;    
}
.div-template-nintyfour button:hover, .div-template-nintyfour button:focus {
    color:#1D1D1D;
    border: 1px solid #1D1D1D;
    background-color: #FFF;
}
.div-template-nintyfour p.item-name {
    letter-spacing: 1.2px;
    font-weight: 400;
    color: #FFFFFF;
    max-height: 35px;
    overflow: hidden;
    font-size: 12px;
    padding: 0px 10px;
    margin-bottom: 0px;
}
.div-template-nintyfour p.item-points-text {
    font-size: 15px;
    color: #fff;
    font-weight: 600; 
    margin-bottom: 5px;
    text-transform: uppercase;
}
.div-template-nintyfour .div-points {
    padding-top: 0px;
}

.div-template-nintyfour .div-items {
    width: 125px;
    text-align: center;
    position: absolute;
    right: 30px;
    top: 27%;
}

.div-template-nintyfour .div-diamond {
    width: 125px;
    height: 125px;
    background-color: #1D1D1D;
    position: absolute;
    transform: rotate(45deg);
    top: 20%;
    right: 30px;
}
.div-template-nintyfour .btn-info {
    left: 20px;
    bottom: 10px;
    border: 0px;
    background-color: transparent;
    font-size: unset;
    position: absolute;
}

/* Template 95 */
.div-template-nintyfive{
    border-radius: 15px;
    position: relative;
    overflow: hidden;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color: #96A202;    
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    min-height: 200px;
}

.div-template-nintyfive button {
    font-size :10px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    color: #C94428;
    border: 1px solid #C94428;
    background-color: #fff;
    padding: 4px 8px;
    border-radius: 3px;
}
.div-template-nintyfive button:hover, .div-template-nintyfive button:focus {
    color:#C94428;
    background-color: #fff;
    border: 1px solid #C94428;
}
.div-template-nintyfive span, .div-template-nintyfive p {    
    color: #fff;
    text-transform: uppercase;
}
.div-template-nintyfive span.item-name {
    letter-spacing: 1.2px;
    font-weight: 300;
    position: absolute;
    top: 15px;
    left:30px;
}
.div-template-nintyfive p.item-points-text {
    font-size: 9px;
    margin-bottom: 5px;
}
.div-template-nintyfive p.item-points {
    font-size: 14px;
    margin-bottom: 0px;
    font-weight: 600;
}
.div-template-nintyfive .col-5 .div-left {
    padding-left: 30px;
    width: 100%;
    height: 0;    
    border-top: 200px solid #C94428;
    border-right: 25px solid transparent;
}

.div-template-nintyfive .col-5 .div-points-btn {
    position: absolute;
    bottom: 30px;
    left:30px;
}
.div-template-nintyfive .btn-info {
    right: 20px;
    top: 10px;
    border: 0px;
    background-color: transparent;
    font-size: unset;
    position: absolute;
}
/* media query for screen width 360 */
@media only screen and (max-width: 374px) and (min-width: 360px) {
    .div-template-nintyfive button.btn_not {
        font-size: 9px;
    }
}
/* media query for screen width 320 */
@media only screen and (max-width: 359px) and (min-width: 320px) {
    .div-template-nintyfive button.btn_not {
        font-size: 8px;
    }
}

/* Template 96 */
.div-template-nintysix{
    border-radius: 15px;
    position: relative;
    overflow: hidden;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    /* background-color: #96A202;     */
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    min-height: 200px;
}

.div-template-nintysix button {
    font-size :10px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    color: #ED8F29;
    border: 1px solid #ED8F29;
    background-color: #fff;
    padding: 4px 8px;
    border-radius: 3px;
    max-height: 25px;
    margin-top: 7px;
    float:right;
}
.div-template-nintysix button:hover, .div-template-nintysix button:focus {
    color: #ED8F29;
    background-color: #fff;
    border: 1px solid #ED8F29;
}
.div-template-nintysix .col-5 .div-right {    
    width: 100%;
    height: 0;    
    border-top: 120px solid #ED8F29;
    border-left: 130px solid transparent;
}
.div-template-nintysix .col-7 {
    min-height: 200px;    
}
.div-template-nintysix p {
    color: #FFF;
    text-transform: uppercase;
    letter-spacing: 1.5px;
}
.div-template-nintysix p.item-points-text {
    font-size: 9px;
    margin-bottom: 5px;
}
.div-template-nintysix p.item-points {
    font-size: 14px;
    margin-bottom: 0px;
    font-weight: 600;
}
.div-template-nintysix .div-points {
    position: absolute;
    top:15px;
    right:30px;
    padding-top: 0px;
}
.div-template-nintysix .div-name-btn {
    padding: 10px 15px;
    position: absolute;
    bottom: 0px;
    width: 100%;
    background-color: #ED8F29;
    display: flex;
    border-top-right-radius: 15px;
}
.div-template-nintysix .div-name-btn p.item-name {
    letter-spacing: 1.2px;
    font-weight: 500;
    font-size: 12px;
    width:85px;
    max-height: 35px;
    overflow: hidden;
    margin-bottom: 0px;
}
.div-template-nintysix .btn-info {
    left: 20px;
    top: 10px;
    border: 0px;
    background-color: transparent;
    font-size: unset;
    position: absolute;
}
/* media query for screen width 414 */
@media only screen and (max-width: 599px) and (min-width: 411px) {
    .div-template-nintysix .col-5 .div-right {
        border-left: 160px solid transparent;
    }
}
/* media query for screen width 375 */
@media only screen and (max-width: 410px) and (min-width: 375px) {
    .div-template-nintysix .col-5 .div-right {
        border-left: 140px solid transparent;
    }
}
/* media query for screen width 360 */
@media only screen and (max-width: 374px) and (min-width: 360px) {
    .div-template-nintysix .col-5 .div-right {
        border-left: 135px solid transparent;
    }
    .div-template-nintysix button.btn_not {
        font-size: 9px;
    }
}
/* media query for screen width 320 */
@media only screen and (max-width: 359px) and (min-width: 320px) {
    .div-template-nintysix button.btn_not {
        font-size: 8px;
    }
}

/* Template 97 */
.div-template-nintyseven{
    border-radius: 15px;
    position: relative;
    overflow: hidden;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    /* background-color: #96A202;     */
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    min-height: 200px;
}

.div-template-nintyseven button {
    font-size :10px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    color: #0D171C;
    border: 1px solid #0D171C;
    background-color: #fff;
    padding: 4px 8px;
    border-radius: 3px;
    max-height: 25px;
    margin-top: 7px;
    float:right;
}
.div-template-nintyseven button:hover, .div-template-nintyseven button:focus {
    color:#0D171C;
    background-color: #fff;
    border: 1px solid #0D171C;
}
.div-template-nintyseven .col-8 {
    min-height: 200px;    
}
.div-template-nintyseven p {
    color: #FFF;
    text-transform: uppercase;
    letter-spacing: 1.5px;
}
.div-template-nintyseven p.item-points-text, .div-template-nintyseven p.item-points {
    font-size: 12px;
    margin-bottom: 0px;
    font-weight: 500;
}
 
.div-template-nintyseven .div-points {
    position: absolute;
    top:0px;
    left:30px;
    width: 25%;
    padding: 10px;
    background-color: #0D171C;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    color:#fff;
    text-align: center;
}
.div-template-nintyseven .div-name-btn {
    padding: 10px;
    position: absolute;
    bottom: 0px;
    width: 100%;
    background-color:#0D171C;
    display: flex;
    border-top-right-radius: 15px;
}
.div-template-nintyseven .div-name-btn p.item-name {
    letter-spacing: 1.2px;
    font-weight: 500;
    font-size: 12px;
    width:125px;
    max-height: 35px;
    overflow: hidden;
    margin-bottom: 0px;
}
.div-template-nintyseven .btn-info {
    right: 10px;
    top: 10px;
    border: 0px;
    background-color: transparent;
    font-size: unset;
    position: absolute;
}
/* media query for screen width 360 */
@media only screen and (max-width: 374px) and (min-width: 360px) {
    .div-template-nintyseven button.btn_not {
        font-size: 9px;
    }
}
/* media query for screen width 320 */
@media only screen and (max-width: 359px) and (min-width: 320px) {
    .div-template-nintyseven button.btn_not {
        font-size: 8px;
    }
}

/* Template 98 */
.div-template-nintyeight{
    border-radius: 15px;
    position: relative;
    overflow: hidden;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;       
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    min-height: 200px;
}

.div-template-nintyeight .div-parent{
    background-color: #C2064B;
    min-height: 130px;
    border-radius: 15px;
    position: absolute;
    top:35px;
    left:35px;
    right:35px;    
}
.div-template-nintyeight .item-name{
    color:#fff;
    font-weight: 500;
    max-height:48px;
    overflow:hidden;
    margin-bottom: 0px;
}

.div-template-nintyeight button {
    font-size :10px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    color: #C2064B;
    border: 1px solid #C2064B;
    background-color:#fff;
    padding: 4px 8px;    
    border-radius: 3px;    
}
.div-template-nintyeight button:hover, .div-template-nintyeight button:focus {
    color: #C2064B;
    border: 1px solid #C2064B;
    background-color:#fff;
}
.div-template-nintyeight p.item-points-text {
    font-size: 14px;
    margin-bottom: 0px;
    text-transform: uppercase;
}
.div-template-nintyeight p.item-points {
    font-size: 28px;
    margin-bottom: 0px;
    font-weight: 700;
}
.div-template-nintyeight .btn-info {
    right: 10px;
    top: 10px;
    border: 0px;
    background-color: transparent;
    font-size: unset;
    position: absolute;
}
/* media query for screen width 414 */
@media only screen and (max-width: 599px) and (min-width: 411px) {
    .div-template-nintyeight .p18per {
        padding-top: 12%;
    }
}
/* media query for screen width 375 */
@media only screen and (max-width: 410px) and (min-width: 375px) {
    .div-template-nintyeight .p18per {
        padding-top: 14%;
    }
}
/* media query for screen width 360 */
@media only screen and (max-width: 374px) and (min-width: 360px) {
    .div-template-nintyeight .btn_not {
        font-size: 9px;
    }
}
/* media query for screen width 320 */
@media only screen and (max-width: 359px) and (min-width: 320px) {
    .div-template-nintyeight .btn_not {
        font-size: 9px;
    }
}

/* Template 99 */
.div-template-nintynine{
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    min-height: 200px;
}

.div-template-nintynine .div-template-footer {
    background-color: #272727;
    padding: 10px 15px;
    width: 100%;
    position: absolute;
    bottom: 0px;
    opacity: 0.85;
}
.div-template-nintynine .div-template-footer button {
    font-size :10px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    color: #fff;    
    background-color: #CD7F4E;
    border: 1px solid transparent;
    padding: 4px 8px;
    float:right;
    border-radius: 3px;
    margin-top: 10px;
}
.div-template-nintynine button:hover, .div-template-nintynine button:focus {
    color: #fff;
    background-color:#CD7F4E;
    border: 1px solid transparent;
}
.div-template-nintynine .div-template-footer span {
    letter-spacing: 1.2px;
    color: #FFFFFF;
    text-transform: uppercase;
}
.div-template-nintynine .div-template-footer p.item-name {
    font-weight: 300;
    text-transform: uppercase;
    max-height: 24px;
    overflow:hidden;
    margin-bottom: 0px;
}
.div-template-nintynine .div-template-footer span.item-points {    
    font-weight: 700;
}
.div-template-nintynine .btn-info {
    left: 20px;
    top: 10px;
    border: 0px;
    background-color: transparent;
    font-size: unset;
    position: absolute;
}
/* media query for screen width 375 */
@media only screen and (max-width: 410px) and (min-width: 375px) {
    .div-template-nintynine .div-template-footer button.btn_not {
        font-size: 9px;
    }
}
/* media query for screen width 360 */
@media only screen and (max-width: 374px) and (min-width: 360px) {
    .div-template-nintynine .div-template-footer button.btn_not {
        font-size: 9px;
    }
}
/* media query for screen width 320 */
@media only screen and (max-width: 359px) and (min-width: 320px) {
    .div-template-nintynine .div-template-footer button.btn_not {
        font-size: 8px;
    }
}

/* Template 100 */
.div-template-hundred{
    border-radius: 15px;
    position: relative;
    overflow: hidden;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;    
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    min-height: 200px;
}

.div-template-hundred button {
    font-size :10px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    color:#D23038;
    border: 1px solid #D23038;
    background-color: #FFF;
    padding: 4px 8px;    
    border-radius: 3px;
    margin-right: 0px;    
}
.div-template-hundred button:hover, .div-template-hundred button:focus {
    color:#D23038;
    border: 1px solid #D23038;
    background-color: #FFF;
}
.div-template-hundred p {    
    color: #CA0103;
}
.div-template-hundred p.item-name {
    letter-spacing: 1.2px;
    font-weight: 400;
    color: #FFFFFF;
    max-height: 48px;
    overflow:hidden;
    margin-bottom: 0px;
}
.div-template-hundred p.item-points-text {
    font-size: 15px;
    color: #fff;
    font-weight: 600; 
    margin-bottom: 5px;
    text-transform: uppercase;  
}
.div-template-hundred .div-items {
    width: 125px;
    text-align: center;
    position: absolute;
    left: 30%;
    top: 27%;
}
.div-template-hundred .div-points {
    padding-top: 0px;
}

.div-template-hundred .div-diamond {
    width: 125px;
    height: 125px;
    background-color: #D23038;
    position: absolute;
    border-radius: 50%;
    top: 20%;
    left: 30%;
}
.div-template-hundred .btn-info {
    right: 20px;
    top: 10px;
    border: 0px;
    background-color: transparent;
    font-size: unset;
    position: absolute;
}