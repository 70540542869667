.media_box {
    border: 2px solid #eee;
    padding: 5px;
    border-radius: 5px;
    -webkit-transition: all .3s ease-out 0s;
    -moz-transition: all .3s ease-out 0s;
    -ms-transition: all .3s ease-out 0s;
    -o-transition: all .3s ease-out 0s;
    transition: all .3s ease-out 0s;
}

.div-mystore-faq p {
    font-size: 14px;
}

.div-mystore-faq h5 {
    font-size: 16px;
    font-weight: 600;
}

li.nav-item a.active {

    font-weight: bold;
    text-decoration: underline;
}

#vpop {
    width: 100%;
    height: 100%;
    position: absolute;
    /* visibility:hidden;
    display:none; */
    top: 0%;
	left:0;
    background-color: rgba(22, 22, 22, 0.5);
}

#vpop:target {
    visibility: visible;
    display: block;
}

.reveal-modal {
    background: #fff;
    margin: 0 auto;
    width: 800px;
    position: relative;
    z-index: 41;
    top: 20%;
    padding: 20px;
    box-shadow: 0 0 10px rgb(0 0 0 / 40%);
    min-height: 206px;

}

.div-play {
    height: 30px;
    position: absolute;
    border-radius: 50px;
    background-color: #333;
    width: 30px;
    color: #fff;
    top: 40%;
    left: 42%;
}

.div-play i {
    margin-left: 10px;
    margin-top: 7px;
    font-size: 13px;
}

.reveal-close {
    position: absolute;
    top: -9px;
    right: 12px;
    font-size: 38px;
    color: #000;
}

.reveal-close:hover {
    text-decoration: none;
}

@media screen and (max-width: 750px) {}

.videoWrapper {
    display: block;
    height: 0;
    overflow: hidden;
    padding: 0 0 56.25%;
    position: relative;
}

.videoWrapper iframe {
    border: 0 none;
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.videoWrapper video {
    border: 0 none;
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}