 .div-parent-cart {
     position: fixed;
     bottom: 70px;
     right: 50px;
     z-index: 1;
 }

 .div-parent-cart .pr {
     width: 60px;
     height: 60px;
     border-radius: 15px;
     background-color: #DC0000;
     display: flex;
     justify-content: center;
     align-items: center;
 }

 .div-parent-cart a {
     width: 100%;
     height: 100%;
     display: flex;
     justify-content: center;
     align-items: center;
 }

 .div-parent-cart .pr a i {
     font-size: 24px;
     color: #fff;
     margin-top: 5px;
 }

 .close_modal {

     margin-right: 5px;
     background: none;
     border: none;
     text-shadow: none;
     font-size: 32px;
     font-weight: 700;
     z-index: 999;
 }

 .close_modal1 {
     margin-top: 10px;
     margin-right: 5px;
 }

 .div-right-cart-popup {
     position: fixed;
     background-color: #fff;
     /* border: 2px solid #ddd; */
     border: 1px solid #DADCE0;
     bottom: 150px;
     right: 50px;
     z-index: 100;
     /* border-radius: 5px; */
     border-radius: 8px;
     /* width: 500px; */
     width: 400px;
     box-shadow: 0px 0px 5px #dadce0;
     padding: 5px 5px 15px 5px;
 }

 .div-product-name {
     font-size: 18px;
     color: #DC0000;
     font-weight: 600;
     /* margin-bottom: 10px; */
     padding-bottom: 10px;
     /* border-bottom: 3px solid #ddd; */
     border-bottom: 1px solid #DADCE0;
 }

 .m_top2 {
     margin-top: 20px;
 }

 .div-cart-total span {
     font-size: 20px;
     font-weight: 700;
 }

 .img-cart {
     width: 100%;
 }

 .dtime_span {
     font-size: 11px;
     float: right;
     margin-right: 29px;
     border: 1px solid #dc0000 !important;
     padding: 5px;
     border-radius: 5px;
     color: #dc0000 !important;
 }

 .df {
     display: flex;
 }

 .div-cart-item p,
 .div-cart-item span {
     font-size: 14px;
 }

 .div-cart-item a {
     font-size: 12px;
     font-weight: 500;
     transition: all 0.3s ease 0s;
     color: #DC0000;
 }

 .div-cart-item a i {
     font-size: 12px;
     color: #DC0000;
 }

 .btn_round {
     width: 25px;
     border-radius: 50%;
     height: 25px;
     font-size: 15px;
     background: #b71c1c;
     color: #fff;
     border: none;
     text-align: center;
 }

 .fw-700 {
     font-weight: 700;
 }

 .p0 {
     padding-left: 0px;
     padding-right: 0px;
 }

 .btn-myfront-add-cart {
     border: 1px solid #DC0000;
     background-color: #DC0000;
     border-radius: 5px;
     color: #fff;
     width: 100%;
     font-weight: 700;
     padding: 7px 12px;
     font-size: 14px;
 }

 .cart_ball_guest {
     position: absolute;
     /* background-color: #fff; */
     padding: 1px 6px;
     border-radius: 15px;
     top: 5px;
     right: 7px;
     /* color: #DC0000; */
     font-size: 10px;
 }

 .div-parent-cart a:hover {

     text-decoration: none;
 }

 .cartempty {
     font-size: 18px;
     font-weight: bold;
 }

 @media only screen and (max-width: 599px) {
     .div-right-cart-popup {
         width: 98%;
         right: 4px;
         bottom: 50px;
     }

     .modal-body {
         padding: 15px 10px;
     }

     .modal-body .col-1 {
         padding-left: 0px;
         padding-right: 0px;
     }

     .modal-body .col-1.text-right {
         text-align: left !important;
     }
 }

 /* media query for screen width 320 */
 @media only screen and (max-width: 359px) and (min-width: 320px) {
     .modal-body .col-4 {
         padding-left: 0px;
         padding-right: 0px;
     }
 }