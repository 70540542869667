nav {
    position: fixed;
    top: 0;
    width: 100%;
    height: 50px;
    /*  background: #06AB9B; */
    z-index: 9999;
}

.nav_bot {
    display: block;
    margin-bottom: 4%;
}

.list {
    list-style-type: none;
    /* background: #06AB9B; */
    height: 100%;
    display: flex;
    /* justify-content: center; */
    justify-content: right;
    align-items: center;
    position: relative;
    /* margin-top:12px; */
    margin-top: 0px;
    margin-bottom: 0px;
}

.items {
    /* margin-right: 20px; */
    font-size: 20px;
    color: #f1f1f1;
    cursor: pointer;
    font-size: 14px;
    padding: 13px 6px;
}

.items a {
    cursor: pointer;
    font-size: 14px;
	padding: 13px 6px;
    /* color: #f1f1f1; */
}

/* .items.isActive,
.items:hover {
    background-color: #fff;
    border-radius: 4px;
}

.items.isActive a,
.items:hover a {
    color: #06AB9B !important;
    text-decoration: none;
} */

.itemsa:hover {
    text-decoration: none;
}
.btn_nav {
    display: none;
    position: absolute;
    right: 10px;
    top: 7px;
    padding: 5px;
    color: #000;
    font-size: 18px;
}

.li-avatar {
    margin-right: 20px;
    margin-left: 20px;
}

.li-avatar a {
	padding: 5px 10px 2px;
    border: 2px solid #fff;
   /*  border-radius: 25px */;
    cursor: pointer;
    font-size: 12px;
}

.li-avatar a:hover{
    letter-spacing: 1.5px;
    border-radius: 0;
    transition: 0.6s;
    width: 100%;
    padding: 5px;
    transition: .6s;
}

.li-avatar a i {
    margin-right: 5px;
}

.div-dropdown {
    width: 200px;
    border: 1px solid #ddd;
    background-color: #009688;
    position: absolute;
    right: 15px;
    border-radius: 4px;
}

.div-dropdown ul {
    list-style: none;
    padding-left: 0px;
    margin-bottom: 0px;
}

.div-dropdown ul li {
    border-bottom: 1px solid #ddd;
    transition: .3s;
}

.div-dropdown ul li:last-child {
    border: 0px;
}

.div-dropdown ul li:hover {
    background-color: #fff;
}

.div-dropdown ul li:hover a {
    color: #009688 !important;
}

.d-none-desk {
    display: none;
}
.dropdown-item:focus, .dropdown-item:hover,.dropdown-item:active {
    color: #16181b !important;
    text-decoration: none !important;
    background: none !important;
}

@media screen and (max-width: 500px) {
    .list {
        flex-direction: column;
        height: auto;
        margin: 0px;
        padding: 0px;
    }

    .items:last-child {
        border-bottom: none;
    }

    .items {
        width: 100%;
        border-bottom: 1px solid rgba(255, 255, 255, 0.555);
        text-align: left;
        margin-right: 0px;
         padding: 0px;
        font-size: 14px;
    }
	.items a {
		display: inline-block;
		width:100%;
		
	}	
    .btn_nav {
        display: block;
        z-index: 999;
        background: none;
        color: #fff;
        border: none;
		right: 20px;

    }

    .d-none-desk {
        display: block;
    }

    .li-avatar {
        display: none;
    }

    .nav_bot nav a {
        float: unset !important;
    }

    .div-dropdown {
        display: none;
    }
}
.btn_frst_ltr::after {
	display:none !important;
}
.btn_frst_ltr {
    width: 35px;
    height: 35px;
    line-height: 31px !important;
    border-radius: 18px !important;
    text-align: center !important;
    box-sizing: border-box !important;
    border: 2px solid #FFF !important;
    font-size: 17px !important;
    color: #FFF !important;
    text-transform: uppercase !important;
    font-weight: 600 !important;
    background-color: #fc424a !important;
    display: inline-block !important;
    margin: 6px 7px 0 0 !important;
    transition: all 0.3s ease 0s !important;
    position: relative !important;
    padding-top: 1px !important;
    transition: all 0.3s !important;
	padding:0 !important;
}