.m0 {
    margin-left: 0px !important;
    margin-right: 0px !important;
}

.PhoneInputInput {
    display: block;
    width: 81%;
    height: calc(1.5em + .75rem + 2px);
    /* padding: 1.375rem .75rem; */
    font-size: 1rem;
    font-weight: 400;
    line-height: 2.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    float: left;
    padding: 0px 6px;
    height: 25px;
    border: none !important;
}
.PhoneInputInput:focus {
	
	 border: medium none !important;
     outline: none;
}	
.PhoneInput:focus {
	
	 border: medium none !important;
}
.PhoneInput-color--focus{
	 border: medium none !important;
	
}	
.PhoneInputCountry {
	margin-top: 5px;
	float: left;
}
.PhoneInputInput:focus{
	
	  border: none;
}	
body {
    overflow-x: hidden;
}
.curr_unavail {
    position: absolute;
    left: 36%;
    top: 10%;
    font-size: 26px;
}
strong {
    font-weight: 600 !important;
}
.m_bot3 {
    margin-bottom: 30px;
}
.pr0 {
    padding-right: 0px !important;
}
.pl0 {
    padding-left: 0px !important;
}
.pr02 {
    padding-right:2px !important;
}
.pt10 {
    padding-top: 10px;
}
.m0 {
    margin-left: 0px;
    margin-right: 0px;
}

.p18per {
    padding-top: 18%;
}

.p7per {
    padding-top: 7%;
}

.p15 {
    padding: 15px;
}

.btn_not {
    letter-spacing: unset !important;
}
.react-datepicker-wrapper{
	
	display: block !important;
}	
/* media query for screen width 320 */
@media only screen and (max-width: 359px) and (min-width: 320px) {
    .m0 .m_bot2 {
        padding-left: 2px;
        padding-right: 2px;
    }
}


/* Gift Template design - Starts */
.m_bot5 {
    margin-bottom: 5px;
}
.m_top1 {
    margin-top: 10px;
}
.m_top2 {
    margin-top: 20px;
}
.m_top25per {
    margin-top: 25%;
}
.m_bot2 {
    margin-bottom: 20px;
}
.m_bot25 {
    margin-bottom: 25px;
}
.df {
    display:flex;
}
.btfff {
    border-top: 1px solid #fff;
}
.bg-fff {
    background-color: #fff;
}
.btn-buy {
    padding: 2px 8px !important;
    font-size: 12px !important;
    background-color: transparent;
}
.div-card-list span, .div-card-list label {
    font-size: 14px;
    font-weight: 600;
}
.div-card-list .col-md-4 label {
    width: 40px;
    text-align: right;
    font-weight: 700;
    padding-right: 5px;
}
.div-card-list .col-md-4 input {
    background-color: transparent;
    padding: 2px 8px;
    height: unset;
    font-size: 12px;
    width: 38px;
    margin-right: 6px;
}
.div-card-list .col-md-3, .div-card-list .col-md-5, .div-card-list .col-md-1, .div-card-list .col-md-8, .div-card-list .col-md-2 {
    padding-top: 22px;
    padding-bottom: 16px;
}
.div-card-list .col-md-8 span {
    padding-left: 25px;
}

.div-gift-template-footer {
    background-color: #fff;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.div-limited {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    border: 1px solid #DADCE0;
    border-bottom: 0px;
    padding: 10px 15px;
    margin-left: -1px;
    margin-right: -1px;
    cursor: pointer;
}

.div-limited p {
    margin-bottom: 0px;
    font-size: 12px;
    font-weight: 600;
}
.div-limited span {
    font-size: 9px;
}

.div-limited-child {
    padding: 10px 30px;
    text-align: center;
}
.div-limited-child p {
    font-size: 6px;
    margin-bottom: 0px;
}
.div-gift-promo span {
    font-size: 12px;
    width: 90%;
}
.div-gift-promo input, .div-gift-promo input:focus {
    padding: 2px 4px 0px;
    font-size: 10px;
    width:100%;
    height: unset;
    border-radius: 2px;
    background-color: #fff;
    margin-top: 10px;
    margin-bottom: 10px;
}
.div-gift-promo .btn{
    padding: 2px 8px 1px;
    font-size: 10px;
    border:1px solid #DADCE0;
    border-radius: 2px;
    background-color: #fff;
}
.div-gift-promo .col-md-6 {
    padding: 10px 15px 15px;
    border:1px solid #DADCE0;
    -ms-flex: 0 0 49.5%;
    flex: 0 0 49.5%;
    max-width: 49.5%;

}
.mr_03 {
    margin-right: 3px;
}
.div-buy1-child, .div-buy2-child {
    padding:  0px 0px 10px;
    /* font-size: 8px; */
    font-size: 12px;
    text-align: left;
    font-weight: 600;
}
/* .div-limited-child, .div-gift-child, .div-promo-child {
    display:none;
} */
 .div-gift-child, .div-promo-child {
    display:none;
} 
.div-gift-code, .div-promo-code {
    cursor: pointer;
}
.div-buy1-parent, .div-buy2-parent {
    cursor: pointer;
}
.div-buy1-child, .div-buy2-child {
   /*  display:none; */
}
.div-limited-child input {
    font-size: 12px;
    padding: 6px 12px;
    height: unset;
    border-radius: 0px;
}
.div-limited-child input:focus {
    background: #fff;
}
.div-footer-description {
    text-align: center;
    /* font-size: 8px; */
    font-size: 12px;
    font-weight: 600;
}
.div-footer-description p {
    margin-bottom: 0px;
    /* padding-top: 5px;
    padding-bottom: 5px; */
    /* padding: 10px; */
    padding: 15px;
}
.div-limited-child .btn {
    /* padding: 6px;
    font-size: 12px; */
    padding: 5px 12px;
    font-size: 13px;
}
.div-limited-child {
    /* border-left: 1px solid #DADCE0;
    border-right: 1px solid #DADCE0; */
}
.div-gift-template{
    border-radius: 15px;
    position: relative;
    overflow: hidden;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    /* min-height: 200px; */
    min-height: 275px;
    margin-top: -1px;
    margin-left: -1px;
    margin-right: -1px;
}

.div_template_parent .div-footer-description p {
    /* max-height: 46px; */
    max-height: 65px;
    overflow: hidden;
}
.bt {
    border-top: 1px solid #DADCE0;
}

.bb {
    border-bottom: 1px solid #fff;
}

/* Buy Section - Starts */
.div-buy-section {
    padding: 20px;
}
.div-buy-section label {
    font-weight: 500;
}
.div-buy-heading {
    text-align: center;
    padding: 0px 0px 15px 0px;
}

.div-order-head {
    font-size: 16px;
    font-weight: 700;
}

.div-order-subhead {
    font-size: 13px;
    font-weight: 600;
    padding-left: 20px;
    border-left: 1px dashed rgb(133, 80, 42);
}
.div-order-subhead span {
    font-style: italic;
}

.btn-select {
    width:100%;
    padding: 20px !important;
    background-color: #fff;
    border-radius: 5px;
    border: 0px;
}

.btn-select:focus {
    outline: 0px;
}
.btn-select i {
    display:block;
    font-size: 24px;
}

.btn-select.active {
    width:100%;
    padding: 20px !important;
   /*  background-color: #D8AC6D; */
    color: #fff;
}
.btn-select.active i {
    /* color: #fff; */
}

.btn-continue {
    background-color: #D8AC6D;
    color: #fff;
    width:100%;
    
}
.btn-continue i, .btn-continue-payment i  {
    color:#fff;
    margin-left: 10px;
}

.btn-continue-payment {
    width:100%;
    padding: 0px !important;
    text-align: unset !important;
    display: flex !important;
}

.btn-continue-payment img {
    width:35px;
    height:35px;
}
.btn-continue-payment p{
    display: table;
    width: 100%;
    text-align: center;
    margin-bottom: 0px;
    margin-top: 5px;
}
.div-pay-or p {
    text-align: center;
}

.div-gift-template-buy {
    background-color: #DADCE0;
    border: 1px solid #DADCE0;
    /* border-top-left-radius: 15px;
    border-top-right-radius: 15px; */
    position: relative;
    overflow: hidden;
    margin-left: 25px;
    margin-right: 25px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    margin-bottom: 20px;
}

.div_template_parent {
    max-width: 500px;
    margin: auto;
}

.div-amt {
    padding-left: 25px !important;
}
.div-amt span {
    margin-right: 10px;
    text-decoration: line-through;
}

.inval_cpn_cls {
    color: red !important;
    text-align: center;
    display: block;
    font-size: 12px !important;
    font-weight: 600;
    background-color: #efe5e5;
    padding: 5px;
    margin-top: 10px;
    border-radius: 5px;
}
.succ_cpn_cls {
    color: green !important;
    text-align: center;
    display: block;
    font-size: 10px !important;
    font-weight: 600;
    background-color: #E5EFE5;
    padding: 5px;
    margin-top: 5px;
    border-radius: 5px;
}
.succ_cpn_cls i {
    margin-right: 5px;
    font-size: 12px;
}
.coupon_desc{
	font-size: 12px !important;
	margin-top:2%;
	margin-bottom:2%;
}	

/* for all mobile */
@media only screen and (max-width: 599px) {
    .div-gift-template-buy {
        margin-left: 0px;
        margin-right: 0px;
    }

    .div_template_parent .div-footer-description p {
        max-height: 85px;
    }
}

/* media query for screen width 414 */
@media only screen and (max-width: 599px) and (min-width: 411px) {
    .div-gift-template {
        min-height: 240px;
    }
    .div-amt {
        padding-left: 20px !important;
    }
}

/* media query for screen width 375 */
@media only screen and (max-width: 410px) and (min-width: 375px) {
    .div-gift-template {
        min-height: 230px;
    }
    .div-amt {
        padding-left: 12px !important;
    }
    .btn-buy {
        padding: 2px 6px !important;
    }
    .res-p0 {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
}

/* media query for screen width 360 */
@media only screen and (max-width: 374px) and (min-width: 360px) {
    .div-gift-template {
        min-height: 220px;
    }
    .res-p0 {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    .div-amt {
        padding-left: 5px !important;
    }
    .btn-buy {
        padding: 4px 4px !important;
    }
}

/* media query for screen width 320 */
@media only screen and (max-width: 359px) and (min-width: 320px) {
    .div-gift-template {
        min-height: 200px;
    }
    .res-p0 {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    .div-amt {
        padding-left: 5px !important;
    }
    .btn-buy {
        padding: 4px 4px !important;
        font-size: 10px !important;
    }
    .div-card-list span, .div-card-list label {
        font-size: 12px;
    }
}

/* Buy Section - Ends */

/* Gift Template 1 */
.div-gift-template-one {
    /* background-color: #DADCE0;
    border: 1px solid #DADCE0; */
    /* border-top-left-radius: 15px;
    border-top-right-radius: 15px; */
    /* border-radius: 15px; */
    position: relative;
    overflow: hidden;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.div-gift-template-one .div-gift-template .div-template-header {
    background-color: #D8AC6D;
    padding: 10px 15px;
    text-align: center;
}
.div-gift-template-one .div-gift-template .div-template-header img {    
    max-height: 32px;
}

.div-gift-template-one .div-gift-template .div-template-footer {
    background-color: #D8AC6D;
    padding: 15px;
    width: 100%;
    position: absolute;
    bottom: 0px;
    text-align: center;
}
.div-gift-template-one .div-gift-template .div-template-footer span {
    letter-spacing: 1.2px;
    color: #E7DCD4;
    font-weight: 300;      
}
.div-gift-template-one span, .div-gift-template-one label, .div-gift-template-one p, .div-gift-template-one i {
   /*  color: #D8AC6D; */
}
.err_bor{
	
	border: 1px solid red !important;
}	
.div-gift-template-one .div-card-list .btn-buy {
    color: #D8AC6D;
    border: 1px solid #D8AC6D;
}
.div-gift-template-one .div-card-list .btn-buy:hover, .div-gift-template-one .div-card-list .btn-buy:focus {
    color: #fff;
    border: 1px solid #D8AC6D;
    background-color: #D8AC6D;
}
.div-gift-template-one .div-card-list .col-md-4 input {
    border: 1px solid #D8AC6D;
}
.div-gift-template-one .div-gift-promo input {
    border: 1px solid #D8AC6D;
    color: #D8AC6D;
}
.div-gift-template-one .div-limited-child input{
    color: #D8AC6D;
}
.div-gift-template-one .div-limited-child input::placeholder {
    color: #D8AC6DCC;
}
.div-gift-template-one .div-limited-child .btn {
    color: #D8AC6D;
    border: 1px solid #D8AC6D;
}
.div-gift-template-one .div-buy1-child, .div-gift-template-one .div-buy2-child, .div-gift-template-one .div-footer-description {
    color: #D8AC6D;
}
.div-gift-template-one .div-limited-child input {
    color: #D8AC6D;
    border: 1px solid #D8AC6D;
    background-color: #fff;
}
.div-gift-template-one .div-limited-child .btn:hover, .div-gift-template-one .div-limited-child .btn:focus {
    color: #fff;
    border: 1px solid #D8AC6D;
    background-color: #D8AC6D;
}

/* Gift Template 2 */
.div-gift-template-two {
    background-color: #DADCE0;
    border: 1px solid #DADCE0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
    overflow: hidden;
}

.div-gift-template-two .div-gift-template .div-template-footer {
    background-color: #ECB621;
    padding: 15px;
    width: 100%;
    position: absolute;
    bottom: 0px;
    text-align: center;
}
.div-gift-template-two .div-gift-template .div-template-footer span {
    letter-spacing: 1.2px;
    color: #fff;
    font-weight: 300;      
}
.div-gift-template-two .div-gift-template .div-template-footer img {
    max-height: 32px;
}
.div-gift-template-two span, .div-gift-template-two label, .div-gift-template-two p, .div-gift-template-two i {
    color: #ECB621;
}
.div-gift-template-two .div-card-list .btn-buy {
    color: #ECB621;
    border: 1px solid #ECB621;
}
.div-gift-template-two .div-card-list .btn-buy:hover, .div-gift-template-two .div-card-list .btn-buy:focus {
    color: #fff;
    border: 1px solid #ECB621;
    background-color: #ECB621;
}
.div-gift-template-two .div-card-list .col-md-4 input {
    border: 1px solid #ECB621;
}
.div-gift-template-two .div-gift-promo input {
    border: 1px solid #ECB621;
    color: #ECB621;
}
.div-gift-template-two .div-limited-child .btn {
    color: #ECB621;
    border: 1px solid #ECB621;
}
.div-gift-template-two .div-buy1-child, .div-gift-template-two .div-buy2-child, .div-gift-template-two .div-footer-description {
    color: #ECB621;
}
.div-gift-template-two .div-limited-child input {
    color: #ECB621;
    border: 1px solid #ECB621;
    background-color: #fff;
}
.div-gift-template-two .div-limited-child input {
    color: #ECB621;
}
.div-gift-template-two .div-limited-child input::placeholder {
    color: #ECB621CC;
}
.div-gift-template-two .div-limited-child .btn:hover, .div-gift-template-two .div-limited-child .btn:focus {
    color: #fff;
    border: 1px solid #ECB621;
    background-color: #ECB621;
}

/* Gift Template 3 */
.div-gift-template-three {
    background-color: #DADCE0;
    border: 1px solid #DADCE0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
    overflow: hidden;
}

/*Right pointing*/
.div-gift-template-three .col-5 div.triangle-right {
    width: 100%;    
	height: 0;
	border-top: 138px solid #426329;
	border-left: 70px solid transparent;
	border-bottom: 137px solid #426329;
}
.div-gift-template-three .div-gift-template .col-5 .div-logo {
    position: absolute;
    top: 15px;
    left: 30px;
}

.div-gift-template-three .div-gift-template .col-5 img {
    max-height: 32px;
}
.div-gift-template-three .div-gift-template .col-5 .div-card-header {
    position: absolute;
    bottom: 15px;
    left: 30px;
}
.div-gift-template-three .div-gift-template .col-5 .div-card-header span {
    color: #fff;
}
.div-gift-template-three span, .div-gift-template-three label, .div-gift-template-three p, .div-gift-template-three i {
    color: #426329;
}
.div-gift-template-three .div-card-list .btn-buy {
    color: #426329;
    border: 1px solid #426329;
}
.div-gift-template-three .div-card-list .btn-buy:hover, .div-gift-template-three .div-card-list .btn-buy:focus {
    color: #fff;
    border: 1px solid #426329;
    background-color: #426329;
}
.div-gift-template-three .div-card-list .col-md-4 input {
    border: 1px solid #426329;
}
.div-gift-template-three .div-gift-promo input {
    border: 1px solid #426329;
    color: #426329;
}
.div-gift-template-three .div-limited-child .btn {
    color: #426329;
    border: 1px solid #426329;
}
.div-gift-template-three .div-buy1-child, .div-gift-template-three .div-buy2-child, .div-gift-template-three .div-footer-description {
    color: #426329;
}
.div-gift-template-three .div-limited-child input {
    color: #426329;
    border: 1px solid #426329;
    background-color: #fff;
}
.div-gift-template-three .div-limited-child input {
    color: #426329;
}
.div-gift-template-three .div-limited-child input::placeholder {
    color: #426329CC;
}
.div-gift-template-three .div-limited-child .btn:hover, .div-gift-template-three .div-limited-child .btn:focus {
    color: #fff;
    border: 1px solid #426329;
    background-color: #426329;
}

/* Gift Template 4 */
.div-gift-template-four {
    background-color: #DADCE0;
    border: 1px solid #DADCE0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
    overflow: hidden;
}

/*Right pointing*/
.div-gift-template-four .col-7 .triangle-right {
	width: 0;
	height: 0;
	border-top: 7px solid transparent;
	border-left: 8px solid #817F88;
    border-bottom: 7px solid transparent;
    margin-top: 125px;
}
.div-gift-template-four .div-gift-template .col-5 {
    background-color: #817F88;
    height:275px;
}

.div-gift-template-four .div-gift-template .col-5 .div-logo {
    position: absolute;
    top: 15px;
    left: 30px;
}

.div-gift-template-four .div-gift-template .col-5 img {
    max-height: 32px;
}
.div-gift-template-four .div-gift-template .col-5 .div-card-header {
    position: absolute;
    bottom: 15px;
    width: 90%;
    text-align: center;
}
.div-gift-template-four .div-gift-template .col-5 .div-card-header span {
    color: #fff;
}
.div-gift-template-four span, .div-gift-template-four label, .div-gift-template-four p, .div-gift-template-four i {
    color: #817F88;
}
.div-gift-template-four .div-card-list .btn-buy {
    color: #817F88;
    border: 1px solid #817F88;
}
.div-gift-template-four .div-card-list .btn-buy:hover, .div-gift-template-four .div-card-list .btn-buy:focus {
    color: #fff;
    border: 1px solid #817F88;
    background-color: #817F88;
}
.div-gift-template-four .div-card-list .col-md-4 input {
    border: 1px solid #817F88;
}
.div-gift-template-four .div-gift-promo input {
    border: 1px solid #817F88;
    color: #817F88;
}
.div-gift-template-four .div-limited-child .btn {
    color: #817F88;
    border: 1px solid #817F88;
}
.div-gift-template-four .div-buy1-child, .div-gift-template-four .div-buy2-child, .div-gift-template-four .div-footer-description {
    color: #817F88;
}
.div-gift-template-four .div-limited-child input {
    color: #817F88;
    border: 1px solid #817F88;
    background-color: #fff;
}
.div-gift-template-four .div-limited-child input {
    color: #817F88;
}
.div-gift-template-four .div-limited-child input::placeholder {
    color: #817F88CC;
}
.div-gift-template-four .div-limited-child .btn:hover, .div-gift-template-four .div-limited-child .btn:focus {
    color: #fff;
    border: 1px solid #817F88;
    background-color: #817F88;
}

/* Gift Template 5 */
.div-gift-template-five {
    background-color: #DADCE0;
    border: 1px solid #DADCE0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
    overflow: hidden;
}

/*Right pointing*/
.div-gift-template-five .col-6 div.half-circle {
    width: 100%;
    height: 275px;
    background-color: #E7C094;
    border-top-left-radius: 30% 50%;
    border-bottom-left-radius: 30% 50%;    
    border-bottom: 0;
}

.div-gift-template-five .div-gift-template .col-6 .div-logo {
    position: absolute;
    top: 15px;
    left: 60px;
}

.div-gift-template-five .div-gift-template .col-6 img {
    max-height: 32px;
}
.div-gift-template-five .div-gift-template .col-6 .div-card-header {
    position: absolute;
    bottom: 15px;
    width: 90%;
    text-align: center;
}
.div-gift-template-five .div-gift-template .col-6 .div-card-header span {
    color: #07293B;
}
.div-gift-template-five span, .div-gift-template-five label, .div-gift-template-five p, .div-gift-template-five i {
    color: #E7C094;
}
.div-gift-template-five .div-card-list .btn-buy {
    color: #E7C094;
    border: 1px solid #E7C094;
}
.div-gift-template-five .div-card-list .btn-buy:hover, .div-gift-template-five .div-card-list .btn-buy:focus {
    color: #fff;
    border: 1px solid #E7C094;
    background-color: #E7C094;
}
.div-gift-template-five .div-card-list .col-md-4 input {
    border: 1px solid #E7C094;
}
.div-gift-template-five .div-gift-promo input {
    border: 1px solid #E7C094;
    color: #E7C094;
}
.div-gift-template-five .div-limited-child .btn {
    color: #E7C094;
    border: 1px solid #E7C094;
}
.div-gift-template-five .div-buy1-child, .div-gift-template-five .div-buy2-child, .div-gift-template-five .div-footer-description {
    color: #E7C094;
}
.div-gift-template-five .div-limited-child input {
    color: #E7C094;
    border: 1px solid #E7C094;
    background-color: #fff;
}
.div-gift-template-five .div-limited-child input {
    color: #E7C094;
}
.div-gift-template-five .div-limited-child input::placeholder {
    color: #E7C094CC;
}
.div-gift-template-five .div-limited-child .btn:hover, .div-gift-template-five .div-limited-child .btn:focus {
    color: #fff;
    border: 1px solid #E7C094;
    background-color: #E7C094;
}

/* Gift Template 6 */
.div-gift-template-six {
    background-color: #DADCE0;
    border: 1px solid #DADCE0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
    overflow: hidden;
}
.div-gift-template-six .div-gift-template {
    background-color: #910E0B;
}

.div-gift-template-six .div-template-child {
    background-color: #fff;
    margin: 20px;
    border-radius: 15px;
    min-height: 235px;
    text-align: center;
}

.div-gift-template-six .div-gift-template .div-logo {
    padding-top: 75px;
}

.div-gift-template-six .div-gift-template img {
    max-height: 35px;
}

.div-gift-template-six .div-gift-template .col-6 .div-card-header span {
    color: #910E0B;
}
.div-gift-template-six span, .div-gift-template-six label, .div-gift-template-six p, .div-gift-template-six i {
    color: #910E0B;
}
.div-gift-template-six .div-card-list .btn-buy {
    color: #910E0B;
    border: 1px solid #910E0B;
}
.div-gift-template-six .div-card-list .btn-buy:hover, .div-gift-template-six .div-card-list .btn-buy:focus {
    color: #fff;
    border: 1px solid #910E0B;
    background-color: #910E0B;
}
.div-gift-template-six .div-card-list .col-md-4 input {
    border: 1px solid #910E0B;
}
.div-gift-template-six .div-gift-promo input {
    border: 1px solid #910E0B;
    color: #910E0B;
}
.div-gift-template-six .div-limited-child .btn {
    color: #910E0B;
    border: 1px solid #910E0B;
}
.div-gift-template-six .div-buy1-child, .div-gift-template-six .div-buy2-child, .div-gift-template-six .div-footer-description {
    color: #910E0B;
}
.div-gift-template-six .div-limited-child input {
    color: #910E0B;
    border: 1px solid #910E0B;
    background-color: #fff;
}
.div-gift-template-six .div-limited-child input {
    color: #910E0B;
}
.div-gift-template-six .div-limited-child input::placeholder {
    color: #910E0BCC;
}
.div-gift-template-six .div-limited-child .btn:hover, .div-gift-template-six .div-limited-child .btn:focus {
    color: #fff;
    border: 1px solid #910E0B;
    background-color: #910E0B;
}

/* Gift Template 7 */
.div-gift-template-seven {
    background-color: #DADCE0;
    border: 1px solid #DADCE0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
    overflow: hidden;
}

.div-gift-template-seven .div-gift-template .div-template-footer {
    background-color: #1C466C;
    padding: 15px;
    width: 100%;
    position: absolute;
    top: 0px;
    text-align: center;
}
.div-gift-template-seven .div-gift-template .div-template-footer span {
    letter-spacing: 1.2px;
    color: #fff;
    font-weight: 300;      
}
.div-gift-template-seven .div-gift-template .div-template-footer img {
    max-height: 32px;
}
.div-gift-template-seven span, .div-gift-template-seven label, .div-gift-template-seven p, .div-gift-template-seven i {
    color: #1C466C;
}
.div-gift-template-seven .div-card-list .btn-buy {
    color: #1C466C;
    border: 1px solid #1C466C;
}
.div-gift-template-seven .div-card-list .btn-buy:hover, .div-gift-template-seven .div-card-list .btn-buy:focus {
    color: #fff;
    border: 1px solid #1C466C;
    background-color: #1C466C;
}
.div-gift-template-seven .div-card-list .col-md-4 input {
    border: 1px solid #1C466C;
}
.div-gift-template-seven .div-gift-promo input {
    border: 1px solid #1C466C;
    color: #1C466C;
}
.div-gift-template-seven .div-limited-child .btn {
    color: #1C466C;
    border: 1px solid #1C466C;
}
.div-gift-template-seven .div-buy1-child, .div-gift-template-seven .div-buy2-child, .div-gift-template-seven .div-footer-description {
    color: #1C466C;
}
.div-gift-template-seven .div-limited-child input {
    color: #1C466C;
    border: 1px solid #1C466C;
    background-color: #fff;
}
.div-gift-template-seven .div-limited-child input {
    color: #1C466C;
}
.div-gift-template-seven .div-limited-child input::placeholder {
    color: #1C466CCC;
}
.div-gift-template-seven .div-limited-child .btn:hover, .div-gift-template-seven .div-limited-child .btn:focus {
    color: #fff;
    border: 1px solid #1C466C;
    background-color: #1C466C;
}

/* Gift Template 8 */
.div-gift-template-eight {
    background-color: #DADCE0;
    border: 1px solid #DADCE0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
    overflow: hidden;
}

.div-gift-template-eight .col-5 {
    padding-left: 15px;
    height:200px;
    background-color: #474A58;
    border-bottom-right-radius: 30% 100%;
}


.div-gift-template-eight .div-gift-template .col-5 {
    background-color: #817F88;
    height:275px;
}

.div-gift-template-eight .div-gift-template .col-5 .div-logo {
    position: absolute;
    top: 15px;
    left: 30px;
}

.div-gift-template-eight .div-gift-template .col-5 img {
    max-height: 32px;
}
.div-gift-template-eight .div-gift-template .col-5 .div-card-header {
    position: absolute;
    bottom: 15px;
    width: 70%;
    text-align: center;
}
.div-gift-template-eight .div-gift-template .col-5 .div-card-header span {
    color: #fff;
}
.div-gift-template-eight span, .div-gift-template-eight label, .div-gift-template-eight p, .div-gift-template-eight i {
    color: #474A58;
}
.div-gift-template-eight .div-card-list .btn-buy {
    color: #474A58;
    border: 1px solid #474A58;
}
.div-gift-template-eight .div-card-list .btn-buy:hover, .div-gift-template-eight .div-card-list .btn-buy:focus {
    color: #fff;
    border: 1px solid #474A58;
    background-color: #474A58;
}
.div-gift-template-eight .div-card-list .col-md-4 input {
    border: 1px solid #474A58;
}
.div-gift-template-eight .div-gift-promo input {
    border: 1px solid #474A58;
    color: #474A58;
}
.div-gift-template-eight .div-limited-child .btn {
    color: #474A58;
    border: 1px solid #474A58;
}
.div-gift-template-eight .div-buy1-child, .div-gift-template-eight .div-buy2-child, .div-gift-template-eight .div-footer-description {
    color: #474A58;
}
.div-gift-template-eight .div-limited-child input {
    color: #474A58;
    border: 1px solid #474A58;
    background-color: #fff;
}
.div-gift-template-eight .div-limited-child input {
    color: #474A58;
}
.div-gift-template-eight .div-limited-child input::placeholder {
    color: #474A58CC;
}
.div-gift-template-eight .div-limited-child .btn:hover, .div-gift-template-eight .div-limited-child .btn:focus {
    color: #fff;
    border: 1px solid #474A58;
    background-color: #474A58;
}

/* Gift Template 9 */
.div-gift-template-nine {
    background-color: #DADCE0;
    border: 1px solid #DADCE0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
    overflow: hidden;
}

.div-gift-template-nine .div-template-child {
    background-color: #4940A9;
    margin: 0px;
    border-radius: 15px;
    min-height: 275px;
    text-align: center;
}

.div-gift-template-nine .div-gift-template .div-logo {
    padding-top: 120px;
}

.div-gift-template-nine .div-gift-template img {
    max-height: 35px;
}

.div-gift-template-nine .div-template-child .div-card-header {
    margin-top: 45px;
}

.div-gift-template-nine .div-template-child .div-card-header span {
    color: #fff;    
}
.div-gift-template-nine span, .div-gift-template-nine label, .div-gift-template-nine p, .div-gift-template-nine i {
    color: #4940A9;
}
.div-gift-template-nine .div-card-list .btn-buy {
    color: #4940A9;
    border: 1px solid #4940A9;
}
.div-gift-template-nine .div-card-list .btn-buy:hover, .div-gift-template-nine .div-card-list .btn-buy:focus {
    color: #fff;
    border: 1px solid #4940A9;
    background-color: #4940A9;
}
.div-gift-template-nine .div-card-list .col-md-4 input {
    border: 1px solid #4940A9;
}
.div-gift-template-nine .div-gift-promo input {
    border: 1px solid #4940A9;
    color: #4940A9;
}
.div-gift-template-nine .div-limited-child .btn {
    color: #4940A9;
    border: 1px solid #4940A9;
}
.div-gift-template-nine .div-buy1-child, .div-gift-template-nine .div-buy2-child, .div-gift-template-nine .div-footer-description {
    color: #4940A9;
}
.div-gift-template-nine .div-limited-child input {
    color: #4940A9;
    border: 1px solid #4940A9;
    background-color: #fff;
}
.div-gift-template-nine .div-limited-child input {
    color: #4940A9;
}
.div-gift-template-nine .div-limited-child input::placeholder {
    color: #4940A9CC;
}
.div-gift-template-nine .div-limited-child .btn:hover, .div-gift-template-nine .div-limited-child .btn:focus {
    color: #fff;
    border: 1px solid #4940A9;
    background-color: #4940A9;
}

/* Gift Template 10 */
.div-gift-template-ten {
    background-color: #DADCE0;
    border: 1px solid #DADCE0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
    overflow: hidden;
}

.div-gift-template-ten .div-gift-template .div-template-footer {
    background-color: #42D7D9;
    padding: 15px;
    width: 100%;
    position: absolute;
    bottom: 0px;
    /* text-align: center; */
}
.div-gift-template-ten .div-gift-template .div-template-footer span {
    letter-spacing: 1.2px;
    color: #fff;
    font-weight: 300;      
}
.div-gift-template-ten .div-gift-template .div-template-footer img {
    max-height: 32px;
}
.div-gift-template-ten span, .div-gift-template-ten label, .div-gift-template-ten p, .div-gift-template-ten i {
    color: #42D7D9;
}
.div-gift-template-ten .div-card-list .btn-buy {
    color: #42D7D9;
    border: 1px solid #42D7D9;
}
.div-gift-template-ten .div-card-list .btn-buy:hover, .div-gift-template-ten .div-card-list .btn-buy:focus {
    color: #fff;
    border: 1px solid #42D7D9;
    background-color: #42D7D9;
}
.div-gift-template-ten .div-card-list .col-md-4 input {
    border: 1px solid #42D7D9;
}
.div-gift-template-ten .div-gift-promo input {
    border: 1px solid #42D7D9;
    color: #42D7D9;
}
.div-gift-template-ten .div-limited-child .btn {
    color: #42D7D9;
    border: 1px solid #42D7D9;
}
.div-gift-template-ten .div-buy1-child, .div-gift-template-ten .div-buy2-child, .div-gift-template-ten .div-footer-description {
    color: #42D7D9;
}
.div-gift-template-ten .div-limited-child input {
    color: #42D7D9;
    border: 1px solid #42D7D9;
    background-color: #fff;
}
.div-gift-template-ten .div-limited-child input {
    color: #42D7D9;
}
.div-gift-template-ten .div-limited-child input::placeholder {
    color: #42D7D9CC;
}
.div-gift-template-ten .div-limited-child .btn:hover, .div-gift-template-ten .div-limited-child .btn:focus {
    color: #fff;
    border: 1px solid #42D7D9;
    background-color: #42D7D9;
}

/* Gift Template 11 */
.div-gift-template-eleven {
    background-color: #DADCE0;
    border: 1px solid #DADCE0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
    overflow: hidden;
}

.div-gift-template-eleven .col-6 .div-left {
    padding-left: 30px;
    width: 100%;
    height: 0;    
    border-top: 200px solid #F19E05;
    border-right: 40px solid transparent;
}

.div-gift-template-eleven .div-gift-template .col-6 .div-logo {
    position: absolute;
    top: 15px;
    left: 30px;
}

.div-gift-template-eleven .div-gift-template .col-6 img {
    max-height: 32px;
}
.div-gift-template-eleven .div-gift-template .col-6 .div-card-header {
    position: absolute;
    bottom: 15px;
    width: 70%;
    text-align: center;
}
.div-gift-template-eleven .div-gift-template .col-6 .div-card-header span {
    color: #fff;
}
.div-gift-template-eleven span, .div-gift-template-eleven label, .div-gift-template-eleven p, .div-gift-template-eleven i {
    color: #F19E05;
}
.div-gift-template-eleven .div-card-list .btn-buy {
    color: #F19E05;
    border: 1px solid #F19E05;
}
.div-gift-template-eleven .div-card-list .btn-buy:hover, .div-gift-template-eleven .div-card-list .btn-buy:focus {
    color: #fff;
    border: 1px solid #F19E05;
    background-color: #F19E05;
}
.div-gift-template-eleven .div-card-list .col-md-4 input {
    border: 1px solid #F19E05;
}
.div-gift-template-eleven .div-gift-promo input {
    border: 1px solid #F19E05;
    color: #F19E05;
}
.div-gift-template-eleven .div-limited-child .btn {
    color: #F19E05;
    border: 1px solid #F19E05;
}
.div-gift-template-eleven .div-buy1-child, .div-gift-template-eleven .div-buy2-child, .div-gift-template-eleven .div-footer-description {
    color: #F19E05;
}
.div-gift-template-eleven .div-limited-child input {
    color: #F19E05;
    border: 1px solid #F19E05;
    background-color: #fff;
}
.div-gift-template-eleven .div-limited-child input {
    color: #F19E05;
}
.div-gift-template-eleven .div-limited-child input::placeholder {
    color: #F19E05CC;
}
.div-gift-template-eleven .div-limited-child .btn:hover, .div-gift-template-eleven .div-limited-child .btn:focus {
    color: #fff;
    border: 1px solid #F19E05;
    background-color: #F19E05;
}

/* Gift Template 12 */
.div-gift-template-twelve {
    background-color: #DADCE0;
    border: 1px solid #DADCE0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
    overflow: hidden;
}

.div-gift-template-twelve .col-5 .div-right {    
    width: 100%;
    height: 0;    
    border-top: 170px solid #17171E;
    border-left: 205px solid transparent;
}
.div-gift-template-twelve .div-gift-template .col-7 {
    height:275px;
}
.div-gift-template-twelve .div-gift-template .col-7 .div-logo {
    padding: 10px 15px;
    position: absolute;
    bottom: 0px;
    width: 100%;
    background-color: #17171E;    
    border-top-right-radius: 15px;
}

.div-gift-template-twelve .div-gift-template .col-7 img {
    max-height: 32px;
}
.div-gift-template-twelve .div-gift-template .col-5 .div-card-header {
    position: absolute;
    top: 20px;
    width: 60%;
    text-align: center;
    right: 15px;
}
.div-gift-template-twelve .div-gift-template .col-5 .div-card-header span {
    color: #fff;
}
.div-gift-template-twelve span, .div-gift-template-twelve label, .div-gift-template-twelve p, .div-gift-template-twelve i {
    color: #17171E;
}
.div-gift-template-twelve .div-card-list .btn-buy {
    color: #17171E;
    border: 1px solid #17171E;
}
.div-gift-template-twelve .div-card-list .btn-buy:hover, .div-gift-template-twelve .div-card-list .btn-buy:focus {
    color: #fff;
    border: 1px solid #17171E;
    background-color: #17171E;
}
.div-gift-template-twelve .div-card-list .col-md-4 input {
    border: 1px solid #17171E;
}
.div-gift-template-twelve .div-gift-promo input {
    border: 1px solid #17171E;
    color: #17171E;
}
.div-gift-template-twelve .div-limited-child .btn {
    color: #17171E;
    border: 1px solid #17171E;
}
.div-gift-template-twelve .div-buy1-child, .div-gift-template-twelve .div-buy2-child, .div-gift-template-twelve .div-footer-description {
    color: #17171E;
}
.div-gift-template-twelve .div-limited-child input {
    color: #17171E;
    border: 1px solid #17171E;
    background-color: #fff;
}
.div-gift-template-twelve .div-limited-child input {
    color: #17171E;
}
.div-gift-template-twelve .div-limited-child input::placeholder {
    color: #17171ECC;
}
.div-gift-template-twelve .div-limited-child .btn:hover, .div-gift-template-twelve .div-limited-child .btn:focus {
    color: #fff;
    border: 1px solid #17171E;
    background-color: #17171E;
}

/* Gift Template 13 */
.div-gift-template-thirteen {
    background-color: #DADCE0;
    border: 1px solid #DADCE0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
    overflow: hidden;
}

.div-gift-template-thirteen .div-template-child {
    background-color: #42D7D999;
    margin: 0px;
    border-radius: 15px;
    min-height: 275px;
    text-align: center;
}

.div-gift-template-thirteen .div-gift-template .div-logo {
    padding-top: 110px;
}

.div-gift-template-thirteen .div-gift-template img {
    max-height: 40px;
    min-height: 40px;
}

.div-gift-template-thirteen .div-template-child .div-card-header {
    margin-top: 30px;
}

.div-gift-template-thirteen .div-template-child .div-card-header span {
    color: #fff;    
}
.div-gift-template-thirteen span, .div-gift-template-thirteen label, .div-gift-template-thirteen p, .div-gift-template-thirteen i {
    color: #42D7D9;
}
.div-gift-template-thirteen .div-card-list .btn-buy {
    color: #42D7D9;
    border: 1px solid #42D7D9;
}
.div-gift-template-thirteen .div-card-list .btn-buy:hover, .div-gift-template-thirteen .div-card-list .btn-buy:focus {
    color: #fff;
    border: 1px solid #42D7D9;
    background-color: #42D7D9;
}
.div-gift-template-thirteen .div-card-list .col-md-4 input {
    border: 1px solid #42D7D9;
}
.div-gift-template-thirteen .div-gift-promo input {
    border: 1px solid #42D7D9;
    color: #42D7D9;
}
.div-gift-template-thirteen .div-limited-child .btn {
    color: #42D7D9;
    border: 1px solid #42D7D9;
}
.div-gift-template-thirteen .div-buy1-child, .div-gift-template-thirteen .div-buy2-child, .div-gift-template-thirteen .div-footer-description {
    color: #42D7D9;
}
.div-gift-template-thirteen .div-limited-child input {
    color: #42D7D9;
    border: 1px solid #42D7D9;
    background-color: #fff;
}
.div-gift-template-thirteen .div-limited-child input {
    color: #42D7D9;
}
.div-gift-template-thirteen .div-limited-child input::placeholder {
    color: #42D7D9CC;
}
.div-gift-template-thirteen .div-limited-child .btn:hover, .div-gift-template-thirteen .div-limited-child .btn:focus {
    color: #fff;
    border: 1px solid #42D7D9;
    background-color: #42D7D9;
}

/*Gift Template 14 */

.div-gift-template-fourteen {
    background-color: #DADCE0;
    border: 1px solid #DADCE0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
    overflow: hidden;
}

.div-gift-template-fourteen .div-template-child {
    background-color: #42D7D999;
    margin: 0px;
    border-radius: 15px;
    min-height: 200px;
    text-align: center;
}

.div-gift-template-fourteen .col-5 div.div-card-header {
    width:60%;
    position: absolute;
    bottom:15px;    
}
.div-gift-template-fourteen .col-5 div.div-card-header span {
    color:#fff;
}
.div-gift-template-fourteen .col-5 {
    padding-left: 15px;
    height:275px;
    background-color: #1A683A;
}
.div-gift-template-fourteen .div-points {
    position: absolute;
    background-color: #fff;
    height: 120px;
    width: 120px;
    border-radius: 50%;
    text-align: center;
    left: -60px;
    top: 15px;
}

.div-gift-template-fourteen .div-gift-template .div-logo {
    padding-top: 30px;
}

.div-gift-template-fourteen .div-gift-template img {
    max-height: 35px;
    width:80%;
}

.div-gift-template-fourteen .div-template-child .div-card-header {
    margin-top: 30px;
}

.div-gift-template-fourteen .div-template-child .div-card-header span {
    color: #fff;    
}
.div-gift-template-fourteen span, .div-gift-template-fourteen label, .div-gift-template-fourteen p, .div-gift-template-fourteen i {
    color: #1A683A;
}
.div-gift-template-fourteen .div-card-list .btn-buy {
    color: #1A683A;
    border: 1px solid #1A683A;
}
.div-gift-template-fourteen .div-card-list .btn-buy:hover, .div-gift-template-fourteen .div-card-list .btn-buy:focus {
    color: #fff;
    border: 1px solid #1A683A;
    background-color: #1A683A;
}
.div-gift-template-fourteen .div-card-list .col-md-4 input {
    border: 1px solid #1A683A;
}
.div-gift-template-fourteen .div-gift-promo input {
    border: 1px solid #1A683A;
    color: #1A683A;
}
.div-gift-template-fourteen .div-limited-child .btn {
    color: #1A683A;
    border: 1px solid #1A683A;
}
.div-gift-template-fourteen .div-buy1-child, .div-gift-template-fourteen .div-buy2-child, .div-gift-template-fourteen .div-footer-description {
    color: #1A683A;
}
.div-gift-template-fourteen .div-limited-child input {
    color: #1A683A;
    border: 1px solid #1A683A;
    background-color: #fff;
}
.div-gift-template-fourteen .div-limited-child input {
    color: #1A683A;
}
.div-gift-template-fourteen .div-limited-child input::placeholder {
    color: #1A683ACC;
}
.div-gift-template-fourteen .div-limited-child .btn:hover, .div-gift-template-fourteen .div-limited-child .btn:focus {
    color: #fff;
    border: 1px solid #1A683A;
    background-color: #1A683A;
}

/*Gift Template 15 */

.div-gift-template-fifteen {
    background-color: #DADCE0;
    border: 1px solid #DADCE0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
    overflow: hidden;
}

.div-gift-template-fifteen .div-template-child {
    background-color: #42D7D999;
    margin: 0px;
    border-radius: 15px;
    min-height: 200px;
    text-align: center;
}

.div-gift-template-fifteen .col-5 div.div-card-header {
    width:60%;
    position: absolute;
    bottom:15px;    
}
.div-gift-template-fifteen .col-5 div.div-card-header span {
    color:#fff;
}
.div-gift-template-fifteen .col-8 .div-left {
    padding-left: 30px;
    width: 100%;
    height: 0;    
    border-top: 275px solid #948F34;
    border-right: 100px solid transparent;
}

.div-gift-template-fifteen .div-gift-template .div-logo {
    position: absolute;
    top: 20px;
    left: 30px;
}
.div-gift-template-fifteen .div-card-header {
    position: absolute;
    bottom: 20px;
    left: 30px;
}

.div-gift-template-fifteen .div-card-header span {
    color: #fff;    
}
.div-gift-template-fifteen span, .div-gift-template-fifteen label, .div-gift-template-fifteen p, .div-gift-template-fifteen i {
    color: #948F34;
}
.div-gift-template-fifteen .div-card-list .btn-buy {
    color: #948F34;
    border: 1px solid #948F34;
}
.div-gift-template-fifteen .div-card-list .btn-buy:hover, .div-gift-template-fifteen .div-card-list .btn-buy:focus {
    color: #fff;
    border: 1px solid #948F34;
    background-color: #948F34;
}
.div-gift-template-fifteen .div-card-list .col-md-4 input {
    border: 1px solid #948F34;
}
.div-gift-template-fifteen .div-gift-promo input {
    border: 1px solid #948F34;
    color: #948F34;
}
.div-gift-template-fifteen .div-limited-child .btn {
    color: #948F34;
    border: 1px solid #948F34;
}
.div-gift-template-fifteen .div-buy1-child, .div-gift-template-fifteen .div-buy2-child, .div-gift-template-fifteen .div-footer-description {
    color: #948F34;
}
.div-gift-template-fifteen .div-limited-child input {
    color: #948F34;
    border: 1px solid #948F34;
    background-color: #fff;
}
.div-gift-template-fifteen .div-limited-child input {
    color: #948F34;
}
.div-gift-template-fifteen .div-limited-child input::placeholder {
    color: #948F34CC;
}
.div-gift-template-fifteen .div-limited-child .btn:hover, .div-gift-template-fifteen .div-limited-child .btn:focus {
    color: #fff;
    border: 1px solid #948F34;
    background-color: #948F34;
}

/*Gift Template 16 */

.div-gift-template-sixteen {
    background-color: #DADCE0;
    border: 1px solid #DADCE0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
    overflow: hidden;
}

.div-gift-template-sixteen .div-template-child {
    background-color: #42D7D999;
    margin: 0px;
    border-radius: 15px;
    min-height: 275px;
    text-align: center;
}

.div-gift-template-sixteen .col-7 {
    padding-left: 15px;
    height:275px;
    background-color: #2D9C59;
    text-align: center;
}
.div-gift-template-sixteen .div-points {
    position: relative;
    background-color: #fff;
    height: 140px;
    width: 140px;
    border-radius: 50%;
    text-align: center;
    margin-left: 25%;
    margin-top: 25px; 
}
.div-gift-template-sixteen .div-points div {
    position: absolute;
    top:10px;    
}

.div-gift-template-sixteen .div-gift-template .div-logo {
    padding-top: 50px;
    width:100%;
}

.div-gift-template-sixteen .div-gift-template img {
    max-height: 35px;
    width:80%;
}

.div-gift-template-sixteen .div-card-header {
    margin-top: 50px;
}

.div-gift-template-sixteen .div-card-header span {
    color: #fff;    
}
.div-gift-template-sixteen span, .div-gift-template-sixteen label, .div-gift-template-sixteen p, .div-gift-template-sixteen i {
    color: #2D9C59;
}
.div-gift-template-sixteen .div-card-list .btn-buy {
    color: #2D9C59;
    border: 1px solid #2D9C59;
}
.div-gift-template-sixteen .div-card-list .btn-buy:hover, .div-gift-template-sixteen .div-card-list .btn-buy:focus {
    color: #fff;
    border: 1px solid #2D9C59;
    background-color: #2D9C59;
}
.div-gift-template-sixteen .div-card-list .col-md-4 input {
    border: 1px solid #2D9C59;
}
.div-gift-template-sixteen .div-gift-promo input {
    border: 1px solid #2D9C59;
    color: #2D9C59;
}
.div-gift-template-sixteen .div-limited-child .btn {
    color: #2D9C59;
    border: 1px solid #2D9C59;
}
.div-gift-template-sixteen .div-buy1-child, .div-gift-template-sixteen .div-buy2-child, .div-gift-template-sixteen .div-footer-description {
    color: #2D9C59;
}
.div-gift-template-sixteen .div-limited-child input {
    color: #2D9C59;
    border: 1px solid #2D9C59;
    background-color: #fff;
}
.div-gift-template-sixteen .div-limited-child input {
    color: #2D9C59;
}
.div-gift-template-sixteen .div-limited-child input::placeholder {
    color: #2D9C59CC;
}
.div-gift-template-sixteen .div-limited-child .btn:hover, .div-gift-template-sixteen .div-limited-child .btn:focus {
    color: #fff;
    border: 1px solid #2D9C59;
    background-color: #2D9C59;
}

/*Gift Template 17 */

.div-gift-template-seventeen {
    background-color: #DADCE0;
    border: 1px solid #DADCE0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
    overflow: hidden;
}

.div-gift-template-seventeen .div-template-child {
    background-color: #42D7D999;
    margin: 0px;
    border-radius: 15px;
    min-height: 275px;
    text-align: center;
}

.div-gift-template-seventeen .col-md-8 {
    background-color: #36462F;
    opacity: 0.9;
    min-height: 275px;
    padding-top: 15px;
}
.div-gift-template-seventeen .div-gift-template .div-logo {
    padding-top: 30px;
    text-align: center;
}

.div-gift-template-seventeen .div-gift-template img {
    max-height: 35px;
    width:80%;
}

.div-gift-template-seventeen .div-card-header {
    margin-top: 110px;
}

.div-gift-template-seventeen .div-card-header p {
    color: #E0BB4B;
    text-transform: uppercase;
    width: 100%;
    text-align: center;
}
.div-gift-template-seventeen span, .div-gift-template-seventeen label, .div-gift-template-seventeen p, .div-gift-template-seventeen i {
    color: #E0BB4B;
}
.div-gift-template-seventeen .div-card-list .btn-buy {
    color: #E0BB4B;
    border: 1px solid #E0BB4B;
}
.div-gift-template-seventeen .div-card-list .btn-buy:hover, .div-gift-template-seventeen .div-card-list .btn-buy:focus {
    color: #fff;
    border: 1px solid #E0BB4B;
    background-color: #E0BB4B;
}
.div-gift-template-seventeen .div-card-list .col-md-4 input {
    border: 1px solid #E0BB4B;
}
.div-gift-template-seventeen .div-gift-promo input {
    border: 1px solid #E0BB4B;
    color: #E0BB4B;
}
.div-gift-template-seventeen .div-limited-child .btn {
    color: #E0BB4B;
    border: 1px solid #E0BB4B;
}
.div-gift-template-seventeen .div-buy1-child, .div-gift-template-seventeen .div-buy2-child, .div-gift-template-seventeen .div-footer-description {
    color: #E0BB4B;
}
.div-gift-template-seventeen .div-limited-child input {
    color: #E0BB4B;
    border: 1px solid #E0BB4B;
    background-color: #fff;
}
.div-gift-template-seventeen .div-limited-child input {
    color: #E0BB4B;
}
.div-gift-template-seventeen .div-limited-child input::placeholder {
    color: #E0BB4BCC;
}
.div-gift-template-seventeen .div-limited-child .btn:hover, .div-gift-template-seventeen .div-limited-child .btn:focus {
    color: #fff;
    border: 1px solid #E0BB4B;
    background-color: #E0BB4B;
}

/*Gift Template 18 */

.div-gift-template-eightteen {
    background-color: #DADCE0;
    border: 1px solid #DADCE0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
    overflow: hidden;
}

.div-gift-template-eightteen .div-template-child {
    background-color: #42D7D999;
    margin: 0px;
    border-radius: 15px;
    min-height: 275px;
    text-align: center;
}

.div-gift-template-eightteen .div-gift-template .div-logo {
    padding-top: 100px;
    padding-left: 25px;
}

.div-gift-template-eightteen .div-gift-template img {
    min-height: 35px;
    max-height: 35px; 
}

.div-gift-template-eightteen .div-card-header {
    margin-top: 5px;
    padding-left: 25px;
}

.div-gift-template-eightteen .div-card-header span {
    color: #595A5C;
    text-transform: uppercase; 
}
.div-gift-template-eightteen span, .div-gift-template-eightteen label, .div-gift-template-eightteen p, .div-gift-template-eightteen i {
    color: #595A5C;
}
.div-gift-template-eightteen .div-card-list .btn-buy {
    color: #595A5C;
    border: 1px solid #595A5C;
}
.div-gift-template-eightteen .div-card-list .btn-buy:hover, .div-gift-template-eightteen .div-card-list .btn-buy:focus {
    color: #fff;
    border: 1px solid #595A5C;
    background-color: #595A5C;
}
.div-gift-template-eightteen .div-card-list .col-md-4 input {
    border: 1px solid #595A5C;
}
.div-gift-template-eightteen .div-gift-promo input {
    border: 1px solid #595A5C;
    color: #595A5C;
}
.div-gift-template-eightteen .div-limited-child .btn {
    color: #595A5C;
    border: 1px solid #595A5C;
}
.div-gift-template-eightteen .div-buy1-child, .div-gift-template-eightteen .div-buy2-child, .div-gift-template-eightteen .div-footer-description {
    color: #595A5C;
}
.div-gift-template-eightteen .div-limited-child input {
    color: #595A5C;
    border: 1px solid #595A5C;
    background-color: #fff;
}
.div-gift-template-eightteen .div-limited-child input {
    color: #595A5C;
}
.div-gift-template-eightteen .div-limited-child input::placeholder {
    color: #595A5CCC;
}
.div-gift-template-eightteen .div-limited-child .btn:hover, .div-gift-template-eightteen .div-limited-child .btn:focus {
    color: #fff;
    border: 1px solid #595A5C;
    background-color: #595A5C;
}

/* Gift Template 19 */
.div-gift-template-ninteen {
    background-color: #DADCE0;
    border: 1px solid #DADCE0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
    overflow: hidden;
}

.div-gift-template-ninteen .col-5 {
    padding-left: 15px;
    height:275px;
    background-color: #F6CD3E;
    border-bottom-left-radius: 30% 100%;
}


.div-gift-template-ninteen .div-gift-template .col-5 {
    /* background-color: #817F88; */
    height:275px;
}

.div-gift-template-ninteen .div-gift-template .col-5 .div-logo {
    position: absolute;
    top: 15px;
    left: 25px;
}

.div-gift-template-ninteen .div-gift-template .col-5 img {
    max-height: 35px;
}
.div-gift-template-ninteen .div-gift-template .col-5 .div-card-header {
    position: absolute;
    bottom: 25px;
    width: 70%;
    text-align: center;
    left: 25px;
}
.div-gift-template-ninteen .div-gift-template .col-5 .div-card-header span {
    color: #595A5C;
    text-transform: uppercase;
    font-weight: 600;
}
.div-gift-template-ninteen span, .div-gift-template-ninteen label, .div-gift-template-ninteen p, .div-gift-template-ninteen i {
    color: #F6CD3E;
}
.div-gift-template-ninteen .div-card-list .btn-buy {
    color: #F6CD3E;
    border: 1px solid #F6CD3E;
}
.div-gift-template-ninteen .div-card-list .btn-buy:hover, .div-gift-template-ninteen .div-card-list .btn-buy:focus {
    color: #fff;
    border: 1px solid #F6CD3E;
    background-color: #F6CD3E;
}
.div-gift-template-ninteen .div-card-list .col-md-4 input {
    border: 1px solid #F6CD3E;
}
.div-gift-template-ninteen .div-gift-promo input {
    border: 1px solid #F6CD3E;
    color: #F6CD3E;
}
.div-gift-template-ninteen .div-limited-child .btn {
    color: #F6CD3E;
    border: 1px solid #F6CD3E;
}
.div-gift-template-ninteen .div-buy1-child, .div-gift-template-ninteen .div-buy2-child, .div-gift-template-ninteen .div-footer-description {
    color: #F6CD3E;
}
.div-gift-template-ninteen .div-limited-child input {
    color: #F6CD3E;
    border: 1px solid #F6CD3E;
    background-color: #fff;
}
.div-gift-template-ninteen .div-limited-child input {
    color: #F6CD3E;
}
.div-gift-template-ninteen .div-limited-child input::placeholder {
    color: #F6CD3ECC;
}
.div-gift-template-ninteen .div-limited-child .btn:hover, .div-gift-template-ninteen .div-limited-child .btn:focus {
    color: #fff;
    border: 1px solid #F6CD3E;
    background-color: #F6CD3E;
}

/* Gift Template 20 */
.div-gift-template-twenty {
    background-color: #DADCE0;
    border: 1px solid #DADCE0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
    overflow: hidden;
}

.div-gift-template-twenty .div-parent{
    background-color: #6A55A4;
    min-height: 205px;
    border-radius: 15px;
    position: absolute;
    top:35px;
    left:35px;
    right:35px;
    opacity: 0.9;
    text-align: center;
}

.div-gift-template-twenty .div-parent img{
    height:35px;
}
.div-gift-template-twenty .div-parent .div-logo, .div-gift-template-twenty .div-parent .div-card-header {
    margin-top: 50px;
}

.div-gift-template-twenty .div-parent .div-card-header span {
    color: #fff;
    font-weight: 600;
    text-transform: uppercase;
}

.div-gift-template-twenty span, .div-gift-template-twenty label, .div-gift-template-twenty p, .div-gift-template-twenty i {
    color: #6A55A4;
}
.div-gift-template-twenty .div-card-list .btn-buy {
    color: #6A55A4;
    border: 1px solid #6A55A4;
}
.div-gift-template-twenty .div-card-list .btn-buy:hover, .div-gift-template-twenty .div-card-list .btn-buy:focus {
    color: #fff;
    border: 1px solid #6A55A4;
    background-color: #6A55A4;
}
.div-gift-template-twenty .div-card-list .col-md-4 input {
    border: 1px solid #6A55A4;
}
.div-gift-template-twenty .div-gift-promo input {
    border: 1px solid #6A55A4;
    color: #6A55A4;
}
.div-gift-template-twenty .div-limited-child .btn {
    color: #6A55A4;
    border: 1px solid #6A55A4;
}
.div-gift-template-twenty .div-buy1-child, .div-gift-template-twenty .div-buy2-child, .div-gift-template-twenty .div-footer-description {
    color: #6A55A4;
}
.div-gift-template-twenty .div-limited-child input {
    color: #6A55A4;
    border: 1px solid #6A55A4;
    background-color: #fff;
}
.div-gift-template-twenty .div-limited-child input {
    color: #6A55A4;
}
.div-gift-template-twenty .div-limited-child input::placeholder {
    color: #6A55A4CC;
}
.div-gift-template-twenty .div-limited-child .btn:hover, .div-gift-template-twenty .div-limited-child .btn:focus {
    color: #fff;
    border: 1px solid #6A55A4;
    background-color: #6A55A4;
}

/* Gift Template 21 */
.div-gift-template-twentyone {
    background-color: #DADCE0;
    border: 1px solid #DADCE0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
    overflow: hidden;
}
.div-gift-template-twentyone .div-parent img{
    height:35px;
}
.div-gift-template-twentyone .div-parent .div-logo {
    margin-left:20px;
    padding: 7px 15px;
    background-color: #50CB06;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    display: inline-flex;
}

.div-gift-template-twentyone .div-parent .div-card-header {
    position:absolute;
    bottom:0px;
    background-color: #50CB06;    
    border-top-right-radius: 15px;
    padding: 10px 20px;
}

.div-gift-template-twentyone .div-parent .div-card-header span {
    color: #fff;    
}

.div-gift-template-twentyone span, .div-gift-template-twentyone label, .div-gift-template-twentyone p, .div-gift-template-twentyone i {
    color: #50CB06;
}
.div-gift-template-twentyone .div-card-list .btn-buy {
    color: #50CB06;
    border: 1px solid #50CB06;
}
.div-gift-template-twentyone .div-card-list .btn-buy:hover, .div-gift-template-twentyone .div-card-list .btn-buy:focus {
    color: #fff;
    border: 1px solid #50CB06;
    background-color: #50CB06;
}
.div-gift-template-twentyone .div-card-list .col-md-4 input {
    border: 1px solid #50CB06;
}
.div-gift-template-twentyone .div-gift-promo input {
    border: 1px solid #50CB06;
    color: #50CB06;
}
.div-gift-template-twentyone .div-limited-child .btn {
    color: #50CB06;
    border: 1px solid #50CB06;
}
.div-gift-template-twentyone .div-buy1-child, .div-gift-template-twentyone .div-buy2-child, .div-gift-template-twentyone .div-footer-description {
    color: #50CB06;
}
.div-gift-template-twentyone .div-limited-child input {
    color: #50CB06;
    border: 1px solid #50CB06;
    background-color: #fff;
}
.div-gift-template-twentyone .div-limited-child input {
    color: #50CB06;
}
.div-gift-template-twentyone .div-limited-child input::placeholder {
    color: #50CB06CC;
}
.div-gift-template-twentyone .div-limited-child .btn:hover, .div-gift-template-twentyone .div-limited-child .btn:focus {
    color: #fff;
    border: 1px solid #50CB06;
    background-color: #50CB06;
}

/* Gift Template 22 */
.div-gift-template-twentytwo {
    background-color: #DADCE0;
    border: 1px solid #DADCE0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
    overflow: hidden;
}

.div-gift-template-twentytwo .col-5 {
    padding-left: 15px;
    height:275px;
    background-color: #8D4626;
    border-bottom-left-radius: 30% 100%;
}

.div-gift-template-twentytwo .div-gift-template .col-5 {
    /* background-color: #817F88; */
    height:275px;
}

.div-gift-template-twentytwo .div-gift-template .col-5 .div-logo {
    position: absolute;
    top: 15px;
    left: 10px;
}

.div-gift-template-twentytwo .div-gift-template .col-5 img {
    max-height: 35px;
}
.div-gift-template-twentytwo .div-gift-template .col-5 .div-card-header {
    position: absolute;
    bottom: 40px;
    left: 40px;
    text-align: center;
}
.div-gift-template-twentytwo .div-gift-template .col-5 .div-card-header span {
    color: #fff;
    text-transform: uppercase;
    font-weight: 600;
}
.div-gift-template-twentytwo span, .div-gift-template-twentytwo label, .div-gift-template-twentytwo p, .div-gift-template-twentytwo i {
    color: #8D4626;
}
.div-gift-template-twentytwo .div-card-list .btn-buy {
    color: #8D4626;
    border: 1px solid #8D4626;
}
.div-gift-template-twentytwo .div-card-list .btn-buy:hover, .div-gift-template-twentytwo .div-card-list .btn-buy:focus {
    color: #fff;
    border: 1px solid #8D4626;
    background-color: #8D4626;
}
.div-gift-template-twentytwo .div-card-list .col-md-4 input {
    border: 1px solid #8D4626;
}
.div-gift-template-twentytwo .div-gift-promo input {
    border: 1px solid #8D4626;
    color: #8D4626;
}
.div-gift-template-twentytwo .div-limited-child .btn {
    color: #8D4626;
    border: 1px solid #8D4626;
}
.div-gift-template-twentytwo .div-buy1-child, .div-gift-template-twentytwo .div-buy2-child, .div-gift-template-twentytwo .div-footer-description {
    color: #8D4626;
}
.div-gift-template-twentytwo .div-limited-child input {
    color: #8D4626;
    border: 1px solid #8D4626;
    background-color: #fff;
}
.div-gift-template-twentytwo .div-limited-child input {
    color: #8D4626;
}
.div-gift-template-twentytwo .div-limited-child input::placeholder {
    color: #8D4626CC;
}
.div-gift-template-twentytwo .div-limited-child .btn:hover, .div-gift-template-twentytwo .div-limited-child .btn:focus {
    color: #fff;
    border: 1px solid #8D4626;
    background-color: #8D4626;
}

/* Gift Template 23 */
.div-gift-template-twentythree {
    background-color: #DADCE0;
    border: 1px solid #DADCE0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
    overflow: hidden;
}
.div-gift-template-twentythree .div-circle {
    width: 320px;
    height: 320px;
    background-color: #556B22;
    border-radius: 50%;
    position: absolute;
    top: -150px;
    left: -8px;
}
.div-gift-template-twentythree img{
    height:35px;
}
.div-gift-template-twentythree .div-logo {
    position: absolute;
    top: 35px;
    width: 65%;
    text-align: center;
}

.div-gift-template-twentythree .div-card-header {
    position: absolute;
    top: 75px;
    width: 65%;
    text-align: center;    
}

.div-gift-template-twentythree .div-card-header span {
    color: #fff;    
}

.div-gift-template-twentythree span, .div-gift-template-twentythree label, .div-gift-template-twentythree p, .div-gift-template-twentythree i {
    color: #556B22;
}
.div-gift-template-twentythree .div-card-list .btn-buy {
    color: #556B22;
    border: 1px solid #556B22;
}
.div-gift-template-twentythree .div-card-list .btn-buy:hover, .div-gift-template-twentythree .div-card-list .btn-buy:focus {
    color: #fff;
    border: 1px solid #556B22;
    background-color: #556B22;
}
.div-gift-template-twentythree .div-card-list .col-md-4 input {
    border: 1px solid #556B22;
}
.div-gift-template-twentythree .div-gift-promo input {
    border: 1px solid #556B22;
    color: #556B22;
}
.div-gift-template-twentythree .div-limited-child .btn {
    color: #556B22;
    border: 1px solid #556B22;
}
.div-gift-template-twentythree .div-buy1-child, .div-gift-template-twentythree .div-buy2-child, .div-gift-template-twentythree .div-footer-description {
    color: #556B22;
}
.div-gift-template-twentythree .div-limited-child input {
    color: #556B22;
    border: 1px solid #556B22;
    background-color: #fff;
}
.div-gift-template-twentythree .div-limited-child input {
    color: #556B22;
}
.div-gift-template-twentythree .div-limited-child input::placeholder {
    color: #556B22CC;
}
.div-gift-template-twentythree .div-limited-child .btn:hover, .div-gift-template-twentythree .div-limited-child .btn:focus {
    color: #fff;
    border: 1px solid #556B22;
    background-color: #556B22;
}

/* Gift Template 24 */
.div-gift-template-twentyfour {
    background-color: #DADCE0;
    border: 1px solid #DADCE0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
    overflow: hidden;
}

.div-gift-template-twentyfour .col-6 .div-left {
    padding-left: 30px;
    width: 100%;
    height: 0;    
    border-top: 275px solid #A15726;
    border-left: 25px solid transparent;
}

.div-gift-template-twentyfour .div-gift-template .col-6 .div-logo {
    position: absolute;
    top: 40%;
    left: 40px;
}

.div-gift-template-twentyfour .div-gift-template .col-6 img {
    max-height: 35px;
}
.div-gift-template-twentyfour .div-gift-template .col-6 .div-card-header {
    position: absolute;
    bottom: 90px;
    width: 70%;
    text-align: center;
}
.div-gift-template-twentyfour .div-gift-template .col-6 .div-card-header span {
    color: #fff;
    text-transform: uppercase;
}
.div-gift-template-twentyfour span, .div-gift-template-twentyfour label, .div-gift-template-twentyfour p, .div-gift-template-twentyfour i {
    color: #A15726;
}
.div-gift-template-twentyfour .div-card-list .btn-buy {
    color: #A15726;
    border: 1px solid #A15726;
}
.div-gift-template-twentyfour .div-card-list .btn-buy:hover, .div-gift-template-twentyfour .div-card-list .btn-buy:focus {
    color: #fff;
    border: 1px solid #A15726;
    background-color: #A15726;
}
.div-gift-template-twentyfour .div-card-list .col-md-4 input {
    border: 1px solid #A15726;
}
.div-gift-template-twentyfour .div-gift-promo input {
    border: 1px solid #A15726;
    color: #A15726;
}
.div-gift-template-twentyfour .div-limited-child .btn {
    color: #A15726;
    border: 1px solid #A15726;
}
.div-gift-template-twentyfour .div-buy1-child, .div-gift-template-twentyfour .div-buy2-child, .div-gift-template-twentyfour .div-footer-description {
    color: #A15726;
}
.div-gift-template-twentyfour .div-limited-child input {
    color: #A15726;
    border: 1px solid #A15726;
    background-color: #fff;
}
.div-gift-template-twentyfour .div-limited-child input {
    color: #A15726;
}
.div-gift-template-twentyfour .div-limited-child input::placeholder {
    color: #A15726CC;
}
.div-gift-template-twentyfour .div-limited-child .btn:hover, .div-gift-template-twentyfour .div-limited-child .btn:focus {
    color: #fff;
    border: 1px solid #A15726;
    background-color: #A15726;
}

/* Gift Template 25 */
.div-gift-template-twentyfive {
    background-color: #DADCE0;
    border: 1px solid #DADCE0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
    overflow: hidden;
}

.div-gift-template-twentyfive .div-gift-template .div-template-footer {
    background-color: #272727;
    padding: 15px;
    width: 100%;
    position: absolute;
    bottom: 0px;    
    opacity: 0.85;
}
.div-gift-template-twentyfive .div-gift-template .div-template-footer span {
    letter-spacing: 1.2px;
    color: #fff;
    font-weight: 300;
    text-transform: uppercase;
}
.div-gift-template-twentyfive .div-gift-template .div-template-footer img {
    max-height: 35px;
}
.div-gift-template-twentyfive span, .div-gift-template-twentyfive label, .div-gift-template-twentyfive p, .div-gift-template-twentyfive i {
    color: #272727;
}
.div-gift-template-twentyfive .div-card-list .btn-buy {
    color: #272727;
    border: 1px solid #272727;
}
.div-gift-template-twentyfive .div-card-list .btn-buy:hover, .div-gift-template-twentyfive .div-card-list .btn-buy:focus {
    color: #fff;
    border: 1px solid #272727;
    background-color: #272727;
}
.div-gift-template-twentyfive .div-card-list .col-md-4 input {
    border: 1px solid #272727;
}
.div-gift-template-twentyfive .div-gift-promo input {
    border: 1px solid #272727;
    color: #272727;
}
.div-gift-template-twentyfive .div-limited-child .btn {
    color: #272727;
    border: 1px solid #272727;
}
.div-gift-template-twentyfive .div-buy1-child, .div-gift-template-twentyfive .div-buy2-child, .div-gift-template-twentyfive .div-footer-description {
    color: #272727;
}
.div-gift-template-twentyfive .div-limited-child input {
    color: #272727;
    border: 1px solid #272727;
    background-color: #fff;
}
.div-gift-template-twentyfive .div-limited-child input {
    color: #272727;
}
.div-gift-template-twentyfive .div-limited-child input::placeholder {
    color: #272727CC;
}
.div-gift-template-twentyfive .div-limited-child .btn:hover, .div-gift-template-twentyfive .div-limited-child .btn:focus {
    color: #fff;
    border: 1px solid #272727;
    background-color: #272727;
}

/* Gift Template 26 */
.div-gift-template-twentysix {
    background-color: #DADCE0;
    border: 1px solid #DADCE0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
    overflow: hidden;
}

/*Right pointing*/
.div-gift-template-twentysix .col-5 div.triangle-right {
    width: 100%;
	height: 0;
	border-top: 100px solid #011C46;
	border-right: 50px solid transparent;
	border-bottom: 100px solid #011C46;
}
.div-gift-template-twentysix .div-gift-template .col-5 .div-logo {
    position: absolute;
    top: 15px;
    left: 30px;
}

.div-gift-template-twentysix .div-gift-template .col-5 img {
    max-height: 22px;
}
.div-gift-template-twentysix .div-gift-template .col-5 .div-card-header {
    position: absolute;
    bottom: 15px;
    left: 30px;
}
.div-gift-template-twentysix .div-gift-template .col-5 .div-card-header span {
    color: #fff;
}
.div-gift-template-twentysix span, .div-gift-template-twentysix label, .div-gift-template-twentysix p, .div-gift-template-twentysix i {
    color: #011C46;
}
.div-gift-template-twentysix .div-card-list .btn-buy {
    color: #011C46;
    border: 1px solid #011C46;
}
.div-gift-template-twentysix .div-card-list .btn-buy:hover, .div-gift-template-twentysix .div-card-list .btn-buy:focus {
    color: #fff;
    border: 1px solid #011C46;
    background-color: #011C46;
}
.div-gift-template-twentysix .div-card-list .col-md-4 input {
    border: 1px solid #011C46;
}
.div-gift-template-twentysix .div-gift-promo input {
    border: 1px solid #011C46;
    color: #011C46;
}
.div-gift-template-twentysix .div-limited-child .btn {
    color: #011C46;
    border: 1px solid #011C46;
}
.div-gift-template-twentysix .div-buy1-child, .div-gift-template-twentysix .div-buy2-child, .div-gift-template-twentysix .div-footer-description {
    color: #011C46;
}
.div-gift-template-twentysix .div-limited-child input {
    color: #011C46;
    border: 1px solid #011C46;
    background-color: #fff;
}
.div-gift-template-twentysix .div-limited-child input {
    color: #011C46;
}
.div-gift-template-twentysix .div-limited-child input::placeholder {
    color: #011C46CC;
}
.div-gift-template-twentysix .div-limited-child .btn:hover, .div-gift-template-twentysix .div-limited-child .btn:focus {
    color: #fff;
    border: 1px solid #011C46;
    background-color: #011C46;
}

/* Gift Template 27 */
.div-gift-template-twentyseven {
    background-color: #DADCE0;
    border: 1px solid #DADCE0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
    overflow: hidden;
}

/*Right pointing*/
.div-gift-template-twentyseven .col-7 .triangle-right {
	width: 0;
	height: 0;
	border-top: 7px solid transparent;
	border-right: 8px solid #003260;
    border-bottom: 7px solid transparent;
    top: 133px;
    position:absolute;
    right:0px;
}
.div-gift-template-twentyseven .div-gift-template .col-5 {
    background-color: #003260;
    height:275px;
}

.div-gift-template-twentyseven .div-gift-template .col-5 .div-logo {
    position: absolute;
    top: 15px;
    left: 30px;
}

.div-gift-template-twentyseven .div-gift-template .col-5 img {
    max-height: 35px;
}
.div-gift-template-twentyseven .div-gift-template .col-5 .div-card-header {
    position: absolute;
    bottom: 15px;
    width: 90%;
    text-align: center;
}
.div-gift-template-twentyseven .div-gift-template .col-5 .div-card-header span {
    color: #fff;
}
.div-gift-template-twentyseven span, .div-gift-template-twentyseven label, .div-gift-template-twentyseven p, .div-gift-template-twentyseven i {
    color: #003260;
}
.div-gift-template-twentyseven .div-card-list .btn-buy {
    color: #003260;
    border: 1px solid #003260;
}
.div-gift-template-twentyseven .div-card-list .btn-buy:hover, .div-gift-template-twentyseven .div-card-list .btn-buy:focus {
    color: #fff;
    border: 1px solid #003260;
    background-color: #003260;
}
.div-gift-template-twentyseven .div-card-list .col-md-4 input {
    border: 1px solid #003260;
}
.div-gift-template-twentyseven .div-gift-promo input {
    border: 1px solid #003260;
    color: #003260;
}
.div-gift-template-twentyseven .div-limited-child .btn {
    color: #003260;
    border: 1px solid #003260;
}
.div-gift-template-twentyseven .div-buy1-child, .div-gift-template-twentyseven .div-buy2-child, .div-gift-template-twentyseven .div-footer-description {
    color: #003260;
}
.div-gift-template-twentyseven .div-limited-child input {
    color: #003260;
    border: 1px solid #003260;
    background-color: #fff;
}
.div-gift-template-twentyseven .div-limited-child input {
    color: #003260;
}
.div-gift-template-twentyseven .div-limited-child input::placeholder {
    color: #003260CC;
}
.div-gift-template-twentyseven .div-limited-child .btn:hover, .div-gift-template-twentyseven .div-limited-child .btn:focus {
    color: #fff;
    border: 1px solid #003260;
    background-color: #003260;
}

/* Gift Template 28 */
.div-gift-template-twentyeight {
    background-color: #DADCE0;
    border: 1px solid #DADCE0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
    overflow: hidden;
}
.div-giftcard {
    position: absolute;
    bottom: 15px;
    width: 70%;
    text-align: center;
    color: #E0BE55;
}
.div-gift-template-twentyeight .col-4 {
    background-color: #181C14;
    min-height: 275px;
    padding-top: 20px;
    padding-left: 30px;
}
.div-gift-template-twentyeight .col-4 div.div-re-points {
    width:100%;   
    padding-top: 30px;
}
.div-gift-template-twentyeight span.item-points {
    font-size: 9px;
    font-weight: 700;
}
.div-gift-template-twentyeight span.item-points strong {
    font-size: 16px;
    font-weight: 700;
}
/*Right pointing*/
.div-gift-template-twentyeight .col-1 div.triangle-left {
    width: 100%;    
	height: 0;
	border-top: 50px solid transparent;
	border-left: 30px solid #181C14;
	border-bottom: 150px solid transparent;
}
.div-gift-template-twentyeight .div-gift-template .col-4 .div-card-header span {
    color:#E0BE55;
    position: absolute;
    bottom: 25px;
    width: 70%;
   text-align: center;
}
.div-gift-template-twentyeight span, .div-gift-template-twentyeight label, .div-gift-template-twentyeight p, .div-gift-template-twentyeight i {
    color: #181C14;
}
.div-gift-template-twentyeight .div-card-list .btn-buy {
    color: #181C14;
    border: 1px solid #181C14;
}
.div-gift-template-twentyeight .div-card-list .btn-buy:hover, .div-gift-template-twentyeight .div-card-list .btn-buy:focus {
    color: #fff;
    border: 1px solid #181C14;
    background-color: #181C14;
}
.div-gift-template-twentyeight .div-card-list .col-md-4 input {
    border: 1px solid #003260;
}
.div-gift-template-twentyeight .div-gift-promo input {
    border: 1px solid #181C14;
    color: #181C14;
}
.div-gift-template-twentyeight .div-limited-child .btn {
    color: #181C14;
    border: 1px solid #181C14;
}
.div-gift-template-twentyeight .div-buy1-child, .div-gift-template-twentyeight .div-buy2-child, .div-gift-template-twentyeight .div-footer-description {
    color: #181C14;
}
.div-gift-template-twentyeight .div-limited-child input {
    color: #181C14;
    border: 1px solid #181C14;
    background-color: #fff;
}
.div-gift-template-twentyeight .div-limited-child input {
    color: #181C14;
}
.div-gift-template-twentyeight .div-limited-child input::placeholder {
    color:#181C14CC;
}
.div-gift-template-twentyeight .div-limited-child .btn:hover, .div-gift-template-twentyeight .div-limited-child .btn:focus {
    color: #fff;
    border: 1px solid #181C14;
    background-color: #181C14;
}

/* Gift Template 29 */

.div-gift-template-twentynine {
    background-color: #DADCE0;
    border: 1px solid #DADCE0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
    overflow: hidden;
}
.div-gift-template-twentynine .col-7.div-twentynine-content {    
    min-height: 275px;    
    padding-left: 30px;
}
.div-gift-template-twentynine .col-8 {
    min-height: 275px;
    background-color: #0F303E;
    z-index: 1;
    padding-top: 20px;
}
.div-gift-template-twentynine .col-8 div.div-re-points {
    width:100%;   
    padding-top: 60px;
}
.div-gift-template-twentynine span.item-points {
    font-size: 9px;    
}
.div-gift-template-twentynine span.item-points strong {
    font-size: 12px;
    font-weight: 700;
}
.div-gift-template-twentynine .col-7 {
    position: relative;
    overflow: hidden;
    min-height: 275px;
}
.div-gift-template-twentynine .col-4 div.semicircle-left {    
    position: absolute;
    top: 0; 
    bottom: 0;
    box-shadow: 0px 0px 0px 110px #0F303E;
    width: 100%
}
.div-gift-template-twentynine .col-4 div.semicircle-left-curve {   
    border-radius: 100% 0 0 25% / 50% 0  0 50%;
}
.div-gift-template-twentynine .div-gift-template .col-8 .div-card-header span {
    color:#fff;
    position: absolute;
    bottom: 25px;
    width: 70%;
    text-align: center;
    text-transform: uppercase;
}
.div-gift-template-twentynine span, .div-gift-template-twentynine label, .div-gift-template-twentynine p, .div-gift-template-twentynine i {
    color: #0F303E;
}
.div-gift-template-twentynine .div-card-list .btn-buy {
    color: #0F303E;
    border: 1px solid #0F303E;
}
.div-gift-template-twentynine .div-card-list .btn-buy:hover, .div-gift-template-twentynine .div-card-list .btn-buy:focus {
    color: #fff;
    border: 1px solid #0F303E;
    background-color: #0F303E;
}
.div-gift-template-twentynine .div-card-list .col-md-4 input {
    border: 1px solid #003260;
}
.div-gift-template-twentynine .div-gift-promo input {
    border: 1px solid #0F303E;
    color: #0F303E;
}
.div-gift-template-twentynine .div-limited-child .btn {
    color: #0F303E;
    border: 1px solid #0F303E;
}
.div-gift-template-twentynine .div-buy1-child, .div-gift-template-twentynine .div-buy2-child, .div-gift-template-twentynine .div-footer-description {
    color: #0F303E;
}
.div-gift-template-twentynine .div-limited-child input {
    color: #0F303E;
    border: 1px solid #0F303E;
    background-color: #fff;
}
.div-gift-template-twentynine .div-limited-child input {
    color: #0F303E;
}
.div-gift-template-twentynine .div-limited-child input::placeholder {
    color:#0F303ECC;
}
.div-gift-template-twentynine .div-limited-child .btn:hover, .div-gift-template-twentynine .div-limited-child .btn:focus {
    color: #fff;
    border: 1px solid #0F303E;
    background-color: #0F303E;
}

/* Gift Template 30 */

.div-gift-template-thirty {
    background-color: #DADCE0;
    border: 1px solid #DADCE0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
    overflow: hidden;
}

.div-gift-template-thirty .col-6 .div-left {
    padding-left: 30px;
    width: 100%;
    height: 0;    
    border-top: 275px solid #BB261D;
    border-right: 25px solid transparent;
}

.div-gift-template-thirty .div-gift-template .col-6 .div-logo {
    position: absolute;
    top: 120px;
    left: 30px;
}

.div-gift-template-thirty .div-gift-template .col-6 img {
    max-height: 35px;
    margin-left:30px;
}
.div-gift-template-thirty .div-gift-template .col-6 .div-card-header {
    position: absolute;
    bottom: 15px;
    width: 70%;
    text-align: center;
}
.div-gift-template-thirty .div-gift-template .col-6 .div-card-header span {
    color: #fff;
    text-transform: uppercase;
}
.div-gift-template-thirty span, .div-gift-template-thirty label, .div-gift-template-thirty p, .div-gift-template-thirty i {
    color: #BB261D;
}
.div-gift-template-thirty .div-card-list .btn-buy {
    color: #BB261D;
    border: 1px solid #BB261D;
}
.div-gift-template-thirty .div-card-list .btn-buy:hover, .div-gift-template-thirty .div-card-list .btn-buy:focus {
    color: #fff;
    border: 1px solid #BB261D;
    background-color: #BB261D;
}
.div-gift-template-thirty .div-card-list .col-md-4 input {
    border: 1px solid #BB261D;
}
.div-gift-template-thirty .div-gift-promo input {
    border: 1px solid #BB261D;
    color: #BB261D;
}
.div-gift-template-thirty .div-limited-child .btn {
    color: #BB261D;
    border: 1px solid #BB261D;
}
.div-gift-template-thirty .div-buy1-child, .div-gift-template-thirty .div-buy2-child, .div-gift-template-thirty .div-footer-description {
    color: #BB261D;
}
.div-gift-template-thirty .div-limited-child input {
    color: #BB261D;
    border: 1px solid #BB261D;
    background-color: #fff;
}
.div-gift-template-thirty .div-limited-child input {
    color: #BB261D;
}
.div-gift-template-thirty .div-limited-child input::placeholder {
    color: #BB261DCC;
}
.div-gift-template-thirty .div-limited-child .btn:hover, .div-gift-template-thirty .div-limited-child .btn:focus {
    color: #fff;
    border: 1px solid #BB261D;
    background-color: #BB261D;
}

/* Gift Template 31 */

.div-gift-template-thirtyone {
    background-color: #DADCE0;
    border: 1px solid #DADCE0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
    overflow: hidden;
}

.div-gift-template-thirtyone .div-gift-template .div-template-footer {
    background-color: #233C71;
    padding: 15px;
    width: 100%;
    position: absolute;
    bottom: 0px;
    text-align: center;
}
.div-gift-template-thirtyone .div-gift-template .div-template-footer span {
    letter-spacing: 1.2px;
    color: #fff;
    font-weight: 300;      
}
.div-gift-template-thirtyone .div-gift-template .div-template-footer img {
    max-height: 35px;
}
.div-gift-template-thirtyone span, .div-gift-template-thirtyone label, .div-gift-template-thirtyone p, .div-gift-template-thirtyone i {
    color: #233C71;
}
.div-gift-template-thirtyone .div-card-list .btn-buy {
    color: #233C71;
    border: 1px solid #233C71;
}
.div-gift-template-thirtyone .div-card-list .btn-buy:hover, .div-gift-template-thirtyone .div-card-list .btn-buy:focus {
    color: #fff;
    border: 1px solid #233C71;
    background-color: #233C71;
}
.div-gift-template-thirtyone .div-card-list .col-md-4 input {
    border: 1px solid #233C71;
}
.div-gift-template-thirtyone .div-gift-promo input {
    border: 1px solid #233C71;
    color: #233C71;
}
.div-gift-template-thirtyone .div-limited-child .btn {
    color: #233C71;
    border: 1px solid #233C71;
}
.div-gift-template-thirtyone .div-buy1-child, .div-gift-template-thirtyone .div-buy2-child, .div-gift-template-thirtyone .div-footer-description {
    color: #233C71;
}
.div-gift-template-thirtyone .div-limited-child input {
    color: #233C71;
    border: 1px solid #233C71;
    background-color: #fff;
}
.div-gift-template-thirtyone .div-limited-child input {
    color: #233C71;
}
.div-gift-template-thirtyone .div-limited-child input::placeholder {
    color:#233C71CC;
}
.div-gift-template-thirtyone .div-limited-child .btn:hover, .div-gift-template-thirtyone .div-limited-child .btn:focus {
    color: #fff;
    border: 1px solid #233C71;
    background-color: #233C71;
}

/* Gift Template 32 */

.div-gift-template-thirtytwo {
    background-color: #DADCE0;
    border: 1px solid #DADCE0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
    overflow: hidden;
}

.div-gift-template-thirtytwo .div-gift-template .div-template-header {
    background-color: #D94186;
    padding: 10px 15px;
}
.div-gift-template-thirtytwo .div-gift-template .div-template-header img {    
    max-height: 32px;
}

.div-gift-template-thirtytwo .div-gift-template .div-template-footer {
    background-color: #D94186;
    padding: 15px;
    width: 100%;
    position: absolute;
    bottom: 0px;
    text-align: right;
}
.div-gift-template-thirtytwo .div-gift-template .div-template-footer span {
    letter-spacing: 1.2px;
    color: #E7DCD4;
    font-weight: 300;      
}
.div-gift-template-thirtytwo span, .div-gift-template-thirtytwo label, .div-gift-template-thirtytwo p, .div-gift-template-thirtytwo i {
    color: #D94186;
}
.div-gift-template-thirtytwo .div-card-list .btn-buy {
    color: #D94186;
    border: 1px solid #D94186;
}
.div-gift-template-thirtytwo .div-card-list .btn-buy:hover, .div-gift-template-thirtytwo .div-card-list .btn-buy:focus {
    color: #fff;
    border: 1px solid #D94186;
    background-color: #D94186;
}
.div-gift-template-thirtytwo .div-card-list .col-md-4 input {
    border: 1px solid #D94186;
}
.div-gift-template-thirtytwo .div-gift-promo input {
    border: 1px solid #D94186;
    color: #D94186;
}
.div-gift-template-thirtytwo .div-limited-child input{
    color: #D94186;
}
.div-gift-template-thirtytwo .div-limited-child input::placeholder {
    color: #D94186CC;
}
.div-gift-template-thirtytwo .div-limited-child .btn {
    color: #D94186;
    border: 1px solid #D94186;
}
.div-gift-template-thirtytwo .div-buy1-child, .div-gift-template-thirtytwo .div-buy2-child, .div-gift-template-thirtytwo .div-footer-description {
    color: #D94186;
}
.div-gift-template-thirtytwo .div-limited-child input {
    color: #D94186;
    border: 1px solid #D94186;
    background-color: #fff;
}
.div-gift-template-thirtytwo .div-limited-child .btn:hover, .div-gift-template-thirtytwo .div-limited-child .btn:focus {
    color: #fff;
    border: 1px solid #D94186;
    background-color: #D94186;
}

/*Gift Template 33 */

.div-gift-template-thirtythree {
    background-color: #DADCE0;
    border: 1px solid #DADCE0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
    overflow: hidden;
}

.div-gift-template-thirtythree .div-template-child {
    background-color: #42D7D999;
    margin: 0px;
    border-radius: 15px;
    min-height: 200px;
    text-align: center;
}

.div-gift-template-thirtythree .col-md-8 {
    background-color: #0201D4;
    opacity: 0.9;
    min-height: 275px;
    padding-top: 15px;
}
.div-gift-template-thirtythree .div-gift-template .div-logo {
    padding-top: 15px;
}

.div-gift-template-thirtythree .div-gift-template img {
    max-height: 35px;
    width:80%;
}

.div-gift-template-thirtythree .div-card-header {
    margin-top: 160px;
}

.div-gift-template-thirtythree .div-card-header span {
    color: #fff;
    text-transform: uppercase; 
}
.div-gift-template-thirtythree span, .div-gift-template-thirtythree label, .div-gift-template-thirtythree p, .div-gift-template-thirtythree i {
    color: #0201D4;
}
.div-gift-template-thirtythree .div-card-list .btn-buy {
    color: #0201D4;
    border: 1px solid #0201D4;
}
.div-gift-template-thirtythree .div-card-list .btn-buy:hover, .div-gift-template-thirtythree .div-card-list .btn-buy:focus {
    color: #fff;
    border: 1px solid #0201D4;
    background-color: #0201D4;
}
.div-gift-template-thirtythree .div-card-list .col-md-4 input {
    border: 1px solid #0201D4;
}
.div-gift-template-thirtythree .div-gift-promo input {
    border: 1px solid #0201D4;
    color: #0201D4;
}
.div-gift-template-thirtythree .div-limited-child .btn {
    color: #0201D4;
    border: 1px solid #0201D4;
}
.div-gift-template-thirtythree .div-buy1-child, .div-gift-template-thirtythree .div-buy2-child, .div-gift-template-thirtythree .div-footer-description {
    color: #0201D4;
}
.div-gift-template-thirtythree .div-limited-child input {
    color: #0201D4;
    border: 1px solid #0201D4;
    background-color: #fff;
}
.div-gift-template-thirtythree .div-limited-child input {
    color: #0201D4;
}
.div-gift-template-thirtythree .div-limited-child input::placeholder {
    color: #0201D4CC;
}
.div-gift-template-thirtythree .div-limited-child .btn:hover, .div-gift-template- .div-limited-child .btn:focus {
    color: #fff;
    border: 1px solid #0201D4;
    background-color: #0201D4;
}

/* Gift Template 34 */

.div-gift-template-thirtyfour {
    background-color: #DADCE0;
    border: 1px solid #DADCE0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
    overflow: hidden;
}
.div-gift-template-thirtyfour .col-5 .div-left {
    padding-left: 30px;
    width: 100%;
    height: 0;    
    border-top: 275px solid #653525;
    border-right: 45px solid transparent;
}

.div-gift-template-thirtyfour .div-gift-template .col-5 .div-logo {
    position: absolute;
    top: 25px;
    left: 30px;
}

.div-gift-template-thirtyfour .div-gift-template .col-5 img {
    max-height: 22px;
}
.div-gift-template-thirtyfour .div-gift-template .col-5 .div-card-header {
    position: absolute;
    bottom: 20px;
    width: 60%;
    text-align: center;
}
.div-gift-template-thirtyfour .div-gift-template .col-5 .div-card-header span {
    color: #fff;
    text-transform: uppercase;
}
.div-gift-template-thirtyfour span, .div-gift-template-thirtyfour label, .div-gift-template-thirtyfour p, .div-gift-template-thirtyfour i {
    color: #653525;
}
.div-gift-template-thirtyfour .div-card-list .btn-buy {
    color: #653525;
    border: 1px solid #653525;
}
.div-gift-template-thirtyfour .div-card-list .btn-buy:hover, .div-gift-template-thirtyfour .div-card-list .btn-buy:focus {
    color: #fff;
    border: 1px solid #653525;
    background-color: #653525;
}
.div-gift-template-thirtyfour .div-card-list .col-md-4 input {
    border: 1px solid #653525;
}
.div-gift-template-thirtyfour .div-gift-promo input {
    border: 1px solid #653525;
    color: #653525;
}
.div-gift-template-thirtyfour .div-limited-child .btn {
    color: #653525;
    border: 1px solid #653525;
}
.div-gift-template-thirtyfour .div-buy1-child, .div-gift-template-thirtyfour .div-buy2-child, .div-gift-template-thirtyfour .div-footer-description {
    color: #653525;
}
.div-gift-template-thirtyfour .div-limited-child input {
    color: #653525;
    border: 1px solid #653525;
    background-color: #fff;
}
.div-gift-template-thirtyfour .div-limited-child input {
    color: #653525;
}
.div-gift-template-thirtyfour .div-limited-child input::placeholder {
    color: #653525CC;
}
.div-gift-template-thirtyfour .div-limited-child .btn:hover, .div-gift-template-thirtyfour .div-limited-child .btn:focus {
    color: #fff;
    border: 1px solid #653525;
    background-color: #653525;
}

/* Gift Template 35 */

.div-gift-template-thirtyfive {
    background-color: #DADCE0;
    border: 1px solid #DADCE0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
    overflow: hidden;
}
.div-gift-template-thirtyfive .div-gift-template .div-template-footer {
    background-color: #151515DB;
    padding: 12px;
    width: 30%;
    position: absolute;
    bottom: 0px;
    text-align: center;
    border-radius: 0px 15px 0px 0px;
}
.div-gift-template-thirtyfive .div-gift-template .div-template-footer span {
    letter-spacing: 1.2px;
    color: #fff;
    font-weight: 300;      
}
.div-gift-template-thirtyfive .div-gift-template .div-template-footer img {
    max-height: 22px;
}
.div-gift-template-thirtyfive span, .div-gift-template-thirtyfive label, .div-gift-template-thirtyfive p, .div-gift-template-thirtyfive i {
    color: #151515DB;
}
.div-gift-template-thirtyfive .div-card-list .btn-buy {
    color: #151515DB;
    border: 1px solid #151515DB;
}
.div-gift-template-thirtyfive .div-card-list .btn-buy:hover, .div-gift-template-thirtyfive .div-card-list .btn-buy:focus {
    color: #fff;
    border: 1px solid #151515DB;
    background-color: #151515DB;
}
.div-gift-template-thirtyfive .div-card-list .col-md-4 input {
    border: 1px solid #151515DB;
}
.div-gift-template-thirtyfive .div-gift-promo input {
    border: 1px solid #151515DB;
    color: #151515DB;
}
.div-gift-template-thirtyfive .div-limited-child .btn {
    color: #151515DB;
    border: 1px solid #151515DB;
}
.div-gift-template-thirtyfive .div-buy1-child, .div-gift-template-thirtyfive .div-buy2-child, .div-gift-template-thirtyfive .div-footer-description {
    color: #151515DB;
}
.div-gift-template-thirtyfive .div-limited-child input {
    color: #151515DB;
    border: 1px solid #151515DB;
    background-color: #fff;
}
.div-gift-template-thirtyfive .div-limited-child input {
    color: #151515DB;
}
.div-gift-template-thirtyfive .div-limited-child input::placeholder {
    color:#151515DB;
}
.div-gift-template-thirtyfive .div-limited-child .btn:hover, .div-gift-template-thirtyfive .div-limited-child .btn:focus {
    color: #fff;
    border: 1px solid #151515DB;
    background-color: #151515DB;
}

/* Gift Template 36 */

.div-gift-template-thirtysix {
    background-color: #DADCE0;
    border: 1px solid #DADCE0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
    overflow: hidden;
}
.div-gift-template-thirtysix .col-6 {
    height:275px;
}
.div-gift-template-thirtysix .col-6 div.div-logo {
    width: 100%;
    position: absolute;
    left: 40px;
    top: 40px;
}
.div-gift-template-thirtysix .col-6 div.div-re-points {
    width:100%;   
    position: absolute;    
    left: 40px;
    bottom: 60px;
}
.div-gift-template-thirtysix .col-6 div.div-re-points span {
    color: #fff;
}
/*Right pointing*/
.div-gift-template-thirtysix .col-6 div.triangle-left {
    width: 100%;
    height: 275px;
    border-top: 10px solid transparent;
    border-left: 150px solid #432112;
    border-bottom: 80px solid transparent;
    position: absolute;
    top: 15px;
}

.div-gift-template-thirtysix .div-gift-template .div-template-footer span {
    letter-spacing: 1.2px;
    color: #fff;
    font-weight: 300;      
}
.div-gift-template-thirtysix .div-gift-template .div-template-footer img {
    max-height: 22px;
}
.div-gift-template-thirtysix span, .div-gift-template-thirtysix label, .div-gift-template-thirtysix p, .div-gift-template-thirtysix i {
    color: #432112;
}
.div-gift-template-thirtysix .div-card-list .btn-buy {
    color: #432112;
    border: 1px solid #432112;
}
.div-gift-template-thirtysix .div-card-list .btn-buy:hover, .div-gift-template-thirtysix .div-card-list .btn-buy:focus {
    color: #fff;
    border: 1px solid #432112;
    background-color: #432112;
}
.div-gift-template-thirtysix .div-card-list .col-md-4 input {
    border: 1px solid #432112;
}
.div-gift-template-thirtysix .div-gift-promo input {
    border: 1px solid #432112;
    color: #432112;
}
.div-gift-template-thirtysix .div-limited-child .btn {
    color: #432112;
    border: 1px solid #432112;
}
.div-gift-template-thirtysix .div-buy1-child, .div-gift-template-thirtysix .div-buy2-child, .div-gift-template-thirtysix .div-footer-description {
    color: #432112;
}
.div-gift-template-thirtysix .div-limited-child input {
    color: #432112;
    border: 1px solid #432112;
    background-color: #fff;
}
.div-gift-template-thirtysix .div-limited-child input {
    color: #432112;
}
.div-gift-template-thirtysix .div-limited-child input::placeholder {
    color:#432112;
}
.div-gift-template-thirtysix .div-limited-child .btn:hover, .div-gift-template-thirtysix .div-limited-child .btn:focus {
    color: #fff;
    border: 1px solid #432112;
    background-color: #432112;
}

/* Gift Template 37 */
.div-gift-template-thirtyseven {
    background-color: #DADCE0;
    border: 1px solid #DADCE0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
    overflow: hidden;
}

/*Right pointing*/
.div-gift-template-thirtyseven .col-7 .triangle-right {
	width: 0;
	height: 0;
	border-top: 7px solid transparent;
	border-left: 8px solid #281207;
    border-bottom: 7px solid transparent;
    margin-top: 93px;
}
.div-gift-template-thirtyseven .div-gift-template .col-5 {
    background-color: #281207;
    height:275px;
}

.div-gift-template-thirtyseven .div-gift-template .col-5 .div-logo {
    position: absolute;
    top: 105px;
    left: 40px;
}

.div-gift-template-thirtyseven .div-gift-template .col-5 img {
    max-height: 22px;
}
.div-gift-template-thirtyseven .div-gift-template .col-5 .div-card-header {
    position: absolute;
    bottom: 105px;
    left : 40px;
    text-align: center;
}
.div-gift-template-thirtyseven .div-gift-template .col-5 .div-card-header span {
    color: #fff;
}
.div-gift-template-thirtyseven span, .div-gift-template-thirtyseven label, .div-gift-template-thirtyseven p, .div-gift-template-thirtyseven i {
    color: #281207;
}
.div-gift-template-thirtyseven .div-card-list .btn-buy {
    color: #281207;
    border: 1px solid #281207;
}
.div-gift-template-thirtyseven .div-card-list .btn-buy:hover, .div-gift-template-thirtyseven .div-card-list .btn-buy:focus {
    color: #fff;
    border: 1px solid #281207;
    background-color: #281207;
}
.div-gift-template-thirtyseven .div-card-list .col-md-4 input {
    border: 1px solid #281207;
}
.div-gift-template-thirtyseven .div-gift-promo input {
    border: 1px solid #281207;
    color: #281207;
}
.div-gift-template-thirtyseven .div-limited-child .btn {
    color: #281207;
    border: 1px solid #281207;
}
.div-gift-template-thirtyseven .div-buy1-child, .div-gift-template-thirtyseven .div-buy2-child, .div-gift-template-thirtyseven .div-footer-description {
    color: #281207;
}
.div-gift-template-thirtyseven .div-limited-child input {
    color: #281207;
    border: 1px solid #281207;
    background-color: #fff;
}
.div-gift-template-thirtyseven .div-limited-child input {
    color: #281207;
}
.div-gift-template-thirtyseven .div-limited-child input::placeholder {
    color: #281207CC;
}
.div-gift-template-thirtyseven .div-limited-child .btn:hover, .div-gift-template-thirtyseven .div-limited-child .btn:focus {
    color: #fff;
    border: 1px solid #281207;
    background-color: #281207;
}

/* Gift Template 38 */

.div-gift-template-thirtyeight {
    background-color: #DADCE0;
    border: 1px solid #DADCE0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
    overflow: hidden;
}

.div-gift-template-thirtyeight .col-7, .div-gift-template-thirtyeight .col-5 {
    /* height: 200px; */
    background-color: #292242;
    position: absolute;
    bottom: 0px;
    width:100%;
}
.div-gift-template-thirtyeight .col-7 {
    right: 0px;
}
.div-gift-template-thirtyeight .col-5 {
    left: 0px;
    padding:10px 15px;    
}

.div-gift-template-thirtyeight .col-7 div {    
    padding: 15px 15px 15px 30px;
    text-align: right;
}

.div-gift-template-thirtyeight .div-gift-template .div-template-footer span {
    letter-spacing: 1.2px;
    color: #F3A84A;
    font-weight: 600;
    text-transform: uppercase;
}
.div-gift-template-thirtyeight .div-gift-template .div-template-footer img {
    max-height: 35px;
}
.div-gift-template-thirtyeight span, .div-gift-template-thirtyeight label, .div-gift-template-thirtyeight p, .div-gift-template-thirtyeight i {
    color: #292242;
}
.div-gift-template-thirtyeight .div-card-list .btn-buy {
    color: #292242;
    border: 1px solid #292242;
}
.div-gift-template-thirtyeight .div-card-list .btn-buy:hover, .div-gift-template-thirtyeight .div-card-list .btn-buy:focus {
    color: #fff;
    border: 1px solid #292242;
    background-color: #292242;
}
.div-gift-template-thirtyeight .div-card-list .col-md-4 input {
    border: 1px solid #292242;
}
.div-gift-template-thirtyeight .div-gift-promo input {
    border: 1px solid #292242;
    color: #292242;
}
.div-gift-template-thirtyeight .div-limited-child .btn {
    color: #292242;
    border: 1px solid #292242;
}
.div-gift-template-thirtyeight .div-buy1-child, .div-gift-template-thirtyeight .div-buy2-child, .div-gift-template-thirtyeight .div-footer-description {
    color: #233C71;
}
.div-gift-template-thirtyeight .div-limited-child input {
    color: #292242;
    border: 1px solid #292242;
    background-color: #fff;
}
.div-gift-template-thirtyeight .div-limited-child input {
    color: #292242;
}
.div-gift-template-thirtyeight .div-limited-child input::placeholder {
    color:#292242CC;
}
.div-gift-template-thirtyeight .div-limited-child .btn:hover, .div-gift-template-thirtyeight .div-limited-child .btn:focus {
    color: #fff;
    border: 1px solid #292242;
    background-color: #292242;
}

/* Gift Template 39 */
.div-gift-template-thirtynine {
    background-color: #DADCE0;
    border: 1px solid #DADCE0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
    overflow: hidden;
}

.div-gift-template-thirtynine .div-gift-template .col-5 {
    background-color: #B1324E;
    height:275px;
}

.div-gift-template-thirtynine .div-gift-template .col-5 .div-logo {
    position: absolute;
    top: 15px;
    left: 30px;
}

.div-gift-template-thirtynine .div-gift-template .col-5 img {
    max-height: 35px;
}
.div-gift-template-thirtynine .div-gift-template .col-5 .div-card-header {
    position: absolute;
    bottom: 15px;
    width: 90%;
    text-align: center;
    right:15px;
}
.div-gift-template-thirtynine .div-gift-template .col-5 .div-card-header span {
    color: #fff;
    text-transform: uppercase;
    font-weight: 600;
}
.div-gift-template-thirtynine span, .div-gift-template-thirtynine label, .div-gift-template-thirtynine p, .div-gift-template-thirtynine i {
    color: #B1324E;
}
.div-gift-template-thirtynine .div-card-list .btn-buy {
    color: #B1324E;
    border: 1px solid #B1324E;
}
.div-gift-template-thirtynine .div-card-list .btn-buy:hover, .div-gift-template-thirtynine .div-card-list .btn-buy:focus {
    color: #fff;
    border: 1px solid #B1324E;
    background-color: #B1324E;
}
.div-gift-template-thirtynine .div-card-list .col-md-4 input {
    border: 1px solid #B1324E;
}
.div-gift-template-thirtynine .div-gift-promo input {
    border: 1px solid #B1324E;
    color: #B1324E;
}
.div-gift-template-thirtynine .div-limited-child .btn {
    color: #B1324E;
    border: 1px solid #B1324E;
}
.div-gift-template-thirtynine .div-buy1-child, .div-gift-template-thirtynine .div-buy2-child, .div-gift-template-thirtynine .div-footer-description {
    color: #B1324E;
}
.div-gift-template-thirtynine .div-limited-child input {
    color: #B1324E;
    border: 1px solid #B1324E;
    background-color: #fff;
}
.div-gift-template-thirtynine .div-limited-child input {
    color: #B1324E;
}
.div-gift-template-thirtynine .div-limited-child input::placeholder {
    color: #B1324ECC;
}
.div-gift-template-thirtynine .div-limited-child .btn:hover, .div-gift-template-thirtynine .div-limited-child .btn:focus {
    color: #fff;
    border: 1px solid #B1324E;
    background-color: #B1324E;
}

/* Gift Template 40 */

.div-gift-template-fourty {
    background-color: #DADCE0;
    border: 1px solid #DADCE0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
    overflow: hidden;
}
.div-gift-template-fourty .col-7.div-twentynine-content {    
    min-height: 275px;    
    /* padding-left: 30px; */
}
.div-gift-template-fourty .col-8 {
    min-height: 275px;
    background-color: #0F303E;
    z-index: 1;
    padding-top: 20px;
}
.div-gift-template-fourty .col-8 div.div-re-points {
    width:100%;   
    padding-top: 60px;
}
.div-gift-template-fourty span.item-points {
    font-size: 9px;    
}
.div-gift-template-fourty span.item-points strong {
    font-size: 12px;
    font-weight: 700;
}
.div-gift-template-fourty .col-7 {
    position: relative;
    overflow: hidden;
    min-height: 200px;
}
.div-gift-template-fourty .col-4 div.semicircle-right {    
    position: absolute;
    top: 0; 
    bottom: 0;
    box-shadow: 0px 0px 0px 110px #0F303E;
    width: 100%
}
.div-gift-template-fourty .col-4 div.semicircle-right-curve {   
    border-radius: 0 140% 60% 0 / 0 60% 100% 0;
}
.div-gift-template-fourty .div-gift-template .col-8 .div-card-header span {
    color:#B6AB9E;
    position: absolute;
    bottom: 25px;
    width: 70%;
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
}
.div-gift-template-fourty .div-gift-template .col-8 img {
    max-height: 35px;
}
.div-gift-template-fourty span, .div-gift-template-fourty label, .div-gift-template-fourty p, .div-gift-template-fourty i {
    color: #0F303E;
}
.div-gift-template-fourty .div-card-list .btn-buy {
    color: #0F303E;
    border: 1px solid #0F303E;
}
.div-gift-template-fourty .div-card-list .btn-buy:hover, .div-gift-template-fourty .div-card-list .btn-buy:focus {
    color: #fff;
    border: 1px solid #0F303E;
    background-color: #0F303E;
}
.div-gift-template-fourty .div-card-list .col-md-4 input {
    border: 1px solid #003260;
}
.div-gift-template-fourty .div-gift-promo input {
    border: 1px solid #0F303E;
    color: #0F303E;
}
.div-gift-template-fourty .div-limited-child .btn {
    color: #0F303E;
    border: 1px solid #0F303E;
}
.div-gift-template-fourty .div-buy1-child, .div-gift-template-fourty .div-buy2-child, .div-gift-template-fourty .div-footer-description {
    color: #0F303E;
}
.div-gift-template-fourty .div-limited-child input {
    color: #0F303E;
    border: 1px solid #0F303E;
    background-color: #fff;
}
.div-gift-template-fourty .div-limited-child input {
    color: #0F303E;
}
.div-gift-template-fourty .div-limited-child input::placeholder {
    color:#0F303ECC;
}
.div-gift-template-fourty .div-limited-child .btn:hover, .div-gift-template-fourty .div-limited-child .btn:focus {
    color: #fff;
    border: 1px solid #0F303E;
    background-color: #0F303E;
}


/* Gift Template 41*/
.div-gift-template-fourtyone {
    background-color: #DADCE0;
    border: 1px solid #DADCE0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
    overflow: hidden;
}

.div-gift-template-fourtyone .div-gift-template .div-template-footer {
    background-color: #033f71;
    padding: 15px;
    width: 100%;
    position: absolute;
    top: 0px;
    text-align: center;
}
.div-gift-template-fourtyone .div-gift-template .div-template-footer span {
    letter-spacing: 1.2px;
    color: #fff;
    font-weight: 300;      
}
.div-gift-template-fourtyone .div-gift-template .div-template-footer img {
    max-height: 22px;
}
.div-gift-template-sfourtyone span, .div-gift-template-fourtyone label, .div-gift-template-fourtyone p, .div-gift-template-fourtyone i {
    color: #033f71;
}
.div-gift-template-fourtyone .div-card-list .btn-buy {
    color: #033f71;
    border: 1px solid #033f71;
}
.div-gift-template-fourtyone .div-card-list .btn-buy:hover, .div-gift-template-fourtyone .div-card-list .btn-buy:focus {
    color: #fff;
    border: 1px solid #033f71;
    background-color: #033f71;
}
.div-gift-template-fourtyone .div-card-list .col-md-4 input {
    border: 1px solid #033f71;
}
.div-gift-template-fourtyone .div-gift-promo input {
    border: 1px solid #033f71;
    color: #033f71;
}
.div-gift-template-fourtyone .div-limited-child .btn {
    color: #033f71;
    border: 1px solid #033f71;
}
.div-gift-template-fourtyone .div-buy1-child, .div-gift-template-fourtyone .div-buy2-child, .div-gift-template-fourtyone .div-footer-description {
    color: #033f71;
}
.div-gift-template-fourtyone .div-limited-child input {
    color: #033f71;
    border: 1px solid #033f71;
    background-color: #fff;
}
.div-gift-template-fourtyone .div-limited-child input {
    color: #033f71;
}
.div-gift-template-fourtyone .div-limited-child input::placeholder {
    color: #033f71CC;
}
.div-gift-template-fourtyone .div-limited-child .btn:hover, .div-gift-template-fourtyone .div-limited-child .btn:focus {
    color: #fff;
    border: 1px solid #033f71;
    background-color: #033f71;
}

/* Gift Template 42*/

.div-gift-template-fourtytwo {
    background-color: #DADCE0;
    border: 1px solid #DADCE0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
    overflow: hidden;
}

.div-gift-template-fourtytwo .div-gift-template .div-template-footer {
    background-color: #3b1c10;
    padding: 15px;
    width: 100%;
    position: absolute;
    bottom: 0px;
    text-align: center;
}
.div-gift-template-fourtytwo .div-gift-template .div-template-footer span {
    letter-spacing: 1.2px;
    color: #fff;
    font-weight: 300;      
}
.div-gift-template-fourtytwo .div-gift-template .div-template-footer img {
    max-height: 22px;
}
.div-gift-template-fourtytwo span, .div-gift-template-fourtytwo label, .div-gift-template-fourtytwo p, .div-gift-template-fourtytwo i {
    color: #3b1c10;
}
.div-gift-template-fourtytwo .div-card-list .btn-buy {
    color: #3b1c10;
    border: 1px solid #3b1c10;
}
.div-gift-template-fourtytwo .div-card-list .btn-buy:hover, .div-gift-template-fourtytwo .div-card-list .btn-buy:focus {
    color: #fff;
    border: 1px solid #3b1c10;
    background-color: #3b1c10;
}
.div-gift-template-fourtytwo .div-card-list .col-md-4 input {
    border: 1px solid #ECB621;
}
.div-gift-template-fourtytwo .div-gift-promo input {
    border: 1px solid #3b1c10;
    color: #3b1c10;
}
.div-gift-template-fourtytwo .div-limited-child .btn {
    color: #3b1c10;
    border: 1px solid #3b1c10;
}
.div-gift-template-fourtytwo .div-buy1-child, .div-gift-template-fourtytwo .div-buy2-child, .div-gift-template-fourtytwo .div-footer-description {
    color: #3b1c10;
}
.div-gift-template-fourtytwo .div-limited-child input {
    color: #3b1c10;
    border: 1px solid #3b1c10;
    background-color: #fff;
}
.div-gift-template-fourtytwo .div-limited-child input {
    color: #3b1c10;
}
.div-gift-template-fourtytwo .div-limited-child input::placeholder {
    color: #3b1c10CC;
}
.div-gift-template-fourtytwo .div-limited-child .btn:hover, .div-gift-template-fourtytwo .div-limited-child .btn:focus {
    color: #fff;
    border: 1px solid #3b1c10;
    background-color: #3b1c10;
}

.div-gift-template-fourtythree {
    background-color: #DADCE0;
    border: 1px solid #DADCE0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
    overflow: hidden;
}

.div-gift-template-fourtythree .col-5 {
    padding-left: 15px;
    height:275px;
    background-color: #e9d18c;
    border-bottom-right-radius: 30% 100%;
}
.div-gift-template-fourtythree .div-gift-template .col-5 {
    background-color: #c29c29;
    height:275px;
}
.div-gift-template-fourtythree .div-gift-template .col-5 .div-logo {
    position: absolute;
    top: 15px;
    left: 30px;
}
.div-gift-template-fourtythree .div-gift-template .col-5 img {
    max-height: 22px;
}
.div-gift-template-fourtythree .div-gift-template .col-5 .div-card-header {
    position: absolute;
    bottom: 15px;
    width: 70%;
    text-align: center;
}
.div-gift-template-fourtythree .div-gift-template .col-5 .div-card-header span {
    color: #fff;
}
.div-gift-template-fourtythree span, .div-gift-template-fourtythree label, .div-gift-template-fourtythree p, .div-gift-template-fourtythree i {
    color: #c29c29;
}
.div-gift-template-fourtythree .div-card-list .btn-buy {
    color: #e9d18c;
    border: 1px solid #c29c29;
}
.div-gift-template-fourtythree .div-card-list .btn-buy:hover, .div-gift-template-fourtythree .div-card-list .btn-buy:focus {
    color: #fff;
    border: 1px solid #c29c29;
    background-color: #c29c29;
}
.div-gift-template-fourtythree .div-card-list .col-md-4 input {
    border: 1px solid #c29c29;
}
.div-gift-template-fourtythree .div-gift-promo input {
    border: 1px solid #c29c29;
    color: #c29c29;
}
.div-gift-template-fourtythree .div-limited-child .btn {
    color: #c29c29;
    border: 1px solid #c29c29;
}
.div-gift-template-fourtythree .div-buy1-child, .div-gift-template-fourtythree .div-buy2-child, .div-gift-template-fourtythree .div-footer-description {
    color: #e9d18c;
}
.div-gift-template-fourtythree .div-limited-child input {
    color: #e9d18c;
    border: 1px solid #c29c29;
    background-color: #fff;
}
.div-gift-template-fourtythree .div-limited-child input {
    color: #c29c29;
}
.div-gift-template-fourtythree .div-limited-child input::placeholder {
    color: #c29c29CC;
}
.div-gift-template-fourtythree .div-limited-child .btn:hover, .div-gift-template-fourtythree .div-limited-child .btn:focus {
    color: #fff;
    border: 1px solid #c29c29;
    background-color: #c29c29;
}

/* Gift Template 44 */
.div-gift-template-fourtyfour {
    background-color: #DADCE0;
    border: 1px solid #DADCE0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
    overflow: hidden;
}
/*Right pointing*/
.div-gift-template-fourtyfour .col-5 div.triangle-right {
    width: 100%;
    height: 0;
    border-top: 137px solid #17180a;
    border-left: 36px solid transparent;
    border-bottom: 138px solid #17180a;
}
.div-gift-template-fourtyfour .div-gift-template .col-5 .div-logo {
    position: absolute;
    top: 15px;
    left: 30px;
}

.div-gift-template-fourtyfour .div-gift-template .col-5 img {
    max-height: 22px;
}
.div-gift-template-fourtyfour .div-gift-template .col-5 .div-card-header {
    position: absolute;
    bottom: 15px;
    left: 30px;
}
.div-gift-template-fourtyfour .div-gift-template .col-5 .div-card-header span {
    color: #fff;
}
.div-gift-template-fourtyfour span, .div-gift-template-fourtyfour label, .div-gift-template-fourtyfour p, .div-gift-template-fourtyfour i {
    color: #17180a;
}
.div-gift-template-fourtyfour .div-card-list .btn-buy {
    color: #de2d31;
    border: 1px solid #17180a;
}
.div-gift-template-fourtyfour .div-card-list .btn-buy:hover, .div-gift-template-fourtyfour .div-card-list .btn-buy:focus {
    color: #fff;
    border: 1px solid #17180a;
    background-color: #17180a;
}
.div-gift-template-fourtyfour .div-card-list .col-md-4 input {
    border: 1px solid #17180a;
}
.div-gift-template-fourtyfour .div-gift-promo input {
    border: 1px solid #17180a;
    color: #17180a;
}
.div-gift-template-fourtyfour .div-limited-child .btn {
    color: #17180a;
    border: 1px solid #17180a;
}
.div-gift-template-fourtyfour .div-buy1-child, .div-gift-template-fourtyfour .div-buy2-child, .div-gift-template-fourtyfour .div-footer-description {
    color: #17180a;
}
.div-gift-template-fourtyfour .div-limited-child input {
    color: #de2d31;
    border: 1px solid #17180a;
    background-color: #fff;
}
.div-gift-template-fourtyfour .div-limited-child input {
    color: #17180a;
}
.div-gift-template-fourtyfour .div-limited-child input::placeholder {
    color: #17180aCC;
}
.div-gift-template-fourtyfour .div-limited-child .btn:hover, .div-gift-template-fourtyfour .div-limited-child .btn:focus {
    color: #fff;
    border: 1px solid #17180a;
    background-color: #17180a;
}

/* Gift Template 45 */

.div-gift-template-fourtyfive {
    background-color: #DADCE0;
    border: 1px solid #DADCE0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
    overflow: hidden;
}

.div-gift-template-fourtyfive .div-gift-template .col-5 {
    background-color: #de2d31;
    height:275px;
}

.div-gift-template-fourtyfive .div-gift-template .col-5 .div-logo {
    position: absolute;
    top: 125px;
    left: 65px;
}

.div-gift-template-fourtyfive .div-gift-template .col-5 img {
    max-height: 22px;
}
.div-gift-template-fourtyfive .div-gift-template .col-5 .div-card-header {
    position: absolute;
    bottom: 15px;
    width: 90%;
    text-align: center;
    right:15px;
}
.div-gift-template-fourtyfive .div-gift-template .col-5 .div-card-header span {
    color: #fff;
    text-transform: uppercase;
    font-weight: 600;
}
.div-gift-template-fourtyfive span, .div-gift-template-fourtyfive label, .div-gift-template-fourtyfive p, .div-gift-template-fourtyfive i {
    color: #de2d31;
}
.div-gift-template-fourtyfive .div-card-list .btn-buy {
    color: #B1324E;
    border: 1px solid #de2d31;
}
.div-gift-template-fourtyfive .div-card-list .btn-buy:hover, .div-gift-template-fourtyfive .div-card-list .btn-buy:focus {
    color: #fff;
    border: 1px solid #de2d31;
    background-color: #de2d31;
}
.div-gift-template-fourtyfive .div-card-list .col-md-4 input {
    border: 1px solid #de2d31;
}
.div-gift-template-fourtyfive .div-gift-promo input {
    border: 1px solid #de2d31;
    color: #B1324E;
}
.div-gift-template-fourtyfive .div-limited-child .btn {
    color: #de2d31;
    border: 1px solid #de2d31;
}
.div-gift-template-fourtyfive .div-buy1-child, .div-gift-template-fourtyfive .div-buy2-child, .div-gift-template-fourtyfive .div-footer-description {
    color: #de2d31;
}
.div-gift-template-fourtyfive .div-limited-child input {
    color: #de2d31;
    border: 1px solid #de2d31;
    background-color: #fff;
}
.div-gift-template-fourtyfive .div-limited-child input {
    color: #de2d31;
}
.div-gift-template-fourtyfive .div-limited-child input::placeholder {
    color: #de2d31CC;
}
.div-gift-template-fourtyfive .div-limited-child .btn:hover, .div-gift-template-fourtyfive .div-limited-child .btn:focus {
    color: #fff;
    border: 1px solid #de2d31;
    background-color: #de2d31;
}

/* Gift Template 46 */

.div-gift-template-fourtysix {
    background-color: #DADCE0;
    border: 1px solid #DADCE0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
    overflow: hidden;
}
.div-giftcard {
    position: absolute;
    bottom: 15px;
    width: 70%;
    text-align: center;
    color: #E0BE55;
}
.div-gift-template-fourtysix .col-4 {
    background-color: #362f28;
    min-height: 275px;
    padding-top: 20px;
    padding-left: 30px;
}
.div-gift-template-fourtysix .col-4 div.div-re-points {
    width:100%;   
    padding-top: 30px;
}
.div-gift-template-fourtysix span.item-points {
    font-size: 9px;
    font-weight: 700;
}
.div-gift-template-fourtysix span.item-points strong {
    font-size: 16px;
    font-weight: 700;
}
/*Right pointing*/
.div-gift-template-fourtysix .col-1 div.triangle-left {
    width: 100%;    
    height: 0;
    border-top: 125px solid transparent;
    border-left: 30px solid #362f28;
    border-bottom: 150px solid transparent;
}

.div-gift-template-fourtysix .div-gift-template .col-4 .div-card-header span {
    color:#fff;
    position: absolute;
    bottom: 25px;
    width: 70%;
    text-align: center;
}
.div-gift-template-fourtysix span, .div-gift-template-fourtysix label, .div-gift-template-fourtysix p, .div-gift-template-fourtysix i {
    color: #362f28;
}
.div-gift-template-fourtysix .div-card-list .btn-buy {
    color: #362f28;
    border: 1px solid #362f28;
}
.div-gift-template-fourtysix .div-card-list .btn-buy:hover, .div-gift-template-fourtysix .div-card-list .btn-buy:focus {
    color: #fff;
    border: 1px solid #362f28;
    background-color: #362f28;
}
.div-gift-template-fourtysix .div-card-list .col-md-4 input {
    border: 1px solid #003260;
}
.div-gift-template-fourtysix .div-gift-promo input {
    border: 1px solid #362f28;
    color: #181C14;
}
.div-gift-template-fourtysix .div-limited-child .btn {
    color: #181C14;
    border: 1px solid #362f28;
}
.div-gift-template-fourtysix .div-buy1-child, .div-gift-template-fourtysix .div-buy2-child, .div-gift-template-fourtysix .div-footer-description {
    color: #362f28;
}
.div-gift-template-fourtysix .div-limited-child input {
    color: #362f28;
    border: 1px solid #362f28;
    background-color: #fff;
}
.div-gift-template-fourtysix .div-limited-child input {
    color: #362f28;
}
.div-gift-template-fourtysix .div-limited-child input::placeholder {
    color:#362f28CC;
}
.div-gift-template-fourtysix .div-limited-child .btn:hover, .div-gift-template-fourtysix .div-limited-child .btn:focus {
    color: #fff;
    border: 1px solid #362f28;
    background-color: #362f28;
}

/* Gift Template 47 */

.div-gift-template-fourtyseven {
    background-color: #DADCE0;
    border: 1px solid #DADCE0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
    overflow: hidden;
}

/*Right pointing*/
.div-gift-template-fourtyseven .col-6 div.half-circle {
    width: 100%;
    height: 275px;
    background-color: #cc8c1c;
    border-top-left-radius: 30% 50%;
    border-bottom-left-radius: 30% 50%;    
    border-bottom: 0;
}

.div-gift-template-fourtyseven .div-gift-template .col-6 .div-logo {
    position: absolute;
    top: 15px;
    left: 80px;
}

.div-gift-template-fourtyseven .div-gift-template .col-6 img {
    max-height: 22px;
}
.div-gift-template-fourtyseven .div-gift-template .col-6 .div-card-header {
    position: absolute;
    bottom: 15px;
    width: 90%;
    text-align: center;
    color: #fff;
}
.div-gift-template-fourtyseven .div-gift-template .col-6 .div-card-header span {
    color: #fff;
}
.div-gift-template-fourtyseven span, .div-gift-template-fourtyseven label, .div-gift-template-fourtyseven p, .div-gift-template-fourtyseven i {
    color: #cc8c1c;
}
.div-gift-template-fourtyseven .div-card-list .btn-buy {
    color: #cc8c1c;
    border: 1px solid #cc8c1c;
}
.div-gift-template-fourtyseven .div-card-list .btn-buy:hover, .div-gift-template-fourtyseven .div-card-list .btn-buy:focus {
    color: #fff;
    border: 1px solid #cc8c1c;
    background-color: #cc8c1c;
}
.div-gift-template-fourtyseven .div-card-list .col-md-4 input {
    border: 1px solid #cc8c1c;
}
.div-gift-template-fourtyseven .div-gift-promo input {
    border: 1px solid #cc8c1c;
    color: #cc8c1c;
}
.div-gift-template-fourtyseven .div-limited-child .btn {
    color: #cc8c1c;
    border: 1px solid #cc8c1c;
}
.div-gift-template-fourtyseven .div-buy1-child, .div-gift-template-fourtyseven .div-buy2-child, .div-gift-template-fourtyseven .div-footer-description {
    color: #cc8c1c;
}
.div-gift-template-fourtyseven .div-limited-child input {
    color: #cc8c1c;
    border: 1px solid #cc8c1c;
    background-color: #fff;
}
.div-gift-template-fourtyseven .div-limited-child input {
    color: #cc8c1c;
}
.div-gift-template-fourtyseven .div-limited-child input::placeholder {
    color: #cc8c1cCC;
}
.div-gift-template-fourtyseven .div-limited-child .btn:hover, .div-gift-template-fourtyseven .div-limited-child .btn:focus {
    color: #fff;
    border: 1px solid #cc8c1c;
    background-color: #cc8c1c;
}

/*Gift Template 48 */

.div-gift-template-fourtyeight {
    background-color: #DADCE0;
    border: 1px solid #DADCE0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
    overflow: hidden;
}

.div-gift-template-fourtyeight .div-template-child {
    background-color: #42D7D999;
    margin: 0px;
    border-radius: 15px;
    min-height: 200px;
    text-align: center;
}

.div-gift-template-fourtyeight .col-7 {
    padding-left: 15px;
    height:275px;
    background-color: #ca7f2d;
    text-align: center;
}
.div-gift-template-fourtyeight .div-points {
    position: relative;
    height: 100px;
    width: 100px;
    text-align: center;
    margin-left: 23%;
    margin-top: 5px; 
}
.div-gift-template-fourtyeight .div-points div {
    position: absolute;
    top:10px;    
}

.div-gift-template-fourtyeight .div-gift-template .div-logo {
    padding-top: 30px;
}

.div-gift-template-fourtyeight .div-gift-template img {
    max-height: 35px;
    width:80%;
}

.div-gift-template-fourtyeight .div-card-header {
    margin-top: 30px;
}

.div-gift-template-fourtyeight .div-card-header span {
    color: #fff;    
}
.div-gift-template-fourtyeight span, .div-gift-template-fourtyeight label, .div-gift-template-fourtyeight p, .div-gift-template-fourtyeight i {
    color: #ca7f2d;
}
.div-gift-template-fourtyeight .div-card-list .btn-buy {
    color: #ca7f2d;
    border: 1px solid #ca7f2d;
}
.div-gift-template-fourtyeight .div-card-list .btn-buy:hover, .div-gift-template-fourtyeight .div-card-list .btn-buy:focus {
    color: #fff;
    border: 1px solid #ca7f2d;
    background-color: #ca7f2d;
}
.div-gift-template-fourtyeight .div-card-list .col-md-4 input {
    border: 1px solid #ca7f2d;
}
.div-gift-template-fourtyeight .div-gift-promo input {
    border: 1px solid #ca7f2d;
    color: #ca7f2d;
}
.div-gift-template-fourtyeight .div-limited-child .btn {
    color: #ca7f2d;
    border: 1px solid #ca7f2d;
}
.div-gift-template-fourtyeight .div-buy1-child, .div-gift-template-fourtyeight .div-buy2-child, .div-gift-template-fourtyeight .div-footer-description {
    color: #ca7f2d;
}
.div-gift-template-fourtyeight .div-limited-child input {
    color: #ca7f2d;
    border: 1px solid #ca7f2d;
    background-color: #fff;
}
.div-gift-template-fourtyeight .div-limited-child input {
    color: #ca7f2d;
}
.div-gift-template-fourtyeight .div-limited-child input::placeholder {
    color: #ca7f2dCC;
}
.div-gift-template-fourtyeight .div-limited-child .btn:hover, .div-gift-template-fourtyeight .div-limited-child .btn:focus {
    color: #fff;
    border: 1px solid #ca7f2d;
    background-color: #ca7f2d;
}

.div-gift-template-fourtynine {
    background-color: #DADCE0;
    border: 1px solid #DADCE0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
    overflow: hidden;
}
.div-gift-template-fourtynine .div-template-child {
    background-color: #42D7D999;
    margin: 0px;
    border-radius: 15px;
    min-height: 275px;
    text-align: center;
}
.div-gift-template-fourtynine button:hover, .div-gift-template-fourtynine button:focus {
    color:#D23038;
    border: 1px solid #541106;
    background-color: #FFF;
}
.div-gift-template-fourtynine p {    
    color: #541106;
    text-transform: uppercase;
}
.div-gift-template-fourtynine span.item-name {
    letter-spacing: 1.2px;
    font-weight: 400;
    color: #FFFFFF;
}
.div-gift-template-fourtynine p.item-points-text {
    font-size: 15px;
    color: #fff;
    font-weight: 600; 
    margin-bottom: 5px;   
}
.div-gift-template-fourtynine p.item-points {
    font-size: 20px;
    margin-bottom: 0px;
    font-weight: 800;
    margin-top: 15px;
    text-transform: uppercase;
}

.div-gift-template-fourtynine .div-items {
    width: 125px;
    text-align: center;
    position: absolute;
    left: 30%;
    top: 38%;
}
.div-gift-template-fourtynine .div-diamond {
    width: 125px;
    height: 125px;
    background-color: #541106;
    position: absolute;
    transform: rotate(45deg);
    top: 20%;
    left: 30%;
}
.div-gift-template-fourtynine .div-gift-template .col-6 .div-card-header {
    position: absolute;
    bottom: 15px;
    width: 90%;
    text-align: center;
    color: #fff;
}
.div-gift-template-fourtynine .div-gift-template .col-6 .div-card-header span {
    color: #fff;
}
.div-gift-template-fourtynine span, .div-gift-template-fourtynine label, .div-gift-template-fourtynine p, .div-gift-template-fourtynine i {
    color: #cc8c1c;
}
.div-gift-template-fourtynine .div-card-list .btn-buy {
    color: #cc8c1c;
    border: 1px solid #541106;
}
.div-gift-template-fourtynine .div-card-list .btn-buy:hover, .div-gift-template-fourtynine .div-card-list .btn-buy:focus {
    color: #fff;
    border: 1px solid #541106;
    background-color: #541106;
}
.div-gift-template-fourtynine .div-card-list .col-md-4 input {
    border: 1px solid #541106;
}
.div-gift-template-fourtynine .div-gift-promo input {
    border: 1px solid #541106;
    color: #541106;
}
.div-gift-template-fourtynine .div-limited-child .btn {
    color: #541106;
    border: 1px solid #541106;
}
.div-gift-template-fourtynine .div-buy1-child, .div-gift-template-fourtynine .div-buy2-child, .div-gift-template-fourtynine .div-footer-description {
    color: #cc8c1c;
}
.div-gift-template-fourtynine .div-limited-child input {
    color: #541106;
    border: 1px solid #541106;
    background-color: #fff ;
}
.div-gift-template-fourtynine .div-limited-child input {
    color: #cc8c1c;
}
.div-gift-template-fourtynine .div-limited-child input::placeholder {
    color: #541106CC;
}
.div-gift-template-fourtynine .div-limited-child .btn:hover, .div-gift-template-fourtynine .div-limited-child .btn:focus {
    color: #fff;
    border: 1px solid #541106;
    background-color: #541106;
}

/* Gift Template 50 */

.div-gift-template-fifty {
    background-color: #DADCE0;
    border: 1px solid #DADCE0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
    overflow: hidden;
}
.div-giftcard {
    position: absolute;
    bottom: 15px;
    width: 70%;
    text-align: center;
    color: #E0BE55;
}
.div-gift-template-fifty .col-5 {
    background-color: #30261e;
    min-height: 275px;
    padding-top: 20px;
    padding-left: 30px;
}
.div-gift-template-fifty .col-5 div.div-re-points {
    width:100%;   
    padding-top: 30px;
}
.div-gift-template-fifty span.item-points {
    font-size: 9px;
    font-weight: 700;
}
.div-gift-template-fifty span.item-points strong {
    font-size: 16px;
    font-weight: 700;
}
/*Right pointing*/
.div-gift-template-fifty .col-1 div.triangle-left {
    width: 100%;    
    height: 0;
    border-top: 130px solid transparent;
    border-left: 30px solid #30261e;
    border-bottom: 130px solid transparent;
}

.div-gift-template-fifty .div-gift-template .col-5 .div-card-header span {
    color:#fff;
    position: absolute;
    bottom: 25px;
    width: 70%;
    text-align: center;
}
.div-gift-template-fifty span, .div-gift-template-fifty label, .div-gift-template-fifty p, .div-gift-template-fifty i {
    color: #30261e;
}
.div-gift-template-fifty .div-card-list .btn-buy {
    color: #30261e;
    border: 1px solid #30261e;
}
.div-gift-template-fifty .div-card-list .btn-buy:hover, .div-gift-template-fifty .div-card-list .btn-buy:focus {
    color: #fff;
    border: 1px solid #30261e;
    background-color:#30261e;
}
.div-gift-template-fifty .div-card-list .col-md-4 input {
    border: 1px solid #003260;
}
.div-gift-template-fifty .div-gift-promo input {
    border: 1px solid #30261e;
    color: #181C14;
}
.div-gift-template-fifty .div-limited-child .btn {
    color: #181C14;
    border: 1px solid #30261e;
}
.div-gift-template-fifty .div-buy1-child, .div-gift-template-fifty .div-buy2-child, .div-gift-template-fifty .div-footer-description {
    color: #30261e;
}
.div-gift-template-fifty .div-limited-child input {
    color: #30261e;
    border: 1px solid #30261e;
    background-color: #fff;
}
.div-gift-template-fifty .div-limited-child input {
    color: #30261e;
}
.div-gift-template-fifty .div-limited-child input::placeholder {
    color:#30261eCC;
}
.div-gift-template-fifty .div-limited-child .btn:hover, .div-gift-template-fifty .div-limited-child .btn:focus {
    color: #fff;
    border: 1px solid #30261e;
    background-color: #30261e;
}

/* Gift Template 51 */ 

.div-gift-template-fiftyone {
    background-color: #DADCE0;
    border: 1px solid #DADCE0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
    overflow: hidden;
}
.div-gift-template-fiftyone .div-template-child {
    background-color: #42D7D999;
    margin: 0px;
    border-radius: 15px;
    min-height: 275px;
    text-align: center;
}
.div-gift-template-fiftyone .div-gift-template .div-logo {
    padding-top: 50px;
    padding-left: 15px;
}
.div-gift-template-fiftyone .div-gift-template img {
    max-height: 35px;
    width:80%;
}
.div-gift-template-fiftyone .div-card-header {
    margin-top: 5px;
    padding-left: 15px;
}

.div-gift-template-fiftyone .div-card-header span {
    color: #fff;
    text-transform: uppercase; 
}
.div-gift-template-fiftyone span, .div-gift-template-fiftyone label, .div-gift-template-fiftyone p, .div-gift-template-fiftyone i {
    color: #f29942;
}
.div-gift-template-fiftyone .div-card-list .btn-buy {
    color: #f29942;
    border: 1px solid #f29942;
}
.div-gift-template-fiftyone .div-card-list .btn-buy:hover, .div-gift-template-fiftyone .div-card-list .btn-buy:focus {
    color: #fff;
    border: 1px solid #f29942;
    background-color: #251107;
}
.div-gift-template-fiftyone .div-card-list .col-md-4 input {
    border: 1px solid #f29942;
}
.div-gift-template-fiftyone .div-gift-promo input {
    border: 1px solid #f29942;
    color: #595A5C;
}
.div-gift-template-fiftyone .div-limited-child .btn {
    color: #f29942;
    border: 1px solid #f29942;
}
.div-gift-template-fiftyone .div-buy1-child, .div-gift-template-fiftyone .div-buy2-child, .div-gift-template-fiftyone .div-footer-description {
    color: #f29942;
}
.div-gift-template-fiftyone .div-limited-child input {
    color: #f29942;
    border: 1px solid #f29942;
    background-color: #fff;
}
.div-gift-template-fiftyone .div-limited-child input {
    color: #f29942;
}
.div-gift-template-fiftyone .div-limited-child input::placeholder {
    color: #f29942CC;
}
.div-gift-template-fiftyone .div-limited-child .btn:hover, .div-gift-template-fiftyone .div-limited-child .btn:focus {
    color: #fff;
    border: 1px solid #f29942;
    background-color: #251107;
}

/* Gift Template 52*/

.div-gift-template-fiftytwo {
    background-color: #DADCE0;
    border: 1px solid #DADCE0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
    overflow: hidden;
}
.div-gift-template-fiftytwo .div-gift-template .div-template-footer {
    background-color: #471409;
    padding: 15px;
    width: 100%;
    position: absolute;
    bottom: 0px;
    text-align: center;
}
.div-gift-template-fiftytwo .div-gift-template .div-template-footer span {
    letter-spacing: 1.2px;
    color: #fff;
    font-weight: 300;      
}
.div-gift-template-fiftytwo .div-gift-template .div-template-footer img {
    max-height: 22px;
}
.div-gift-template-fiftytwo span, .div-gift-template-fiftytwo label, .div-gift-template-fiftytwo p, .div-gift-template-fiftytwo i {
    color: #e6ac50;
}
.div-gift-template-fiftytwo .div-card-list .btn-buy {
    color: #3b1c10;
    border: 1px solid #471409;
}
.div-gift-template-fiftytwo .div-card-list .btn-buy:hover, .div-gift-template-fiftytwo .div-card-list .btn-buy:focus {
    color: #fff;
    border: 1px solid #471409;
    background-color: #471409;
}
.div-gift-template-fiftytwo .div-card-list .col-md-4 input {
    border: 1px solid #e6ac50;
}
.div-gift-template-fiftytwo .div-gift-promo input {
    border: 1px solid #e6ac50;
    color: #e6ac50;
}
.div-gift-template-fiftytwo .div-limited-child .btn {
    color: #e6ac50;
    border: 1px solid #e6ac50;
}
.div-gift-template-fiftytwo .div-buy1-child, .div-gift-template-fiftytwo .div-buy2-child, .div-gift-template-fiftytwo .div-footer-description {
    color: #e6ac50;
}
.div-gift-template-fiftytwo .div-limited-child input {
    color: #e6ac50;
    border: 1px solid #e6ac50;
    background-color: #fff;
}
.div-gift-template-fiftytwo .div-limited-child input {
    color: #e6ac50;
}
.div-gift-template-fiftytwo .div-limited-child input::placeholder {
    color: #e6ac50CC;
}
.div-gift-template-fiftytwo .div-limited-child .btn:hover, .div-gift-template-fiftytwo .div-limited-child .btn:focus {
    color: #fff;
    border: 1px solid #e6ac50;
    background-color: #471409;
}

/* Gift Template 53 */

.div-gift-template-fiftythree {
    background-color: #DADCE0;
    border: 1px solid #DADCE0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
    overflow: hidden;
}

.div-gift-template-fiftythree .div-gift-template .div-template-header {
    background-color: #d3b02e;
    padding: 10px 15px;
    text-align: center;
}
.div-gift-template-fiftythree .div-gift-template .div-template-header img {    
    max-height: 32px;
}
.div-gift-template-fiftythree .div-gift-template .div-template-footer {
    background-color: #d3b02e;
    padding: 15px;
    width: 100%;
    position: absolute;
    bottom: 0px;
    text-align: center;
}
.div-gift-template-fiftythree .div-gift-template .div-template-footer span {
    letter-spacing: 1.2px;
    color: #1a3a24;
    font-weight: 300;      
}
.div-gift-template-fiftythree span, .div-gift-template-fiftythree label, .div-gift-template-fiftythree p, .div-gift-template-fiftythree i {
    color: #1a3a24;
}
.div-gift-template-fiftythree .div-card-list .btn-buy {
    color: #1a3a24;
    border: 1px solid #1a3a24;
}
.div-gift-template-fiftythree .div-card-list .btn-buy:hover, .div-gift-template-fiftythree .div-card-list .btn-buy:focus {
    color: #fff;
    border: 1px solid #1a3a24;
    background-color: #d3b02e;
}
.div-gift-template-fiftythree .div-card-list .col-md-4 input {
    border: 1px solid #1a3a24;
}
.div-gift-template-fiftythree .div-gift-promo input {
    border: 1px solid #1a3a24;
    color: #1a3a24;
}
.div-gift-template-fiftythree .div-limited-child input{
    color: #1a3a24;
}
.div-gift-template-fiftythree .div-limited-child input::placeholder {
    color: #1a3a24CC;
}
.div-gift-template-fiftythree .div-limited-child .btn {
    color: #1a3a24;
    border: 1px solid #1a3a24;
}
.div-gift-template-fiftythree .div-buy1-child, .div-gift-template-fiftythree .div-buy2-child, .div-gift-template-fiftythree .div-footer-description {
    color: #1a3a24;
}
.div-gift-template-fiftythree .div-limited-child input {
    color: #1a3a24;
    border: 1px solid #1a3a24;
    background-color: #fff;
}
.div-gift-template-fiftythree .div-limited-child .btn:hover, .div-gift-template-fiftythree .div-limited-child .btn:focus {
    color: #fff;
    border: 1px solid #1a3a24;
    background-color: #d3b02e;
}

/*Gift Template 54 */
.div-gift-template-fiftyfour {
    background-color: #DADCE0;
    border: 1px solid #DADCE0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
    overflow: hidden;
}
.div-gift-template-fiftyfour .div-template-child {
    background-color: #6c513b;
    margin: 0px;
    border-radius: 15px;
    min-height: 275px;
    text-align: center;
}
.div-gift-template-fiftyfour .col-md-8 {
    background-color: #6c513b;
    opacity: 0.9;
    min-height: 275px;
    padding-top: 15px;
}
.div-gift-template-fiftyfour .div-gift-template .div-logo {
    padding-top: 30px;
}
.div-gift-template-fiftyfour .div-gift-template img {
    max-height: 35px;
    width:80%;
}
.div-gift-template-fiftyfour .div-card-header {
    margin-top: 120px;
}

.div-gift-template-fiftyfour .div-card-header span {
    color: #d2b53c;
    text-transform: uppercase; 
}
.div-gift-template-fiftyfour span, .div-gift-template-fiftyfour label, .div-gift-template-fiftyfour p, .div-gift-template-fiftyfour i {
    color: #d2b53c;
}
.div-gift-template-fiftyfour .div-card-list .btn-buy {
    color: #d2b53c;
    border: 1px solid #d2b53c;
}
.div-gift-template-fiftyfour .div-card-list .btn-buy:hover, .div-gift-template-fiftyfour .div-card-list .btn-buy:focus {
    color: #fff;
    border: 1px solid #d2b53c;
    background-color: #6c513b;
}
.div-gift-template-fiftyfour .div-card-list .col-md-4 input {
    border: 1px solid #d2b53c;
}
.div-gift-template-fiftyfour .div-gift-promo input {
    border: 1px solid #d2b53c;
    color: #d2b53c;
}
.div-gift-template-fiftyfour .div-limited-child .btn {
    color:#d2b53c;
    border: 1px solid #d2b53c;
}
.div-gift-template-fiftyfour .div-buy1-child, .div-gift-template-fiftyfour .div-buy2-child, .div-gift-template-fiftyfour .div-footer-description {
    color: #d2b53c;
}
.div-gift-template-fiftyfour .div-limited-child input {
    color: #d2b53c;
    border: 1px solid #d2b53c;
    background-color: #fff;
}
.div-gift-template-fiftyfour .div-limited-child input {
    color: #d2b53c;
}
.div-gift-template-fiftyfour .div-limited-child input::placeholder {
    color:#d2b53cCC;
}
.div-gift-template-fiftyfour .div-limited-child .btn:hover, .div-gift-template-fiftyfour .div-limited-child .btn:focus {
    color: #fff;
    border: 1px solid #d2b53c;
    background-color: #6c513b;
}

/* Gift Template 55 */

.div-gift-template-fiftyfive {
    background-color: #DADCE0;
    border: 1px solid #DADCE0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
    overflow: hidden;
}
.div-gift-template-fiftyfive .div-gift-template .div-template-footer {
    background-color: #1e2b36;
    padding: 25px;
    width: 55%;
    position: absolute;
    bottom: 0px;
    text-align: center;
    border-radius: 0px 15px 0px 0px;
}
.div-gift-template-fiftyfive .div-gift-template .div-template-footer span {
    letter-spacing: 1.2px;
    color: #fff;
    font-weight: 300;      
}
.div-gift-template-fiftyfive .div-gift-template .div-template-footer img {
    max-height: 22px;
}
.div-gift-template-fiftyfive span, .div-gift-template-fiftyfive label, .div-gift-template-fiftyfive p, .div-gift-template-fiftyfive i {
    color: #4c4945;
}
.div-gift-template-fiftyfive .div-card-list .btn-buy {
    color: #4c4945;
    border: 1px solid #4c4945;
}
.div-gift-template-fiftyfive .div-card-list .btn-buy:hover, .div-gift-template-fiftyfive .div-card-list .btn-buy:focus {
    color: #fff;
    border: 1px solid #4c4945;
    background-color: #1e2b36;
}
.div-gift-template-fiftyfive .div-card-list .col-md-4 input {
    border: 1px solid #4c4945;
}
.div-gift-template-fiftyfive .div-gift-promo input {
    border: 1px solid #4c4945;
    color: #4c4945;
}
.div-gift-template-fiftyfive .div-limited-child .btn {
    color: #4c4945;
    border: 1px solid #4c4945;
}
.div-gift-template-fiftyfive .div-buy1-child, .div-gift-template-fiftyfive .div-buy2-child, .div-gift-template-fiftyfive .div-footer-description {
    color: #d9cdbd;
}
.div-gift-template-fiftyfive .div-limited-child input {
    color: #4c4945;
    border: 1px solid #4c4945;
    background-color: #fff;
}
.div-gift-template-fiftyfive .div-limited-child input {
    color: #4c4945;
}
.div-gift-template-fiftyfive .div-limited-child input::placeholder {
    color:#4c4945;
}
.div-gift-template-fiftyfive .div-limited-child .btn:hover, .div-gift-template-fiftyfive .div-limited-child .btn:focus {
    color: #fff;
    border: 1px solid #4c4945;
    background-color: #1e2b36;
}

/* Gift Template 56 */

.div-gift-template-fiftysix {
    background-color: #DADCE0;
    border: 1px solid #DADCE0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
    overflow: hidden;
}

.div-gift-template-fiftysix .col-7 .div-left {
    padding-left: 30px;
    width: 100%;
    height: 0;    
    border-top: 275px solid #e2c4a7;
    border-right: 30px solid transparent;
}

.div-gift-template-fiftysix .div-gift-template .col-7 .div-logo {
    position: absolute;
    top: 15px;
    left: 30px;
}

.div-gift-template-fiftysix .div-gift-template .col-7 img {
    max-height: 22px;
}
.div-gift-template-fiftysix .div-gift-template .col-7 .div-card-header {
    position: absolute;
    bottom: 15px;
    width: 70%;
    text-align: center;
}
.div-gift-template-fiftysix .div-gift-template .col-7 .div-card-header span {
    color: #fff;
}
.div-gift-template-fiftysix span, .div-gift-template-fiftysix label, .div-gift-template-fiftysix p, .div-gift-template-fiftysix i {
    color: #5d4f41;
}
.div-gift-template-fiftysix .div-card-list .btn-buy {
    color: #5d4f41;
    border: 1px solid #5d4f41;
}
.div-gift-template-fiftysix .div-card-list .btn-buy:hover, .div-gift-template-fiftysix .div-card-list .btn-buy:focus {
    color: #fff;
    border: 1px solid #5d4f41;
    background-color: #4a4549;
}
.div-gift-template-fiftysix .div-card-list .col-md-4 input {
    border: 1px solid #5d4f41;
}
.div-gift-template-fiftysix .div-gift-promo input {
    border: 1px solid #5d4f41;
    color: #5d4f41;
}
.div-gift-template-fiftysix .div-limited-child .btn {
    color: #5d4f41;
    border: 1px solid #5d4f41;
}
.div-gift-template-fiftysix .div-buy1-child, .div-gift-template-fiftysix .div-buy2-child, .div-gift-template-fiftysix .div-footer-description {
    color:#5d4f41
}
.div-gift-template-fiftysix .div-limited-child input {
    color:#5d4f41;
    border: 1px solid #5d4f41;
    background-color: #fff;
}
.div-gift-template-fiftysix .div-limited-child input {
    color:#5d4f41;
}
.div-gift-template-fiftysix .div-limited-child input::placeholder {
    color:#5d4f41CC;
}
.div-gift-template-fiftysix .div-limited-child .btn:hover, .div-gift-template-fiftysix .div-limited-child .btn:focus {
    color: #fff;
    border: 1px solid #5d4f41;
    background-color: #4a4549;
}

/* Gift Template 57 */
.div-gift-template-fiftyseven {
    background-color: #DADCE0;
    border: 1px solid #DADCE0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
    overflow: hidden;
}
/*Right pointing*/
.div-gift-template-fiftyseven .col-7 .triangle-right {
    width: 0;
    height: 0;
    border-top: 7px solid transparent;
    border-left: 8px solid #2b490f;
    border-bottom: 7px solid transparent;
    margin-top: 93px;
}
.div-gift-template-fiftyseven .div-gift-template .col-5 {
    background-color: #2b490f;
    height:275px;
}

.div-gift-template-fiftyseven .div-gift-template .col-5 .div-logo {
    position: absolute;
    top: 15px;
    left: 60px;
}
.div-gift-template-fiftyseven .div-gift-template .col-5 img {
    max-height: 22px;
}
.div-gift-template-fiftyseven .div-gift-template .col-5 .div-card-header {
    position: absolute;
    bottom: 15px;
    width: 90%;
    text-align: center;
}
.div-gift-template-fiftyseven .div-gift-template .col-5 .div-card-header span {
    color: #fff;
}
.div-gift-template-fiftyseven span, .div-gift-template-fiftyseven label, .div-gift-template-fiftyseven p, .div-gift-template-fiftyseven i {
    color: #2b490f;
}
.div-gift-template-fiftyseven .div-card-list .btn-buy {
    color: #2b490f;
    border: 1px solid #2b490f;
}
.div-gift-template-fiftyseven .div-card-list .btn-buy:hover, .div-gift-template-fiftyseven .div-card-list .btn-buy:focus {
    color: #fff;
    border: 1px solid #2b490f;
    background-color: #2b490f;
}
.div-gift-template-fiftyseven .div-card-list .col-md-4 input {
    border: 1px solid #2b490f;
}
.div-gift-template-fiftyseven .div-gift-promo input {
    border: 1px solid #2b490f;
    color: #2b490f;
}
.div-gift-template-fiftyseven .div-limited-child .btn {
    color: #2b490f;
    border: 1px solid #2b490f;
}
.div-gift-template-fiftyseven .div-buy1-child, .div-gift-template-fiftyseven .div-buy2-child, .div-gift-template-fiftyseven .div-footer-description {
    color: #2b490f;
}
.div-gift-template-fiftyseven .div-limited-child input {
    color: #2b490f;
    border: 1px solid #2b490f;
    background-color: #fff;
}
.div-gift-template-fiftyseven .div-limited-child input {
    color: #2b490f;
}
.div-gift-template-fiftyseven .div-limited-child input::placeholder {
    color: #2b490fCC;
}
.div-gift-template-fiftyseven .div-limited-child .btn:hover, .div-gift-template-fiftyseven .div-limited-child .btn:focus {
    color: #fff;
    border: 1px solid #2b490f;
    background-color: #2b490f;
}

/* Gift Template 58 */

.div-gift-template-fiftyeight {
    background-color: #DADCE0;
    border: 1px solid #DADCE0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
    overflow: hidden;
}

.div-gift-template-fiftyeight  .col-7, .div-gift-template-fiftyeight  .col-5 {
    /* height: 200px; */
    background-color: #291b13;
    position: absolute;
    bottom: 0px;
    width:100%;
}
.div-gift-template-fiftyeight  .col-7 {
    right: 0px;
}
.div-gift-template-fiftyeight  .col-5 {
    left: 0px;
    padding:10px 15px;    
}

.div-gift-template-fiftyeight  .col-7 div {    
    padding: 25px 15px 15px 30px;
    text-align: right;
}

.div-gift-template-fiftyeight  .div-gift-template .div-template-footer span {
    letter-spacing: 1.2px;
    color: #291b13;
    font-weight: 600;
    text-transform: uppercase;
}
.div-gift-template-fiftyeight  .div-gift-template .div-template-footer img {
    max-height: 22px;
}
.div-gift-template-fiftyeight  span, .div-gift-template-fiftyeight  label, .div-gift-template-fiftyeight  p, .div-gift-template-fiftyeight  i {
    color: #291b13;
}
.div-gift-template-fiftyeight  .div-card-list .btn-buy {
    color: #291b13;
    border: 1px solid #291b13;
}
.div-gift-template-fiftyeight  .div-card-list .btn-buy:hover, .div-gift-template-fiftyeight  .div-card-list .btn-buy:focus {
    color: #fff;
    border: 1px solid #291b13;
    background-color: #291b13;
}
.div-gift-template-fiftyeight  .div-card-list .col-md-4 input {
    border: 1px solid #291b13;
}
.div-gift-template-fiftyeight  .div-gift-promo input {
    border: 1px solid #291b13;
    color: #291b13;
}
.div-gift-template-fiftyeight  .div-limited-child .btn {
    color: #291b13;
    border: 1px solid #291b13;
}
.div-gift-template-fiftyeight  .div-buy1-child, .div-gift-template-fiftyeight  .div-buy2-child, .div-gift-template-fiftyeight  .div-footer-description {
    color: #291b13;
}
.div-gift-template-fiftyeight  .div-limited-child input {
    color: #291b13;
    border: 1px solid #291b13;
    background-color: #fff;
}
.div-gift-template-fiftyeight  .div-limited-child input {
    color: #291b13;
}
.div-gift-template-fiftyeight .div-limited-child input::placeholder {
    color:#291b13CC;
}
.div-gift-template-fiftyeight  .div-limited-child .btn:hover, .div-gift-template-fiftyeight  .div-limited-child .btn:focus {
    color: #fff;
    border: 1px solid #291b13;
    background-color: #291b13;
}

/* Gift Template 59 */

.div-gift-template-fiftynine {
    background-color: #DADCE0;
    border: 1px solid #DADCE0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
    overflow: hidden;
}

.div-gift-template-fiftynine .div-gift-template .div-template-footer {
    background-color: #595959;
    padding: 15px;
    width: 100%;
    position: absolute;
    bottom: 0px;
    text-align: center;
}
.div-gift-template-fiftynine .div-gift-template .div-template-footer span {
    letter-spacing: 1.2px;
    color: #fff;
    font-weight: 300;      
}
.div-gift-template-fiftynine .div-gift-template .div-template-footer img {
    max-height: 22px;
}
.div-gift-template-fiftynine span, .div-gift-template-fiftynine label, .div-gift-template-fiftynine p, .div-gift-template-fiftynine i {
    color:#595959;
}
.div-gift-template-fiftynine .div-card-list .btn-buy {
    color: #595959;
    border: 1px solid #595959;
}
.div-gift-template-fiftynine .div-card-list .btn-buy:hover, .div-gift-template-fiftynine .div-card-list .btn-buy:focus {
    color: #fff;
    border: 1px solid #595959;
    background-color: #595959;
}
.div-gift-template-fiftynine .div-card-list .col-md-4 input {
    border: 1px solid #595959;
}
.div-gift-template-fiftynine .div-gift-promo input {
    border: 1px solid #595959;
    color: #595959;
}
.div-gift-template-fiftynine .div-limited-child .btn {
    color: #595959;
    border: 1px solid #595959;
}
.div-gift-template-fiftynine .div-buy1-child, .div-gift-template-fiftynine .div-buy2-child, .div-gift-template-fiftynine .div-footer-description {
    color: #595959;
}
.div-gift-template-fiftynine .div-limited-child input {
    color: #595959;
    border: 1px solid #595959;
    background-color: #fff;
}
.div-gift-template-fiftynine .div-limited-child input {
    color: #595959;
}
.div-gift-template-fiftynine .div-limited-child input::placeholder {
    color: #595959CC;
}
.div-gift-template-fiftynine .div-limited-child .btn:hover, .div-gift-template-fiftynine .div-limited-child .btn:focus {
    color: #fff;
    border: 1px solid #595959;
    background-color: #595959;
}

/* Gift Template 60 */

.div-gift-template-sixty {
    background-color: #DADCE0;
    border: 1px solid #DADCE0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
    overflow: hidden;
}
.div-gift-template-sixty .col-7.div-sixty-content {    
    min-height: 275px;    
    padding-left: 30px;
}
.div-gift-template-sixty .col-8 {
    min-height: 275px;
    background-color: #bd905d;
    z-index: 1;
    padding-top: 20px;
}
.div-gift-template-sixty .col-8 div.div-re-points {
    width:100%;   
    padding-top: 60px;
}
.div-gift-template-sixty span.item-points {
    font-size: 9px;    
}
.div-gift-template-sixty span.item-points strong {
    font-size: 12px;
    font-weight: 700;
}
.div-gift-template-sixty .col-7 {
    position: relative;
    overflow: hidden;
    min-height: 200px;
}
.div-gift-template-sixty .col-4 div.semicircle-left {    
    position: absolute;
    top: 0; 
    bottom: 0;
    box-shadow: 0px 0px 0px 110px #bd905d;
    width: 100%
}
.div-gift-template-sixty .col-4 div.semicircle-left-curve {   
    border-radius: 100% 0 0 25% / 50% 0  0 50%;
}
.div-gift-template-sixty .div-gift-template .col-8 .div-card-header span {
    color:#fff;
    position: absolute;
    bottom: 25px;
    width: 70%;
    text-align: center;
    text-transform: uppercase;
}
.div-gift-template-sixty span, .div-gift-template-sixty label, .div-gift-template-sixty p, .div-gift-template-sixty i {
    color: #bd905d;
}
.div-gift-template-sixty .div-card-list .btn-buy {
    color: #bd905d;
    border: 1px solid #bd905d;
}
.div-gift-template-sixty .div-card-list .btn-buy:hover, .div-gift-template-sixty .div-card-list .btn-buy:focus {
    color: #fff;
    border: 1px solid #bd905d;
    background-color: #bd905d;
}
.div-gift-template-sixty .div-card-list .col-md-4 input {
    border: 1px solid #bd905d;
}
.div-gift-template-sixty .div-gift-promo input {
    border: 1px solid #bd905d;
    color: #bd905d;
}
.div-gift-template-sixty .div-limited-child .btn {
    color: #bd905d;
    border: 1px solid #bd905d;
}
.div-gift-template-sixty .div-buy1-child, .div-gift-template-sixty .div-buy2-child, .div-gift-template-sixty .div-footer-description {
    color:#bd905d;
}
.div-gift-template-sixty .div-limited-child input {
    color: #bd905d;
    border: 1px solid #bd905d;
    background-color: #fff;
}
.div-gift-template-sixty .div-limited-child input {
    color:#bd905d;
}
.div-gift-template-sixty .div-limited-child input::placeholder {
    color:#bd905dCC;
}
.div-gift-template-sixty .div-limited-child .btn:hover, .div-gift-template-sixty .div-limited-child .btn:focus {
    color: #fff;
    border: 1px solid #bd905d;
    background-color: #bd905d;
}

/* Gift Template 61 */

.div-gift-template-sixtyone {
    background-color: #DADCE0;
    border: 1px solid #DADCE0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
    overflow: hidden;
}

/*Right pointing*/
.div-gift-template-sixtyone .col-7 .triangle-right {
    width: 0;
    height: 0;
    border-top: 7px solid transparent;
    border-right: 8px solid #45291d;
    border-bottom: 7px solid transparent;
    top: 133px;
    position:absolute;
    right:0px;
}
.div-gift-template-sixtyone .div-gift-template .col-5 {
    background-color: #45291d;
    height:275px;
}

.div-gift-template-sixtyone .div-gift-template .col-5 .div-logo {
    position: absolute;
    top: 15px;
    left: 60px;
}
.div-gift-template-sixtyone .div-gift-template .col-5 img {
    max-height: 22px;
}
.div-gift-template-sixtyone .div-gift-template .col-5 .div-card-header {
    position: absolute;
    bottom: 15px;
    width: 90%;
    text-align: center;
}
.div-gift-template-sixtyone .div-gift-template .col-5 .div-card-header span {
    color: #fff;
}
.div-gift-template-sixtyone span, .div-gift-template-sixtyone label, .div-gift-template-sixtyone p, .div-gift-template-sixtyone i {
    color: #45291d;
}
.div-gift-template-sixtyone .div-card-list .btn-buy {
    color: #45291d;
    border: 1px solid #45291d;
}
.div-gift-template-sixtyone .div-card-list .btn-buy:hover, .div-gift-template-sixtyone .div-card-list .btn-buy:focus {
    color: #fff;
    border: 1px solid #45291d;
    background-color: #45291d;
}
.div-gift-template-sixtyone .div-card-list .col-md-4 input {
    border: 1px solid #45291d;
}
.div-gift-template-sixtyone .div-gift-promo input {
    border: 1px solid #45291d;
    color: #45291d;
}
.div-gift-template-sixtyone .div-limited-child .btn {
    color: #45291d;
    border: 1px solid #45291d;
}
.div-gift-template-sixtyone .div-buy1-child, .div-gift-template-sixtyone .div-buy2-child, .div-gift-template-sixtyone .div-footer-description {
    color: #45291d;
}
.div-gift-template-sixtyone .div-limited-child input {
    color: #45291d;
    border: 1px solid #45291d;
    background-color: #fff;
}
.div-gift-template-sixtyone .div-limited-child input {
    color: #45291d;
}
.div-gift-template-sixtyone .div-limited-child input::placeholder {
    color: #45291dCC;
}
.div-gift-template-sixtyone .div-limited-child .btn:hover, .div-gift-template-sixtyone .div-limited-child .btn:focus {
    color: #fff;
    border: 1px solid #45291d;
    background-color: #45291d;
}

/* Gift Template 62 */
.div-gift-template-sixtytwo {
    background-color: #DADCE0;
    border: 1px solid #DADCE0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
    overflow: hidden;
}
.div-gift-template-sixtytwo .col-6 .div-left {
    padding-left: 30px;
    width: 100%;
    height: 0;    
    border-top: 275px solid #1e1e1e;
    border-left: 25px solid transparent;
}
.div-gift-template-sixtytwo .div-gift-template .col-6 .div-logo {
    position: absolute;
    top: 30%;
    left: 55px;
}
.div-gift-template-sixtytwo .div-gift-template .col-6 img {
    max-height: 25px;
}
.div-gift-template-sixtytwo .div-gift-template .col-6 .div-card-header {
    position: absolute;
    bottom: 70px;
    width: 70%;
    text-align: center;
}
.div-gift-template-sixtytwo .div-gift-template .col-6 .div-card-header span {
    color: #fff;
    text-transform: uppercase;
}
.div-gift-template-sixtytwo span, .div-gift-template-sixtytwo label, .div-gift-template-sixtytwo p, .div-gift-template-sixtytwo i {
    color: #1e1e1e;
}
.div-gift-template-sixtytwo .div-card-list .btn-buy {
    color: #1e1e1e;
    border: 1px solid #1e1e1e;
}
.div-gift-template-sixtytwo .div-card-list .btn-buy:hover, .div-gift-template-sixtytwo .div-card-list .btn-buy:focus {
    color: #fff;
    border: 1px solid #1e1e1e;
    background-color: #1e1e1e;
}
.div-gift-template-sixtytwo .div-card-list .col-md-4 input {
    border: 1px solid #1e1e1e;
}
.div-gift-template-sixtytwo .div-gift-promo input {
    border: 1px solid #1e1e1e;
    color: #1e1e1e;
}
.div-gift-template-sixtytwo .div-limited-child .btn {
    color: #1e1e1e;
    border: 1px solid #1e1e1e;
}
.div-gift-template-sixtytwo .div-buy1-child, .div-gift-template-sixtytwo .div-buy2-child, .div-gift-template-sixtytwo .div-footer-description {
    color: #1e1e1e;
}
.div-gift-template-sixtytwo .div-limited-child input {
    color: #1e1e1e;
    border: 1px solid #1e1e1e;
    background-color: #fff;
}
.div-gift-template-sixtytwo .div-limited-child input {
    color: #1e1e1e;
}
.div-gift-template-sixtytwo .div-limited-child input::placeholder {
    color: #1e1e1eCC;
}
.div-gift-template-sixtytwo .div-limited-child .btn:hover, .div-gift-template-sixtytwo .div-limited-child .btn:focus {
    color: #fff;
    border: 1px solid #1e1e1e;
    background-color: #1e1e1e;
}

/*Gift Template 63 */

.div-gift-template-sixtythree {
    background-color: #DADCE0;
    border: 1px solid #DADCE0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
    overflow: hidden;
}
.div-gift-template-sixtythree .div-template-child {
    background-color: #eac185;
    margin: 0px;
    border-radius: 15px;
    min-height: 275px;
    text-align: center;
}
.div-gift-template-sixtythree .col-md-8 {
    background-color: #eac185;
    opacity: 0.9;
    min-height: 275px;
    padding-top: 15px;
}
.div-gift-template-sixtythree .div-gift-template .div-logo {
    padding-top: 15px;
}
.div-gift-template-sixtythree .div-gift-template img {
    max-height: 35px;
    width:80%;
}
.div-gift-template-sixtythree .div-card-header {
    margin-top: 110px;
}
.div-gift-template-sixtythree .div-card-header span {
    color: #fff;
    text-transform: uppercase; 
}
.div-gift-template-sixtythree span, .div-gift-template-sixtythree label, .div-gift-template-sixtythree p, .div-gift-template-sixtythree i {
    color: #eac185;
}
.div-gift-template-sixtythree .div-card-list .btn-buy {
    color: #eac185;
    border: 1px solid #eac185;
}
.div-gift-template-sixtythree .div-card-list .btn-buy:hover, .div-gift-template-sixtythree .div-card-list .btn-buy:focus {
    color: #fff;
    border: 1px solid #eac185;
    background-color: #eac185;
}
.div-gift-template-sixtythree .div-card-list .col-md-4 input {
    border: 1px solid #eac185;
}
.div-gift-template-sixtythree .div-gift-promo input {
    border: 1px solid #eac185;
    color: #eac185;
}
.div-gift-template-sixtythree .div-limited-child .btn {
    color: #eac185;
    border: 1px solid #eac185;
}
.div-gift-template-sixtythree .div-buy1-child, .div-gift-template-sixtythree .div-buy2-child, .div-gift-template-sixtythree .div-footer-description {
    color: #eac185;
}
.div-gift-template-sixtythree .div-limited-child input {
    color: #eac185;
    border: 1px solid #eac185;
    background-color: #fff;
}
.div-gift-template-sixtythree .div-limited-child input {
    color: #eac185;
}
.div-gift-template-sixtythree .div-limited-child input::placeholder {
    color: #eac185CC;
}
.div-gift-template-sixtythree .div-limited-child .btn:hover, .div-gift-template- .div-limited-child .btn:focus {
    color: #fff;
    border: 1px solid #eac185;
    background-color: #eac185;
}

/* Gift Template 64 */

.div-gift-template-sixtyfour {
    background-color: #DADCE0;
    border: 1px solid #DADCE0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
    overflow: hidden;
}
.div-gift-template-sixtyfour .col-6 {
    height:275px;
}
.div-gift-template-sixtyfour .col-6 div.div-logo {
    width: 100%;
    position: absolute;
    left: 40px;
    top: 40px;
}
.div-gift-template-sixtyfour .col-6 div.div-re-points {
    width:100%;   
    position: absolute;    
    left: 40px;
    bottom: 50px;
}
.div-gift-template-sixtyfour .col-6 div.div-re-points span {
    color: #fff;
}
/*Right pointing*/
.div-gift-template-sixtyfour .col-6 div.triangle-left {
    width: 100%;
    height: 275px;
    border-top: 10px solid transparent;
    border-left: 150px solid #c47562;
    border-bottom: 80px solid transparent;
    position: absolute;
    top: 15px;
}

.div-gift-template-sixtyfour .div-gift-template .div-template-footer span {
    letter-spacing: 1.2px;
    color: #fff;
    font-weight: 300;      
}
.div-gift-template-sixtyfour .div-gift-template .div-template-footer img {
    max-height: 22px;
}
.div-gift-template-sixtyfour span, .div-gift-template-sixtyfour label, .div-gift-template-sixtyfour p, .div-gift-template-sixtyfour i {
    color: #c47562;
}
.div-gift-template-sixtyfour .div-card-list .btn-buy {
    color: #c47562;
    border: 1px solid #c47562;
}
.div-gift-template-sixtyfour .div-card-list .btn-buy:hover, .div-gift-template-sixtyfour .div-card-list .btn-buy:focus {
    color: #fff;
    border: 1px solid #c47562;
    background-color: #c47562;
}
.div-gift-template-sixtyfour .div-card-list .col-md-4 input {
    border: 1px solid #c47562;
}
.div-gift-template-sixtyfour .div-gift-promo input {
    border: 1px solid #c47562;
    color: #c47562;
}
.div-gift-template-sixtyfour .div-limited-child .btn {
    color: #c47562;
    border: 1px solid #c47562;
}
.div-gift-template-sixtyfour .div-buy1-child, .div-gift-template-sixtyfour .div-buy2-child, .div-gift-template-sixtyfour .div-footer-description {
    color: #c47562;
}
.div-gift-template-sixtyfour .div-limited-child input {
    color: #c47562;
    border: 1px solid #c47562;
    background-color: #fff;
}
.div-gift-template-sixtyfour .div-limited-child input {
    color: #c47562;
}
.div-gift-template-sixtyfour .div-limited-child input::placeholder {
    color: #c47562;
}
.div-gift-template-sixtyfour .div-limited-child .btn:hover, .div-gift-template-sixtyfour .div-limited-child .btn:focus {
    color: #fff;
    border: 1px solid #c47562;
    background-color: #c47562;
}

 /* Template 65 */

.div-gift-template-sixtyfive {
    background-color: #DADCE0;
    border: 1px solid #DADCE0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
    overflow: hidden;
}
.div-gift-template-sixtyfive .div-circle {
    width: 250px;
    height: 275px;
    background-color: #261e13;
    border-radius: 50%;
    position: absolute;
    top: -150px;
    left: 20px;
}
.div-gift-template-sixtyfive img{
    height:25px;
}
.div-gift-template-sixtyfive .div-logo {
    position: absolute;
    top: 25px;
    width: 60%;
    text-align: center;
}
.div-gift-template-sixtyfive .div-card-header {
    position: absolute;
    top: 55px;
    width: 60%;
    text-align: center;    
}

.div-gift-template-sixtyfive .div-card-header span {
    color: #fff;    
}

.div-gift-template-sixtyfive span, .div-gift-template-sixtyfive label, .div-gift-template-sixtyfive p, .div-gift-template-sixtyfive i {
    color: #261e13;
}
.div-gift-template-sixtyfive .div-card-list .btn-buy {
    color: #261e13;
    border: 1px solid #261e13;
}
.div-gift-template-sixtyfive .div-card-list .btn-buy:hover, .div-gift-template-sixtyfive .div-card-list .btn-buy:focus {
    color: #fff;
    border: 1px solid #261e13;
    background-color: #261e13;
}
.div-gift-template-sixtyfive .div-card-list .col-md-4 input {
    border: 1px solid #261e13;
}
.div-gift-template-sixtyfive .div-gift-promo input {
    border: 1px solid #261e13;
    color: #261e13;
}
.div-gift-template-sixtyfive .div-limited-child .btn {
    color: #261e13;
    border: 1px solid #261e13;
}
.div-gift-template-sixtyfive .div-buy1-child, .div-gift-template-sixtyfive .div-buy2-child, .div-gift-template-sixtyfive .div-footer-description {
    color: #261e13;
}
.div-gift-template-sixtyfive .div-limited-child input {
    color: #261e13;
    border: 1px solid #261e13;
    background-color: #fff;
}
.div-gift-template-sixtyfive .div-limited-child input {
    color: #261e13;
}
.div-gift-template-sixtyfive .div-limited-child input::placeholder {
    color: #261e13CC;
}
.div-gift-template-sixtyfive .div-limited-child .btn:hover, .div-gift-template-sixtyfive .div-limited-child .btn:focus {
    color: #fff;
    border: 1px solid #261e13;
    background-color: #261e13;
}

/* Gift Template 66 */

.div-gift-template-sixtysix {
    background-color: #DADCE0;
    border: 1px solid #DADCE0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
    overflow: hidden;
}

.div-gift-template-sixtysix .col-8 .div-left {
    padding-left: 30px;
    width: 100%;
    height: 0;    
    border-top: 275px solid #c46848;
    border-left: 65px solid transparent;
}

.div-gift-template-sixtysix .div-gift-template .col-8 .div-logo {
    position: absolute;
    top: 30%;
    left: 140px;
}

.div-gift-template-sixtysix .div-gift-template .col-8 img {
    max-height: 25px;
}
.div-gift-template-sixtysix .div-gift-template .col-8 .div-card-header {
    position: absolute;
    bottom: 60px;
    width: 100%;
    text-align: center;
}
.div-gift-template-sixtysix .div-gift-template .col-8 .div-card-header span {
    color: #fff;
    text-transform: uppercase;
}
.div-gift-template-sixtysix span, .div-gift-template-sixtysix label, .div-gift-template-sixtysix p, .div-gift-template-sixtysix i {
    color: #c46848;
}
.div-gift-template-sixtysix .div-card-list .btn-buy {
    color: #c46848;
    border: 1px solid #c46848;
}
.div-gift-template-sixtysix .div-card-list .btn-buy:hover, .div-gift-template-sixtysix .div-card-list .btn-buy:focus {
    color: #fff;
    border: 1px solid #c46848;
    background-color: #c46848;
}
.div-gift-template-sixtysix .div-card-list .col-md-4 input {
    border: 1px solid #c46848;
}
.div-gift-template-sixtysix .div-gift-promo input {
    border: 1px solid #c46848;
    color: #c46848;
}
.div-gift-template-sixtysix .div-limited-child .btn {
    color: #c46848;
    border: 1px solid #c46848;
}
.div-gift-template-sixtysix .div-buy1-child, .div-gift-template-sixtysix .div-buy2-child, .div-gift-template-sixtysix .div-footer-description {
    color: #c46848;
}
.div-gift-template-sixtysix .div-limited-child input {
    color: #c46848;
    border: 1px solid #c46848;
    background-color: #fff;
}
.div-gift-template-sixtysix .div-limited-child input {
    color: #c46848;
}
.div-gift-template-sixtysix .div-limited-child input::placeholder {
    color: #c46848CC;
}
.div-gift-template-sixtysix .div-limited-child .btn:hover, .div-gift-template-sixtysix .div-limited-child .btn:focus {
    color: #fff;
    border: 1px solid #c46848;
    background-color: #c46848;
}

/* Template 67 */

.div-gift-template-sixtyseven {
    background-color: #DADCE0;
    border: 1px solid #DADCE0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
    overflow: hidden;
}
.div-gift-template-sixtyseven .div-circle {
    width: 250px;
    height: 275px;
    background-color: #e382b0;
    border-radius: 50%;
    position: absolute;
    top: -150px;
    left: -6px;
}
.div-gift-template-sixtyseven img{
    height:25px;
}
.div-gift-template-sixtyseven .div-logo {
    position: absolute;
    top: 25px;
    width: 50%;
    text-align: center;
}
.div-gift-template-sixtyseven .div-card-header {
    position: absolute;
    top: 55px;
    width: 50%;
    text-align: center;    
}
.div-gift-template-sixtyseven .div-card-header span {
    color: #fff;    
}

.div-gift-template-sixtyseven span, .div-gift-template-sixtyseven label, .div-gift-template-sixtyseven p, .div-gift-template-sixtyseven i {
    color: #e382b0;
}
.div-gift-template-sixtyseven .div-card-list .btn-buy {
    color: #e382b0;
    border: 1px solid #e382b0;
}
.div-gift-template-sixtyseven .div-card-list .btn-buy:hover, .div-gift-template-sixtyseven .div-card-list .btn-buy:focus {
    color: #fff;
    border: 1px solid #e382b0;
    background-color: #e382b0;
}
.div-gift-template-sixtyseven .div-card-list .col-md-4 input {
    border: 1px solid #e382b0;
}
.div-gift-template-sixtyseven .div-gift-promo input {
    border: 1px solid #e382b0;
    color: #e382b0;
}
.div-gift-template-sixtyseven .div-limited-child .btn {
    color: #e382b0;
    border: 1px solid #e382b0;
}
.div-gift-template-sixtyseven .div-buy1-child, .div-gift-template-sixtyseven .div-buy2-child, .div-gift-template-sixtyseven .div-footer-description {
    color: #e382b0;
}
.div-gift-template-sixtyseven .div-limited-child input {
    color: #e382b0;
    border: 1px solid #e382b0;
    background-color: #fff;
}
.div-gift-template-sixtyseven .div-limited-child input {
    color: #e382b0;
}
.div-gift-template-sixtyseven .div-limited-child input::placeholder {
    color: #e382b0CC;
}
.div-gift-template-sixtyseven .div-limited-child .btn:hover, .div-gift-template-sixtyseven .div-limited-child .btn:focus {
    color: #fff;
    border: 1px solid #e382b0;
    background-color: #e382b0;
}

/* Template 68 */

.div-gift-template-sixtyeight {
    background-color: #DADCE0;
    border: 1px solid #DADCE0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
    overflow: hidden;
}
.div-gift-template-sixtyeight .col-7.div-twentynine-content {    
    min-height: 200px;    
    /* padding-left: 30px; */
}
.div-gift-template-sixtyeight .col-8 {
    min-height: 275px;
    background-color: #3c402a;
    z-index: 1;
    padding-top: 20px;
}
.div-gift-template-sixtyeight .col-8 div.div-re-points {
    width:100%;   
    padding-top: 60px;
}
.div-gift-template-sixtyeight span.item-points {
    font-size: 9px;    
}
.div-gift-template-sixtyeight span.item-points strong {
    font-size: 12px;
    font-weight: 700;
}
.div-gift-template-sixtyeight .col-7 {
    position: relative;
    overflow: hidden;
    min-height: 275px;
}
.div-gift-template-sixtyeight .col-4 div.semicircle-right {    
    position: absolute;
    top: 0; 
    bottom: 0;
    box-shadow: 0px 0px 0px 110px #3c402a;
    width: 100%
}
.div-gift-template-sixtyeight .col-4 div.semicircle-right-curve {   
    border-radius: 0 140% 60% 0 / 0 60% 100% 0;
}
.div-gift-template-sixtyeight .div-gift-template .col-8 .div-card-header span {
    color:#fff;
    position: absolute;
    bottom: 25px;
    width: 70%;
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
}
.div-gift-template-sixtyeight .div-gift-template .col-8 img {
    max-height: 22px;
}
.div-gift-template-sixtyeight span, .div-gift-template-sixtyeight label, .div-gift-template-sixtyeight p, .div-gift-template-sixtyeight i {
    color: #3c402a;
}
.div-gift-template-sixtyeight .div-card-list .btn-buy {
    color: #3c402a;
    border: 1px solid #3c402a;
}
.div-gift-template-sixtyeight .div-card-list .btn-buy:hover, .div-gift-template-sixtyeight .div-card-list .btn-buy:focus {
    color: #fff;
    border: 1px solid #3c402a;
    background-color: #3c402a;
}
.div-gift-template-sixtyeight .div-card-list .col-md-4 input {
    border: 1px solid #3c402a;
}
.div-gift-template-sixtyeight .div-gift-promo input {
    border: 1px solid #3c402a;
    color: #3c402a;
}
.div-gift-template-sixtyeight .div-limited-child .btn {
    color: #3c402a;
    border: 1px solid #3c402a;
}
.div-gift-template-sixtyeight .div-buy1-child, .div-gift-template-sixtyeight .div-buy2-child, .div-gift-template-sixtyeight .div-footer-description {
    color: #3c402a;
}
.div-gift-template-sixtyeight .div-limited-child input {
    color: #3c402a;
    border: 1px solid #3c402a;
    background-color: #fff;
}
.div-gift-template-sixtyeight .div-limited-child input {
    color: #3c402a;
}
.div-gift-template-sixtyeight .div-limited-child input::placeholder {
    color:#3c402aCC;
}
.div-gift-template-sixtyeight .div-limited-child .btn:hover, .div-gift-template-sixtyeight .div-limited-child .btn:focus {
    color: #fff;
    border: 1px solid #3c402a;
    background-color: #3c402a;
}

/* Gift Template 69 */
.div-gift-template-sixtynine {
    background-color: #DADCE0;
    border: 1px solid #DADCE0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
    overflow: hidden;
}

.div-gift-template-sixtynine .col-6 .div-left {
    padding-left: 30px;
    width: 100%;
    height: 0;    
    border-top: 275px solid #6d77ac;
    border-bottom-left-radius: 30% 100%;
}

.div-gift-template-sixtynine .div-gift-template .col-6 .div-logo {
    position: absolute;
    top: 30%;
    left: 50px;
}
.div-gift-template-sixtynine .div-gift-template .col-6 img {
    max-height: 25px;
}
.div-gift-template-sixtynine .div-gift-template .col-6 .div-card-header {
    position: absolute;
    bottom: 70px;
    width: 70%;
    text-align: center;
}
.div-gift-template-sixtynine .div-gift-template .col-6 .div-card-header span {
    color: #fff;
    text-transform: uppercase;
}
.div-gift-template-sixtynine span, .div-gift-template-sixtynine label, .div-gift-template-sixtynine p, .div-gift-template-sixtynine i {
    color: #6d77ac;
}
.div-gift-template-sixtynine .div-card-list .btn-buy {
    color: #6d77ac;
    border: 1px solid #6d77ac;
}
.div-gift-template-sixtynine .div-card-list .btn-buy:hover, .div-gift-template-sixtynine .div-card-list .btn-buy:focus {
    color: #fff;
    border: 1px solid #6d77ac;
    background-color: #6d77ac;
}
.div-gift-template-sixtynine .div-card-list .col-md-4 input {
    border: 1px solid #1e1e1e;
}
.div-gift-template-sixtynine .div-gift-promo input {
    border: 1px solid #6d77ac;
    color: #6d77ac;
}
.div-gift-template-sixtynine .div-limited-child .btn {
    color: #6d77ac;
    border: 1px solid #6d77ac;
}
.div-gift-template-sixtynine .div-buy1-child, .div-gift-template-sixtynine .div-buy2-child, .div-gift-template-sixtynine .div-footer-description {
    color: #6d77ac;
}
.div-gift-template-sixtynine .div-limited-child input {
    color: #6d77ac;
    border: 1px solid #6d77ac;
    background-color: #fff;
}
.div-gift-template-sixtynine .div-limited-child input {
    color: #6d77ac;
}
.div-gift-template-sixtynine .div-limited-child input::placeholder {
    color: #6d77acCC;
}
.div-gift-template-sixtynine .div-limited-child .btn:hover, .div-gift-template-sixtynine .div-limited-child .btn:focus {
    color: #fff;
    border: 1px solid #6d77ac;
    background-color: #6d77ac;
}

/* Gift Template 70 */

.div-gift-template-seventy {
    background-color: #DADCE0;
    border: 1px solid #DADCE0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
    overflow: hidden;
}
.div-gift-template-seventy .div-gift-template .div-template-header {
    background-color: #3d4045;
    padding: 10px 15px;
    text-align: center;
}
.div-gift-template-seventy .div-gift-template .div-template-header img {    
    max-height: 32px;
}
.div-gift-template-seventy .div-gift-template .div-template-footer {
    background-color: #3d4045;
    padding: 15px;
    width: 100%;
    position: absolute;
    bottom: 0px;
    text-align: center;
}
.div-gift-template-seventy .div-gift-template .div-template-footer span {
    letter-spacing: 1.2px;
    color: #fff;
    font-weight: 300;      
}
.div-gift-template-seventy span, .div-gift-template-seventy label, .div-gift-template-seventy p, .div-gift-template-seventy i {
    color: #1a3a24;
}
.div-gift-template-seventy .div-card-list .btn-buy {
    color: #1a3a24;
    border: 1px solid #3d4045;
}
.div-gift-template-seventy .div-card-list .btn-buy:hover, .div-gift-template-seventy .div-card-list .btn-buy:focus {
    color: #fff;
    border: 1px solid #3d4045;
    background-color: #3d4045;
}
.div-gift-template-seventy .div-card-list .col-md-4 input {
    border: 1px solid #3d4045;
}
.div-gift-template-seventy .div-gift-promo input {
    border: 1px solid #3d4045;
    color: #3d4045;
}
.div-gift-template-seventy .div-limited-child input{
    color: #3d4045;
}
.div-gift-template-seventy .div-limited-child input::placeholder {
    color: #3d4045CC;
}
.div-gift-template-seventy .div-limited-child .btn {
    color: #1a3a24;
    border: 1px solid #3d4045;
}
.div-gift-template-seventy .div-buy1-child, .div-gift-template-seventy .div-buy2-child, .div-gift-template-seventy .div-footer-description {
    color: #3d4045;
}
.div-gift-template-seventy .div-limited-child input {
    color: #3d4045;
    border: 1px solid #3d4045;
    background-color: #fff;
}
.div-gift-template-seventy .div-limited-child .btn:hover, .div-gift-template-seventy .div-limited-child .btn:focus {
    color: #fff;
    border: 1px solid #3d4045;
    background-color: #3d4045;
}

/* Gift Template 71 */

.div-gift-template-seventyone {
    background-color: #DADCE0;
    border: 1px solid #DADCE0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
    overflow: hidden;
}

.div-gift-template-seventyone .col-6 .div-left {
    padding-left: 30px;
    width: 100%;
    height: 0;    
    border-top: 275px solid #aca7a2;
    border-right: 30px solid transparent;
}

.div-gift-template-seventyone .div-gift-template .col-6 .div-logo {
    position: absolute;
    top: 15px;
    left: 30px;
}

.div-gift-template-seventyone .div-gift-template .col-6 img {
    max-height: 22px;
}
.div-gift-template-seventyone .div-gift-template .col-6 .div-card-header {
    position: absolute;
    bottom: 15px;
    width: 70%;
    text-align: center;
}
.div-gift-template-seventyone .div-gift-template .col-6 .div-card-header span {
    color: #fff !important;
}
.div-gift-template-seventyone span, .div-gift-template-seventyone label, .div-gift-template-seventyone p, .div-gift-template-seventyone i {
    color: #aca7a2;
}
.div-gift-template-seventyone .div-card-list .btn-buy {
    color: #aca7a2;
    border: 1px solid #aca7a2;
}
.div-gift-template-seventyone .div-card-list .btn-buy:hover, .div-gift-template-seventyone .div-card-list .btn-buy:focus {
    color: #fff;
    border: 1px solid #aca7a2;
    background-color: #aca7a2;
}
.div-gift-template-seventyone .div-card-list .col-md-4 input {
    border: 1px solid #aca7a2;
}
.div-gift-template-seventyone .div-gift-promo input {
    border: 1px solid #aca7a2;
    color: #aca7a2;
}
.div-gift-template-seventyone .div-limited-child .btn {
    color: #aca7a2;
    border: 1px solid #aca7a2;
}
.div-gift-template-seventyone .div-buy1-child, .div-gift-template-seventyone .div-buy2-child, .div-gift-template-seventyone .div-footer-description {
    color:#aca7a2;
}
.div-gift-template-seventyone .div-limited-child input {
    color:#aca7a2;
    border: 1px solid #aca7a2;
    background-color: #fff;
}
.div-gift-template-seventyone .div-limited-child input {
    color:#aca7a2;
}
.div-gift-template-seventyone .div-limited-child input::placeholder {
    color:#aca7a2CC;
}
.div-gift-template-seventyone .div-limited-child .btn:hover, .div-gift-template-seventyone .div-limited-child .btn:focus {
    color: #fff;
    border: 1px solid #aca7a2;
    background-color: #aca7a2;
}

/*Gift Template 72 */

.div-gift-template-seventytwo {
    background-color: #DADCE0;
    border: 1px solid #DADCE0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
    overflow: hidden;
}

.div-gift-template-seventytwo .div-template-child {
    background-color: #6b4332;
    margin: 0px;
    border-radius: 15px;
    min-height: 275px;
    text-align: center;
}

.div-gift-template-seventytwo .col-md-5 {
    background-color: #6b4332;
    opacity: 0.9;
    min-height: 275px;
    padding-top: 15px;
}
.div-gift-template-seventytwo .div-gift-template .div-logo {
    padding-top: 15px;
    padding-left: 30px;
}

.div-gift-template-seventytwo .div-gift-template img {
    max-height: 35px;
    width:80%;
}

.div-gift-template-seventytwo .div-card-header {
    margin-top: 160px;
    padding-left: 30px;
}

.div-gift-template-seventytwo .div-card-header span {
    color: #fff;
    text-transform: uppercase; 
}
.div-gift-template-seventytwo span, .div-gift-template-seventytwo label, .div-gift-template-seventytwo p, .div-gift-template-seventytwo i {
    color: #6b4332;
}
.div-gift-template-seventytwo .div-card-list .btn-buy {
    color: #6b4332;
    border: 1px solid #6b4332;
}
.div-gift-template-seventytwo .div-card-list .btn-buy:hover, .div-gift-template-seventytwo .div-card-list .btn-buy:focus {
    color: #fff;
    border: 1px solid #6b4332;
    background-color: #6b4332;
}
.div-gift-template-seventytwo .div-card-list .col-md-4 input {
    border: 1px solid #6b4332;
}
.div-gift-template-seventytwo .div-gift-promo input {
    border: 1px solid #6b4332;
    color: #6b4332;
}
.div-gift-template-seventytwo .div-limited-child .btn {
    color: #6b4332;
    border: 1px solid #6b4332;
}
.div-gift-template-seventytwo .div-buy1-child, .div-gift-template-seventytwo .div-buy2-child, .div-gift-template-seventytwo .div-footer-description {
    color: #6b4332;
}
.div-gift-template-seventytwo .div-limited-child input {
    color: #6b4332;
    border: 1px solid #6b4332;
    background-color: #fff;
}
.div-gift-template-seventytwo .div-limited-child input {
    color: #6b4332;
}
.div-gift-template-seventytwo .div-limited-child input::placeholder {
    color: #6b4332CC;
}
.div-gift-template-seventytwo .div-limited-child .btn:hover, .div-gift-template- .div-limited-child .btn:focus {
    color: #fff;
    border: 1px solid #6b4332;
    background-color: #6b4332;
}

/* Template 73 */

.div-gift-template-seventythree {
    background-color: #DADCE0;
    border: 1px solid #DADCE0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
    overflow: hidden;
}
.div-gift-template-seventythree .col-7.div-twentynine-content {    
    min-height: 275px;    
    /* padding-left: 30px; */
}
.div-gift-template-seventythree .col-8 {
    min-height: 275px;
    background-color: #24280c;
    z-index: 1;
    padding-top: 20px;
}
.div-gift-template-seventythree .col-8 div.div-re-points {
    width:100%;   
    padding-top: 60px;
}
.div-gift-template-seventythree span.item-points {
    font-size: 9px;    
}
.div-gift-template-seventythree span.item-points strong {
    font-size: 12px;
    font-weight: 700;
}
.div-gift-template-seventythree .col-7 {
    position: relative;
    overflow: hidden;
    min-height: 200px;
}
.div-gift-template-seventythree .col-4 div.semicircle-right {    
    position: absolute;
    top: 0; 
    bottom: 0;
    box-shadow: 0px 0px 0px 110px #24280c;
    width: 100%
}
.div-gift-template-seventythree .col-4 div.semicircle-right-curve {   
    border-radius: 0 140% 60% 0 / 0 60% 100% 0;
}
.div-gift-template-seventythree .div-gift-template .col-8 .div-card-header span {
    color:#fff;
    position: absolute;
    bottom: 25px;
    width: 70%;
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
}
.div-gift-template-seventythree .div-gift-template .col-8 img {
    max-height: 22px;
}
.div-gift-template-seventythree span, .div-gift-template-seventythree label, .div-gift-template-seventythree p, .div-gift-template-seventythree i {
    color: #24280c;
}
.div-gift-template-seventythree .div-card-list .btn-buy {
    color: #24280c;
    border: 1px solid #24280c;
}
.div-gift-template-seventythree .div-card-list .btn-buy:hover, .div-gift-template-seventythree .div-card-list .btn-buy:focus {
    color: #fff;
    border: 1px solid #24280c;
    background-color: #24280c;
}
.div-gift-template-seventyhree .div-card-list .col-md-4 input {
    border: 1px solid #24280c;
}
.div-gift-template-seventyhree .div-gift-promo input {
    border: 1px solid #24280c;
    color: #24280c;
}
.div-gift-template-seventyhree .div-limited-child .btn {
    color: #24280c;
    border: 1px solid #24280c;
}
.div-gift-template-seventythree .div-buy1-child, .div-gift-template-seventythree .div-buy2-child, .div-gift-template-seventythree .div-footer-description {
    color: #24280c;
}
.div-gift-template-seventythree .div-limited-child input {
    color: #24280c;
    border: 1px solid #24280c;
    background-color: #fff;
}
.div-gift-template-seventythree .div-limited-child input {
    color: #24280c;
}
.div-gift-template-seventythree .div-limited-child input::placeholder {
    color:#24280cCC;
}
.div-gift-template-seventythree .div-limited-child .btn:hover, .div-gift-template-seventythree .div-limited-child .btn:focus {
    color: #fff;
    border: 1px solid #24280c;
    background-color: #24280c;
}

/* Gift Template 74 */

.div-gift-template-seventyfour {
    background-color: #DADCE0;
    border: 1px solid #DADCE0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
    overflow: hidden;
}

/*Right pointing*/
.div-gift-template-seventyfour .col-6 div.half-circle {
    width: 100%;
    height: 275px;
    background-color: #292723;
    border-top-left-radius: 30% 50%;
    border-bottom-left-radius: 30% 50%;    
    border-bottom: 0;
}

.div-gift-template-seventyfour .div-gift-template .col-6 .div-logo {
    position: absolute;
    top: 15px;
    left: 80px;
}

.div-gift-template-seventyfour .div-gift-template .col-6 img {
    max-height: 22px;
}
.div-gift-template-seventyfour .div-gift-template .col-6 .div-card-header {
    position: absolute;
    bottom: 15px;
    width: 90%;
    text-align: center;
}
.div-gift-template-seventyfour .div-gift-template .col-6 .div-card-header span {
    color: #fff;
}
.div-gift-template-seventyfour span, .div-gift-template-seventyfour label, .div-gift-template-seventyfour p, .div-gift-template-seventyfour i {
    color: #292723;
}
.div-gift-template-seventyfour .div-card-list .btn-buy {
    color: #292723;
    border: 1px solid #292723;
}
.div-gift-template-seventyfour .div-card-list .btn-buy:hover, .div-gift-template-seventyfour .div-card-list .btn-buy:focus {
    color: #fff;
    border: 1px solid #292723;
    background-color: #292723;
}
.div-gift-template-seventyfour .div-card-list .col-md-4 input {
    border: 1px solid #292723;
}
.div-gift-template-seventyfour .div-gift-promo input {
    border: 1px solid #292723;
    color: #292723;
}
.div-gift-template-seventyfour .div-limited-child .btn {
    color: #292723;
    border: 1px solid #292723;
}
.div-gift-template-seventyfour .div-buy1-child, .div-gift-template-seventyfour .div-buy2-child, .div-gift-template-seventyfour .div-footer-description {
    color: #292723;
}
.div-gift-template-seventyfour .div-limited-child input {
    color: #292723;
    border: 1px solid #471409;
    background-color: #fff;
}
.div-gift-template-seventyfour .div-limited-child input {
    color: #292723;
}
.div-gift-template-seventyfour .div-limited-child input::placeholder {
    color: #292723CC;
}
.div-gift-template-seventyfour .div-limited-child .btn:hover, .div-gift-template-seventyfour .div-limited-child .btn:focus {
    color: #fff;
    border: 1px solid #292723;
    background-color: #292723;
}

/* Gift Template 75 */

.div-gift-template-seventyfive {
    background-color: #DADCE0;
    border: 1px solid #DADCE0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
    overflow: hidden;
}

/*Right pointing*/
.div-gift-template-seventyfive .col-6 div.half-circle {
    width: 100%;
    height: 275px;
    background-color: #3e4454;
    border-top-right-radius: 30% 50%;
    border-bottom-right-radius: 30% 50%;    
    border-bottom: 0;
}

.div-gift-template-seventyfive .div-gift-template .col-6 .div-logo {
    position: absolute;
    top: 15px;
    left: 80px;
}

.div-gift-template-seventyfive .div-gift-template .col-6 img {
    max-height: 22px;
}
.div-gift-template-seventyfive .div-gift-template .col-6 .div-card-header {
    position: absolute;
    bottom: 15px;
    width: 90%;
    text-align: center;
}
.div-gift-template-seventyfive .div-gift-template .col-6 .div-card-header span {
    color: #fff;
}
.div-gift-template-seventyfive span, .div-gift-template-seventyfive label, .div-gift-template-seventyfive p, .div-gift-template-seventyfive i {
    color: #3e4454;
}
.div-gift-template-seventyfive .div-card-list .btn-buy {
    color: #3e4454;
    border: 1px solid #3e4454;
}
.div-gift-template-seventyfive .div-card-list .btn-buy:hover, .div-gift-template-seventyfive .div-card-list .btn-buy:focus {
    color: #fff;
    border: 1px solid #3e4454;
    background-color: #3e4454;
}
.div-gift-template-seventyfive .div-card-list .col-md-4 input {
    border: 1px solid #3e4454;
}
.div-gift-template-seventyfive .div-gift-promo input {
    border: 1px solid #3e4454;
    color: #3e4454;
}
.div-gift-template-seventyfive .div-limited-child .btn {
    color: #3e4454;
    border: 1px solid #3e4454;
}
.div-gift-template-seventyfive .div-buy1-child, .div-gift-template-seventyfive .div-buy2-child, .div-gift-template-seventyfive .div-footer-description {
    color: #3e4454;
}
.div-gift-template-seventyfive .div-limited-child input {
    color: #3e4454;
    border: 1px solid #3e4454;
    background-color: #fff;
}
.div-gift-template-seventyfive .div-limited-child input {
    color: #3e4454;
}
.div-gift-template-seventyfive .div-limited-child input::placeholder {
    color: #3e4454CC;
}
.div-gift-template-seventyfive .div-limited-child .btn:hover, .div-gift-template-seventyfive .div-limited-child .btn:focus {
    color: #fff;
    border: 1px solid #3e4454;
    background-color: #3e4454;
}

/* Gift Template 76 */

.div-gift-template-seventysix {
    background-color: #DADCE0;
    border: 1px solid #DADCE0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
    overflow: hidden;
}
/*Right pointing*/
.div-gift-template-seventysix .col-7 .triangle-right {
    width: 0;
    height: 0;
    border-top: 7px solid transparent;
    border-left: 8px solid #d69065;
    border-bottom: 7px solid transparent;
    top: 125px;
    position: absolute;
}
.br-left {
    border-left: 8px solid #d69065;
}
.pdlft{
    padding-left: 0px !important;
    padding-right: 0px;
}
.div-gift-template-seventysix .div-gift-template .col-5 {
    background-color: #d69065;
    height:275px;
}

.div-gift-template-seventysix .div-gift-template .col-5 .div-logo {
    position: absolute;
    top: 15px;
    left: 30px;
}

.div-gift-template-seventysix .div-gift-template .col-5 img {
    max-height: 22px;
}
.div-gift-template-seventysix .div-gift-template .col-5 .div-card-header {
    position: absolute;
    bottom: 15px;
    width: 90%;
    text-align: center;
}
.div-gift-template-seventysix .div-gift-template .col-5 .div-card-header span {
    color: #fff;
}
.div-gift-template-seventysix span, .div-gift-template-seventysix label, .div-gift-template-seventysix p, .div-gift-template-seventysix i {
    color: #d69065;
}
.div-gift-template-seventysix .div-card-list .btn-buy {
    color: #d69065;
    border: 1px solid #d69065;
}
.div-gift-template-seventysix .div-card-list .btn-buy:hover, .div-gift-template-seventysix .div-card-list .btn-buy:focus {
    color: #fff;
    border: 1px solid #d69065;
    background-color: #d69065;
}
.div-gift-template-seventysix .div-card-list .col-md-4 input {
    border: 1px solid #d69065;
}
.div-gift-template-seventysix .div-gift-promo input {
    border: 1px solid #d69065;
    color: #d69065;
}
.div-gift-template-seventysix .div-limited-child .btn {
    color: #d69065;
    border: 1px solid #d69065;
}
.div-gift-template-seventysix .div-buy1-child, .div-gift-template-seventysix .div-buy2-child, .div-gift-template-seventysix .div-footer-description {
    color: #d69065;
}
.div-gift-template-seventysix .div-limited-child input {
    color: #d69065;
    border: 1px solid #d69065;
    background-color: #fff;
}
.div-gift-template-seventysix .div-limited-child input {
    color: #d69065;
}
.div-gift-template-seventysix .div-limited-child input::placeholder {
    color: #d69065CC;
}
.div-gift-template-seventysix .div-limited-child .btn:hover, .div-gift-template-seventysix .div-limited-child .btn:focus {
    color: #fff;
    border: 1px solid #d69065;
    background-color: #d69065;
}

/* Gift Template 77 */

.div-gift-template-seventyseven {
    background-color: #DADCE0;
    border: 1px solid #DADCE0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
    overflow: hidden;
}
/*Right pointing*/
.div-gift-template-seventyseven .col-7 .triangle-right {
    width: 0;
    height: 0;
    border-top: 7px solid transparent;
    border-right: 8px solid #ecc01e;
    border-bottom: 7px solid transparent;
    top: 120px;
    position:absolute;
    right:0px;
}
.div-gift-template-seventyseven .div-gift-template .col-5 {
    background-color: #ecc01e;
    height:275px;
}

.div-gift-template-seventyseven .div-gift-template .col-5 .div-logo {
    position: absolute;
    top: 15px;
    left: 30px;
}

.div-gift-template-seventyseven .div-gift-template .col-5 img {
    max-height: 22px;
}
.div-gift-template-seventyseven .div-gift-template .col-5 .div-card-header {
    position: absolute;
    bottom: 15px;
    width: 90%;
    text-align: center;
}
.div-gift-template-seventyseven .div-gift-template .col-5 .div-card-header span {
    color: #fff;
}
.div-gift-template-seventyseven span, .div-gift-template-seventyseven label, .div-gift-template-seventyseven p, .div-gift-template-seventyseven i {
    color: #ecc01e;
}
.div-gift-template-seventyseven .div-card-list .btn-buy {
    color: #ecc01e;
    border: 1px solid #ecc01e;
}
.div-gift-template-seventyseven .div-card-list .btn-buy:hover, .div-gift-template-seventyseven .div-card-list .btn-buy:focus {
    color: #fff;
    border: 1px solid #ecc01e;
    background-color: #ecc01e;
}
.div-gift-template-seventyseven .div-card-list .col-md-4 input {
    border: 1px solid #ecc01e;
}
.div-gift-template-seventyseven .div-gift-promo input {
    border: 1px solid #ecc01e;
    color: #ecc01e;
}
.div-gift-template-seventyseven .div-limited-child .btn {
    color: #ecc01e;
    border: 1px solid #ecc01e;
}
.div-gift-template-seventyseven .div-buy1-child, .div-gift-template-seventyseven .div-buy2-child, .div-gift-template-seventyseven .div-footer-description {
    color: #ecc01e;
}
.div-gift-template-seventyseven .div-limited-child input {
    color: #ecc01e;
    border: 1px solid #ecc01e;
    background-color: #fff;
}
.div-gift-template-seventyseven .div-limited-child input {
    color: #ecc01e;
}
.div-gift-template-seventyseven .div-limited-child input::placeholder {
    color: #ecc01eCC;
}
.div-gift-template-seventyseven .div-limited-child .btn:hover, .div-gift-template-seventyseven .div-limited-child .btn:focus {
    color: #fff;
    border: 1px solid #ecc01e;
    background-color: #ecc01e;
}

/* Gift Template 78 */

.div-gift-template-seventyeight {
    background-color: #DADCE0;
    border: 1px solid #DADCE0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
    overflow: hidden;
}

.div-gift-template-seventyeight .col-6 .div-left {
    padding-left: 30px;
    width: 100%;
    height: 0;    
    border-top: 275px solid #243d1f;
    border-bottom-right-radius: 30% 100%;
}

.div-gift-template-seventyeight .div-gift-template .col-6 .div-logo {
    position: absolute;
    top: 30%;
    left: 50px;
}

.div-gift-template-seventyeight .div-gift-template .col-6 img {
    max-height: 25px;
}
.div-gift-template-seventyeight .div-gift-template .col-6 .div-card-header {
    position: absolute;
    bottom: 70px;
    width: 70%;
    text-align: center;
}
.div-gift-template-seventyeight .div-gift-template .col-6 .div-card-header span {
    color: #fff;
    text-transform: uppercase;
}
.div-gift-template-seventyeight span, .div-gift-template-seventyeight label, .div-gift-template-seventyeight p, .div-gift-template-seventyeight i {
    color: #243d1f;
}
.div-gift-template-seventyeight .div-card-list .btn-buy {
    color: #243d1f;
    border: 1px solid #243d1f;
}
.div-gift-template-seventyeight .div-card-list .btn-buy:hover, .div-gift-template-seventyeight .div-card-list .btn-buy:focus {
    color: #fff;
    border: 1px solid #243d1f;
    background-color: #243d1f;
}
.div-gift-template-seventyeight .div-card-list .col-md-4 input {
    border: 1px solid #243d1f;
}
.div-gift-template-seventyeight .div-gift-promo input {
    border: 1px solid #243d1f;
    color: #243d1f;
}
.div-gift-template-seventyeight .div-limited-child .btn {
    color: #243d1f;
    border: 1px solid #243d1f;
}
.div-gift-template-seventyeight .div-buy1-child, .div-gift-template-seventyeight .div-buy2-child, .div-gift-template-seventyeight .div-footer-description {
    color: #243d1f;
}
.div-gift-template-seventyeight .div-limited-child input {
    color: #243d1f;
    border: 1px solid #243d1f;
    background-color: #fff;
}
.div-gift-template-seventyeight .div-limited-child input {
    color: #243d1f;
}
.div-gift-template-seventyeight .div-limited-child input::placeholder {
    color: #243d1fCC;
}
.div-gift-template-seventyeight .div-limited-child .btn:hover, .div-gift-template-seventyeight .div-limited-child .btn:focus {
    color: #fff;
    border: 1px solid #243d1f;
    background-color: #6d77ac;
}

/* Gift Template 79 */

.div-gift-template-seventynine {
    background-color: #DADCE0;
    border: 1px solid #DADCE0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
    overflow: hidden;
}

.div-gift-template-seventynine .col-6 .div-left {
    padding-left: 30px;
    width: 100%;
    height: 0;    
    border-top: 275px solid #980702;
    border-bottom-left-radius: 30% 100%;
}

.div-gift-template-seventynine .div-gift-template .col-6 .div-logo {
    position: absolute;
    top: 30%;
    left: 50px;
}

.div-gift-template-seventynine .div-gift-template .col-6 img {
    max-height: 25px;
}
.div-gift-template-seventynine .div-gift-template .col-6 .div-card-header {
    position: absolute;
    bottom: 70px;
    width: 70%;
    text-align: center;
}
.div-gift-template-seventynine .div-gift-template .col-6 .div-card-header span {
    color: #fff;
    text-transform: uppercase;
}
.div-gift-template-seventynine span, .div-gift-template-seventynine label, .div-gift-template-seventynine p, .div-gift-template-seventynine i {
    color: #980702;
}
.div-gift-template-seventynine .div-card-list .btn-buy {
    color: #980702;
    border: 1px solid #980702;
}
.div-gift-template-seventynine .div-card-list .btn-buy:hover, .div-gift-template-seventynine .div-card-list .btn-buy:focus {
    color: #fff;
    border: 1px solid #980702;
    background-color: #980702;
}
.div-gift-template-seventynine .div-card-list .col-md-4 input {
    border: 1px solid #980702;
}
.div-gift-template-seventynine .div-gift-promo input {
    border: 1px solid #980702;
    color: #980702;
}
.div-gift-template-seventynine .div-limited-child .btn {
    color: #980702;
    border: 1px solid #980702;
}
.div-gift-template-seventynine .div-buy1-child, .div-gift-template-seventynine .div-buy2-child, .div-gift-template-seventynine .div-footer-description {
    color: #980702;
}
.div-gift-template-seventynine .div-limited-child input {
    color: #980702;
    border: 1px solid #980702;
    background-color: #fff;
}
.div-gift-template-seventynine .div-limited-child input {
    color: #980702;
}
.div-gift-template-seventynine .div-limited-child input::placeholder {
    color: #980702CC;
}
.div-gift-template-seventynine .div-limited-child .btn:hover, .div-gift-template-seventynine .div-limited-child .btn:focus {
    color: #fff;
    border: 1px solid #980702;
    background-color: #980702;
}

/* Gift Template 80*/

.div-gift-template-eighty {
    background-color: #DADCE0;
    border: 1px solid #DADCE0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
    overflow: hidden;
}

.div-gift-template-eighty .div-gift-template .div-template-footer {
    background-color: #ffffff;
    padding: 15px;
    width: 100%;
    position: absolute;
    bottom: 0px;
    text-align: center;
}
.div-gift-template-eighty .div-gift-template .div-template-footer span {
    letter-spacing: 1.2px;
    color: #040606;
    font-weight: 300;      
}
.div-gift-template-eighty .div-gift-template .div-template-footer img {
    max-height: 22px;
}
.div-gift-template-eighty span, .div-gift-template-eighty label, .div-gift-template-eighty p, .div-gift-template-eighty i {
    color: #040606;
}
.div-gift-template-eighty .div-card-list .btn-buy {
    color: #040606;
    border: 1px solid #040606;
}
.div-gift-template-eighty .div-card-list .btn-buy:hover, .div-gift-template-eighty .div-card-list .btn-buy:focus {
    color: #fff;
    border: 1px solid #040606;
    background-color: #ffffff;
}
.div-gift-template-eighty .div-card-list .col-md-4 input {
    border: 1px solid #040606;
}
.div-gift-template-eighty .div-gift-promo input {
    border: 1px solid #040606;
    color: #040606;
}
.div-gift-template-eighty .div-limited-child .btn {
    color: #040606;
    border: 1px solid #040606;
}
.div-gift-template-eighty .div-buy1-child, .div-gift-template-eighty .div-buy2-child, .div-gift-template-eighty .div-footer-description {
    color: #040606;
}
.div-gift-template-eighty .div-limited-child input {
    color: #040606;
    border: 1px solid #040606;
    background-color: #fff;
}
.div-gift-template-eighty .div-limited-child input {
    color: #040606;
}
.div-gift-template-eighty .div-limited-child input::placeholder {
    color: #040606CC;
}
.div-gift-template-eighty .div-limited-child .btn:hover, .div-gift-template-eighty .div-limited-child .btn:focus {
    color: #fff;
    border: 1px solid #040606;
    background-color: #ffffff;
}

/* Gift Template 81*/

.div-gift-template-eightyone {
    background-color: #DADCE0;
    border: 1px solid #DADCE0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
    overflow: hidden;
}

.div-gift-template-eightyone .div-gift-template .div-template-footer {
    background-color: #a9934c;
    padding: 15px;
    width: 100%;
    position: absolute;
    top: 0px;
    text-align: center;
}
.div-gift-template-eightyone .div-gift-template .div-template-footer span {
    letter-spacing: 1.2px;
    color: #fff;
    font-weight: 300;      
}
.div-gift-template-eightyone .div-gift-template .div-template-footer img {
    max-height: 22px;
}
.div-gift-template-eightyone span, .div-gift-template-eightyone label, .div-gift-template-eightyone p, .div-gift-template-eightyone i {
    color: #a9934c;
}
.div-gift-template-eightyone .div-card-list .btn-buy {
    color: #a9934c;
    border: 1px solid #a9934c;
}
.div-gift-template-eightyone .div-card-list .btn-buy:hover, .div-gift-template-eightyone .div-card-list .btn-buy:focus {
    color: #fff;
    border: 1px solid #a9934c;
    background-color: #a9934c;
}
.div-gift-template-eightyone .div-card-list .col-md-4 input {
    border: 1px solid #a9934c;
}
.div-gift-template-eightyone .div-gift-promo input {
    border: 1px solid #a9934c;
    color: #a9934c;
}
.div-gift-template-eightyone .div-limited-child .btn {
    color: #a9934c;
    border: 1px solid #a9934c;
}
.div-gift-template-eightyone .div-buy1-child, .div-gift-template-eightyone .div-buy2-child, .div-gift-template-eightyone .div-footer-description {
    color: #a9934c;
}
.div-gift-template-eightyone .div-limited-child input {
    color: #a9934c;
    border: 1px solid #a9934c;
    background-color: #fff;
}
.div-gift-template-eightyone .div-limited-child input {
    color: #a9934c;
}
.div-gift-template-eightyone .div-limited-child input::placeholder {
    color: #a9934cCC;
}
.div-gift-template-eightyone .div-limited-child .btn:hover, .div-gift-template-eightyone .div-limited-child .btn:focus {
    color: #fff;
    border: 1px solid #a9934c;
    background-color: #a9934c;
}

/* Gift Template 82 */

.div-gift-template-eightytwo {
    background-color: #DADCE0;
    border: 1px solid #DADCE0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
    overflow: hidden;
}

.div-gift-template-eightytwo .col-8 .div-left {
    padding-left: 30px;
    width: 100%;
    height: 0;    
    border-top: 275px solid #17290e;
    border-right: 85px solid transparent;
}

.div-gift-template-eightytwo .div-gift-template .col-8 .div-logo {
    position: absolute;
    top: 30%;
    left: 60px;
}

.div-gift-template-eightytwo .div-gift-template .col-8 img {
    max-height: 25px;
}
.div-gift-template-eightytwo .div-gift-template .col-8 .div-card-header {
    position: absolute;
    bottom: 60px;
    width: 100%;
    left: 60px;
}
.div-gift-template-eightytwo .div-gift-template .col-8 .div-card-header span {
    color: #fff;
    text-transform: uppercase;
}
.div-gift-template-eightytwo span, .div-gift-template-eightytwo label, .div-gift-template-eightytwo p, .div-gift-template-eightytwo i {
    color: #17290e;
}
.div-gift-template-eightytwo .div-card-list .btn-buy {
    color: #17290e;
    border: 1px solid #17290e;
}
.div-gift-template-eightytwo .div-card-list .btn-buy:hover, .div-gift-template-eightytwo .div-card-list .btn-buy:focus {
    color: #fff;
    border: 1px solid #17290e;
    background-color: #17290e;
}
.div-gift-template-eightytwo .div-card-list .col-md-4 input {
    border: 1px solid #17290e;
}
.div-gift-template-eightytwo .div-gift-promo input {
    border: 1px solid #17290e;
    color: #17290e;
}
.div-gift-template-eightytwo .div-limited-child .btn {
    color: #17290e;
    border: 1px solid #17290e;
}
.div-gift-template-eightytwo .div-buy1-child, .div-gift-template-eightytwo .div-buy2-child, .div-gift-template-eightytwo .div-footer-description {
    color: #17290e;
}
.div-gift-template-eightytwo .div-limited-child input {
    color: #17290e;
    border: 1px solid #17290e;
    background-color: #fff;
}
.div-gift-template-eightytwo .div-limited-child input {
    color: #17290e;
}
.div-gift-template-eightytwo .div-limited-child input::placeholder {
    color: #17290eCC;
}
.div-gift-template-eightytwo .div-limited-child .btn:hover, .div-gift-template-eightytwo .div-limited-child .btn:focus {
    color: #fff;
    border: 1px solid #17290e;
    background-color: #17290e;
}

/* Gift Template 83 */

.div-gift-template-eightythree {
    background-color: #DADCE0;
    border: 1px solid #DADCE0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
    overflow: hidden;
}
.div-gift-template-eightythree .col-8 .div-left {
    padding-left: 30px;
    width: 100%;
    height: 0;    
    border-top: 275px solid #10131a;
    border-left: 85px solid transparent;
}

.div-gift-template-eightythree .div-gift-template .col-8 .div-logo {
    position: absolute;
    top: 30%;
    left: 140px;
}
.div-gift-template-eightythree .div-gift-template .col-8 img {
    max-height: 25px;
}
.div-gift-template-eightythree .div-gift-template .col-8 .div-card-header {
    position: absolute;
    bottom: 60px;
    width: 100%;
    text-align: center;
}
.div-gift-template-eightythree .div-gift-template .col-8 .div-card-header span {
    color: #fff;
    text-transform: uppercase;
}
.div-gift-template-eightythree span, .div-gift-template-eightythree label, .div-gift-template-eightythree p, .div-gift-template-eightythree i {
    color: #10131a;
}
.div-gift-template-eightythree .div-card-list .btn-buy {
    color: #10131a;
    border: 1px solid #10131a;
}
.div-gift-template-eightythree .div-card-list .btn-buy:hover, .div-gift-template-eightythree .div-card-list .btn-buy:focus {
    color: #fff;
    border: 1px solid #10131a;
    background-color: #10131a;
}
.div-gift-template-eightythree .div-card-list .col-md-4 input {
    border: 1px solid #10131a;
}
.div-gift-template-eightythree .div-gift-promo input {
    border: 1px solid #10131a;
    color: #10131a;
}
.div-gift-template-eightythree .div-limited-child .btn {
    color: #10131a;
    border: 1px solid #10131a;
}
.div-gift-template-eightythree .div-buy1-child, .div-gift-template-eightythree .div-buy2-child, .div-gift-template-eightythree .div-footer-description {
    color: #10131a;
}
.div-gift-template-eightythree .div-limited-child input {
    color: #10131a;
    border: 1px solid #10131a;
    background-color: #fff;
}
.div-gift-template-eightythree .div-limited-child input {
    color: #10131a;
}
.div-gift-template-eightythree .div-limited-child input::placeholder {
    color: #10131aCC;
}
.div-gift-template-eightythree .div-limited-child .btn:hover, .div-gift-template-eightythree .div-limited-child .btn:focus {
    color: #fff;
    border: 1px solid #10131a;
    background-color: #10131a;
}

/* Template 84 */

.div-gift-template-eightyfour {
    background-color: #DADCE0;
    border: 1px solid #DADCE0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
    overflow: hidden;
}
.div-gift-template-eightyfour .div-circle {
    width: 250px;
    height: 275px;
    background-color: #e4b945;
    border-radius: 50%;
    position: absolute;
    top: -150px;
    left: 20px;
}
.div-gift-template-eightyfour img{
    height:25px;
}
.div-gift-template-eightyfour .div-logo {
    position: absolute;
    top: 25px;
    width: 60%;
    text-align: center;
}
.div-gift-template-eightyfour .div-card-header {
    position: absolute;
    top: 55px;
    width: 60%;
    text-align: center;    
}
.div-gift-template-eightyfour .div-card-header span {
    color: #fff;    
}
.div-gift-template-eightyfour span, .div-gift-template-eightyfour label, .div-gift-template-eightyfour p, .div-gift-template-eightyfour i {
    color: #e4b945;
}
.div-gift-template-eightyfour .div-card-list .btn-buy {
    color: #e4b945;
    border: 1px solid #e4b945;
}
.div-gift-template-eightyfour .div-card-list .btn-buy:hover, .div-gift-template-eightyfour .div-card-list .btn-buy:focus {
    color: #fff;
    border: 1px solid #e4b945;
    background-color: #e4b945;
}
.div-gift-template-eightyfour .div-card-list .col-md-4 input {
    border: 1px solid #e4b945;
}
.div-gift-template-eightyfour .div-gift-promo input {
    border: 1px solid #e4b945;
    color: #e4b945;
}
.div-gift-template-eightyfour .div-limited-child .btn {
    color: #e4b945;
    border: 1px solid #e4b945;
}
.div-gift-template-eightyfour .div-buy1-child, .div-gift-template-eightyfour .div-buy2-child, .div-gift-template-eightyfour .div-footer-description {
    color: #e4b945;
}
.div-gift-template-eightyfour .div-limited-child input {
    color: #e4b945;
    border: 1px solid #e4b945;
    background-color: #fff;
}
.div-gift-template-eightyfour .div-limited-child input {
    color: #e4b945;
}
.div-gift-template-eightyfour .div-limited-child input::placeholder {
    color: #e4b945CC;
}
.div-gift-template-eightyfour .div-limited-child .btn:hover, .div-gift-template-eightyfour .div-limited-child .btn:focus {
    color: #fff;
    border: 1px solid #e4b945;
    background-color: #e4b945;
}

/* Template 85 */

.div-gift-template-eightyfive {
    background-color: #DADCE0;
    border: 1px solid #DADCE0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
    overflow: hidden;
}
.div-gift-template-eightyfive .div-circle {
    width: 220px;
    height: 275px;
    background-color: #e5c0c6;
    border-radius: 50%;
    position: absolute;
    bottom : -150px;
    left: 20px;
}
.div-gift-template-eightyfive img{
    height:25px;
}
.div-gift-template-eightyfive .div-logo {
    position: absolute;
    top: 185px;
    width: 55%;
    text-align: center;
}
.div-gift-template-eightyfive .div-card-header {
    position: absolute;
    top: 225px;
    width: 55%;
    text-align: center;    
}
.div-gift-template-eightyfive .div-card-header span {
    color: #fff;    
}
.div-gift-template-eightyfive span, .div-gift-template-eightyfive label, .div-gift-template-eightyfive p, .div-gift-template-eightyfive i {
    color: #181e17;
}
.div-gift-template-eightyfive .div-card-list .btn-buy {
    color: #181e17;
    border: 1px solid #181e17;
}
.div-gift-template-eightyfive .div-card-list .btn-buy:hover, .div-gift-template-eightyfive .div-card-list .btn-buy:focus {
    color: #fff;
    border: 1px solid #181e17;
    background-color: #e5c0c6;
}
.div-gift-template-eightyfive .div-card-list .col-md-4 input {
    border: 1px solid #181e17;
}
.div-gift-template-eightyfive .div-gift-promo input {
    border: 1px solid #181e17;
    color: #181e17;
}
.div-gift-template-eightyfive .div-limited-child .btn {
    color: #181e17;
    border: 1px solid #181e17;
}
.div-gift-template-eightyfive .div-buy1-child, .div-gift-template-eightyfive .div-buy2-child, .div-gift-template-eightyfive .div-footer-description {
    color: #181e17;
}
.div-gift-template-eightyfive .div-limited-child input {
    color: #181e17;
    border: 1px solid #181e17;
    background-color: #fff;
}
.div-gift-template-eightyfive .div-limited-child input {
    color: #181e17;
}
.div-gift-template-eightyfive .div-limited-child input::placeholder {
    color: #181e17CC;
}
.div-gift-template-eightyfive .div-limited-child .btn:hover, .div-gift-template-eightyfive .div-limited-child .btn:focus {
    color: #fff;
    border: 1px solid #181e17;
    background-color: #e5c0c6;
}

/* Gift Template 86 */

.div-gift-template-eightysix {
    background-color: #DADCE0;
    border: 1px solid #DADCE0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
    overflow: hidden;
}
/*Right pointing*/
.div-gift-template-eightysix .col-5 div.triangle-right {
    width: 100%;
    height: 0;
    border-top: 137px solid #4a1802;
    border-right: 50px solid transparent;
    border-bottom: 138px solid #4a1802;
}
.div-gift-template-eightysix .div-gift-template .col-5 .div-logo {
    position: absolute;
    top: 15px;
    left: 30px;
}

.div-gift-template-eightysix .div-gift-template .col-5 img {
    max-height: 22px;
}
.div-gift-template-eightysix .div-gift-template .col-5 .div-card-header {
    position: absolute;
    bottom: 15px;
    left: 30px;
}
.div-gift-template-eightysix .div-gift-template .col-5 .div-card-header span {
    color: #fff;
}
.div-gift-template-eightysix span, .div-gift-template-eightysix label, .div-gift-template-eightysix p, .div-gift-template-eightysix i {
    color: #4a1802;
}
.div-gift-template-eightysix .div-card-list .btn-buy {
    color: #4a1802;
    border: 1px solid #4a1802;
}
.div-gift-template-eightysix .div-card-list .btn-buy:hover, .div-gift-template-eightysix .div-card-list .btn-buy:focus {
    color: #fff;
    border: 1px solid #4a1802;
    background-color: #4a1802;
}
.div-gift-template-eightysix .div-card-list .col-md-4 input {
    border: 1px solid #4a1802;
}
.div-gift-template-eightysix .div-gift-promo input {
    border: 1px solid #4a1802;
    color: #4a1802;
}
.div-gift-template-eightysix .div-limited-child .btn {
    color: #4a1802;
    border: 1px solid #4a1802;
}
.div-gift-template-eightysix .div-buy1-child, .div-gift-template-eightysix .div-buy2-child, .div-gift-template-eightysix .div-footer-description {
    color: #4a1802;
}
.div-gift-template-eightysix .div-limited-child input {
    color: #4a1802;
    border: 1px solid #4a1802;
    background-color: #fff;
}
.div-gift-template-eightysix .div-limited-child input {
    color: #4a1802;
}
.div-gift-template-eightysix .div-limited-child input::placeholder {
    color: #4a1802CC;
}
.div-gift-template-eightysix .div-limited-child .btn:hover, .div-gift-template-eightysix .div-limited-child .btn:focus {
    color: #fff;
    border: 1px solid #4a1802;
    background-color: #4a1802;
}

/* Gift Template 87 */

.div-gift-template-eightyseven {
    background-color: #DADCE0;
    border: 1px solid #DADCE0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
    overflow: hidden;
}

/*Right pointing*/
.div-gift-template-eightyseven .col-6 div.triangle-right {
    width: 100%;
    height: 0;
    border-top: 137px solid #000000;
    border-left: 36px solid transparent;
    border-bottom: 138px solid #000000;
}
.div-gift-template-eightyseven .div-gift-template .col-6 .div-logo {
    position: absolute;
    top: 15px;
    left: 50px;
}

.div-gift-template-eightyseven .div-gift-template .col-6 img {
    max-height: 22px;
}
.div-gift-template-eightyseven .div-gift-template .col-6 .div-card-header {
    position: absolute;
    bottom: 15px;
    left: 50px;
}
.div-gift-template-eightyseven .div-gift-template .col-6 .div-card-header span {
    color: #fff;
}
.div-gift-template-eightyseven span, .div-gift-template-eightyseven label, .div-gift-template-eightyseven p, .div-gift-template-eightyseven i {
    color: #000000;
}
.div-gift-template-eightyseven .div-card-list .btn-buy {
    color: #000000;
    border: 1px solid #000000;
}
.div-gift-template-eightyseven .div-card-list .btn-buy:hover, .div-gift-template-eightyseven .div-card-list .btn-buy:focus {
    color: #fff;
    border: 1px solid #000000;
    background-color: #000000;
}
.div-gift-template-eightyseven .div-card-list .col-md-4 input {
    border: 1px solid #000000;
}
.div-gift-template-eightyseven .div-gift-promo input {
    border: 1px solid #000000;
    color: #000000;
}
.div-gift-template-eightyseven .div-limited-child .btn {
    color: #000000;
    border: 1px solid #000000;
}
.div-gift-template-eightyseven .div-buy1-child, .div-gift-template-eightyseven .div-buy2-child, .div-gift-template-eightyseven .div-footer-description {
    color: #000000;
}
.div-gift-template-eightyseven .div-limited-child input {
    color: #000000;
    border: 1px solid #000000;
    background-color: #fff;
}
.div-gift-template-eightyseven .div-limited-child input {
    color: #000000;
}
.div-gift-template-eightyseven .div-limited-child input::placeholder {
    color: #000000CC;
}
.div-gift-template-eightyseven .div-limited-child .btn:hover, .div-gift-template-eightyseven .div-limited-child .btn:focus {
    color: #fff;
    border: 1px solid #000000;
    background-color: #000000;
}

/* Template 88 */

.div-gift-template-eightyeight {
    background-color: #DADCE0;
    border: 1px solid #DADCE0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
    overflow: hidden;
}
.div-gift-template-eightyeight .col-7.div-twentynine-content {    
    min-height: 275px;    
    /* padding-left: 30px; */
}
.div-gift-template-eightyeight .col-8 {
    min-height: 275px;
    background-color: #000000;
    z-index: 1;
    padding-top: 20px;
}
.div-gift-template-eightyeight .col-8 div.div-re-points {
    width:100%;   
    padding-top: 60px;
}
.div-gift-template-eightyeight span.item-points {
    font-size: 9px;    
}
.div-gift-template-eightyeight span.item-points strong {
    font-size: 12px;
    font-weight: 700;
}
.div-gift-template-eightyeight .col-7 {
    position: relative;
    overflow: hidden;
    min-height: 200px;
}
.div-gift-template-eightyeight .col-4 div.semicircle-right {    
    position: absolute;
    top: 0; 
    bottom: 0;
    box-shadow: 0px 0px 0px 110px #000000;
    width: 100%
}
.div-gift-template-eightyeight .col-4 div.semicircle-right-curve {   
    border-radius: 0 140% 60% 0 / 0 60% 100% 0;
}
.div-gift-template-eightyeight .div-gift-template .col-8 .div-card-header span {
    color:#fff;
    position: absolute;
    bottom: 25px;
    width: 70%;
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
}
.div-gift-template-eightyeight .div-gift-template .col-8 img {
    max-height: 22px;
}
.div-gift-template-eightyeight span, .div-gift-template-eightyeight label, .div-gift-template-eightyeight p, .div-gift-template-eightyeight i {
    color: #9f6128;
}
.div-gift-template-eightyeight .div-card-list .btn-buy {
    color: #9f6128;
    border: 1px solid #9f6128;
}
.div-gift-template-eightyeight .div-card-list .btn-buy:hover, .div-gift-template-eightyeight .div-card-list .btn-buy:focus {
    color: #fff;
    border: 1px solid #9f6128;
    background-color: #000000;
}
.div-gift-template-eightyeight .div-card-list .col-md-4 input {
    border: 1px solid #9f6128;
}
.div-gift-template-eightyeight .div-gift-promo input {
    border: 1px solid #9f6128;
    color: #9f6128;
}
.div-gift-template-eightyeight .div-limited-child .btn {
    color: #9f6128;
    border: 1px solid #9f6128;
}
.div-gift-template-eightyeight .div-buy1-child, .div-gift-template-eightyeight .div-buy2-child, .div-gift-template-eightyeight .div-footer-description {
    color: #9f6128;
}
.div-gift-template-eightyeight .div-limited-child input {
    color: #9f6128;
    border: 1px solid #9f6128;
    background-color: #fff;
}
.div-gift-template-eightyeight .div-limited-child input {
    color: #9f6128;
}
.div-gift-template-eightyeight .div-limited-child input::placeholder {
    color:#9f6128CC;
}
.div-gift-template-eightyeight .div-limited-child .btn:hover, .div-gift-template-eightyeight .div-limited-child .btn:focus {
    color: #fff;
    border: 1px solid #9f6128;
    background-color: #000000;
}

/* Gift Template 89 */

.div-gift-template-eightynine {
    background-color: #DADCE0;
    border: 1px solid #DADCE0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
    overflow: hidden;
}
.div-gift-template-eightynine .col-7.div-eightynine-content {    
    min-height: 275px;    
    padding-left: 30px;
}
.div-gift-template-eightynine .col-8 {
    min-height: 275px;
    background-color: #000000;
    z-index: 1;
    padding-top: 20px;
}
.div-gift-template-eightynine .col-8 div.div-re-points {
    width:100%;   
    padding-top: 60px;
}
.div-gift-template-eightynine span.item-points {
    font-size: 9px;    
}
.div-gift-template-eightynine span.item-points strong {
    font-size: 12px;
    font-weight: 700;
}
.div-gift-template-eightynine .col-7 {
    position: relative;
    overflow: hidden;
    min-height: 200px;
}
.div-gift-template-eightynine .col-4 div.semicircle-left {    
    position: absolute;
    top: 0; 
    bottom: 0;
    box-shadow: 0px 0px 0px 110px #000000;
    width: 100%
}
.div-gift-template-eightynine .col-4 div.semicircle-left-curve {   
    border-radius: 100% 0 0 25% / 50% 0  0 50%;
}
.div-gift-template-eightynine .div-gift-template .col-8 .div-card-header span {
    color:#fff;
    position: absolute;
    bottom: 25px;
    width: 70%;
    text-align: center;
    text-transform: uppercase;
}
.div-gift-template-eightynine span, .div-gift-template-eightynine label, .div-gift-template-eightynine p, .div-gift-template-eightynine i {
    color: #ec6e64;
}
.div-gift-template-eightynine .div-card-list .btn-buy {
    color: #ec6e64;
    border: 1px solid #ec6e64;
}
.div-gift-template-eightynine .div-card-list .btn-buy:hover, .div-gift-template-eightynine .div-card-list .btn-buy:focus {
    color: #fff;
    border: 1px solid #ec6e64;
    background-color: #000000;
}
.div-gift-template-eightynine .div-card-list .col-md-4 input {
    border: 1px solid #ec6e64;
}
.div-gift-template-eightynine .div-gift-promo input {
    border: 1px solid #ec6e64;
    color: #ec6e64;
}
.div-gift-template-eightynine .div-limited-child .btn {
    color: #ec6e64;
    border: 1px solid #ec6e64;
}
.div-gift-template-eightynine .div-buy1-child, .div-gift-template-eightynine .div-buy2-child, .div-gift-template-eightynine .div-footer-description {
    color:#bd905d;
}
.div-gift-template-eightynine .div-limited-child input {
    color: #ec6e64;
    border: 1px solid #ec6e64;
    background-color: #fff;
}
.div-gift-template-eightynine .div-limited-child input {
    color:#ec6e64;
}
.div-gift-template-eightynine .div-limited-child input::placeholder {
    color:#ec6e64CC;
}
.div-gift-template-eightynine .div-limited-child .btn:hover, .div-gift-template-eightynine .div-limited-child .btn:focus {
    color: #fff;
    border: 1px solid #ec6e64;
    background-color: #000000;
}

/* Template 90 */

.div-gift-template-ninety {
    background-color: #DADCE0;
    border: 1px solid #DADCE0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
    overflow: hidden;
}
.div-gift-template-ninety .div-circle {
    width: 180px;
    height: 180px;
    background-color: #852539;
    top: 20%;
    left: 5%;
    border-radius: 50%;
    position: absolute;
}
.div-gift-template-ninety img{
    height:25px;
}
.div-gift-template-ninety .div-logo {
    position: absolute;
    top: 90px;
    width: 48%;
    text-align: center;
}
.div-gift-template-ninety .div-card-header {
    position: absolute;
    top: 140px;
    width: 48%;
    text-align: center;    
}
.div-gift-template-ninety .div-card-header span {
    color: #fff;    
}
.div-gift-template-ninety span, .div-gift-template-ninety label, .div-gift-template-ninety p, .div-gift-template-ninety i {
    color: #852539;
}
.div-gift-template-ninety .div-card-list .btn-buy {
    color: #852539;
    border: 1px solid #852539;
}
.div-gift-template-ninety .div-card-list .btn-buy:hover, .div-gift-template-ninety .div-card-list .btn-buy:focus {
    color: #fff;
    border: 1px solid #852539;
    background-color: #852539;
}
.div-gift-template-ninety .div-card-list .col-md-4 input {
    border: 1px solid #852539;
}
.div-gift-template-ninety .div-gift-promo input {
    border: 1px solid #852539;
    color: #852539;
}
.div-gift-template-ninety .div-limited-child .btn {
    color: #852539;
    border: 1px solid #852539;
}
.div-gift-template-ninety .div-buy1-child, .div-gift-template-ninety .div-buy2-child, .div-gift-template-ninety .div-footer-description {
    color: #852539;
}
.div-gift-template-ninety .div-limited-child input {
    color: #852539;
    border: 1px solid #852539;
    background-color: #fff;
}
.div-gift-template-ninety .div-limited-child input {
    color: #852539;
}
.div-gift-template-ninety .div-limited-child input::placeholder {
    color: #852539CC;
}
.div-gift-template-ninety .div-limited-child .btn:hover, .div-gift-template-ninety .div-limited-child .btn:focus {
    color: #fff;
    border: 1px solid #852539;
    background-color: #852539;
}

/* Template 91 */

.div-gift-template-ninetyone {
    background-color: #DADCE0;
    border: 1px solid #DADCE0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
    overflow: hidden;
}
.div-gift-template-ninetyone .div-circle {
    width: 180px;
    height: 180px;
    background-color: #000000;
    top: 20%;
    right: 5%;
    border-radius: 50%;
    position: absolute;
}
.div-gift-template-ninetyone img{
    height:25px;
}
.div-gift-template-ninetyone .div-logo {
    position: absolute;
    top: 100px;
    width: 47%;
    text-align: center;
    right: 10px;
}
.div-gift-template-ninetyone .div-card-header {
    position: absolute;
    top: 140px;
    width: 50%;
    text-align: center;   
    right: 0px; 
}
.div-gift-template-ninetyone .div-card-header span {
    color: #fff;    
}
.div-gift-template-ninetyone span, .div-gift-template-ninetyone label, .div-gift-template-ninetyone p, .div-gift-template-ninety i {
    color: #15844c;
}
.div-gift-template-ninetyone .div-card-list .btn-buy {
    color: #15844c;
    border: 1px solid #dee0df;
}
.div-gift-template-ninetyone .div-card-list .btn-buy:hover, .div-gift-template-ninetyone .div-card-list .btn-buy:focus {
    color: #fff;
    border: 1px solid #15844c;
    background-color: #000000;
}
.div-gift-template-ninetyone .div-card-list .col-md-4 input {
    border: 1px solid #15844c;
}
.div-gift-template-ninetyone .div-gift-promo input {
    border: 1px solid #15844c;
    color: #15844c;
}
.div-gift-template-ninetyone .div-limited-child .btn {
    color: #15844c;
    border: 1px solid #15844c;
}
.div-gift-template-ninetyone .div-buy1-child, .div-gift-template-ninetyone .div-buy2-child, .div-gift-template-ninetyone .div-footer-description {
    color: #15844c;
}
.div-gift-template-ninetyone .div-limited-child input {
    color: #15844c;
    border: 1px solid #15844c;
    background-color: #fff;
}
.div-gift-template-ninetyone .div-limited-child input {
    color: #15844c;
}
.div-gift-template-ninetyone .div-limited-child input::placeholder {
    color: #15844cCC;
}
.div-gift-template-ninetyone .div-limited-child .btn:hover, .div-gift-template-ninetyone .div-limited-child .btn:focus {
    color: #fff;
    border: 1px solid #15844c;
    background-color: #000000;
}

/*Gift Template 92 */

.div-gift-template-ninetytwo {
    background-color: #DADCE0;
    border: 1px solid #DADCE0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
    overflow: hidden;
}

.div-gift-template-ninetytwo .div-template-child {
    background-color: #152437;
    margin: 0px;
    border-radius: 15px;
    min-height: 275px;
    text-align: center;
}

.div-gift-template-ninetytwo .col-md-5 {
    background-color: #152437;
    opacity: 0.9;
    min-height: 275px;
    padding-top: 15px;
}
.div-gift-template-ninetytwo .div-gift-template .div-logo {
    padding-top: 15px;
    padding-left: 30px;
}

.div-gift-template-ninetytwo .div-gift-template img {
    max-height: 35px;
    width:80%;
}

.div-gift-template-ninetytwo .div-card-header {
    margin-top: 150px;
    padding-left: 30px;
}

.div-gift-template-ninetytwo .div-card-header span {
    color: #fff;
    text-transform: uppercase; 
}
.div-gift-template-ninetytwo span, .div-gift-template-ninetytwo label, .div-gift-template-ninetytwo p, .div-gift-template-ninetytwo i {
    color: #152437;
}
.div-gift-template-ninetytwo .div-card-list .btn-buy {
    color: #152437;
    border: 1px solid #152437;
}
.div-gift-template-ninetytwo .div-card-list .btn-buy:hover, .div-gift-template-ninetytwo .div-card-list .btn-buy:focus {
    color: #fff;
    border: 1px solid #152437;
    background-color: #152437;
}
.div-gift-template-ninetytwo .div-card-list .col-md-4 input {
    border: 1px solid #152437;
}
.div-gift-template-ninetytwo .div-gift-promo input {
    border: 1px solid #152437;
    color: #152437;
}
.div-gift-template-ninetytwo .div-limited-child .btn {
    color: #152437;
    border: 1px solid #152437;
}
.div-gift-template-ninetytwo .div-buy1-child, .div-gift-template-ninetytwo .div-buy2-child, .div-gift-template-ninetytwo .div-footer-description {
    color: #152437;
}
.div-gift-template-ninetytwo .div-limited-child input {
    color: #152437;
    border: 1px solid #152437;
    background-color: #fff;
}
.div-gift-template-ninetytwo .div-limited-child input {
    color: #152437;
}
.div-gift-template-ninetytwo .div-limited-child input::placeholder {
    color: #152437CC;
}
.div-gift-template-ninetytwo .div-limited-child .btn:hover, .div-gift-template-ninetytwo .div-limited-child .btn:focus {
    color: #fff;
    border: 1px solid #152437;
    background-color: #152437;
}

/*Gift Template 93 */

.div-gift-template-ninetythree {
    background-color: #DADCE0;
    border: 1px solid #DADCE0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
    overflow: hidden;
}
.div-gift-template-ninetythree .div-template-child {
    background-color: #111316;
    margin: 0px;
    border-radius: 15px;
    min-height: 275px;
    text-align: center;
}
.div-gift-template-ninetythree .col-md-5 {
    background-color: #111316;
    opacity: 0.9;
    min-height: 275px;
    padding-top: 15px;
}
.div-gift-template-ninetythree .div-gift-template .div-logo {
    padding-top: 15px;
    padding-left: 45px;
}
.div-gift-template-ninetythree .div-gift-template img {
    max-height: 35px;
    width:80%;
}
.div-gift-template-ninetythree .div-card-header {
    margin-top: 150px;
    padding-left: 45px;
}
.div-gift-template-ninetythree .div-card-header span {
    color: #fff;
    text-transform: uppercase; 
}
.div-gift-template-ninetythree span, .div-gift-template-ninetythree label, .div-gift-template-ninetythree p, .div-gift-template-ninetythree i {
    color: #152437;
}
.div-gift-template-ninetythree .div-card-list .btn-buy {
    color: #111316;
    border: 1px solid #111316;
}
.div-gift-template-ninetythree .div-card-list .btn-buy:hover, .div-gift-template-ninetythree .div-card-list .btn-buy:focus {
    color: #fff;
    border: 1px solid #111316;
    background-color: #111316;
}
.div-gift-template-ninetythree .div-card-list .col-md-4 input {
    border: 1px solid #111316;
}
.div-gift-template-ninetythree .div-gift-promo input {
    border: 1px solid #111316;
    color: #111316;
}
.div-gift-template-ninetythree .div-limited-child .btn {
    color: #111316;
    border: 1px solid #111316;
}
.div-gift-template-ninetythree .div-buy1-child, .div-gift-template-ninetythree .div-buy2-child, .div-gift-template-ninetythree .div-footer-description {
    color: #111316;
}
.div-gift-template-ninetythree .div-limited-child input {
    color: #111316;
    border: 1px solid #111316;
    background-color: #fff;
}
.div-gift-template-ninetythree .div-limited-child input {
    color: #111316;
}
.div-gift-template-ninetythree .div-limited-child input::placeholder {
    color: #111316CC;
}
.div-gift-template-ninetythree .div-limited-child .btn:hover, .div-gift-template-ninetythree .div-limited-child .btn:focus {
    color: #fff;
    border: 1px solid #111316;
    background-color: #111316;
}

/* Template 94 */

.div-gift-template-ninetyfour {
    background-color: #DADCE0;
    border: 1px solid #DADCE0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
    overflow: hidden;
}
.div-gift-template-ninetyfour .div-circle {
    width: 135px;
    height: 135px;
    background-color: #8a8681;
    transform: rotate(45deg);
    top: 25%;
    right: 12%;
    position: absolute;
}
.div-gift-template-ninetyfour img{
    height:25px;
}
.div-gift-template-ninetyfour .div-logo {
    position: absolute;
    top: 100px;
    width: 50%;
    text-align: center;
    right: 10px;
}
.div-gift-template-ninetyfour .div-card-header {
    position: absolute;
    top: 140px;
    width: 55%;
    text-align: center;   
    right: 0px; 
}
.div-gift-template-ninetyfour .div-card-header span {
    color: #fff;    
}
.div-gift-template-ninetyfour span, .div-gift-template-ninetyfour label, .div-gift-template-ninetyfour p, .div-gift-template-ninetyfour i {
    color: #8a8681;
}
.div-gift-template-ninetyfour .div-card-list .btn-buy {
    color: #8a8681;
    border: 1px solid #8a8681;
}
.div-gift-template-ninetyfour .div-card-list .btn-buy:hover, .div-gift-template-ninetyfour .div-card-list .btn-buy:focus {
    color: #fff;
    border: 1px solid #8a8681;
    background-color: #8a8681;
}
.div-gift-template-ninetyfour .div-card-list .col-md-4 input {
    border: 1px solid #b4b0ab;
}
.div-gift-template-ninetyfour .div-gift-promo input {
    border: 1px solid #8a8681;
    color: #8a8681;
}
.div-gift-template-ninetyfour .div-limited-child .btn {
    color: #8a8681;
    border: 1px solid #8a8681;
}
.div-gift-template-ninetyfour .div-buy1-child, .div-gift-template-ninetyfour .div-buy2-child, .div-gift-template-ninetyfour .div-footer-description {
    color: #8a8681;
}
.div-gift-template-ninetyfour .div-limited-child input {
    color: #8a8681;
    border: 1px solid #8a8681;
    background-color: #fff;
}
.div-gift-template-ninetyfour .div-limited-child input {
    color: #8a8681;
}
.div-gift-template-ninetyfour .div-limited-child input::placeholder {
    color: #8a8681CC;
}
.div-gift-template-ninetyfour .div-limited-child .btn:hover, .div-gift-template-ninetyfour .div-limited-child .btn:focus {
    color: #fff;
    border: 1px solid #8a8681;
    background-color: #8a8681;
}

/* Gift Template 95 */

.div-gift-template-ninetyfive {
    background-color: #DADCE0;
    border: 1px solid #DADCE0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
    overflow: hidden;
}

.div-gift-template-ninetyfive .col-6 .div-left {
    padding-left: 30px;
    width: 100%;
    height: 0;    
    border-top: 275px solid #ad3f44;
    border-right: 30px solid transparent;
}

.div-gift-template-ninetyfive .div-gift-template .col-6 .div-logo {
    position: absolute;
    top: 15px;
    left: 50px;
}

.div-gift-template-ninetyfive .div-gift-template .col-6 img {
    max-height: 22px;
}
.div-gift-template-ninetyfive .div-gift-template .col-6 .div-card-header {
    position: absolute;
    bottom: 15px;
    width: 70%;
    text-align: center;
}
.div-gift-template-ninetyfive .div-gift-template .col-6 .div-card-header span {
    color: #fff;
}
.div-gift-template-ninetyfive span, .div-gift-template-ninetyfive label, .div-gift-template-ninetyfive p, .div-gift-template-ninetyfive i {
    color: #ad3f44;
}
.div-gift-template-ninetyfive .div-card-list .btn-buy {
    color: #ad3f44;
    border: 1px solid #ad3f44;
}
.div-gift-template-ninetyfive .div-card-list .btn-buy:hover, .div-gift-template-ninetyfive .div-card-list .btn-buy:focus {
    color: #fff;
    border: 1px solid #ad3f44;
    background-color: #ad3f44;
}
.div-gift-template-ninetyfive .div-card-list .col-md-4 input {
    border: 1px solid #ad3f44;
}
.div-gift-template-ninetyfive .div-gift-promo input {
    border: 1px solid #ad3f44;
    color: #ad3f44;
}
.div-gift-template-ninetyfive .div-limited-child .btn {
    color: #ad3f44;
    border: 1px solid #ad3f44;
}
.div-gift-template-ninetyfive .div-buy1-child, .div-gift-template-ninetyfive .div-buy2-child, .div-gift-template-ninetyfive .div-footer-description {
    color: #ad3f44;
}
.div-gift-template-ninetyfive .div-limited-child input {
    color: #ad3f44;
    border: 1px solid #ad3f44;
    background-color: #fff;
}
.div-gift-template-ninetyfive .div-limited-child input {
    color: #ad3f44;
}
.div-gift-template-ninetyfive .div-limited-child input::placeholder {
    color: #ad3f44CC;
}
.div-gift-template-ninetyfive .div-limited-child .btn:hover, .div-gift-template-ninetyfive .div-limited-child .btn:focus {
    color: #fff;
    border: 1px solid #ad3f44;
    background-color: #ad3f44;
}

/* Gift Template 96 */

.div-gift-template-ninetysix {
    background-color: #DADCE0;
    border: 1px solid #DADCE0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
    overflow: hidden;
}

.div-gift-template-ninetysix .col-5 .div-right {    
    width: 100%;
    height: 0;    
    border-top: 100px solid #344919;
    border-left: 205px solid transparent;
}
.div-gift-template-ninetysix .div-gift-template .col-7 {
    height:275px;
}
.div-gift-template-ninetysix .div-gift-template .col-7 .div-logo {
    padding: 10px 15px;
    position: absolute;
    bottom: 0px;
    width: 100%;
    background-color: #344919;    
    border-top-right-radius: 15px;
}

.div-gift-template-ninetysix .div-gift-template .col-7 img {
    max-height: 22px;
}
.div-gift-template-ninetysix .div-gift-template .col-5 .div-card-header {
    position: absolute;
    top: 20px;
    width: 60%;
    text-align: center;
    right: 15px;
}
.div-gift-template-ninetysix .div-gift-template .col-5 .div-card-header span {
    color: #fff;
}
.div-gift-template-ninetysix span, .div-gift-template-ninetysix label, .div-gift-template-ninetysix p, .div-gift-template-ninetysix i {
    color: #344919;
}
.div-gift-template-ninetysix .div-card-list .btn-buy {
    color: #344919;
    border: 1px solid #344919;
}
.div-gift-template-ninetysix .div-card-list .btn-buy:hover, .div-gift-template-ninetysix .div-card-list .btn-buy:focus {
    color: #fff;
    border: 1px solid #344919;
    background-color: #344919;
}
.div-gift-template-ninetysix .div-card-list .col-md-4 input {
    border: 1px solid #344919;
}
.div-gift-template-ninetysix .div-gift-promo input {
    border: 1px solid #344919;
    color: #344919;
}
.div-gift-template-ninetysix .div-limited-child .btn {
    color: #344919;
    border: 1px solid #344919;
}
.div-gift-template-ninetysix .div-buy1-child, .div-gift-template-ninetysix .div-buy2-child, .div-gift-template-ninetysix .div-footer-description {
    color: #344919;
}
.div-gift-template-ninetysix .div-limited-child input {
    color: #344919;
    border: 1px solid #344919;
    background-color: #fff;
}
.div-gift-template-ninetysix .div-limited-child input {
    color: #344919;
}
.div-gift-template-ninetysix .div-limited-child input::placeholder {
    color: #344919CC;
}
.div-gift-template-ninetysix .div-limited-child .btn:hover, .div-gift-template-ninetysix .div-limited-child .btn:focus {
    color: #fff;
    border: 1px solid #344919;
    background-color: #344919;
}

/* Gift Template 97 */

.div-gift-template-ninetyseven {
    background-color: #DADCE0;
    border: 1px solid #DADCE0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
    overflow: hidden;
}
.div-gift-template-ninetyseven .div-parent img{
    height:25px;
}
.div-gift-template-ninetyseven .div-parent .div-logo {
    margin-left:10px;
    padding: 5px 10px;
    background-color: #030303;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    display: inline-flex;
}

.div-gift-template-ninetyseven .div-parent .div-card-header {
    position:absolute;
    bottom:0px;
    background-color:#030303;    
    border-top-right-radius: 15px;
    padding: 7px 15px;
}

.div-gift-template-ninetyseven .div-parent .div-card-header span {
    color: #fff;    
}

.div-gift-template-ninetyseven span, .div-gift-template-ninetyseven label, .div-gift-template-ninetyseven p, .div-gift-template-ninetyseven i {
    color: #030303;
}
.div-gift-template-ninetyseven .div-card-list .btn-buy {
    color: #030303;
    border: 1px solid #030303;
}
.div-gift-template-ninetyseven .div-card-list .btn-buy:hover, .div-gift-template-ninetyseven .div-card-list .btn-buy:focus {
    color: #fff;
    border: 1px solid #030303;
    background-color: #030303;
}
.div-gift-template-ninetyseven .div-card-list .col-md-4 input {
    border: 1px solid #030303;
}
.div-gift-template-ninetyseven .div-gift-promo input {
    border: 1px solid #030303;
    color: #030303;
}
.div-gift-template-ninetyseven .div-limited-child .btn {
    color: #030303;
    border: 1px solid #030303;
}
.div-gift-template-ninetyseven .div-buy1-child, .div-gift-template-ninetyseven .div-buy2-child, .div-gift-template-ninetyseven .div-footer-description {
    color: #030303;
}
.div-gift-template-ninetyseven .div-limited-child input {
    color: #030303;
    border: 1px solid #030303;
    background-color: #fff;
}
.div-gift-template-ninetyseven .div-limited-child input {
    color: #030303;
}
.div-gift-template-ninetyseven .div-limited-child input::placeholder {
    color: #030303CC;
}
.div-gift-template-ninetyseven .div-limited-child .btn:hover, .div-gift-template-ninetyseven .div-limited-child .btn:focus {
    color: #fff;
    border: 1px solid #030303;
    background-color: #030303;
}


/* Gift Template 98 */

.div-gift-template-ninetyeight {
    background-color: #DADCE0;
    border: 1px solid #DADCE0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
    overflow: hidden;
}
.div-gift-template-ninetyeight .div-parent{
    background-color: #030303;
    min-height: 200px;
    border-radius: 15px;
    position: absolute;
    top:35px;
    left:35px;
    right:35px;
    opacity: 0.9;
    text-align: center;
}
.div-gift-template-ninetyeight .div-parent img{
    height:25px;
}
.div-gift-template-ninetyeight .div-parent .div-logo, .div-gift-template-ninetyeight .div-parent .div-card-header {
    margin-top: 50px;
}
.div-gift-template-ninetyeight .div-parent .div-card-header span {
    color: #fff;
    font-weight: 600;
    text-transform: uppercase;
}
.div-gift-template-ninetyeight span, .div-gift-template-ninetyeight label, .div-gift-template-ninetyeight p, .div-gift-template-ninetyeight i {
    color: #030303;
}
.div-gift-template-ninetyeight .div-card-list .btn-buy {
    color: #030303;
    border: 1px solid #030303;
}
.div-gift-template-ninetyeight .div-card-list .btn-buy:hover, .div-gift-template-ninetyeight .div-card-list .btn-buy:focus {
    color: #fff;
    border: 1px solid #030303;
    background-color: #030303;
}
.div-gift-template-ninetyeight .div-card-list .col-md-4 input {
    border: 1px solid #030303;
}
.div-gift-template-ninetyeight .div-gift-promo input {
    border: 1px solid #030303;
    color: #030303;
}
.div-gift-template-ninetyeight .div-limited-child .btn {
    color: #030303;
    border: 1px solid #030303;
}
.div-gift-template-ninetyeight .div-buy1-child, .div-gift-template-ninetyeight .div-buy2-child, .div-gift-template-ninetyeight .div-footer-description {
    color: #030303;
}
.div-gift-template-ninetyeight .div-limited-child input {
    color: #030303;
    border: 1px solid #030303;
    background-color: #fff;
}
.div-gift-template-ninetyeight .div-limited-child input {
    color: #030303;
}
.div-gift-template-ninetyeight .div-limited-child input::placeholder {
    color: #030303CC;
}
.div-gift-template-ninetyeight .div-limited-child .btn:hover, .div-gift-template-ninetyeight .div-limited-child .btn:focus {
    color: #fff;
    border: 1px solid #030303;
    background-color: #030303;
}

/* Gift Template 99*/

.div-gift-template-ninetynine {
    background-color: #DADCE0;
    border: 1px solid #DADCE0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
    overflow: hidden;
}

.div-gift-template-ninetynine .div-gift-template .div-template-footer {
    background-color: #17232c;
    padding: 15px;
    width: 100%;
    position: absolute;
    bottom: 0px;
    text-align: center;
}
.div-gift-template-ninetynine .div-gift-template .div-template-footer span {
    letter-spacing: 1.2px;
    color: #fff;
    font-weight: 300;      
}
.div-gift-template-ninetynine .div-gift-template .div-template-footer img {
    max-height: 22px;
}
.div-gift-template-ninetynine span, .div-gift-template-ninetynine label, .div-gift-template-ninetynine p, .div-gift-template-ninetynine i {
    color: #17232c;
}
.div-gift-template-ninetynine .div-card-list .btn-buy {
    color: #17232c;
    border: 1px solid #17232c;
}
.div-gift-template-ninetynine .div-card-list .btn-buy:hover, .div-gift-template-ninetynine .div-card-list .btn-buy:focus {
    color: #fff;
    border: 1px solid #17232c;
    background-color: #17232c;
}
.div-gift-template-ninetynine .div-card-list .col-md-4 input {
    border: 1px solid #17232c;
}
.div-gift-template-ninetynine .div-gift-promo input {
    border: 1px solid #17232c;
    color: #17232c;
}
.div-gift-template-ninetynine .div-limited-child .btn {
    color: #17232c;
    border: 1px solid #17232c;
}
.div-gift-template-ninetynine .div-buy1-child, .div-gift-template-ninetynine .div-buy2-child, .div-gift-template-ninetynine .div-footer-description {
    color: #17232c;
}
.div-gift-template-ninetynine .div-limited-child input {
    color: #17232c;
    border: 1px solid #17232c;
    background-color: #fff;
}
.div-gift-template-ninetynine .div-limited-child input {
    color: #17232c;
}
.div-gift-template-ninetynine .div-limited-child input::placeholder {
    color: #17232cCC;
}
.div-gift-template-ninetynine .div-limited-child .btn:hover, .div-gift-template-ninetynine .div-limited-child .btn:focus {
    color: #fff;
    border: 1px solid #17232c;
    background-color: #17232c;
}

/* Template 91 */

.div-gift-template-hundred {
    background-color: #DADCE0;
    border: 1px solid #DADCE0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    position: relative;
    overflow: hidden;
}
.div-gift-template-hundred .div-circle {
    width: 160px;
    height: 160px;
    background-color: #030303;
    top: 20%;
    right: 30%;
    border-radius: 50%;
    position: absolute;
}
.div-gift-template-hundred img{
    height:25px;
}
.div-gift-template-hundred .div-logo {
    position: absolute;
    top: 90px;
    width: 65%;
    text-align: center;
    right: 65px;
}
.div-gift-template-hundred .div-card-header {
    position: absolute;
    top: 140px;
    width: 70%;
    text-align: center;   
    right: 55px; 
}
.div-gift-template-hundred .div-card-header span {
    color: #fff;    
}
.div-gift-template-hundred span, .div-gift-template-hundred label, .div-gift-template-hundred p, .div-gift-template-hundred i {
    color: #030303;
}
.div-gift-template-hundred .div-card-list .btn-buy {
    color: #030303;
    border: 1px solid #030303;
}
.div-gift-template-hundred .div-card-list .btn-buy:hover, .div-gift-template-hundred .div-card-list .btn-buy:focus {
    color: #fff;
    border: 1px solid #030303;
    background-color: #030303;
}
.div-gift-template-hundred .div-card-list .col-md-4 input {
    border: 1px solid #15844c;
}
.div-gift-template-hundred .div-gift-promo input {
    border: 1px solid #030303;
    color: #030303;
}
.div-gift-template-hundred .div-limited-child .btn {
    color: #030303;
    border: 1px solid #030303;
}
.div-gift-template-hundred .div-buy1-child, .div-gift-template-hundred .div-buy2-child, .div-gift-template-hundred .div-footer-description {
    color: #030303;
}
.div-gift-template-hundred .div-limited-child input {
    color: #030303;
    border: 1px solid #030303;
    background-color: #fff;
}
.div-gift-template-hundred .div-limited-child input {
    color: #030303;
}
.div-gift-template-hundred .div-limited-child input::placeholder {
    color: #030303CC;
}
.div-gift-template-hundred .div-limited-child .btn:hover, .div-gift-template-hundred .div-limited-child .btn:focus {
    color: #fff;
    border: 1px solid #030303;
    background-color: #030303;
}
.err_bor_ot {
    outline: 1px solid red !important;
}


