.img-fluid {
    max-width: 100%;
    height: auto;
}
.pr0, .p-r-0 {
    padding-right: 0px;
}
.card{
	border:none;
}
.ReactModal__Overlay{
	
	background:none !important;
	/* z-index:99; */
	
}
.cpointer{
	
	cursor:pointer;
}
.text_center{
	
	text-align:center;
}
.nav-link {
    padding-left: 0px !important;
}
.back_cls{
	font-size: 17px;
    margin-bottom: 0.5%;
    display: block;
	cursor:pointer;
	
}
.mt-3 {
    margin-top: 1rem!important;
}
.mt-1 {
    margin-top: 0.10rem!important;
}
.nopadd {
   padding-left:0px;
}
.admin_bg {
    /* color: #5A75F1; */
    /* background: #0000001A; */
    padding: 2px 4px;
    border-radius: 3px;
    font-size: 13px;
    border: 1px solid var(--col-3);
    line-height: unset !important;
}

.form-control:focus {   
    border-color: #ced4da !important;
}
.wid14{
	width: 14%;
}
.prelative{
	
	position:relative;
}
.modal-body {
	padding:0;
}	
.mauto{
	
	margin:0 auto;
}  
.div-mystore-faq{
	
	padding:0;
}
.mbot5{
	margin-bottom:5px;
}   
@media only screen and (max-width: 599px) {
	.ReactModal__Content{
		width: 97% !important;
		
		
	}
	.div-right-cart-popup{
		right: 13px !important;
		  width: 96% !important;
		
	}
	.reveal-modal{
		width: 99%;
		top: 16%;
		position: fixed;
		min-height:206px;
		
	}
	.nav-link-mob{
		
		 font-size: 14px;
	}
	.div-mystore-faq h5{
		
		 font-size: 14px;
	}
	.div-play i {
		margin-left: 7px;
		margin-top: 5px;
		font-size: 8px;
	}
	.div-play {
		height: 20px;
		width: 20px;
		top: 27%;
		left: 40%;
	}
}
	 