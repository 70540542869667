.div-cat-box {
    border-radius: 5px;
    padding: 10px;
    background-color: #F8FAFC;
    margin-bottom: 15px;
}

.div-cat-box p {
    color: #DC0000;
    font-size: 18px;
    font-weight: 800;
    margin-top: 10px;
    text-align: center;
    text-decoration: none;
}

a.catlink:hover {
    text-decoration: none;
}

.div-cat-box:hover {
    text-decoration: none;
}

.div-cat-box img {
    border-radius: 15px;
    width: 100%;
    /* height: 275px; */
    height: 200px;
}