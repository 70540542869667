.container {
	width: 100%;
	padding-right: 15px;
	padding-left: 15px;
	margin-right: auto;
	margin-left: auto;
}

.earn_pts {
	font-size: 28px;

}

.visit_store {
	font-size: 14px;
}

.trophy_circle {
	border: 2px solid rgb(0, 150, 136);
	border-radius: 50px;
	/* padding: 50px; */
	/* text-align: center; */
	width: 31%;
	text-align: center;
	margin: 0 auto;
}

.df {
	display: flex;
}

.close_modal {
	float: right;
	font-size: 21px;
	font-weight: 700;
	line-height: 1;
	color: #000;
	text-shadow: 0 1px 0 #fff;
	filter: alpha(opacity=20);
	opacity: 0.8;
	position: absolute;
	top: 0px;
	right: 5px;
}

button.close_modal {
	padding: 0;
	cursor: pointer;
	background: 0 0;
	border: 0;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

body {
	/* background-color: #000; */
}

.div-reward-mob-logo {
	/* padding-top: 20px;
	padding-bottom: 20px; */
	text-align: center;
}

.div-reward-mob-logo img {
	max-height: 50px;
}

.ptb20 {
	padding-top: 20px;
	padding-bottom: 20px;
}

.div-box {
	width: 50px;
	height: 50px;
	background-color: #000;
	color: #000;
	border-radius: 10px;
	margin-right: 8px;
	margin-left: 8px;
	margin-top: 15px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
}

.div-box.active {
	/*background-color: #00CC58;*/
	color: #009688;
}

.div-box i {
	font-size: 28px;
}

.div-points-box {
	border-radius: 10px;
	background-color: #191c24;
	padding: 10px 10px 20px 10px;
	margin: auto;
}

.div-points {
	text-align: center;
	color: #fff;
	padding-top: 20px;
}

.div-points p strong {
	color: #009688;
}

.btn-ow {
	display: inline-block;
	font-weight: 400;
	color: #212529;
	text-align: center;
	vertical-align: middle;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	background-color: transparent;
	border: 1px solid transparent;
	padding: .375rem .75rem;
	font-size: 1rem;
	line-height: 1.5;
	border-radius: .25rem;
	transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

/*.btn-reward-mob {
	width: 100%;
	border: 1px solid #E5E5E5;
	color: #E5E5E5;
	margin-top: 15px;
}*/
.btn-reward-mob:hover,
.btn-reward-mob:visited,
.btn-reward-mob:focus,.btn-reward-mob-continue:hover {

	/*color: #333;*/
	text-decoration: none;
	outline: none;
	border: none;
	letter-spacing: 1.5px;
	transition: .6s;
}

.div-popup-box {
	padding: 20px;
	border-radius: 15px;
	/*background-color: #fff;*/
	background-color: #191c24;
	margin-top: 32%;
	margin-bottom: 50px;
}

.div-popup-head {
	width: 100%;
	text-align: center;
	font-size: 20px;
	font-weight: 600;
	color: #fff;
}

.div-popup-box label {
	color: #fff;
}

.txt-center {
	text-align: center;
}

.txt-right {
	text-align: right;
}

.colorwhite {
	color: #fff;
}

.btn-continue {
	padding: 8px 12px;
	background-color: #00d25b;
	color: #fff;
	transition: 0.6s;
}

.btn-continue:hover {
	box-shadow: 0 0 8px #00d25b;
	color: #fff;
}

.btn-reward-mob {
	border-color: #009688;
	color: #009688;
	background-color: #00968833;
	padding: 0.4375rem 1.25rem 0.4375rem 0.75rem;
	font-weight: 500;
	width: 100%;
	margin-top: 15px;
}

.btn-reward-mob-continue {
	border: 1px solid #009688;
	color: #009688;
	background-color: #00968833;
	padding: 0.4375rem 1.25rem 0.4375rem 0.75rem;
	font-weight: 500 !important;
	width: 100%;
}

.btn-reward-mob.active {
	border: 1px solid #009688;
	background-color: #009688;
	color: #333;
}

.div-reward-ani {
	text-align: center;
}

.div-reward-ani img {
	max-width: 320px;
	/* display:none; */
}
.div-popup-box-ref {
    padding: 20px;
    border-radius: 15px;
    background-color: #191c24;
    margin-bottom: 50px;
}
.div-visit-store {
	color: #fff;
	text-align: center;
	margin-top: 30px;
	font-size: 10px;
	margin-bottom: 50px;
}

.div-visit-store p {
	margin-bottom: 0px;
	letter-spacing: 0.8px;
}
.reward_cash_txt {
    font-size: 14px;
}
 .reward-points-con{
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	width: 200px;
	height: 200px;
	margin-left: calc(46% - 50px);
	margin-top: 1em;
	/* background-color: #fff; */
	border-radius: 50%;
	overflow: hidden;
	-webkit-box-shadow: 1px 1px 15px rgba(0,0,0,.1);
	box-shadow: 1px 1px 15px rgba(0,0,0,.1);
}

.reward-points-con .reward-points{
	width: 200px;
	height: 200px;
	border-radius: 50%;
	overflow: hidden;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	font-weight: 600;
	font-size: 2.5em;
}



.credit-desc-con{
	width: 100%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	padding-bottom: 10px;
}

.credit-desc {
	font-size: 15px;
	margin-top: 16px;
	padding: 8px 16px;
	width: 200px;
	color: #2d9236;
	border-radius: 20px;
	border: 1px dashed #2d9236;
} 
/* media query for screen width 414 */
@media only screen and (min-width: 599px) {
	.div-points-box {
		width: fit-content;
	}

}

@media only screen and (max-width: 599px) {
	.trophy_circle {
		width: 100%;
	}

	.container {
		padding-top: 50px;
	}
	.reward-points-con{
		
			margin-left: calc(37% - 50px);
	}
}

/* media query for screen width 414 */
@media only screen and (max-width: 599px) and (min-width: 411px) {
	.div-box {
		width: 75px;
		height: 75px;
		margin-right: 10px;
		margin-left: 10px;
	}
}

/* media query for screen width 375 */
@media only screen and (max-width: 400px) and (min-width: 375px) {
	.div-box {
		width: 68px;
		height: 68px;
		margin-right: 10px;
		margin-left: 10px;
	}
}

/* media query for screen width 360 */
@media only screen and (max-width: 374px) and (min-width: 360px) {
	.div-box {
		width: 63px;
		height: 63px;
		margin-right: 10px;
		margin-left: 10px;
	}
}

/* media query for screen width 320 */
@media only screen and (max-width: 359px) and (min-width: 320px) {
	.div-box {
		width: 57px;
		height: 57px;
		margin-right: 8px;
		margin-left: 8px;
	}
}