.star_box {
  height: 20px;
  width: 40px;
  padding: 3px 4px;
  font-weight: 400;
  font-size: 10px;
  text-align: center;
  border-radius: 5px;
  margin-top: 4px;
}
.div-offer-txt {
    font-size: 10px;
    position: absolute;
    font-weight: 600;
    height: 16px;
    background: #04AB9B;
    clip-path: polygon(100% 0, 95% 50%, 100% 100%, 0% 100%, 5% 50%, 0% 0%);   
    color: #000000;      
    display: flex;
    justify-content: center;
    align-items: center;
    width: 12ch;
    max-width: 600px;
}
.info:active,.info:focus,info:visited{
	
	border:none !important;
	  outline: none  !important;
}
.btn-myfront-add{
	
	font-size:12px !important;
	
}

.btn-myfront-add:hover,.btn:hover {
    
 
}
/* Templates common - Ends */


/* Template 1 - Starts */
.div-mystore-template1 {
    width: 100%;
    background-color: #eceff1;
    border-radius: 15px;
    /* font: Poppins; */
    letter-spacing: 0px;    
    padding: 15px;
    margin-bottom: 30px;
}

.div-mystore-template1 .prod_amt {
    color: #E53935;
    font-size: 18px;
    font-weight: 700;
}

.div-mystore-template1 .col-md-8 h3, .div-mystore-template1 .col-md-8 div span {
    color: #455a64;
    /* font-size: 18px; */
    font-size: 14px;
    font-weight: 700;
    min-height: 35px;
    max-height: 35px;
    overflow: hidden;    
}

.div-mystore-template1 .col-md-8 p {
    font-size: 11px;
    max-height: 68px;
    min-height: 68px;
    overflow: hidden;
    margin-bottom: 12px;
    color: #263238;
}

.div-mystore-template1 .btn-myfront-add {
    border: 1px solid #ECEFF1;
    border-radius: 5px;
    color: #ECEFF1;
    width: 100%;
    font-weight: 700;
    padding: 3px;
    background-color: #607D8B;
}

.div-mystore-template1 .col-md-4 {
    padding-left: 0px;
    text-align: center;
}

.div-mystore-template1 .col-md-4 img {
    width: 100px;
    height: 100px;
    border-radius: 15px;
    margin-bottom: 15px;
    margin-top: 9px;
}

.div-mystore-template1 .div-offer-txt {
    right: 25px;
    top: 5px;
    color: #ECEFF1;
    background: #607D8B;
}

.div-mystore-template1 .star_box {
    border: 1px solid #607D8B;
    color: #607D8B;
}
.div-mystore-template1 .info {    
    border: 0;    
    margin-left: 2px;
    border-radius: 3px;
    color: #607D8B;
    background-color: initial;
    font-size: 18px;    
}

/* Template 1 - Ends */

/* Template 2 - Starts */

.div-mystore-template2 {
    width: 100%;
    background-color: #eceff1;
    border-radius: 15px;
    font: Poppins;
    letter-spacing: 0px;    
    padding: 0px 0px 0px 15px;
    margin-bottom: 30px;
}

.div-mystore-template2 .prod_amt {
    color: #dc0000;
    font-size: 18px;
    font-weight: 700;
}

.div-mystore-template2 .col-md-8 {
    padding:  15px;
}

.div-mystore-template2 .col-md-8 h3, .div-mystore-template2 .col-md-8 div span {
    color: #455a64;
    /* font-size: 18px; */
    font-size: 14px;
    font-weight: 700;
    min-height: 35px;
    max-height: 35px;
    overflow: hidden;
}

.div-mystore-template2 .col-md-4 div {    
   
    background-image: url('https://rewardsly.io/members/img/menutemp/barber.png');
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    height: 100%;
}

.div-mystore-template2 .col-md-4 {
    padding-left: 0px;    
}

.div-mystore-template2 .col-md-8 p {
    font-size: 11px;
    max-height: 68px;
    min-height: 68px;
    overflow: hidden;
    margin-bottom: 12px;
    color:#263238;
}

.div-mystore-template2 .btn-myfront-add {
    border: 1px solid #eceff1;
    border-radius: 5px;
    background-color: #37474f;
    color: #eceff1;
    width: 100%;
    font-weight: 700;
    padding: 3px;
}

.div-mystore-template2 .div-offer-txt {    
    right: 5px;
    bottom: 5px;
    color: #eceff1;
    background: #dc0000;
}

.div-mystore-template2 .star_box {
    border: 1px solid #607D8B;
    color: #607D8B;
}
.div-mystore-template2 .info {    
    border: 0;    
    margin-left: 2px;
    border-radius: 3px;
    color: #37474f;
    background-color: initial;
    font-size: 18px;    
}
/* Template 2 - Ends */

/* Template 3 - Starts */
.div-mystore-template3 {
    width: 100%;
    background-color: #EDE7F6;
    border-radius: 15px;
    font: Poppins;
    letter-spacing: 0px;    
    padding: 0px 0px 0px 15px;
    margin-bottom: 30px;
}

.div-mystore-template3 .prod_amt {
    color: #E53935;
    font-size: 18px;
    font-weight: 700;
}

.div-mystore-template3 .col-md-8 {
    padding: 15px 30px 15px 15px;
}

.div-mystore-template3 .col-md-8 .row {
    margin-right: 0px;
}

.div-mystore-template3 .col-md-8 h3, .div-mystore-template3 .col-md-8 div span {
    color: #512DA8;
    /* font-size: 18px; */
    font-size: 14px;
    font-weight: 700;
    min-height: 34px;
    max-height: 34px;
    overflow: hidden;
    margin-top: 4px;
}

.div-mystore-template3 .col-md-4 div {    
   
    background-image: url('https://rewardsly.io/members/img/menutemp/jewellery.png');
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    height: 100%;
}

.div-mystore-template3 .col-md-4 {
    padding-left: 0px;
    padding-right: 0px;
}

.div-mystore-template3 .col-md-8 p {
    font-size: 11px;
    max-height: 68px;
    min-height: 68px;
    overflow: hidden;
    margin-bottom: 12px;
    color:#263238;
}

.div-mystore-template3 .btn-myfront-add {
    border: 1px solid #EDE7F6;
    border-radius: 5px;
    background-color: #673AB7;
    color: #EDE7F6;
    width: 100%;
    font-weight: 700;
    padding: 3px;
}

.div-mystore-template3 .div-offer-txt {    
    right: 25px;
    top: 5px;
    color: #EDE7F6;
    background: #673AB7;
}

.div-mystore-template3 .star_box {
    border: 1px solid #673AB7;
    color: #673AB7;
}
.div-mystore-template3 .info {    
    border: 0;    
    margin-left: 2px;
    border-radius: 3px;
    color: #673AB7;
    background-color: initial;
    font-size: 18px;    
}
/* Template 3 - Ends */


.div-mystore-template4 {
    width: 100%;
    background-color: #1a237e;
    border-radius: 15px;
    /* font: Poppins; */
    letter-spacing: 0px;    
    padding: 15px;
    margin-bottom: 30px;
}

.div-mystore-template4 .prod_amt {
    color: #e53935;
    font-size: 18px;
    font-weight: 700;
}

.div-mystore-template4 .col-md-8 h3, .div-mystore-template4 .col-md-8 div span {
    color: #e8eaf6;
    /* font-size: 18px; */
    font-size: 14px;
    font-weight: 700;
    min-height: 35px;
    max-height: 35px;
    overflow: hidden;
}

.div-mystore-template4 .col-md-8 p {
    font-size: 11px;
    max-height: 68px;
    min-height: 68px;
    overflow: hidden;
    margin-bottom: 12px;
    color:#e8eaf6;
}

.div-mystore-template4 .col-md-4 {    
    text-align: center;
}

.div-mystore-template4 .col-md-4 img {
    width: 100px;
    height: 100px;
    border-radius: 15px;
    margin-bottom: 15px;
    margin-top: 5px;
}

.div-mystore-template4 .btn-myfront-add {
    border: 1px solid #e8eaf6;
    border-radius: 5px;
    background-color: #d32f2f;
    color: #e8eaf6;
    width: 100%;
    font-weight: 700;
    padding: 3px;
}

.div-mystore-template4 .div-offer-txt {    
    left: 25px;
    top: 5px;
    color: #1a237e;
    background: #e8eaf6;
}

.div-mystore-template4 .star_box {
    border: 1px solid #e8eaf6;
    color: #e8eaf6;
}
.div-mystore-template4 .info {    
    border: 0;    
    margin-left: 2px;
    border-radius: 3px;
    color: #d32f2f;
    background-color: initial;
    font-size: 18px;        
}
/* Template 4 - Ends */

/* Template 5 - Starts */

.div-mystore-template5 {
    width: 100%;
    background-color: #df0026;
    border-radius: 15px;
    font: Poppins;
    letter-spacing: 0px;    
    padding: 0px 0px 0px 15px;
    margin-bottom: 30px;
}

.div-mystore-template5 .prod_amt {
    color: #fdbd0c;
    font-size: 18px;
    font-weight: 700;
}

.div-mystore-template5 .mr0 {
    margin-right: 0px;
}

.div-mystore-template5 .col-md-8 {
    padding:  15px;
}

.div-mystore-template5 .col-md-8 h3, .div-mystore-template5 .col-md-8 div span {
    color: #ffffff;
    /* font-size: 18px; */
    font-size: 14px;
    font-weight: 700;
    min-height: 35px;
    max-height: 35px;
    overflow: hidden;
}

.div-mystore-template5 .col-md-4 {
    
    background-image: url('https://rewardsly.io/members/img/menutemp/food.png');
    /*background: url('../img/burger.jpg') no-repeat center center fixed; */
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    height: 100%;
}

.div-mystore-template5 .col-md-4 {
    padding-left: 0px;
    
}

.div-mystore-template5 .col-md-8 p {
    font-size: 11px;
    max-height: 68px;
    min-height: 68px;
    overflow: hidden;
    margin-bottom: 12px;
    color:#ffffff;
}

.div-mystore-template5 .col-md-4 div.div-left {
    padding-left: 0px;
    width: 20%;
    height: 0;
    border-top: 180px solid #df0026;
    border-right: 25px solid transparent;
}

.div-mystore-template5 .btn-myfront-add {
    border: 1px solid #df0026;
    border-radius: 5px;
    background-color: #fdbd0c;
    color: #010101;
    width: 100%;
    font-weight: 700;
    padding: 3px;
}

.div-mystore-template5 .div-offer-txt {    
    right: 30%;
    top: 2px;
    z-index: 1;
    color: #010101;
    background: #fdbd0c;
}

.div-mystore-template5 .star_box {
    border: 1px solid #ffffff;
    color: #ffffff;
}

.div-mystore-template5 .info {    
    border: 0;    
    margin-left: 2px;
    border-radius: 3px;
    color: #fdbd0c;
    background-color: initial;
    font-size: 18px;        
}
/* Template 5 - Ends */

/* Template 6 - Starts */

.div-mystore-template6 {
    width: 100%;
    background-color: #EFEBE9;
    border-radius: 15px;
    font: Poppins;
    letter-spacing: 0px;    
    padding: 0px 0px 0px 15px;
    margin-bottom: 30px;
}

.div-mystore-template6 .prod_amt {
    color: #e53935;
    font-size: 18px;
    font-weight: 700;
}

.div-mystore-template6 .col-md-8 {
    padding: 15px;
}

.div-mystore-template6 .col-md-8 .row {
    margin-right: 0px;
}

.div-mystore-template6 .col-md-8 h3, .div-mystore-template6 .col-md-8 div span {
    color: #6d4c41;
    /* font-size: 18px; */
    font-size: 14px;
    font-weight: 700;
    min-height: 35px;
    max-height: 35px;
    overflow: hidden;
}
.header_txt6{
	position:relative;
	top: 3px;
	
}
.div-mystore-template6 .col-md-4 {
    
    background-image: url('https://rewardsly.io/members/img/menutemp/fashion.png');
    /*background: url('../img/burger.jpg') no-repeat center center fixed; */
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    height: 100%;
}

.div-mystore-template6 .col-md-4 {
    padding-left: 0px; 
    padding-right: 0px;;   
}

.div-mystore-template6 .col-md-4 div.div-right {
    /* padding-left: 0px; */
    width: 20%;
    height: 0;
    border-top: 180px solid #efebe9;
    border-left: 25px solid transparent;
    margin-left: 80%;
}

.div-mystore-template6 .col-md-8 p {
    font-size: 11px;
    max-height: 68px;
    min-height: 68px;
    overflow: hidden;
    margin-bottom: 12px;
    color: #4e342e;
}

.div-mystore-template6 .btn-myfront-add {
    border: 1px solid #efebe9;
    border-radius: 5px;
    background-color: #795548;
    color: #efebe9;
    width: 100%;
    font-weight: 700;
    padding: 3px;
}

.div-mystore-template6 .div-offer-txt {    
    right: 25px;
    top: 5px;
    color: #efebe9;
    background: #795548;
}

.div-mystore-template6 .star_box {
    border: 1px solid #795548;
    color: #795548;
}
.div-mystore-template6 .info {    
    border: 0;    
    margin-left: 2px;
    border-radius: 3px;
    color: #795548;
    background-color: initial;
    font-size: 18px;        
}

/* Template 6 - Ends */

/* Template 7 - Starts */

.div-mystore-template7 {
    width: 100%;
    background-color: #f9ebde;
    border-radius: 15px;
    font: Poppins;
    letter-spacing: 0px;    
    padding: 0px 0px 0px 15px;
    margin-bottom: 30px;
}

.div-mystore-template7 .prod_amt {
    color: #815854;
    font-size: 18px;
    font-weight: 700;
}

.div-mystore-template7 .col-md-8 {
    padding:  15px;
}

.div-mystore-template7 .col-md-8 h3, .div-mystore-template7 .col-md-8 div span {
    color: #815854;
    /* font-size: 18px; */
    font-size: 14px;
    font-weight: 700;
    min-height: 35px;
    max-height: 35px;
    overflow: hidden;
}

.div-mystore-template7 .col-md-4 div {
    
    background-image: url('https://rewardsly.io/members/img/menutemp/dogcare.png');
    /*background: url('../img/burger.jpg') no-repeat center center fixed; */
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    border-top-left-radius: 30% 50%;
    border-bottom-left-radius: 30% 50%;
    height: 100%;
}

.div-mystore-template7 .col-md-4 {
    padding-left: 0px;    
}

.div-mystore-template7 .col-md-8 p {
    font-size: 11px;
    max-height: 68px;
    min-height: 68px;
    overflow: hidden;
    margin-bottom: 12px;
    color:#815854;
}

.div-mystore-template7 .btn-myfront-add {
    border: 1px solid #f9ebde;
    border-radius: 5px;
    background-color: #815854;
    color: #f9ebde;
    width: 100%;
    font-weight: 700;
    padding: 3px;
}

.div-mystore-template7 .div-offer-txt {    
    right: 30%;
    top: 2px;
    color: #f9ebde;
    background: #815854;
}

.div-mystore-template7 .star_box {
    border: 1px solid #815854;
    color: #815854;
}
.div-mystore-template7 .info {    
    border: 0;    
    margin-left: 2px;
    border-radius: 3px;
    color: #815854;
    background-color: initial;
    font-size: 18px;        
}
/* Template 7 - Ends */

/* Template 8 - Starts */

.div-mystore-template8 {
    width: 100%;
    background-color: #101820;
    border-radius: 15px;
    font: Poppins;
    letter-spacing: 0px;    
    padding: 0px 0px 0px 15px;
    margin-bottom: 30px;
}

.div-mystore-template8 .prod_amt {
    color: #f2aa4c;
    font-size: 18px;
    font-weight: 700;
}

.div-mystore-template8 .col-md-8 {
    padding:  15px;
}

.div-mystore-template8 .col-md-8 .row {
    margin-right: 0px;
}

.div-mystore-template8 .col-md-8 h3, .div-mystore-template8 .col-md-8 div span {
    color: #f2aa4c;
    /* font-size: 18px; */
    font-size: 14px;
    font-weight: 700;
    min-height: 35px;
    max-height: 35px;
    overflow: hidden;
}
.header_txt8{
	position:relative;
	top: 3px;
	
}
.div-mystore-template8 .col-md-4 div {    
    background-image: url('https://rewardsly.io/members/img/menutemp/brewery.png');
    /*background: url('../img/burger.jpg') no-repeat center center fixed; */
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    border-top-right-radius: 30% 50%;
    border-bottom-right-radius: 30% 50%;
    height: 100%;
}

.div-mystore-template8 .col-md-4 {
    padding-left: 0px;
}

.div-mystore-template8 .col-md-8 p {
    font-size: 11px;
    max-height: 68px;
    min-height: 68px;
    overflow: hidden;
    margin-bottom: 12px;
    color:#f2aa4c;
}

.div-mystore-template8 .btn-myfront-add {
    border: 1px solid #101820;
    border-radius: 5px;
    background-color: #f2aa4c;
    color: #101820;
    width: 100%;
    font-weight: 700;
    padding: 3px;
}

.div-mystore-template8 .div-offer-txt {    
    right: 25px;
    top: 5px;
    color: #101820;
    background: #f2aa4c;
}

.div-mystore-template8 .star_box {
    border: 1px solid #f2aa4c;
    color: #f2aa4c;
}
.div-mystore-template8 .info {    
    border: 0;    
    margin-left: 2px;
    border-radius: 3px;
    color: #f2aa4c;
    background-color: initial;
    font-size: 18px;        
}
/* Template 8 - Ends */

/* Template 9 - Starts */

.div-mystore-template9 {
    width: 100%;
    background-color: #5f0482;
    border-radius: 15px;
    font: Poppins;
    letter-spacing: 0px;    
    padding: 0px 0px 0px 15px;
    margin-bottom: 30px;
}

.div-mystore-template9 .prod_amt {
    color: #fdae7c;
    font-size: 18px;
    font-weight: 700;
}

.div-mystore-template9 .col-md-8 {
    padding:  15px;
}

.div-mystore-template9 .col-md-8 h3, .div-mystore-template9 .col-md-8 div span {
    color: #fdae7c;
    /* font-size: 18px; */
    font-size: 14px;
    font-weight: 700;
    min-height: 35px;
    max-height: 35px;
    overflow: hidden;
}

.div-mystore-template9 .col-md-4 div {
    
    background-image: url('https://rewardsly.io/members/img/menutemp/tattoo.png');
    /*background: url('../img/burger.jpg') no-repeat center center fixed; */
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    /* border-top-left-radius: 30% 50%; */
    border-bottom-left-radius: 80% 100%;
    height: 100%;
}

.div-mystore-template9 .col-md-4 {
    padding-left: 0px;    
}

.div-mystore-template9 .col-md-8 p {
    font-size: 11px;
    max-height: 68px;
    min-height: 68px;
    overflow: hidden;
    margin-bottom: 12px;
    color:#ffffff;
}

.div-mystore-template9 .btn-myfront-add {
    border: 1px solid #5f0482;
    border-radius: 5px;
    background-color: #fdae7c;
    color: #5f0482;
    width: 100%;
    font-weight: 700;
    padding: 3px;    
}

.div-mystore-template9 .div-offer-txt {    
    right: 20%;
    bottom: 2px;
    color: #fdae7c;
    background: #1e0029;
}

.div-mystore-template9 .star_box {
    border: 1px solid #fdae7c;
    color: #fdae7c;
}
.div-mystore-template9 .info {    
    border: 0;    
    margin-left: 2px;
    border-radius: 3px;
    color: #fdae7c;
    background-color: initial;
    font-size: 18px;        
}
/* Template 9 - Ends */


/* Template 10 - Starts */

.div-mystore-template10 {
    width: 100%;
    background-color: #2a3c48;
    border-radius: 15px;
    font: Poppins;
    letter-spacing: 0px;
    padding: 0px 0px 0px 15px;
    margin-bottom: 30px;
}

.div-mystore-template10 .prod_amt {
    color: #db633f;
    font-size: 18px;
    font-weight: 700;
}

.div-mystore-template10 .col-md-8 {
    padding: 15px;
}

.div-mystore-template10 .col-md-8 h3,
.div-mystore-template10 .col-md-8 div span {
    color: #db633f;
    /* font-size: 18px; */
    font-size: 14px;
    font-weight: 700;
    min-height: 35px;
    max-height: 35px;
    overflow: hidden;
}

.div-mystore-template10 .col-md-4 div {

    background-image: url('https://rewardsly.io/members/img/menutemp/music.png');
    /*background: url('../img/burger.jpg') no-repeat center center fixed; */
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    border-top-left-radius: 80% 100%;
    /* border-bottom-left-radius: 80% 100%; */
    height: 100%;
}

.div-mystore-template10 .col-md-4 {
    padding-left: 0px;
}

.div-mystore-template10 .col-md-8 p {
    font-size: 11px;
    max-height: 68px;
    min-height: 68px;
    overflow: hidden;
    margin-bottom: 12px;
    color: #ffffff;
}

.div-mystore-template10 .btn-myfront-add {
    border: 1px solid #2a3c48;
    border-radius: 5px;
    background-color: #db633f;
    color: #ffffff;
    width: 100%;
    font-weight: 700;
    padding: 3px;
}

.div-mystore-template10 .div-offer-txt {
    right: 20%;
    top: 2px;
    color: #db633f;
    background: #0c171f;
}

.div-mystore-template10 .star_box {
    border: 1px solid #db633f;
    color: #db633f;
}

.div-mystore-template10 .info {    
    border: 0;    
    margin-left: 2px;
    border-radius: 3px;
    color: #db633f;
    background-color: initial;
    font-size: 18px;        
}

/* Template 10 - Ends */

/* Template 11 - Starts */

.div-mystore-template11 {
    width: 100%;
    background-color: #ffe1ad;
    border-radius: 15px;
    /* font: Poppins; */
    letter-spacing: 0px;
    padding: 15px;
    margin-bottom: 30px;
}

.div-mystore-template11 .prod_amt {
    color: #aa530e;
    font-size: 18px;
    font-weight: 700;
}

.div-mystore-template11 .col-md-8 h3,
.div-mystore-template11 .col-md-8 div span {
    color: #aa530e;
    /* font-size: 18px; */
    font-size: 14px;
    font-weight: 700;
    min-height: 35px;
    max-height: 35px;
    overflow: hidden;
}

.div-mystore-template11 .col-md-8 p {
    font-size: 11px;
    max-height: 68px;
    min-height: 68px;
    overflow: hidden;
    margin-bottom: 12px;
    color: #aa530e;
    font-weight: 500;
}

.div-mystore-template11 .btn-myfront-add {
    border: 1px solid #ffe1ad;
    border-radius: 5px;
    background-color: #aa530e;
    color: #f5c16c;
    width: 100%;
    font-weight: 700;
    padding: 3px;
}

.div-mystore-template11 .col-md-4 {
    padding-left: 0px;
    text-align: center;
}

.div-mystore-template11 .col-md-4 img {
    width: 100px;
    height: 100px;
    border-radius: 50px;
    margin-bottom: 15px;
    margin-top: 9px;
}

.div-mystore-template11 .div-offer-txt {
    right: 25px;
    top: -11px;
    color: #aa530e;
    background: #eba834;
}

.div-mystore-template11 .star_box {
    border: 1px solid #aa530e;
    color: #aa530e;
}

.div-mystore-template11 .info {    
    border: 0;    
    margin-left: 2px;
    border-radius: 3px;
    color: #aa530e;
    background-color: initial;
    font-size: 18px;        
}

/* Template 11 - Ends */

/* Template 12 - Starts */

.div-mystore-template12 {
    width: 100%;
    background-color: #fff591;
    border-radius: 15px;
    /* font: Poppins; */
    letter-spacing: 0px;
    padding: 15px;
    margin-bottom: 30px;
}

.div-mystore-template12 .prod_amt {
    color: #e41749;
    font-size: 18px;
    font-weight: 700;
}

.div-mystore-template12 .col-md-8 h3,
.div-mystore-template12 .col-md-8 div span {
    color: #f5587b;
    /* font-size: 18px; */
    font-size: 14px;
    font-weight: 700;
    min-height: 35px;
    max-height: 35px;
    overflow: hidden;
}

.div-mystore-template12 .col-md-8 p {
    font-size: 11px;
    max-height: 68px;
    min-height: 68px;
    overflow: hidden;
    margin-bottom: 12px;
    color: #0d0d0d;
    font-weight: 500;
}

.div-mystore-template12 .col-md-4 {
    text-align: center;
}

.div-mystore-template12 .col-md-4 img {
    width: 100px;
    height: 100px;
    border-radius: 50px;
    margin-bottom: 15px;
    margin-top: 9px;
}

.div-mystore-template12 .btn-myfront-add {
    border: 1px solid #fff591;
    border-radius: 5px;
    background-color: #e41749;
    color: #fff591;
    width: 100%;
    font-weight: 700;
    padding: 3px;
}

.div-mystore-template12 .div-offer-txt {
    left: 25px;
    top: -11px;
    color: #e41749;
    background: #fffcdb;
}

.div-mystore-template12 .star_box {
    border: 1px solid #e41749;
    color: #e41749;
}

.div-mystore-template12 .info {    
    border: 0;    
    margin-left: 2px;
    border-radius: 3px;
    color: #e41749;
    background-color: initial;
    font-size: 18px;        
}

/* Template 12 - Ends */

/* Template 13 - Starts */

.div-mystore-template13 {
    width: 100%;
    background-color: #C63D04;
    border-radius: 15px;
    font: Poppins;
    letter-spacing: 0px;
    padding: 0px 0px 0px 15px;
    margin-bottom: 30px;
}

.div-mystore-template13 .prod_amt {
    color: #fdae7c;
    font-size: 18px;
    font-weight: 700;
}

.div-mystore-template13 .col-md-8 {
    padding: 15px 30px 15px 0px;
}

.div-mystore-template13 .col-md-8 h3,
.div-mystore-template13 .col-md-8 div span {
    color: #E6C391;
    /* font-size: 18px; */
    font-size: 14px;
    font-weight: 700;
    min-height: 35px;
    max-height: 35px;
    overflow: hidden;
}

.div-mystore-template13 .col-md-4 div {

    background-image: url('https://rewardsly.io/members/img/menutemp/13_Furniture.png');
    /*background: url('../img/burger.jpg') no-repeat center center fixed; */
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    border-top-right-radius: 80% 100%;
    /* border-bottom-left-radius: 80% 100%; */
    height: 100%;
}

.div-mystore-template13 .col-md-4 {
    padding-left: 0px;
}

.div-mystore-template13 .col-md-8 p {
    font-size: 11px;
    max-height: 68px;
    min-height: 68px;
    overflow: hidden;
    margin-bottom: 12px;
    color: #fff;
}

.div-mystore-template13 .btn-myfront-add {
    border: 1px solid #C63D04;
    border-radius: 5px;
    background-color: #E6C391;
    color: #C63D04;
    width: 100%;
    font-weight: 700;
    padding: 3px;
}

.div-mystore-template13 .div-offer-txt {
    left: 20%;
    top: 2px;
    color: #C63D04;
    background: #E6C391;
}

.div-mystore-template13 .star_box {
    border: 1px solid #E6C391;
    color: #E6C391;
}

.div-mystore-template13 .info {    
    border: 0;    
    margin-left: 2px;
    border-radius: 3px;
    color: #E6C391;
    background-color: initial;
    font-size: 18px;        
}

/* Template 13 - Ends */

/* Template 14 - Starts */

.div-mystore-template14 {
    width: 100%;
    background-color: #FBDDA9;
    border-radius: 15px;
    font: Poppins;
    letter-spacing: 0px;
    padding: 0px 0px 0px 15px;
    margin-bottom: 30px;
}

.div-mystore-template14 .prod_amt {
    color: #fdae7c;
    font-size: 18px;
    font-weight: 700;
}

.div-mystore-template14 .col-md-8 {
    padding: 15px 30px 15px 0px;
}

.div-mystore-template14 .col-md-8 h3,
.div-mystore-template14 .col-md-8 div span {
    color: #6C4A4E;
    /* font-size: 18px; */
    font-size: 14px;
    font-weight: 700;
    min-height: 35px;
    max-height: 35px;
    overflow: hidden;
}

.div-mystore-template14 .col-md-4 div {

    background-image: url('https://rewardsly.io/members/img/menutemp/14_organic.png');
    /*background: url('../img/burger.jpg') no-repeat center center fixed; */
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    /* border-top-left-radius: 30% 50%; */
    border-bottom-right-radius: 80% 100%;
    height: 100%;
}

.div-mystore-template14 .col-md-4 {
    padding-left: 0px;
}

.div-mystore-template14 .col-md-8 p {
    font-size: 11px;
    max-height: 68px;
    min-height: 68px;
    overflow: hidden;
    margin-bottom: 12px;
    color: #333;
}

.div-mystore-template14 .btn-myfront-add {
    border: 1px solid #FBDDA9;
    border-radius: 5px;
    background-color: #6C4A4E;
    color: #FBDDA9;
    width: 100%;
    font-weight: 700;
    padding: 3px;
}

.div-mystore-template14 .div-offer-txt {
    left: 20%;
    bottom: 2px;
    color: #FBDDA9;
    background: #6C4A4E;
}

.div-mystore-template14 .star_box {
    border: 1px solid #6C4A4E;
    color: #6C4A4E;
}

.div-mystore-template14 .info {    
    border: 0;    
    margin-left: 2px;
    border-radius: 3px;
    color: #6C4A4E;
    background-color: initial;
    font-size: 18px;        
}

/* Template 14 - Ends */

/* Template 15 - Starts */

.div-mystore-template15 {
    width: 100%;
    background-color: #D36427;
    border-radius: 15px;
    font: Poppins;
    letter-spacing: 0px;
    padding: 0px 0px 0px 15px;
    margin-bottom: 30px;
}

.div-mystore-template15 .prod_amt {
    color: #fdae7c;
    font-size: 18px;
    font-weight: 700;
}

.div-mystore-template15 .mr0 {
    margin-right: 0px;
}

.div-mystore-template15 .col-md-8 {
    padding: 15px;
}

.div-mystore-template15 .col-md-8 h3,
.div-mystore-template15 .col-md-8 div span {
    color: #FFEB9D;
    /* font-size: 18px; */
    font-size: 14px;
    font-weight: 700;
    min-height: 35px;
    max-height: 35px;
    overflow: hidden;
}

.div-mystore-template15 .col-md-4 {

    background-image: url('https://rewardsly.io/members/img/menutemp/15_books.png');
    /*background: url('../img/burger.jpg') no-repeat center center fixed; */
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    height: 100%;
}

.div-mystore-template15 .col-md-4 {
    padding-left: 0px;

}

.div-mystore-template15 .col-md-8 p {
    font-size: 11px;
    max-height: 68px;
    min-height: 68px;
    overflow: hidden;
    margin-bottom: 12px;
    color: #fff;
}

.div-mystore-template15 .col-md-4 div.div-left {
    padding-left: 0px;
    width: 20%;
    height: 0;
    border-top: 180px solid #D36427;
    border-right: 80px solid transparent;
}

.div-mystore-template15 .btn-myfront-add {
    border: 1px solid #D36427;
    border-radius: 5px;
    background-color: #FFEB9D;
    color: #D36427;
    width: 100%;
    font-weight: 700;
    padding: 3px;
}

.div-mystore-template15 .div-offer-txt {
    right: 22%;
    top: 2px;
    z-index: 1;
    color: #D36427;
    background: #FFEB9D;
}

.div-mystore-template15 .star_box {
    border: 1px solid #FFEB9D;
    color: #FFEB9D;
}

.div-mystore-template15 .info {    
    border: 0;    
    margin-left: 2px;
    border-radius: 3px;
    color: #FFEB9D;
    background-color: initial;
    font-size: 18px;        
}

/* Template 15 - Ends */

/* Template 16 - Starts */

.div-mystore-template16 {
    width: 100%;
    background-color: #F25AB8;
    border-radius: 15px;
    font: Poppins;
    letter-spacing: 0px;
    padding: 0px 0px 0px 15px;
    margin-bottom: 30px;
}

.div-mystore-template16 .prod_amt {
    color: #fdae7c;
    font-size: 18px;
    font-weight: 700;
}

.div-mystore-template16 .col-md-8 {
    padding: 15px;
}

.div-mystore-template16 .col-md-8 .row {
    margin-right: 0px;
}

.div-mystore-template16 .col-md-8 h3,
.div-mystore-template16 .col-md-8 div span {
    color: #940012;
    /* font-size: 18px; */
    font-size: 11px;
    font-weight: 700;
    min-height: 35px;
    max-height: 35px;
    overflow: hidden;
}

.div-mystore-template16 .col-md-8 p {
    color: #fff;
}

.div-mystore-template16 .col-md-4 {

    background-image: url('https://rewardsly.io/members/img/menutemp/16_electronics.png');
    /*background: url('../img/burger.jpg') no-repeat center center fixed; */
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    height: 100%;
}

.div-mystore-template16 .col-md-4 {
    padding-left: 0px;
    padding-right: 0px;
    ;
}

.div-mystore-template16 .col-md-4 div.div-right {
    /* padding-left: 0px; */
    /* width: 20%; */
    height: 0;
    border-top: 180px solid #F25AB8;
    border-left: 68px solid transparent;
    margin-left: 44%;
}

.div-mystore-template16 .col-md-8 p {
    font-size: 11px;
    max-height: 68px;
    min-height: 68px;
    overflow: hidden;
    margin-bottom: 12px;
}

.div-mystore-template16 .btn-myfront-add {
    border: 1px solid #F25AB8;
    border-radius: 5px;
    background-color: #940012;
    color: #F25AB8;
    width: 100%;
    font-weight: 700;
    padding: 3px;
}

.div-mystore-template16 .div-offer-txt {
    right: 25px;
    top: 2px;
    color: #F25AB8;
    background: #940012;
}

.div-mystore-template16 .star_box {
    border: 1px solid #940012;
    color: #940012;
}

.div-mystore-template16 .info {    
    border: 0;    
    margin-left: 2px;
    border-radius: 3px;
    color: #940012;
    background-color: initial;
    font-size: 18px;        
}
.header_txt16{
	position:relative;
	top: 3px;
	
}
/* Template 16 - Ends */

/* Template 17 - Starts */

.div-mystore-template17 {
    width: 100%;
    background-color: #FCA82F;
    border-radius: 15px;
    font: Poppins;
    letter-spacing: 0px;
    padding: 0px 0px 0px 15px;
    margin-bottom: 30px;
}

.div-mystore-template17 .prod_amt {
    color: #fdae7c;
    font-size: 18px;
    font-weight: 700;
}

.div-mystore-template17 .col-md-8 {
    padding: 15px;
}

.div-mystore-template17 .col-md-8 h3,
.div-mystore-template17 .col-md-8 div span {
    color: #811500;
    /* font-size: 18px; */
    font-size: 14px;
    font-weight: 700;
    min-height: 35px;
    max-height: 35px;
    overflow: hidden;
}

.div-mystore-template17 .col-md-4 div {

    background-image: url('https://rewardsly.io/members/img/menutemp/17_bar.png');
    /*background: url('../img/burger.jpg') no-repeat center center fixed; */
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    border-top-left-radius: 85% 50%;
    border-bottom-left-radius: 85% 50%;
    height: 100%;
}

.div-mystore-template17 .col-md-4 {
    padding-left: 0px;
}

.div-mystore-template17 .col-md-8 p {
    font-size: 11px;
    max-height: 68px;
    min-height: 68px;
    overflow: hidden;
    margin-bottom: 12px;
    color: #fff;
}

.div-mystore-template17 .btn-myfront-add {
    border: 1px solid #FCA82F;
    border-radius: 5px;
    background-color: #811500;
    color: #FCA82F;
    width: 100%;
    font-weight: 700;
    padding: 3px;
}

.div-mystore-template17 .div-offer-txt {
    right: 30%;
    top: 2px;
    color: #FCA82F;
    background: #811500;
}

.div-mystore-template17 .star_box {
    border: 1px solid #811500;
    color: #811500;
}

.div-mystore-template17 .info {    
    border: 0;    
    margin-left: 2px;
    border-radius: 3px;
    color: #811500;
    background-color: initial;
    font-size: 18px;        
}
.header_txt16{
	position:relative;
	top: 3px;
	
}
/* Template 17 - Ends */

/* Template 18 - Starts */

.div-mystore-template18 {
    width: 100%;
    background-color: #F2B9AB;
    border-radius: 15px;
    font: Poppins;
    letter-spacing: 0px;
    padding: 0px 0px 0px 15px;
    margin-bottom: 30px;
}

.div-mystore-template18 .prod_amt {
    color: #fdae7c;
    font-size: 18px;
    font-weight: 700;
}

.div-mystore-template18 .col-md-8 {
    padding: 15px;
}

.div-mystore-template18 .col-md-8 .row {
    margin-right: 0px;
}
.header_txt18{
	position:relative;
	top: 5px;
	
}
.div-mystore-template18 .col-md-8 h3,
.div-mystore-template18 .col-md-8 div span {
    color: #60170C;
    /* font-size: 18px; */
    font-size: 14px;
    font-weight: 700;
    min-height: 35px;
    max-height: 35px;
    overflow: hidden;
}

.div-mystore-template18 .col-md-4 div {
    background-image: url('https://rewardsly.io/members/img/menutemp/18_real-estate.png');
    /*background: url('../img/burger.jpg') no-repeat center center fixed; */
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    border-top-right-radius: 85% 50%;
    border-bottom-right-radius: 85% 50%;
    height: 100%;
}

.div-mystore-template18 .col-md-4 {
    padding-left: 0px;
}

.div-mystore-template18 .col-md-8 p {
    font-size: 11px;
    max-height: 68px;
    min-height: 68px;
    overflow: hidden;
    margin-bottom: 12px;
    color: #333;
}

.div-mystore-template18 .btn-myfront-add {
    border: 1px solid #F2B9AB;
    border-radius: 5px;
    background-color: #60170C;
    color: #F2B9AB;
    width: 100%;
    font-weight: 700;
    padding: 3px;
}

.div-mystore-template18 .div-offer-txt {
    right: 25px;
    top: 5px;
    color: #F2B9AB;
    background: #60170C;
}

.div-mystore-template18 .star_box {
    border: 1px solid #60170C;
    color: #60170C;
}

.div-mystore-template18 .info {    
    border: 0;    
    margin-left: 2px;
    border-radius: 3px;
    color: #60170C;
    background-color: initial;
    font-size: 18px;        
}

/* Template 18 - Ends */

/* Template 19 - Starts */

.div-mystore-template19 {
    width: 100%;
    background-color: #8E2F1D;
    border-radius: 15px;
    font: Poppins;
    letter-spacing: 0px;
    padding: 0px 0px 0px 15px;
    margin-bottom: 30px;
}

.div-mystore-template19 .prod_amt {
    color: #fdae7c;
    font-size: 18px;
    font-weight: 700;
}

.div-mystore-template19 .col-md-8 {
    padding: 15px;
}

.div-mystore-template19 .col-md-8 h3,
.div-mystore-template19 .col-md-8 div span {
    color: #F2CFB7;
    /* font-size: 18px; */
    font-size: 14px;
    font-weight: 700;
    min-height: 35px;
    max-height: 35px;
    overflow: hidden;
}
.header_txt19{
	position:relative;
	top: 3px;
	
}
.div-mystore-template19 .col-md-4 div {

    background-image: url('https://rewardsly.io/members/img/menutemp/19_table.png');
    /*background: url('../img/burger.jpg') no-repeat center center fixed; */
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    /* border-top-left-radius: 30% 50%; */
    border-bottom-left-radius: 30% 50%;
    height: 100%;
}

.div-mystore-template19 .col-md-4 {
    padding-left: 0px;
}

.div-mystore-template19 .col-md-8 p {
    font-size: 11px;
    max-height: 68px;
    min-height: 68px;
    overflow: hidden;
    margin-bottom: 12px;
    color: #fff;
}

.div-mystore-template19 .btn-myfront-add {
    border: 1px solid #8E2F1D;
    border-radius: 5px;
    background-color: #F2CFB7;
    color: #8E2F1D;
    width: 100%;
    font-weight: 700;
    padding: 3px;
}

.div-mystore-template19 .div-offer-txt {
    right: 35%;
    top: 2px;
    color: #8E2F1D;
    background: #F2CFB7;
}

.div-mystore-template19 .star_box {
    border: 1px solid #F2CFB7;
    color: #F2CFB7;
}

.div-mystore-template19 .info {    
    border: 0;    
    margin-left: 2px;
    border-radius: 3px;
    color: #F2CFB7;
    background-color: initial;
    font-size: 18px;        
}

/* Template 19 - Ends */

/* Template 20 - Starts */

.div-mystore-template20 {
    width: 100%;
    background-color: #D16A02;
    border-radius: 15px;
    font: Poppins;
    letter-spacing: 0px;
    padding: 0px 0px 0px 15px;
    margin-bottom: 30px;
}

.div-mystore-template20 .prod_amt {
    color: #fdae7c;
    font-size: 18px;
    font-weight: 700;
}

.div-mystore-template20 .col-md-8 {
    padding: 15px 15px 15px 0px;
}

.div-mystore-template20 .col-md-8 .row {
    margin-right: 0px;
}

.div-mystore-template20 .col-md-8 h3,
.div-mystore-template20 .col-md-8 div span {
    color: #303708;
    /* font-size: 18px; */
    font-size: 14px;
    font-weight: 700;
    min-height: 35px;
    max-height: 35px;
    overflow: hidden;
}

.div-mystore-template20 .col-md-4 div {
    background-image: url('https://rewardsly.io/members/img/menutemp/20_videoediting.png');
    /*background: url('../img/burger.jpg') no-repeat center center fixed; */
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    /* border-top-right-radius: 30% 50%; */
    border-bottom-right-radius: 30% 50%;
    height: 100%;
}

.div-mystore-template20 .col-md-4 {
    padding-left: 0px;
}

.div-mystore-template20 .col-md-8 p {
    font-size: 11px;
    max-height: 68px;
    min-height: 68px;
    overflow: hidden;
    margin-bottom: 12px;
    color: #fff;
}

.div-mystore-template20 .btn-myfront-add {
    border: 1px solid #D16A02;
    border-radius: 5px;
    background-color: #303708;
    color: #D16A02;
    width: 100%;
    font-weight: 700;
    padding: 3px;
}

.div-mystore-template20 .div-offer-txt {
    right: 25px;
    top: 5px;
    color: #D16A02;
    background: #303708;
}

.div-mystore-template20 .star_box {
    border: 1px solid #303708;
    color: #303708;
}

.div-mystore-template20 .info {    
    border: 0;    
    margin-left: 2px;
    border-radius: 3px;
    color: #303708;
    background-color: initial;
    font-size: 18px;        
}
.header_txt20{
	position:relative;
	top:5px;
	
}

/* Template 20 - Ends */

/* Template 21 - Starts */

.div-mystore-template21 {
    width: 100%;
    background-color: #FFBC01;
    border-radius: 15px;
    font: Poppins;
    letter-spacing: 0px;
    padding: 0px 0px 0px 15px;
    margin-bottom: 30px;
}

.div-mystore-template21 .prod_amt {
    color: #fdae7c;
    font-size: 18px;
    font-weight: 700;
}

.div-mystore-template21 .col-md-8 {
    padding: 15px;
}

.div-mystore-template21 .col-md-8 h3,
.div-mystore-template21 .col-md-8 div span {
    color: #882E36;
    /* font-size: 18px; */
    font-size: 14px;
    font-weight: 700;
    min-height: 35px;
    max-height: 35px;
    overflow: hidden;
}

.div-mystore-template21 .col-md-4 div {

    background-image: url('https://rewardsly.io/members/img/menutemp/1-cars.png');
    /*background: url('../img/burger.jpg') no-repeat center center fixed; */
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    border-top-left-radius: 30% 50%;
    /* border-bottom-left-radius: 30% 50%; */
    height: 100%;
}

.div-mystore-template21 .col-md-4 {
    padding-left: 0px;
}

.div-mystore-template21 .col-md-8 p {
    font-size: 11px;
    max-height: 68px;
    min-height: 68px;
    overflow: hidden;
    margin-bottom: 12px;
    color: #333;
}

.div-mystore-template21 .btn-myfront-add {
    border: 1px solid #FFBC01;
    border-radius: 5px;
    background-color: #882E36;
    color: #FFBC01;
    width: 100%;
    font-weight: 700;
    padding: 3px;
}

.div-mystore-template21 .div-offer-txt {
    right: 30%;
    top: 2px;
    color: #FFBC01;
    background: #882E36;
}

.div-mystore-template21 .star_box {
    border: 1px solid #882E36;
    color: #882E36;
}

.div-mystore-template21 .info {    
    border: 0;    
    margin-left: 2px;
    border-radius: 3px;
    color: #882E36;
    background-color: initial;
    font-size: 18px;        
}

/* Template 21 - Ends */

/* Template 22 - Starts */

.div-mystore-template22 {
    width: 100%;
    background-color: #96CD5B;
    border-radius: 15px;
    font: Poppins;
    letter-spacing: 0px;
    padding: 0px 0px 0px 15px;
    margin-bottom: 30px;
}

.div-mystore-template22 .prod_amt {
    color: #fdae7c;
    font-size: 18px;
    font-weight: 700;
}

.div-mystore-template22 .col-md-8 {
    padding: 15px 15px 15px 0px;
}

.div-mystore-template22 .col-md-8 .row {
    margin-right: 0px;
}

.div-mystore-template22 .col-md-8 h3,
.div-mystore-template22 .col-md-8 div span {
    color: #4E0C49;
    /* font-size: 18px; */
    font-size: 14px;
    font-weight: 700;
    min-height: 35px;
    max-height: 35px;
    overflow: hidden;
}

.div-mystore-template22 .col-md-4 div {
     background-image: url('https://rewardsly.io/members/img/menutemp/3-spa.png');
    /*background: url('../img/burger.jpg') no-repeat center center fixed; */
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    border-top-right-radius: 30% 50%;
    /* border-bottom-right-radius: 30% 50%; */
    height: 100%;
}

.div-mystore-template22 .col-md-4 {
    padding-left: 0px;
}

.div-mystore-template22 .col-md-8 p {
    font-size: 11px;
    max-height: 68px;
    min-height: 68px;
    overflow: hidden;
    margin-bottom: 12px;
    color: #333;
}

.div-mystore-template22 .btn-myfront-add {
    border: 1px solid #96CD5B;
    border-radius: 5px;
    background-color: #4E0C49;
    color: #96CD5B;
    width: 100%;
    font-weight: 700;
    padding: 3px;
}

.div-mystore-template22 .div-offer-txt {
    right: 25px;
    top: 5px;
    color: #96CD5B;
    background: #4E0C49;
}

.div-mystore-template22 .star_box {
    border: 1px solid #4E0C49;
    color: #4E0C49;
}

.div-mystore-template22 .info {    
    border: 0;    
    margin-left: 2px;
    border-radius: 3px;
    color: #4E0C49;
    background-color: initial;
    font-size: 18px;        
}

/* Template 22 - Ends */

/* Template 23 - Starts */

.div-mystore-template23 {
    width: 100%;
    background-color: #FEC569;
    border-radius: 15px;
    font: Poppins;
    letter-spacing: 0px;
    padding: 0px 0px 0px 15px;
    margin-bottom: 30px;
}

.div-mystore-template23 .prod_amt {
    color: #fdae7c;
    font-size: 18px;
    font-weight: 700;
}

.div-mystore-template23 .col-md-8 {
    padding: 15px;
}

.div-mystore-template23 .col-md-8 h3,
.div-mystore-template23 .col-md-8 div span {
    color: #2B0101;
    /* font-size: 18px; */
    font-size: 14px;
    font-weight: 700;
    min-height: 35px;
    max-height: 35px;
    overflow: hidden;
}

.div-mystore-template23 .col-md-4 div {

    background-image: url('https://rewardsly.io/members/img/menutemp/2-gym.png');
    /*background: url('../img/burger.jpg') no-repeat center center fixed; */
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    border-top-left-radius: 85% 30%;
    border-bottom-left-radius: 85% 30%;
    height: 100%;
}

.div-mystore-template23 .col-md-4 {
    padding-left: 0px;
}

.div-mystore-template23 .col-md-8 p {
    font-size: 11px;
    max-height: 68px;
    min-height: 68px;
    overflow: hidden;
    margin-bottom: 12px;
    color: #333;
}

.div-mystore-template23 .btn-myfront-add {
    border: 1px solid #FEC569;
    border-radius: 5px;
    background-color: #2B0101;
    color: #FEC569;
    width: 100%;
    font-weight: 700;
    padding: 3px;
}

.div-mystore-template23 .div-offer-txt {
    right: 30%;
    top: 2px;
    color: #FEC569;
    background: #2B0101;
}

.div-mystore-template23 .star_box {
    border: 1px solid #2B0101;
    color: #2B0101;
}

.div-mystore-template23 .info {    
    border: 0;    
    margin-left: 2px;
    border-radius: 3px;
    color: #2B0101;
    background-color: initial;
    font-size: 18px;        
}

/* Template 23 - Ends */

/* Template 24 - Starts */

.div-mystore-template24 {
    width: 100%;
    background-color: #F39FC8;
    border-radius: 15px;
    font: Poppins;
    letter-spacing: 0px;
    padding: 0px 0px 0px 15px;
    margin-bottom: 30px;
}

.div-mystore-template24 .prod_amt {
    color: #fdae7c;
    font-size: 18px;
    font-weight: 700;
}

.div-mystore-template24 .col-md-8 {
    padding: 15px 15px 15px 0px;
}

.div-mystore-template24 .col-md-8 .row {
    margin-right: 0px;
}

.div-mystore-template24 .col-md-8 h3,
.div-mystore-template24 .col-md-8 div span {
    color: #1A5093;
    /* font-size: 18px; */
    font-size: 14px;
    font-weight: 700;
    min-height: 35px;
    max-height: 35px;
    overflow: hidden;
}

.div-mystore-template24 .col-md-4 div {
     background-image: url('https://rewardsly.io/members/img/menutemp/4-skincare.png');
    /*background: url('../img/burger.jpg') no-repeat center center fixed; */
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    border-top-right-radius: 85% 30%;
    border-bottom-right-radius: 85% 30%;
    height: 100%;
}

.div-mystore-template24 .col-md-4 {
    padding-left: 0px;
}

.div-mystore-template24 .col-md-8 p {
    font-size: 11px;
    max-height: 68px;
    min-height: 68px;
    overflow: hidden;
    margin-bottom: 12px;
    color: #333;
}

.div-mystore-template24 .btn-myfront-add {
    border: 1px solid #F39FC8;
    border-radius: 5px;
    background-color: #1A5093;
    color: #F39FC8;
    width: 100%;
    font-weight: 700;
    padding: 3px;
}

.div-mystore-template24 .div-offer-txt {
    right: 25px;
    top: 5px;
    color: #F39FC8;
    background: #1A5093;
}

.div-mystore-template24 .star_box {
    border: 1px solid #1A5093;
    color: #1A5093;
}

.div-mystore-template24 .info {    
    border: 0;    
    margin-left: 2px;
    border-radius: 3px;
    color: #1A5093;
    background-color: initial;
    font-size: 18px;        
}

/* Template 24 - Ends */

/* Template 25 - Starts */

.div-mystore-template25 {
    width: 100%;
    background-color: #D8BA87;
    border-radius: 15px;
    /* font: Poppins; */
    letter-spacing: 0px;
    padding: 15px;
    margin-bottom: 30px;
}

.div-mystore-template25 .prod_amt {
    color: #fdae7c;
    font-size: 18px;
    font-weight: 700;
}

.div-mystore-template25 .col-md-8 h3,
.div-mystore-template25 .col-md-8 div span {
    color: #036602;
    /* font-size: 18px; */
    font-size: 14px;
    font-weight: 700;
    min-height: 35px;
    max-height: 35px;
    overflow: hidden;
}

.div-mystore-template25 .col-md-8 p {
    font-size: 11px;
    max-height: 68px;
    min-height: 68px;
    overflow: hidden;
    margin-bottom: 12px;
}

.div-mystore-template25 .btn-myfront-add {
    border: 1px solid #D8BA87;
    border-radius: 5px;
    color: #D8BA87;
    width: 100%;
    font-weight: 700;
    padding: 3px;
    background-color: #036602;
}

.div-mystore-template25 .col-md-4 {
    padding-left: 0px;
    text-align: center;
}

.div-mystore-template25 .col-md-4 img {
    width: 100px;
    height: 100px;
    border-radius: 30px 5px;
    margin-bottom: 15px;
    margin-top: 9px;
}

.div-mystore-template25 .div-offer-txt {
    right: 25px;
    top: 5px;
    color: #D8BA87;
    background: #036602;
}

.div-mystore-template25 .star_box {
    border: 1px solid #036602;
    color: #036602;
}

.div-mystore-template25 .info {    
    border: 0;    
    margin-left: 2px;
    border-radius: 3px;
    color: #036602;
    background-color: initial;
    font-size: 18px;        
}

/* Template 25 - Ends */

/* Template 26 - Starts */

.div-mystore-template26 {
    width: 100%;
    background-color: #E42A11;
    border-radius: 15px;
    /* font: Poppins; */
    letter-spacing: 0px;
    padding: 15px;
    margin-bottom: 30px;
}

.div-mystore-template26 .prod_amt {
    color: #fdae7c;
    font-size: 18px;
    font-weight: 700;
}

.div-mystore-template26 .col-md-8 h3,
.div-mystore-template26 .col-md-8 div span {
    color: #FBF3C3;
    /* font-size: 18px; */
    font-size: 14px;
    font-weight: 700;
    min-height: 35px;
    max-height: 35px;
    overflow: hidden;
}

.div-mystore-template26 .col-md-8 p {
    font-size: 11px;
    max-height: 68px;
    min-height: 68px;
    overflow: hidden;
    margin-bottom: 12px;
    color: #fff;
}

.div-mystore-template26 .col-md-4 {
    text-align: center;
}

.div-mystore-template26 .col-md-4 img {
    width: 100px;
    height: 100px;
    border-radius: 30px 5px;
    margin-bottom: 15px;
    margin-top: 10px;
}

.div-mystore-template26 .btn-myfront-add {
    border: 1px solid #E42A11;
    border-radius: 5px;
    background-color: #FBF3C3;
    color: #E42A11;
    width: 100%;
    font-weight: 700;
    padding: 3px;
}

.div-mystore-template26 .div-offer-txt {
    right: 25px;
    top: 5px;
    color: #E42A11;
    background: #FBF3C3;
}

.div-mystore-template26 .star_box {
    border: 1px solid #FBF3C3;
    color: #FBF3C3;
}

.div-mystore-template26 .info {    
    border: 0;    
    margin-left: 2px;
    border-radius: 3px;
    color: #FBF3C3;
    background-color: initial;
    font-size: 18px;        
}

/* Template 26 - Ends */

/* Template 27 - Starts */

.div-mystore-template27 {
    width: 100%;
    background-color: #C4C1A8;
    border-radius: 15px;
    font: Poppins;
    letter-spacing: 0px;
    padding: 0px 0px 0px 15px;
    margin-bottom: 30px;
}

.div-mystore-template27 .prod_amt {
    color: #fdae7c;
    font-size: 18px;
    font-weight: 700;
}

.div-mystore-template27 .col-md-8 {
    padding: 15px;
}

.div-mystore-template27 .col-md-8 h3,
.div-mystore-template27 .col-md-8 div span {
    color: #577801;
    /* font-size: 18px; */
    font-size: 14px;
    font-weight: 700;
    min-height: 35px;
    max-height: 35px;
    overflow: hidden;
}

.div-mystore-template27 .col-md-4 div.div-back-img {

     background-image: url('https://rewardsly.io/members/img/menutemp/8-interior-designing.png');
    /*background: url('../img/burger.jpg') no-repeat center center fixed; */
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    height: 100%;
}

.div-mystore-template27 .col-md-4 div.triangle-right {
    width: 0;
    height: 0;
    border-top: 12px solid transparent;
    border-left: 20px solid #C4C1A8;
    border-bottom: 12px solid transparent;
    margin-top: 45%;
    position: absolute;
}

.div-mystore-template27 .col-md-4 {
    padding-left: 0px;
}

.div-mystore-template27 .col-md-8 p {
    font-size: 11px;
    max-height: 68px;
    min-height: 68px;
    overflow: hidden;
    margin-bottom: 12px;
    color: #333;
}

.div-mystore-template27 .btn-myfront-add {
    border: 1px solid #C4C1A8;
    border-radius: 5px;
    background-color: #577801;
    color: #C4C1A8;
    width: 100%;
    font-weight: 700;
    padding: 3px;
}

.div-mystore-template27 .div-offer-txt {
    right: 5px;
    bottom: 5px;
    color: #C4C1A8;
    background: #577801;
}

.div-mystore-template27 .star_box {
    border: 1px solid #577801;
    color: #577801;
}

.div-mystore-template27 .info {    
    border: 0;    
    margin-left: 2px;
    border-radius: 3px;
    color: #577801;
    background-color: initial;
    font-size: 18px;        
}

/* Template 27 - Ends */

/* Template 28 - Starts */

.div-mystore-template28 {
    width: 100%;
    background-color: #2E3037;
    border-radius: 15px;
    font: Poppins;
    letter-spacing: 0px;
    padding: 0px 0px 0px 15px;
    margin-bottom: 30px;
}

.div-mystore-template28 .prod_amt {
    color: #fdae7c;
    font-size: 18px;
    font-weight: 700;
}

.div-mystore-template28 .col-md-8 {
    padding: 15px;
}

.div-mystore-template28 .col-md-8 .row {
    margin-right: 0px;
}

.div-mystore-template28 .col-md-8 h3,
.div-mystore-template28 .col-md-8 div span {
    color: #F7EEDD;
    /* font-size: 18px; */
    font-size: 14px;
    font-weight: 700;
    min-height: 35px;
    max-height: 35px;
    overflow: hidden;
}

.div-mystore-template28 .col-md-4 div.div-back-img {
    background-image: url('https://rewardsly.io/members/img/menutemp/10-photography.png');
    /*background: url('../img/burger.jpg') no-repeat center center fixed; */
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    height: 100%;
}

.div-mystore-template28 .col-md-4 div.triangle-left {
    width: 0;
    height: 0;
    border-top: 12px solid transparent;
    border-right: 20px solid #2E3037;
    border-bottom: 12px solid transparent;
    margin-top: 45%;
    position: absolute;
    right: 0px;
}

.div-mystore-template28 .col-md-4 {
    padding-left: 0px;
    padding-right: 0px;
}

.div-mystore-template28 .col-md-8 p {
    font-size: 11px;
    max-height: 68px;
    min-height: 68px;
    overflow: hidden;
    margin-bottom: 12px;
    color: #fff;
}

.div-mystore-template28 .btn-myfront-add {
    border: 1px solid #2E3037;
    border-radius: 5px;
    background-color: #F7EEDD;
    color: #2E3037;
    width: 100%;
    font-weight: 700;
    padding: 3px;
}

.div-mystore-template28 .div-offer-txt {
    right: 25px;
    top: 5px;
    color: #2E3037;
    background: #F7EEDD;
}

.div-mystore-template28 .star_box {
    border: 1px solid #F7EEDD;
    color: #F7EEDD;
}

.div-mystore-template28 .info {    
    border: 0;    
    margin-left: 2px;
    border-radius: 3px;
    color: #F7EEDD;
    background-color: initial;
    font-size: 18px;        
}

/* Template 28 - Ends */

/* Template 29 - Starts */

.div-mystore-template29 {
    width: 100%;
    background-color: #DFBE79;
    border-radius: 15px;
    font: Poppins;
    letter-spacing: 0px;
    padding: 0px 0px 0px 15px;
    margin-bottom: 30px;
}

.div-mystore-template29 .prod_amt {
    color: #fdae7c;
    font-size: 18px;
    font-weight: 700;
}

.div-mystore-template29 .col-md-8 {
    padding: 15px;
}

.div-mystore-template29 .col-md-8 h3,
.div-mystore-template29 .col-md-8 div span {
    color: #131516;
    /* font-size: 18px; */
    font-size: 14px;
    font-weight: 700;
    min-height: 35px;
    max-height: 35px;
    overflow: hidden;
}

.div-mystore-template29 .col-md-4 div.div-back-img {

     background-image: url('https://rewardsly.io/members/img/menutemp/11-Restaurant.png');
    /*background: url('../img/burger.jpg') no-repeat center center fixed; */
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    height: 100%;
}

.div-mystore-template29 .col-md-4 div.triangle-right {
    width: 0;
    height: 0;
    border-top: 92px solid transparent;
    border-left: 50px solid #DFBE79;
    border-bottom: 92px solid transparent;
    /* margin-top: 45%; */
    position: absolute;
}

.div-mystore-template29 .col-md-4 {
    padding-left: 0px;
}

.div-mystore-template29 .col-md-8 p {
    font-size: 11px;
    max-height: 68px;
    min-height: 68px;
    overflow: hidden;
    margin-bottom: 12px;
    color: #333;
}

.div-mystore-template29 .btn-myfront-add {
    border: 1px solid #DFBE79;
    border-radius: 5px;
    background-color: #131516;
    color: #DFBE79;
    width: 100%;
    font-weight: 700;
    padding: 3px;
}

.div-mystore-template29 .div-offer-txt {
    right: 5px;
    bottom: 2px;
    color: #DFBE79;
    background: #131516;
}

.div-mystore-template29 .star_box {
    border: 1px solid #131516;
    color: #131516;
}

.div-mystore-template29 .info {    
    border: 0;    
    margin-left: 2px;
    border-radius: 3px;
    color: #131516;
    background-color: initial;
    font-size: 18px;        
}

/* Template 29 - Ends */

/* Template 30 - Starts */

.div-mystore-template30 {
    width: 100%;
    background-color: #643313;
    border-radius: 15px;
    font: Poppins;
    letter-spacing: 0px;
    padding: 0px 0px 0px 15px;
    margin-bottom: 30px;
}

.div-mystore-template30 .prod_amt {
    color: #fdae7c;
    font-size: 18px;
    font-weight: 700;
}

.div-mystore-template30 .col-md-8 {
    padding: 15px;
}

.div-mystore-template30 .col-md-8 .row {
    margin-right: 0px;
}

.div-mystore-template30 .col-md-8 h3,
.div-mystore-template30 .col-md-8 div span {
    color: #F5F7F9;
    /* font-size: 18px; */
    font-size: 14px;
    font-weight: 700;
    min-height: 35px;
    max-height: 35px;
    overflow: hidden;
}

.div-mystore-template30 .col-md-4 div.div-back-img {
    background-image: url('https://rewardsly.io/members/img/menutemp/12-makeup-products.png');
    /*background: url('../img/burger.jpg') no-repeat center center fixed; */
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    height: 100%;
}

.div-mystore-template30 .col-md-4 div.triangle-left {
    width: 0;
    height: 0;
    border-top: 92px solid transparent;
    border-right: 50px solid #643313;
    border-bottom: 92px solid transparent;
    /* margin-top: 45%; */
    position: absolute;
    right: 0px;
}

.div-mystore-template30 .col-md-4 {
    padding-left: 0px;
    padding-right: 0px;
}

.div-mystore-template30 .col-md-8 p {
    font-size: 11px;
    max-height: 68px;
    min-height: 68px;
    overflow: hidden;
    margin-bottom: 12px;
    color: #fff;
}

.div-mystore-template30 .btn-myfront-add {
    border: 1px solid #643313;
    border-radius: 5px;
    background-color: #F5F7F9;
    color: #643313;
    width: 100%;
    font-weight: 700;
    padding: 3px;
}

.div-mystore-template30 .div-offer-txt {
    right: 25px;
    top: 5px;
    color: #643313;
    background: #F5F7F9;
}

.div-mystore-template30 .star_box {
    border: 1px solid #F5F7F9;
    color: #F5F7F9;
}

.div-mystore-template30 .info {    
    border: 0;    
    margin-left: 2px;
    border-radius: 3px;
    color: #F5F7F9;
    background-color: initial;
    font-size: 18px;        
}

/* Template 30 - Ends */

/* Template 31 - Starts */

.div-mystore-template31 {
    width: 100%;
    background-color: #D2AA7C;
    border-radius: 15px;
    font: Poppins;
    letter-spacing: 0px;
    padding: 0px 0px 0px 15px;
    margin-bottom: 30px;
}

.div-mystore-template31 .prod_amt {
    color: #fdae7c;
    font-size: 18px;
    font-weight: 700;
}

.div-mystore-template31 .col-md-8 {
    padding: 15px;
}

.div-mystore-template31 .col-md-8 h3,
.div-mystore-template31 .col-md-8 div span {
    color: #112E04;
    /* font-size: 18px; */
    font-size: 14px;
    font-weight: 700;
    min-height: 35px;
    max-height: 35px;
    overflow: hidden;
}

.div-mystore-template31 .col-md-4 div.div-back-img {

    background-image: url('https://rewardsly.io/members/img/menutemp/13-clothing.png');
    /*background: url('../img/burger.jpg') no-repeat center center fixed; */
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    height: 100%;
}

.div-mystore-template31 .col-md-4 div.triangle-right {
    width: 0;
    height: 0;
    border-top: 50px solid transparent;
    border-left: 30px solid #D2AA7C;
    border-bottom: 135px solid transparent;
    /* margin-top: 45%; */
    position: absolute;
}

.div-mystore-template31 .col-md-4 {
    padding-left: 0px;
}

.div-mystore-template31 .col-md-8 p {
    font-size: 11px;
    max-height: 68px;
    min-height: 68px;
    overflow: hidden;
    margin-bottom: 12px;
    color: #333;
}

.div-mystore-template31 .btn-myfront-add {
    border: 1px solid #D2AA7C;
    border-radius: 5px;
    background-color: #112E04;
    color: #D2AA7C;
    width: 100%;
    font-weight: 700;
    padding: 3px;
}

.div-mystore-template31 .div-offer-txt {
    right: 5px;
    bottom: 2px;
    color: #D2AA7C;
    background: #112E04;
}

.div-mystore-template31 .star_box {
    border: 1px solid #112E04;
    color: #112E04;
}

.div-mystore-template31 .info {    
    border: 0;    
    margin-left: 2px;
    border-radius: 3px;
    color: #112E04;
    background-color: initial;
    font-size: 18px;        
}

/* Template 31 - Ends */

/* Template 32 - Starts */

.div-mystore-template32 {
    width: 100%;
    background-color: #D9C254;
    border-radius: 15px;
    font: Poppins;
    letter-spacing: 0px;
    padding: 0px 0px 0px 15px;
    margin-bottom: 30px;
}

.div-mystore-template32 .prod_amt {
    color: #fdae7c;
    font-size: 18px;
    font-weight: 700;
}

.div-mystore-template32 .col-md-8 {
    padding: 15px;
}

.div-mystore-template32 .col-md-8 .row {
    margin-right: 0px;
}

.div-mystore-template32 .col-md-8 h3,
.div-mystore-template32 .col-md-8 div span {
    color: #C65A39;
    /* font-size: 18px; */
    font-size: 14px;
    font-weight: 700;
    min-height: 35px;
    max-height: 35px;
    overflow: hidden;
}

.div-mystore-template32 .col-md-4 div.div-back-img {
      background-image: url('https://rewardsly.io/members/img/menutemp/9-gardening.png');
    /*background: url('../img/burger.jpg') no-repeat center center fixed; */
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    height: 100%;
}

.div-mystore-template32 .col-md-4 div.triangle-left {
    width: 0;
    height: 0;
    border-top: 50px solid transparent;
    border-right: 30px solid #D9C254;
    border-bottom: 135px solid transparent;
    /* margin-top: 45%; */
    position: absolute;
    right: 0px;
}

.div-mystore-template32 .col-md-4 {
    padding-left: 0px;
    padding-right: 0px;
}

.div-mystore-template32 .col-md-8 p {
    font-size: 11px;
    max-height: 68px;
    min-height: 68px;
    overflow: hidden;
    margin-bottom: 12px;
    color: #333;
}

.div-mystore-template32 .btn-myfront-add {
    border: 1px solid #D9C254;
    border-radius: 5px;
    background-color: #C65A39;
    color: #D9C254;
    width: 100%;
    font-weight: 700;
    padding: 3px;
}

.div-mystore-template32 .div-offer-txt {
    right: 25px;
    top: 5px;
    color: #D9C254;
    background: #C65A39;
}

.div-mystore-template32 .star_box {
    border: 1px solid #C65A39;
    color: #C65A39;
}

.div-mystore-template32 .info {    
    border: 0;    
    margin-left: 2px;
    border-radius: 3px;
    color: #C65A39;
    background-color: initial;
    font-size: 18px;        
}

/* Template 32 - Ends */

/* Template 33 - Starts */

.div-mystore-template33 {
    width: 100%;
    background-color: #63422F;
    border-radius: 15px;
    font: Poppins;
    letter-spacing: 0px;
    padding: 0px 0px 0px 15px;
    margin-bottom: 30px;
}

.div-mystore-template33 .prod_amt {
    color: #fdae7c;
    font-size: 18px;
    font-weight: 700;
}

.div-mystore-template33 .col-md-8 {
    padding: 15px;
}

.div-mystore-template33 .col-md-8 h3,
.div-mystore-template33 .col-md-8 div span {
    color: #B8B9B7;
    /* font-size: 18px; */
    font-size: 14px;
    font-weight: 700;
    min-height: 35px;
    max-height: 35px;
    overflow: hidden;
}

.div-mystore-template33 .col-md-4 div.div-back-img {

     background-image: url('https://rewardsly.io/members/img/menutemp/14-book-store.png');
    /*background: url('../img/burger.jpg') no-repeat center center fixed; */
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    height: 100%;
}

.div-mystore-template33 .col-md-4 div.triangle-right {
    width: 0;
    height: 0;
    border-top: 135px solid transparent;
    border-left: 30px solid #63422F;
    border-bottom: 50px solid transparent;
    /* margin-top: 45%; */
    position: absolute;
}

.div-mystore-template33 .col-md-4 {
    padding-left: 0px;
}

.div-mystore-template33 .col-md-8 p {
    font-size: 11px;
    max-height: 68px;
    min-height: 68px;
    overflow: hidden;
    margin-bottom: 12px;
    color: #fff;
}

.div-mystore-template33 .btn-myfront-add {
    border: 1px solid #63422F;
    border-radius: 5px;
    background-color: #B8B9B7;
    color: #63422F;
    width: 100%;
    font-weight: 700;
    padding: 3px;
}

.div-mystore-template33 .div-offer-txt {
    right: 5px;
    bottom: 2px;
    color: #63422F;
    background: #B8B9B7;
}

.div-mystore-template33 .star_box {
    border: 1px solid #B8B9B7;
    color: #B8B9B7;
}

.div-mystore-template33 .info {    
    border: 0;    
    margin-left: 2px;
    border-radius: 3px;
    color: #B8B9B7;
    background-color: initial;
    font-size: 18px;        
}

/* Template 33 - Ends */

/* Template 34 - Starts */

.div-mystore-template34 {
    width: 100%;
    background-color: #D97208;
    border-radius: 15px;
    font: Poppins;
    letter-spacing: 0px;
    padding: 0px 0px 0px 15px;
    margin-bottom: 30px;
}

.div-mystore-template34 .prod_amt {
    color: #fdae7c;
    font-size: 18px;
    font-weight: 700;
}

.div-mystore-template34 .col-md-8 {
    padding: 15px;
}

.div-mystore-template34 .col-md-8 .row {
    margin-right: 0px;
}

.div-mystore-template34 .col-md-8 h3,
.div-mystore-template34 .col-md-8 div span {
    color: #101011;
    /* font-size: 18px; */
    font-size: 14px;
    font-weight: 700;
    min-height: 35px;
    max-height: 35px;
    overflow: hidden;
}

.div-mystore-template34 .col-md-4 div.div-back-img {
     background-image: url('https://rewardsly.io/members/img/menutemp/7-pet-store.png');
    /*background: url('../img/burger.jpg') no-repeat center center fixed; */
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    height: 100%;
}

.div-mystore-template34 .col-md-4 div.triangle-left {
    width: 0;
    height: 0;
    border-top: 135px solid transparent;
    border-right: 30px solid #D97208;
    border-bottom: 50px solid transparent;
    /* margin-top: 45%; */
    position: absolute;
    right: 0px;
}

.div-mystore-template34 .col-md-4 {
    padding-left: 0px;
    padding-right: 0px;
}

.div-mystore-template34 .col-md-8 p {
    font-size: 11px;
    max-height: 68px;
    min-height: 68px;
    overflow: hidden;
    margin-bottom: 12px;
    color: #fff;
}

.div-mystore-template34 .btn-myfront-add {
    border: 1px solid #D97208;
    border-radius: 5px;
    background-color: #101011;
    color: #D97208;
    width: 100%;
    font-weight: 700;
    padding: 3px;
}

.div-mystore-template34 .div-offer-txt {
    right: 25px;
    top: 5px;
    color: #D97208;
    background: #101011;
}

.div-mystore-template34 .star_box {
    border: 1px solid #101011;
    color: #101011;
}

.div-mystore-template34 .info {    
    border: 0;    
    margin-left: 2px;
    border-radius: 3px;
    color: #101011;
    background-color: initial;
    font-size: 18px;        
}

/* Template 34 - Ends */

/* Template 35 - Starts */

.div-mystore-template35 {
    width: 100%;
    background-color: #FCC203;
    border-radius: 15px;
    /* font: Poppins; */
    letter-spacing: 0px;
    padding: 15px;
    margin-bottom: 30px;
}

.div-mystore-template35 .prod_amt {
    color: #fdae7c;
    font-size: 18px;
    font-weight: 700;
}

.div-mystore-template35 .col-md-8 h3,
.div-mystore-template35 .col-md-8 div span {
    color: #1F3402;
    /* font-size: 18px; */
    font-size: 14px;
    font-weight: 700;
    min-height: 35px;
    max-height: 35px;
    overflow: hidden;
}

.div-mystore-template35 .col-md-8 p {
    font-size: 11px;
    max-height: 68px;
    min-height: 68px;
    overflow: hidden;
    margin-bottom: 12px;
}

.div-mystore-template35 .btn-myfront-add {
    border: 1px solid #FCC203;
    border-radius: 5px;
    color: #FCC203;
    width: 100%;
    font-weight: 700;
    padding: 3px;
    background-color: #1F3402;
}

.div-mystore-template35 .col-md-4 {
    padding-left: 0px;
    text-align: center;
}

.div-mystore-template35 .col-md-4 img {
    width: 100px;
    height: 100px;
    border-radius: 5px 30px;
    margin-bottom: 15px;
    margin-top: 9px;
}

.div-mystore-template35 .div-offer-txt {
    right: 25px;
    top: 5px;
    color: #FCC203;
    background: #1F3402;
}

.div-mystore-template35 .star_box {
    border: 1px solid #1F3402;
    color: #1F3402;
}

.div-mystore-template35 .info {    
    border: 0;    
    margin-left: 2px;
    border-radius: 3px;
    color: #1F3402;
    background-color: initial;
    font-size: 18px;        
}

/* Template 35 - Ends */

/* Template 36 - Starts */

.div-mystore-template36 {
    width: 100%;
    background-color: #F3E5B7;
    border-radius: 15px;
    /* font: Poppins; */
    letter-spacing: 0px;
    padding: 15px;
    margin-bottom: 30px;
}

.div-mystore-template36 .prod_amt {
    color: #fdae7c;
    font-size: 18px;
    font-weight: 700;
}

.div-mystore-template36 .col-md-8 h3,
.div-mystore-template36 .col-md-8 div span {
    color: #F13E0E;
    /* font-size: 18px; */
    font-size: 14px;
    font-weight: 700;
    min-height: 35px;
    max-height: 35px;
    overflow: hidden;
}

.div-mystore-template36 .col-md-8 p {
    font-size: 11px;
    max-height: 68px;
    min-height: 68px;
    overflow: hidden;
    margin-bottom: 12px;
    color: #333;
}

.div-mystore-template36 .col-md-4 {
    text-align: center;
}

.div-mystore-template36 .col-md-4 img {
    width: 100px;
    height: 100px;
    border-radius: 5px 30px;
    margin-bottom: 15px;
    margin-top: 10px;
}

.div-mystore-template36 .btn-myfront-add {
    border: 1px solid #F3E5B7;
    border-radius: 5px;
    background-color: #F13E0E;
    color: #F3E5B7;
    width: 100%;
    font-weight: 700;
    padding: 3px;
}

.div-mystore-template36 .div-offer-txt {
    right: 25px;
    top: 5px;
    color: #F3E5B7;
    background: #F13E0E;
}

.div-mystore-template36 .star_box {
    border: 1px solid #F13E0E;
    color: #F13E0E;
}

.div-mystore-template36 .info {    
    border: 0;    
    margin-left: 2px;
    border-radius: 3px;
    color: #F13E0E;
    background-color: initial;
    font-size: 18px;        
}

/* Template 36 - Ends */

/* Template 37 - Starts */

.div-mystore-template37 {
    width: 100%;
    background-color: #E6E1B4;
    border-radius: 15px;
    font: Poppins;
    letter-spacing: 0px;
    padding: 0px 0px 0px 15px;
    margin-bottom: 30px;
}

.div-mystore-template37 .prod_amt {
    color: #fdae7c;
    font-size: 18px;
    font-weight: 700;
}

.div-mystore-template37 .col-md-8 {
    padding: 15px;
}

.div-mystore-template37 .col-md-8 h3,
.div-mystore-template37 .col-md-8 div span {
    color: #696068;
    /* font-size: 18px; */
    font-size: 14px;
    font-weight: 700;
    min-height: 35px;
    max-height: 35px;
    overflow: hidden;
}

.div-mystore-template37 .col-md-4 div {

    background-image: url('https://rewardsly.io/members/img/menutemp/17-watches.png');
    /*background: url('../img/burger.jpg') no-repeat center center fixed; */
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    border-top-left-radius: 85% 15%;
    border-bottom-left-radius: 85% 15%;
    height: 100%;
}

.div-mystore-template37 .col-md-4 {
    padding-left: 0px;
}

.div-mystore-template37 .col-md-8 p {
    font-size: 11px;
    max-height: 68px;
    min-height: 68px;
    overflow: hidden;
    margin-bottom: 12px;
    color: #333;
}

.div-mystore-template37 .btn-myfront-add {
    border: 1px solid #E6E1B4;
    border-radius: 5px;
    background-color: #696068;
    color: #E6E1B4;
    width: 100%;
    font-weight: 700;
    padding: 3px;
}

.div-mystore-template37 .div-offer-txt {
    right: 30%;
    top: 2px;
    color: #E6E1B4;
    background: #696068;
}

.div-mystore-template37 .star_box {
    border: 1px solid #696068;
    color: #696068;
}

.div-mystore-template37 .info {    
    border: 0;    
    margin-left: 2px;
    border-radius: 3px;
    color: #696068;
    background-color: initial;
    font-size: 18px;        
}

/* Template 37 - Ends */

/* Template 38 - Starts */

.div-mystore-template38 {
    width: 100%;
    background-color: #C1CBD6;
    border-radius: 15px;
    font: Poppins;
    letter-spacing: 0px;
    padding: 0px 0px 0px 15px;
    margin-bottom: 30px;
}

.div-mystore-template38 .prod_amt {
    color: #fdae7c;
    font-size: 18px;
    font-weight: 700;
}

.div-mystore-template38 .col-md-8 {
    padding: 15px 15px 15px 0px;
}

.div-mystore-template38 .col-md-8 .row {
    margin-right: 0px;
}

.div-mystore-template38 .col-md-8 h3,
.div-mystore-template38 .col-md-8 div span {
    color: #B76403;
    /* font-size: 18px; */
    font-size: 14px;
    font-weight: 700;
    min-height: 35px;
    max-height: 35px;
    overflow: hidden;
}

.div-mystore-template38 .col-md-4 div {
     background-image: url('https://rewardsly.io/members/img/menutemp/18-wine.png');
    /*background: url('../img/burger.jpg') no-repeat center center fixed; */
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    border-top-right-radius: 85% 15%;
    border-bottom-right-radius: 85% 15%;
    height: 100%;
}

.div-mystore-template38 .col-md-4 {
    padding-left: 0px;
}

.div-mystore-template38 .col-md-8 p {
    font-size: 11px;
    max-height: 68px;
    min-height: 68px;
    overflow: hidden;
    margin-bottom: 12px;
    color: #333;
}

.div-mystore-template38 .btn-myfront-add {
    border: 1px solid #C1CBD6;
    border-radius: 5px;
    background-color: #B76403;
    color: #C1CBD6;
    width: 100%;
    font-weight: 700;
    padding: 3px;
}

.div-mystore-template38 .div-offer-txt {
    right: 25px;
    top: 5px;
    color: #C1CBD6;
    background: #B76403;
}

.div-mystore-template38 .star_box {
    border: 1px solid #B76403;
    color: #B76403;
}

.div-mystore-template38 .info {    
    border: 0;    
    margin-left: 2px;
    border-radius: 3px;
    color: #B76403;
    background-color: initial;
    font-size: 18px;        
}

/* Template 38 - Ends */

/* Template 39 - Starts */

.div-mystore-template39 {
    width: 100%;
    background-color: #741116;
    border-radius: 15px;
    font: Poppins;
    letter-spacing: 0px;
    padding: 0px 0px 0px 15px;
    margin-bottom: 30px;
}

.div-mystore-template39 .prod_amt {
    color: #fdae7c;
    font-size: 18px;
    font-weight: 700;
}

.div-mystore-template39 .col-md-8 {
    padding: 15px;
}

.div-mystore-template39 .col-md-8 h3,
.div-mystore-template39 .col-md-8 div span {
    color: #C0B686;
    /* font-size: 18px; */
    font-size: 14px;
    font-weight: 700;
    min-height: 35px;
    max-height: 35px;
    overflow: hidden;
}

.div-mystore-template39 .col-md-4 div {
     
    background-image: url('https://rewardsly.io/members/img/menutemp/19-glass-making.png');
    /*background: url('../img/burger.jpg') no-repeat center center fixed; */
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    /* border-top-left-radius: 85% 30%; */
    border-bottom-left-radius: 85% 30%;
    height: 100%;
}

.div-mystore-template39 .col-md-4 {
    padding-left: 0px;
}

.div-mystore-template39 .col-md-8 p {
    font-size: 11px;
    max-height: 68px;
    min-height: 68px;
    overflow: hidden;
    margin-bottom: 12px;
    color: #fff;
}

.div-mystore-template39 .btn-myfront-add {
    border: 1px solid #741116;
    border-radius: 5px;
    background-color: #C0B686;
    color: #741116;
    width: 100%;
    font-weight: 700;
    padding: 3px;
}

.div-mystore-template39 .div-offer-txt {
    right: 34%;
    top: 2px;
    color: #741116;
    background: #C0B686;
}

.div-mystore-template39 .star_box {
    border: 1px solid #C0B686;
    color: #C0B686;
}

.div-mystore-template39 .info {    
    border: 0;    
    margin-left: 2px;
    border-radius: 3px;
    color: #C0B686;
    background-color: initial;
    font-size: 18px;        
}

/* Template 39 - Ends */

/* Template 40 - Starts */

.div-mystore-template40 {
    width: 100%;
    background-color: #3E4E6C;
    border-radius: 15px;
    font: Poppins;
    letter-spacing: 0px;
    padding: 0px 0px 0px 15px;
    margin-bottom: 30px;
}

.div-mystore-template40 .prod_amt {
    color: #fdae7c;
    font-size: 18px;
    font-weight: 700;
}

.div-mystore-template40 .col-md-8 {
    padding: 15px 15px 15px 0px;
}

.div-mystore-template40 .col-md-8 .row {
    margin-right: 0px;
}

.div-mystore-template40 .col-md-8 h3,
.div-mystore-template40 .col-md-8 div span {
    color: #FBF4E8;
    /* font-size: 18px; */
    font-size: 14px;
    font-weight: 700;
    min-height: 35px;
    max-height: 35px;
    overflow: hidden;
}

.div-mystore-template40 .col-md-4 div {
    background-image: url('https://rewardsly.io/members/img/menutemp/20-wedding-planning.png');
    /*background: url('../img/burger.jpg') no-repeat center center fixed; */
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    /* border-top-right-radius: 85% 30%; */
    border-bottom-right-radius: 85% 30%;
    height: 100%;
}

.div-mystore-template40 .col-md-4 {
    padding-left: 0px;
}

.div-mystore-template40 .col-md-8 p {
    font-size: 11px;
    max-height: 68px;
    min-height: 68px;
    overflow: hidden;
    margin-bottom: 12px;
    color: #fff;
}

.div-mystore-template40 .btn-myfront-add {
    border: 1px solid #3E4E6C;
    border-radius: 5px;
    background-color: #FBF4E8;
    color: #3E4E6C;
    width: 100%;
    font-weight: 700;
    padding: 3px;
}

.div-mystore-template40 .div-offer-txt {
    right: 25px;
    top: 5px;
    color: #3E4E6C;
    background: #FBF4E8;
}

.div-mystore-template40 .star_box {
    border: 1px solid #FBF4E8;
    color: #FBF4E8;
}

.div-mystore-template40 .info {    
    border: 0;    
    margin-left: 2px;
    border-radius: 3px;
    color: #FBF4E8;
    background-color: initial;
    font-size: 18px;        
}

/* Template 40 - Ends */